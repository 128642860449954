import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import {
  setManualDataMultiIds,
  useTimeframeDispatch,
  useTimeframeState,
} from "@/context/Timeframe";
import { _groupBy, _orderBy } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import moment from "moment";
import React, { useMemo } from "react";
import { CheckTreePicker } from "rsuite";

interface iProps {}

const ManualDataTreePickerMulti: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataMultiIds },
  } = useTimeframeState();
  const timeframeDispatch = useTimeframeDispatch();

  const groupedByYear = useMemo(() => {
    const grouped = _groupBy(activeProperty.manualData, (md: tPropertyManualData) =>
      moment(md.from).year()
    );
    Object.keys(grouped).forEach((year) => {
      grouped[year] = _orderBy(
        grouped[year],
        (md: tPropertyManualData) => moment(md.from).valueOf(),
        "asc"
      );
    });
    return grouped;
  }, [activeProperty.manualData]);

  const years = Object.keys(groupedByYear).sort();

  return (
    <CheckTreePicker
      data={years.map((year) => ({
        label: year,
        value: `YEAR$${year}`,
        children: groupedByYear[year].map((md) => ({
          label: `${trans(
            "general.date_.months_." + moment(md.from).format("MMMM").toLowerCase()
          )} - ${year}`,
          value: md._id,
        })),
      }))}
      value={manualDataMultiIds}
      onChange={(rawIds) => {
        const ids: string[] = [];

        (rawIds as string[]).forEach((id) => {
          if (id.indexOf("YEAR$") >= 0) {
            ids.push(...groupedByYear[id.split("$")[1]].map((md) => md._id));
          } else {
            ids.push(id);
          }
        });
        setManualDataMultiIds(timeframeDispatch, ids);
      }}
    />
  );
};

export default ManualDataTreePickerMulti;
