import { ReactComponent as DirectionsWalkIcon } from "@/assets/icons/directions_walk.svg";
import { ReactComponent as EmojiPeopleIcon } from "@/assets/icons/emoji_people.svg";
import BasicCard from "@/components/Cards/BasicCard";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useTimeframeState } from "@/context/Timeframe";
import useEffectSafe from "@/hooks/useEffectSafe";
import { tIconElement } from "@/interfaces/icon";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

type tCheckInData = {
  checkIn: { total: number; toDate: number };
  checkOut: { total: number; toDate: number };
};

interface iProps {}

const CheckInCheckOutWrapped: React.FC<iProps> = () => {
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  const [mainQuery, homologueQuery] = useQueries<tCheckInData[], any>({
    queries: [
      [moment(sensorDataTimeframe[0]).toISOString(), moment(sensorDataTimeframe[1]).toISOString()],
      [
        moment(sensorDataTimeframe[0]).subtract(1, "year").toISOString(),
        moment(sensorDataTimeframe[1]).subtract(1, "year").toISOString(),
      ],
    ].map(([from, to]) => ({
      queryKey: [`check-in-out-${activeProperty._id}|${from}|${to}`],
      enabled: false,
      queryFn: async ({ signal }: { signal: AbortSignal }) => {
        if (activeSubscriptionIsStarter)
          return { checkIn: { toDate: 0, total: 0 }, checkOut: { toDate: 0, total: 0 } };
        return axios
          .get(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/occupancy/check-in-out`,
              USE_MONOLITH_SERVERLESS
            ),
            {
              params: {
                from,
                to,
              },
              signal,
            }
          )
          .then((res) => {
            return res.data.data;
          });
      },
    })),
  });

  useEffectSafe(() => {
    mainQuery.refetch();
    homologueQuery.refetch();
  }, [sensorDataTimeframe]);

  const list: { key: keyof tCheckInData; label: string; icon: tIconElement }[] = [
    {
      key: "checkIn",
      label: trans("pages.overview.sections.summary.cards.checkInOut.label_checkIn"),
      icon: DirectionsWalkIcon,
    },
    {
      key: "checkOut",
      label: trans("pages.overview.sections.summary.cards.checkInOut.label_checkOut"),
      icon: EmojiPeopleIcon,
    },
  ];

  return (
    <>
      {list.map(({ key, label, icon }) => {
        return (
          <BasicCard
            key={key}
            hover
            onClick={() => {
              navigate("/guests");
            }}
            {...{
              icon,
              label,
              ...(mainQuery.isError
                ? null
                : mainQuery.isSuccess
                ? {
                    value: `${mainQuery.data[key].toDate}/${mainQuery.data[key].total}`,
                    ...(homologueQuery.isError
                      ? null
                      : homologueQuery.isSuccess
                      ? {
                          comparison: {
                            baseValue: homologueQuery.data[key].total,
                            value: mainQuery.data[key].total,
                          },
                        }
                      : {
                          comparisonLoading: true,
                        }),
                  }
                : { valueLoading: true, value: "", comparisonLoading: true }),
            }}
          />
        );
      })}
    </>
  );
};

const CheckInCheckOut: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  if (activeSubscriptionIsStarter) return null;

  if (
    !activeProperty.settings.pages.dashboard.checkIn.show &&
    !activeProperty.settings.pages.dashboard.checkOut.show
  )
    return null;

  return <CheckInCheckOutWrapped />;
};

export default CheckInCheckOut;
