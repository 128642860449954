import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import BasicCard from "@/components/Cards/BasicCard";
import { iIconBgProps } from "@/components/Icons/IconBg";
import PageSection from "@/components/PageSection";
import { iTextProps } from "@/components/Text";
import InterTag from "@/components/Text/Inter";
import { toTextProps } from "@/components/Text/utils";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useGetConsumptionTotal, {
  tTotalConsumption,
} from "@/hooks/consumption/useGetConsumptionTotal";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { TE, TW } from "@/models/measures";
import { tReservation } from "@/models/reservation";
import { COLORS } from "@/utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_MASS_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "@/utils/convertUnits/";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { propertyGetBaseMeasuresTrackedV2 } from "@/utils/property/measures";
import { getMeasureInfo } from "@/utils/measures";
import moment from "moment";
import React from "react";

type tCardMeasures = {
  label: string | iTextProps;
  tooltip?: string;
  icon: iIconBgProps;
  key: string;
  valueFromMeasuresRequest(data: tTotalConsumption): number;
  unit: string;
  converter: nUnitConverter.tFunction;
  loading: boolean;
};

interface iProps extends iAverageConsumptionProps {}

const AverageConsumptionWrapped1: React.FC<iProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const conversionFactors = useGetDefaultConverionFactors("PT");

  const { room } = reservation;

  const [measuresRequest2] = useGetConsumptionTotal("stay.average", [
    {
      timeframe: [
        moment(reservation.startAt).toISOString(),
        moment(reservation.endAt).toISOString(),
      ],
      spaces: [room],
      enabled: true,
    },
  ]);

  const measuresList: tCardMeasures[] = [];

  const trackedBaseMeasures = propertyGetBaseMeasuresTrackedV2(activeProperty);

  if (trackedBaseMeasures.e) {
    measuresList.push({
      label: trans("general.measures_.electricity"),
      icon: {
        Element: ElectricBoltIcon,
        fill: getMeasureInfo(TE).color,
        backgroundTrasparency: 20,
      },
      key: "e",
      loading: measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return data.e || 0;
      },
      unit: DEFAULT_ENERGY_MEASURE_UNIT,
      converter: convertEnergyUnit,
    });
  }

  if (trackedBaseMeasures.w) {
    measuresList.push({
      label: trans("general.measures_.water"),
      icon: { Element: WaterDropIcon, fill: getMeasureInfo(TW).color },
      key: "w",
      loading: measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return data.w || 0;
      },
      unit: DEFAULT_WATER_MEASURE_UNIT,
      converter: convertVolumeUnit,
    });
  }
  if (measuresList.length) {
    measuresList.unshift({
      label: { text: trans("general.co_2_emissions"), asHTML: true },
      icon: { Element: EcoIcon, fill: COLORS.esg_environment },
      key: "co2",
      loading: conversionFactors.isLoading || measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return (
          ((data.e || 0) / 1000) * conversionFactors.data.electricity_kWh_to_kgCO2e +
          ((data.w || 0) / 1000) * conversionFactors.data.water_m3_to_kgCO2e
        );
      },
      unit: DEFAULT_MASS_MEASURE_UNIT,
      converter: convertMassUnit,
    });
  } else {
    return <InterTag text={trans("No consumption being tracked. Is the space setup?")} />;
  }

  const renderCards = () => {
    if (measuresRequest2.isError)
      return (
        <>
          {measuresList.map(({ label, icon, key }) => {
            return (
              <BasicCard
                key={key}
                hover
                icon={icon}
                label={{ ...toTextProps(label), asHTML: true }}
                error={measuresRequest2.error?.message || ""}
                reload
                onReload={(e) => {
                  e.stopPropagation();
                  measuresRequest2.refetch();
                }}
              />
            );
          })}
        </>
      );

    if (measuresRequest2.isSuccess)
      return (
        <>
          {measuresList.map(({ label, icon, key, unit, valueFromMeasuresRequest, converter }) => {
            const value = valueFromMeasuresRequest(measuresRequest2.data as tTotalConsumption);
            const converted = converter(value, { from: unit });
            return (
              <BasicCard
                key={key}
                hover
                icon={icon}
                value={converted.value}
                valueUnit={converted.unitInHTML}
                valueProps={{ asHTML: true }}
                label={{ ...toTextProps(label) }}
              />
            );
          })}
        </>
      );
    return (
      <>
        {measuresList.map(({ label, icon, key }) => (
          <BasicCard
            key={key}
            hover
            icon={icon}
            value={""}
            valueLoading
            comparisonLoading
            label={{ ...toTextProps(label), asHTML: true }}
          />
        ))}
      </>
    );
  };

  return <div className="flex flex-row gap-4">{renderCards()}</div>;
};

interface iAverageConsumptionProps {
  reservation: tReservation;
}

const AverageConsumption: React.FC<iAverageConsumptionProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  return (
    <PageSection icon={QueryStatsIcon} title={trans("general.consumption")}>
      <AverageConsumptionWrapped1 {...{ reservation }} />
    </PageSection>
  );
};

export default AverageConsumption;
