import { ReactComponent as ContentCopyIcon } from "@/assets/icons/content_copy.svg";
import { ReactComponent as PeriodIcon } from "@/assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as CheckOutIcon } from "@/assets/icons/event_leaving.svg";
import { ReactComponent as CheckInIcon } from "@/assets/icons/event_upcoming.svg";
import { ReactComponent as GroupIcon } from "@/assets/icons/group.svg";
import { ReactComponent as KeyIcon } from "@/assets/icons/key.svg";
import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/meeting_room.svg";
import { ReactComponent as PersonIcon } from "@/assets/icons/person.svg";
import { ReactComponent as VerifiedIcon } from "@/assets/icons/verified.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/icons/visibility.svg";
import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import NoPermissions from "@/components/Pages/NoPermissions";
import PageSection from "@/components/PageSection";
import SimpleFilterButton from "@/components/RsuiteWrapper/SimpleButton/buttons/filterButton";
import SimplePagination, { usePagination } from "@/components/RsuiteWrapper/SimplePagination";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import Segmentation from "@/components/Segmentation";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useApiRequest from "@/hooks/apiRequests/useApiRequest";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import useEffectSafe from "@/hooks/useEffectSafe";
import useScreenSize from "@/hooks/useScreenSize";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { tGuest } from "@/models/guest";
import { tPropertyAwardId } from "@/models/property";
import { tReservation, tReservationId } from "@/models/reservation";
import {
  apiAddress,
  constructApiAddress,
  SERVERS_HOSTNAMES,
  USE_MONOLITH_SERVERLESS,
} from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { propertyFindSpace } from "@/utils/property/spaces";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { copyToClipboard } from "@/utils/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { findIndex, floor, min, pick } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "rsuite";
import PageTitle from "../../root/components/pageTitle";
import AddReservation from "../addReservation";
import AwardGuestModal from "../awardGuestModal";
import FiltersDrawer from "../filtersDrawer";
import Unlock from "../unlock";
import NoReservations from "./noReservations";

const LS_DATA_FILTERS = "__r-filters__";

type tTopLevelFilter = {
  key: "upcoming" | "canceled" | "ongoing" | "past";
  label: string;
};

type tSorterKey = "startAt" | "endAt" | "numberOfGuests" | "numberOfDays";
type tSorterDirection = "asc" | "desc";

type tStateData = {
  filters: {
    rooms: string[];
    numberOfGuests: [number, number];
    numberOfDays: [number, number];
    checkInFrom: Date | null | undefined;
    checkInTo: Date | null | undefined;
    checkOutFrom: Date | null | undefined;
    checkOutTo: Date | null | undefined;
    periodFrom: Date | null | undefined;
    periodTo: Date | null | undefined;
  };
  sorters: {
    key: tSorterKey;
    direction: tSorterDirection;
  } | null;
};

type tState = {
  data: tStateData;
  initialized: boolean;
};

type tAction =
  | { type: "set default filters"; filters: Partial<tStateData["filters"]> }
  | { type: "set filters"; filters: tStateData["filters"] }
  | { type: "set sorters"; sorters: tStateData["sorters"] }
  | { type: "set data"; data: tStateData };

const initialState: tState = {
  data: {
    filters: {
      rooms: [],
      numberOfGuests: [1, 10],
      numberOfDays: [1, 10],
      checkInFrom: null,
      checkInTo: null,
      checkOutFrom: null,
      checkOutTo: null,
      periodFrom: null,
      periodTo: null,
    },
    sorters: { key: "startAt", direction: "desc" },
  },
  initialized: false,
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      return { ...state, data };
    }
    case "set default filters": {
      const { filters } = action;
      return {
        ...state,
        initialized: true,
        data: {
          ...state.data,
          filters: { ...state.data.filters, ...filters },
        },
      };
    }
    case "set filters": {
      const { filters } = action;
      localStorage.setItem(LS_DATA_FILTERS, JSON.stringify(filters));
      return { ...state, data: { ...state.data, filters } };
    }
    case "set sorters": {
      const { sorters } = action;
      return { ...state, data: { ...state.data, sorters } };
    }
    default:
      return { ...state };
  }
};

interface iProps {
  outletContainerWidth: number;
}

const ReservationsWrapped: React.FC<iProps> = ({ outletContainerWidth }) => {
  const permissionsList = usePermissions();
  const { activeProperty } = usePropertyState();
  const navigate = useNavigate();
  const toaster = useSimpleToaster();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [awardGuestModal, setAwardGuestModal] = useState<
    | {
        open: true;
        reservationId: tReservationId;
      }
    | { open: false }
  >({
    open: false,
  });
  const awardGuestRequest = useApiRequest();
  const deleteReservationRequest = useApiRequest();

  const spacesStringified = JSON.stringify(activeProperty.spaces);
  useEffect(() => {
    dispatch({
      type: "set default filters",
      filters: { rooms: activeProperty.spaces.map(({ _id }) => _id) },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spacesStringified]);

  const reservationsRequest = useGetRequest<{
    list: tReservation[];
    counts: Record<tTopLevelFilter["key"], number | null>;
  }>({
    list: [],
    counts: { upcoming: null, canceled: null, ongoing: null, past: null },
  });
  const {
    pending: reservationsRequestPending,
    resolve: reservationsRequestResolve,
    reject: reservationsRequestReject,
  } = reservationsRequest;

  const [filtersDrawer, setFiltersDrawer] = useState<{ open: boolean }>({
    open: false,
  });
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(1);

  const guestsRequest = useGetRequest<{
    list: tGuest[];
  }>({ list: [] });
  const {
    pending: guestsRequestPending,
    resolve: guestsRequestResolve,
    reject: guestsRequestReject,
  } = guestsRequest;

  const { setTotal, setActivePage, setLimit, ...paginationProps } = usePagination({
    total: 0,
    limit: 10,
    activePage: 1,
  });
  const activePageRef = useRef(paginationProps.activePage);
  const pageLimitRef = useRef(paginationProps.limit);
  const [loadReservationsHelper, setLoadReservationsHelper] = useState(false);
  const loadReservationsHelperRef = useRef(!loadReservationsHelper);

  const { trans } = useLocalizationState();
  const tableRef = useRef<any>();

  const TOP_LEVEL_FILTERS: tTopLevelFilter[] = useMemo(() => {
    return [
      {
        key: "past",
        label: "pages.guests.table.segments.archive",
      },
      {
        key: "ongoing",
        label: "pages.guests.table.segments.ongoing",
      },
      {
        key: "upcoming",
        label: "pages.guests.table.segments.coming_up",
      },
    ];
  }, []);

  const onSelectTopLevelFilter = (key: string) => {
    if (!reservationsRequest.isLoading) {
      setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key));
    }
  };

  useEffect(() => {
    const abortStays = new AbortController();
    const abortGuests = new AbortController();
    let requestInBoundStays = false;
    let requestInBoundGuests = false;

    const loadFunction = (hotelId: string, page: number, pageSize: number) => {
      const params: {
        sort: tStateData["sorters"];
        topLevelFilters: Pick<tTopLevelFilter, "key">[];
        filters: tStateData["filters"];
        page: number;
        pageSize: number;
      } = {
        sort: state.data.sorters,
        topLevelFilters: [pick(TOP_LEVEL_FILTERS[topLevelFilterIndex], ["key"])],
        filters: { ...state.data.filters },
        page,
        pageSize,
      };
      reservationsRequestPending();
      axios
        .get(constructApiAddress(`/v2/hotels/${hotelId}/stays`, USE_MONOLITH_SERVERLESS), {
          params,
          signal: abortStays.signal,
          onDownloadProgress: () => {
            requestInBoundStays = true;
          },
        })
        .then((res) => {
          const {
            data: { reservations, counts },
          } = res;
          reservationsRequestResolve({
            list: reservations,
            counts,
          });
          setTotal(counts[TOP_LEVEL_FILTERS[topLevelFilterIndex].key]);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          reservationsRequestReject(`STAYS: ${error}`, true);
        });

      guestsRequestPending();

      axios
        .get(constructApiAddress(`/v2/hotels/${hotelId}/stays/guests`, USE_MONOLITH_SERVERLESS), {
          params,
          signal: abortGuests.signal,
          onDownloadProgress: (e) => {
            requestInBoundGuests = true;
          },
        })
        .then((res) => {
          const {
            data: { guests },
          } = res;
          guestsRequestResolve({ list: guests });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          guestsRequestReject(`GUESTS: ${error}`, true);
        });
    };

    if (activeProperty._id && state.initialized) {
      if (
        activePageRef.current !== paginationProps.activePage ||
        pageLimitRef.current !== paginationProps.limit ||
        loadReservationsHelperRef.current !== loadReservationsHelper
      ) {
        activePageRef.current = paginationProps.activePage;
        pageLimitRef.current = paginationProps.limit;
        loadReservationsHelperRef.current = loadReservationsHelper;
        loadFunction(activeProperty._id, paginationProps.activePage, paginationProps.limit);
      }
    }

    return () => {
      if (requestInBoundStays) abortStays.abort();
      if (requestInBoundGuests) abortGuests.abort();
    };
  }, [
    activeProperty._id,
    paginationProps.activePage,
    paginationProps.limit,
    loadReservationsHelper,
    state.initialized,
    state.data.sorters,
    state.data.filters,
    topLevelFilterIndex,
    setTotal,
    trans,
    TOP_LEVEL_FILTERS,
    reservationsRequestPending,
    guestsRequestPending,
    reservationsRequestResolve,
    reservationsRequestReject,
    guestsRequestResolve,
    guestsRequestReject,
  ]);

  useEffectSafe(() => {
    if (activePageRef.current !== 1) {
      setActivePage(1);
    } else {
      setLoadReservationsHelper((prev) => !prev);
    }
  }, [topLevelFilterIndex, state.data, setActivePage]);

  const segmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={TOP_LEVEL_FILTERS[topLevelFilterIndex].key}
            onSelect={onSelectTopLevelFilter}
            options={TOP_LEVEL_FILTERS.map((f) => {
              const { key, label } = f;
              return {
                key,
                label: { text: trans(label) },
                count: reservationsRequest.data.counts[key],
                disabled: reservationsRequest.isLoading,
              };
            })}
          />
        </Flex>
        <Flex
          column
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
        >
          <Flex row gap={8}>
            {hasPermission(permissionsList, "create:stays") && (
              <AddReservation afterCreate={() => setLoadReservationsHelper((prev) => !prev)} />
            )}
            <SimpleFilterButton
              onClick={() => setFiltersDrawer((prev) => ({ ...prev, open: true }))}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const handleConfirmFilters = (
    rooms: string[],
    numberOfGuests: [number, number],
    numberOfDays: [number, number],
    checkInFrom: Date | null | undefined,
    checkInTo: Date | null | undefined,
    checkOutFrom: Date | null | undefined,
    checkOutTo: Date | null | undefined,
    periodFrom: Date | null | undefined,
    periodTo: Date | null | undefined
  ) => {
    dispatch({
      type: "set filters",
      filters: {
        rooms,
        numberOfGuests,
        numberOfDays,
        checkInFrom,
        checkInTo,
        checkOutFrom,
        checkOutTo,
        periodFrom,
        periodTo,
      },
    });
    setFiltersDrawer({ open: false });
  };

  const handleDeleteReservation = (reservationId: tReservationId) => {
    if (activeProperty._id) {
      deleteReservationRequest.pending();
      axios
        .delete(
          constructApiAddress(
            `/v2/hotels/${activeProperty._id}/stays/${reservationId}`,
            USE_MONOLITH_SERVERLESS
          )
        )
        .then(() => {
          deleteReservationRequest.resolve();
          setLoadReservationsHelper((prev) => !prev);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          deleteReservationRequest.reject(error);
          toaster.error(error);
        });
    }
  };

  const options = (dataKey: string, reservation: tReservation) => {
    const viewOption = (onClick: () => void) => {
      return {
        key: "view",
        label: trans("pages.guests.table.options.view"),
        onClick() {
          navigate(`/reservations/${reservation[dataKey as keyof tReservation]}`, {
            state: { reservation },
          });
        },
        icon: VisibilityIcon,
        show: true,
      };
    };

    const awardGuestOption = {
      key: "award guest",
      label: trans("pages.guests.table.options.give_award"),
      onClick() {
        setAwardGuestModal({ open: true, reservationId: reservation._id });
      },
      icon: VerifiedIcon,
    };

    const viewMainGuestOption = {
      key: "view guest",
      label: trans("pages.guests.table.options.view_guest"),
      icon: PersonIcon,
      onClick() {
        navigate(`/guests/${reservation.mainGuest._id || reservation.mainGuest.name}`, {
          state: { reservation },
        });
      },
      show: reservation.mainGuest._id !== undefined,
    };

    const copyIdToClipboard = {
      key: "copy id to clipboard",
      label: trans("pages.guests.table.options.copy_id"),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(reservation._id);
      },
      show: apiAddress() === SERVERS_HOSTNAMES.monolith.local,
    };

    const copyCodeToClipboard = {
      key: "copy code to clipboard",
      label: trans("pages.guests.table.options.copy_code"),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(reservation.code);
      },
    };

    const deleteReservation = {
      key: "delete",
      label: { text: trans("general.remove"), color: COLORS.hot },
      onClick() {
        handleDeleteReservation(reservation._id);
      },
      icon: { Element: DeleteIcon, fill: COLORS.hot },
    };

    switch (TOP_LEVEL_FILTERS[topLevelFilterIndex].key) {
      case "upcoming":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "ongoing":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "past":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "canceled":
        return [];
      default:
        return [];
    }
  };

  const handleSortColumn = (sortColumn: string, sortType?: string) => {
    if (sortColumn)
      dispatch({
        type: "set sorters",
        sorters: {
          key: sortColumn as tSorterKey,
          direction: sortType as tSorterDirection,
        },
      });
    else
      dispatch({
        type: "set sorters",
        sorters: null,
      });
  };

  const handleDoubleClick = (reservation: tReservation) => {
    navigate(`/reservations/${reservation._id}`, { state: { reservation } });
  };

  const confirmAwardGuest = (awards: { awardId: tPropertyAwardId; count: number }[]) => {
    if (awardGuestModal.open && awardGuestModal.reservationId) {
      awardGuestRequest.pending();
      axios
        .put(
          constructApiAddress(
            `/v2/hotels/${activeProperty._id}/stays/${awardGuestModal.reservationId}/awards`,
            USE_MONOLITH_SERVERLESS
          ),
          { awards }
        )
        .then((res) => {
          toaster.success(trans("pages.guests.confirmed_stay_awarded"));
          awardGuestRequest.resolve();
          setAwardGuestModal({ open: false });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          toaster.error(error);
          awardGuestRequest.reject(error, true);
        });
    }
  };

  const tableWidth = outletContainerWidth;
  const idWidth = floor(0.07 * tableWidth);
  const mainGuestWidth = floor(0.22 * tableWidth);
  const guestsWidth = floor(0.1 * tableWidth);
  const roomWidth = floor(0.1 * tableWidth);
  const checkInWidth = floor(0.15 * tableWidth);
  const checkOutWidth = floor(0.15 * tableWidth);
  const periodWidth = floor(0.1 * tableWidth);

  const tableIsLoading = deleteReservationRequest.isLoading || reservationsRequest.isLoading;

  const tableHeight = tableIsLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([10, reservationsRequest.data.list.length]) as number) * TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  return (
    <Fragment>
      <AwardGuestModal
        onClose={() => setAwardGuestModal({ open: false })}
        open={awardGuestModal.open}
        onConfirm={confirmAwardGuest}
        isLoading={awardGuestRequest.isLoading}
      />
      <FiltersDrawer
        initialValues={{ ...state.data.filters }}
        open={filtersDrawer.open}
        onClose={() => setFiltersDrawer((prev) => ({ ...prev, open: false }))}
        onConfirm={handleConfirmFilters}
      />

      <div>
        <div>{segmentation()}</div>
        <div className="table-wrapper" style={{ marginTop: "16px" }}>
          <Table
            onSortColumn={handleSortColumn}
            ref={tableRef}
            height={tableHeight}
            data={reservationsRequest.data.list}
            id={`RESERVATIONS.TABLE`}
            rowHeight={TABLE_ROW_HEIGHT.M}
            headerHeight={TABLE_HEADER_HEIGHT}
            loading={tableIsLoading}
            {...{
              ...(state.data.sorters
                ? {
                    sortColumn: state.data.sorters.key,
                    sortType: state.data.sorters.direction,
                  }
                : {}),
            }}
          >
            <Table.Column width={idWidth}>
              <SimpleHeaderCell icon={KeyIcon} name={trans("general.code")} />
              <SimpleKeyValueCell onDoubleClick={handleDoubleClick} dataKey="code" />
            </Table.Column>
            <Table.Column width={mainGuestWidth}>
              <SimpleHeaderCell icon={PersonIcon} name={trans("general.main_guest")} />
              <BaseCell>
                {(rowData: tReservation, index: number) => {
                  const { mainGuest } = rowData;
                  const { name, _id, email } = mainGuest;

                  if (name || email) {
                    return (
                      <Flex column between gap={4}>
                        <InterTag
                          size={12}
                          color={COLORS.secondary}
                          text={name || "------------"}
                        />
                        <InterTag size={10} color={COLORS.gray} text={email || " "} />
                      </Flex>
                    );
                  }

                  if (_id) {
                    if (guestsRequest.isLoading) return <Loader size="xs" />;
                    if (guestsRequest.isResolved) {
                      const guest = guestsRequest.data.list[index];

                      if (!guest) return <Flex></Flex>;
                      return (
                        <Flex column between gap={4}>
                          <InterTag size={12} color={COLORS.secondary} text={guest.name} />
                          <InterTag size={10} color={COLORS.gray} text={guest.email} />
                        </Flex>
                      );
                    }
                  }

                  return null;
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column sortable width={guestsWidth}>
              <SimpleHeaderCell icon={GroupIcon} name={trans("general.guests")} />
              <SimpleKeyValueCell onDoubleClick={handleDoubleClick} dataKey="numberOfGuests" />
            </Table.Column>
            <Table.Column width={roomWidth}>
              <SimpleHeaderCell icon={MeetingRoomIcon} name={trans("general.unit")} />
              <SimpleKeyValueCell
                textProps={(rowData) => ({
                  hoverUnderline: true,
                  onClick: () => {
                    navigate(`/spaces/${rowData.room}`);
                  },
                })}
                onDoubleClick={handleDoubleClick}
                dataKey="room"
                tooltipDisplay
                textFunction={(rowData: tReservation) => {
                  const space = propertyFindSpace(activeProperty, rowData.room);
                  if (!space) return rowData.room;

                  return space?.name;
                }}
              />
            </Table.Column>
            <Table.Column sortable width={checkInWidth}>
              <SimpleHeaderCell icon={CheckInIcon} name={trans("general.check-in")} />
              <SimpleDateCell onDoubleClick={handleDoubleClick} dataKey="startAt" />
            </Table.Column>
            <Table.Column sortable width={checkOutWidth}>
              <SimpleHeaderCell icon={CheckOutIcon} name={trans("general.check-out")} />
              <SimpleDateCell onDoubleClick={handleDoubleClick} dataKey="endAt" />
            </Table.Column>
            <Table.Column sortable width={periodWidth}>
              <SimpleHeaderCell icon={{ Element: PeriodIcon }} name={trans("general.period")} />
              <BaseCell dataKey="numberOfDays" onDoubleClick={handleDoubleClick}>
                {(rowData: tReservation) => {
                  const numberOfNights = moment(rowData.endAt)
                    .endOf("day")
                    .diff(moment(rowData.startAt).startOf("day"), "days");
                  return (
                    <Flex column>
                      <InterTag
                        size={12}
                        color={COLORS.secondary}
                        text={trans(
                          numberOfNights === 1
                            ? "general.x_number_of_nights.singular"
                            : "general.x_number_of_nights.plural",
                          { parameters: [numberOfNights] }
                        )}
                      />
                    </Flex>
                  );
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <SimpleHeaderCell />
              <SimpleActionCell onDoubleClick={handleDoubleClick} options={options} dataKey="_id" />
            </Table.Column>
          </Table>
          {!reservationsRequest.isLoading && reservationsRequest.data.list.length === 0 && (
            <NoReservations>
              <AddReservation afterCreate={() => setLoadReservationsHelper((prev) => !prev)} />
            </NoReservations>
          )}
        </div>
        <Flex right style={{ marginTop: "16px" }}>
          <SimplePagination
            {...{ ...paginationProps, setActivePage, setLimit }}
            layout={["pager"]}
          />
        </Flex>
      </div>
    </Fragment>
  );
};

const ReservationsGuarded: React.FC = () => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const permissionsList = usePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!hasPermission(permissionsList, "read:stays")) {
    return <NoPermissions />;
  }

  if (!oc) return null;
  return (
    <Flex column gap={20}>
      <PageSection
        title={trans("pages.guests.table.title")}
        description={trans("pages.guests.table.description")}
        icon={GroupIcon}
      />
      <ReservationsWrapped outletContainerWidth={oc.width} />
      <PageBottomPadding />
    </Flex>
  );
};

const Reservations: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <PageTitle page={trans("general.guests")} />
      <IpValidatorGuard>
        <ReservationsGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default Reservations;
