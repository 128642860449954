import { nRequestStatus } from "@/interfaces/apiCalls";
import { REQUEST_STATUS } from "./apiCall";

export const renderRequestedData = (
  status: nRequestStatus.tStatus,
  elements: Partial<Record<nRequestStatus.tStatus, () => JSX.Element>>
) => {
  if (status === REQUEST_STATUS.IDLE) {
    if (elements.idle) return elements.idle();
  }
  if (status === REQUEST_STATUS.PENDING) {
    if (elements.pending) return elements.pending();
  }
  if (status === REQUEST_STATUS.RESOLVED) {
    if (elements.resolved) return elements.resolved();
  }
  if (status === REQUEST_STATUS.REJECTED) {
    if (elements.rejected) return elements.rejected();
  }

  return null;
};
