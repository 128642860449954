import DataTypeToggle from "@/components/DataTypeToggle";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { useDataType } from "@/context/DataType";
import { usePropertyState } from "@/context/Property/hooks";
import { propertyActiveSubscriptionTypeIsEnterprise } from "@/utils/property/subscriptions";
import React from "react";
import HistoricData from "./historicData";
import LiveData from "./liveData";

export const CONSUMPTION_PAGE_SECONDARY_TOP_BAR_ELEMENT_ID = "consumption-page-secondary-top-bar";

const Wrapped: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const dataTypeContext = useDataType();

  return (
    <>
      {!propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty }) ||
      !dataTypeContext.dataTypeIsLive ? (
        // <Archives />
        <HistoricData />
      ) : (
        <LiveData />
      )}
    </>
  );
};

const ConsumptionGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsEnterprise = propertyActiveSubscriptionTypeIsEnterprise({
    property: activeProperty,
  });

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row gap-2 items-center">
          {activeSubscriptionIsEnterprise && <DataTypeToggle />}
          <div id={CONSUMPTION_PAGE_SECONDARY_TOP_BAR_ELEMENT_ID}></div>
        </div>
      </SecondaryTopBar>
      <Wrapped />
    </div>
  );
};

const Consumption: React.FC = () => {
  return (
    <IpValidatorGuard>
      <ConsumptionGuarded />
    </IpValidatorGuard>
  );
};

export default Consumption;
