import React from "react";
import { LocalizationStateContext } from "../";

const useLocalizationState = () => {
  const context = React.useContext(LocalizationStateContext);

  if (!context) throw Error("useLocalizationState must be used within LocalizationStateContext");

  return context;
};

export default useLocalizationState;
