import React from "react";
import WithContextProvider from "@/hoc/withProvider";
import SpacesConsumption from "./consumption";
import SpacesContextProvider from "./context";
import SpacesTabTable from "./table";

const SpacesTab: React.FC = () => {
  return (
    <div className="flex flex-col 2xl:flex-row gap-5">
      <div className="flex-1">
        <SpacesConsumption />
      </div>
      <div className="flex-1">
        <SpacesTabTable />
      </div>
      <div />
    </div>
  );
};

export default WithContextProvider(SpacesContextProvider, SpacesTab);
