import { ReactComponent as CompostIcon } from "@/assets/icons/compost.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as RecyclingIcon } from "@/assets/icons/recycling.svg";
import BasicCard from "@/components/Cards/BasicCard";
import ApexChart from "@/components/Graphs/Apex/Chart";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useProfileState } from "@/context/Profile/hooks";
import { tIconElement } from "@/interfaces/icon";
import { tWithRequired } from "@/interfaces/others";
import { _round, _toInteger } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import { add2Arrays } from "@/utils/arrays";
import { COLORS, hexLuminance } from "@/utils/colors";
import { generateGraphCategories } from "@/utils/graphs";
import { propertyGetManualDataByYearWithoutZeroValueYears } from "@/utils/property/manualData";
import { numberFormatter } from "@/utils/numbers";
import { sortBy, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

const Graph: React.FC = () => {
  const { profile } = useProfileState();
  const { activeProperty } = usePropertyState();
  const [graphType, setGraphType] = useState<"line" | "bar">("line");
  const { trans } = useLocalizationState();
  const [selectedCards, setSelectedCards] = useState<number[]>([0]);

  const [{ manualDataByYear, selectedYears }, setState] = useState<{
    manualDataByYear: Record<string, tPropertyManualData[]>;
    selectedYears: number[];
  }>({
    manualDataByYear: {},
    selectedYears: [],
  });

  useEffect(() => {
    const manualDataByYear = propertyGetManualDataByYearWithoutZeroValueYears(activeProperty, [
      "waste",
    ]);
    setState({
      manualDataByYear,
      selectedYears: [...sortBy(Object.keys(manualDataByYear).map((y) => _toInteger(y)))],
    });
  }, [activeProperty]);

  const selectedYearsRef = useRef<number[]>([]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  useEffect(() => {
    setGraphType("bar");
  }, []);

  const seriesObj = useMemo(() => {
    const totalSeries: { name: string; data: number[] }[] = [];
    const mixedSeries: { name: string; data: number[] }[] = [];
    const organicSeries: { name: string; data: number[] }[] = [];
    const plasticSeries: { name: string; data: number[] }[] = [];
    const paperSeries: { name: string; data: number[] }[] = [];
    const glassSeries: { name: string; data: number[] }[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const totalData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const mixedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const organicData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const plasticData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const paperData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const glassData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      list.forEach((md) => {
        const { from } = md;
        const index = moment(from).month();
        if (md.waste) {
          mixedData[index] = md.waste.mixed.totalKg;
          organicData[index] = md.waste.organic.totalKg;
          plasticData[index] = md.waste.plastic.totalKg;
          paperData[index] = md.waste.paper.totalKg;
          glassData[index] = md.waste.glass.totalKg;
          totalData[index] =
            mixedData[index] +
            organicData[index] +
            plasticData[index] +
            paperData[index] +
            glassData[index];
        }
      });

      totalSeries.push({ name: year, data: totalData });
      mixedSeries.push({ name: year, data: mixedData });
      organicSeries.push({ name: year, data: organicData });
      plasticSeries.push({ name: year, data: plasticData });
      paperSeries.push({ name: year, data: paperData });
      glassSeries.push({ name: year, data: glassData });
    });

    return {
      total: totalSeries,
      mixed: mixedSeries,
      organic: organicSeries,
      plastic: plasticSeries,
      paper: paperSeries,
      glass: glassSeries,
    };
  }, [manualDataByYear]);

  const kgCalculated = useMemo(() => {
    let mixed = 0,
      organic = 0,
      plastic = 0,
      paper = 0,
      glass = 0;

    const selectedManualDataEntries = selectedYears.flatMap((year) => manualDataByYear[year]);

    selectedManualDataEntries.forEach((item) => {
      if (item.waste) {
        mixed += item.waste.mixed.totalKg;
        organic += item.waste.organic.totalKg;
        plastic += item.waste.plastic.totalKg;
        paper += item.waste.paper.totalKg;
        glass += item.waste.glass.totalKg;
      }
    });

    return {
      total: mixed + organic + paper + plastic + glass,
      mixed,
      organic,
      paper,
      plastic,
      glass,
    };
  }, [manualDataByYear, selectedYears]);

  const cards: {
    key: keyof tWithRequired<tPropertyManualData, "waste">["waste"];
    label: string;
    icon: tIconElement;
  }[] = [
    {
      key: "mixed",
      icon: DeleteIcon,
      label: trans("general.waste_types.mixed"),
    },
    {
      key: "organic",
      icon: CompostIcon,
      label: trans("general.waste_types.organic"),
    },
    {
      key: "plastic",
      icon: RecyclingIcon,
      label: trans("general.waste_types.plastic"),
    },
    {
      key: "paper",
      icon: RecyclingIcon,
      label: trans("general.waste_types.paper"),
    },
    {
      key: "glass",
      icon: RecyclingIcon,
      label: trans("general.waste_types.glass"),
    },
  ];

  const renderCards = () => {
    return (
      <div className="flex flex-row flex-1 gap-3 flex-wrap">
        <BasicCard
          icon={{ Element: DeleteIcon, fill: COLORS.waste }}
          label={trans("general.total")}
          value={`${numberFormatter(kgCalculated["total"], profile)} kg`}
          hover
          onClick={() => {
            if (selectedCards.length === 0) {
              setSelectedCards([...cards.map((e, i) => i)]);
            } else {
              setSelectedCards([]);
            }
          }}
          showBorder={selectedCards.length === 0}
        />
        {cards.map((elem, i) => {
          const { icon, key, label } = elem;
          const value = kgCalculated[key];
          return (
            <BasicCard
              hover
              key={key}
              showBorder={selectedCards.includes(i)}
              onClick={() => {
                if (selectedCards.length === 0) setSelectedCards([i]);
                else {
                  if (selectedCards.includes(i)) {
                    if (selectedCards.length === 1) setSelectedCards([]);
                    else setSelectedCards(selectedCards.filter((v) => v !== i));
                  } else {
                    setSelectedCards([...selectedCards, i]);
                  }
                }
              }}
              icon={{ Element: icon, fill: COLORS.waste }}
              label={label}
              value={`${numberFormatter(value, profile)} kg`}
            />
          );
        })}
      </div>
    );
  };

  const categories = useMemo(
    () => generateGraphCategories("month", 1, moment().startOf("year"), moment().endOf("year")),
    []
  );

  const graph = () => {
    if (selectedYears.length === 0) return null;

    const series =
      selectedCards.length === 0
        ? seriesObj["total"]
        : (() => {
            const series: { name: string; data: number[] }[] = Object.keys(manualDataByYear).map(
              (year, i) => {
                let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                selectedCards.forEach((cardIndex) => {
                  const { key } = cards[cardIndex];
                  data = add2Arrays(data, seriesObj[key][i].data);
                });

                return { name: year, data };
              }
            );
            return series;
          })();
    return (
      <div className="bg-white rounded-md p-4 pb-0">
        <ApexChart
          {...{
            type: graphType,
            colors: [
              hexLuminance(COLORS.waste, 0.5),
              hexLuminance(COLORS.waste, 0.25),
              COLORS.waste,
              hexLuminance(COLORS.waste, -0.25),
              hexLuminance(COLORS.waste, -0.5),
            ],
            categories,
            series,
            yaxis: {
              labels: {
                show: true,
                formatter(val: any, opts: any) {
                  return `${_round(val, 2)} kg`;
                },
              },
            },
            xaxis: {
              labels: {
                show: true,
                formatter(value: any, timestamp: any, opts: any) {
                  if (value === null) return "";
                  return moment(value).format("MMM");
                },
              },
            },

            legend: {
              show: true,
              showForSingleSeries: true,
            },
            chart: {
              events: {
                legendClick(chart, seriesIndex, options) {
                  if (seriesIndex !== undefined) {
                    const year = toNumber(options.globals.seriesNames[seriesIndex]);
                    if (selectedYearsRef.current.includes(year)) {
                      setState((prev) => ({
                        ...prev,
                        selectedYears: [...prev.selectedYears.filter((y) => y !== year)],
                      }));
                    } else {
                      setState((prev) => ({
                        ...prev,
                        selectedYears: sortBy(uniq([...prev.selectedYears, year])),
                      }));
                    }
                  }
                },
              },
            },
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {graph()}
      {renderCards()}
    </div>
  );
};

export default React.memo(Graph);
