import { tTrans } from "@/context/Localization/utils";
import { tProperty } from "@/models/property";
import { tPropertyActionLog } from "@/models/propertyActionLog";

export const translateHotelActionsAction = (
  action: tPropertyActionLog["action"],
  trans: tTrans
) => {
  switch (action) {
    case "create":
      return trans("general.created");
    case "update":
      return trans("general.updated");
    case "delete":
      return trans("general.deleted");
    default:
      return "";
  }
};

export const translateHotelActionsHotelProperty = (
  hotelProperty: keyof tProperty | "stays",
  trans: tTrans,
  plural: boolean = false
) => {
  switch (hotelProperty) {
    case "awards":
      return plural ? trans("general.awards") : trans("general.award");

    case "challenges":
      return plural ? trans("general.challenges") : trans("general.challenge");

    case "stays":
      return plural ? trans("general.stays") : trans("general.stay");

    default:
      return "";
  }
};
