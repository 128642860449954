import { chunk, toNumber } from "lodash";
import React from "react";
import { Col, Grid, InputGroup, InputNumber, Row } from "rsuite";
import { tState2 } from ".";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { REQUEST_STATUS } from "@/utils/apiCall";
import InputWrapper, { iInputWrapperProps } from "@/components/InputWrapper";

interface iProps {
  status: nRequestStatus.tStatus;
  state: tState2["data"]["waste"];
  errors: tState2["errors"]["waste"];
  onChange: (
    key: keyof tState2["data"]["waste"],
    subKey: "totalKg",
    value: number | string
  ) => void;
}

const Waste: React.FC<iProps> = ({ errors, onChange, state, status }) => {
  const { trans } = useLocalizationState();

  const wasteTypes: {
    key: keyof tState2["data"]["waste"];
    subKey: "totalKg";
    wrapperProps: Omit<iInputWrapperProps, "children">;
  }[] = [
    {
      key: "mixed",
      subKey: "totalKg",
      wrapperProps: { label: trans("Mixed") },
    },
    {
      key: "organic",
      subKey: "totalKg",
      wrapperProps: { label: trans("Organic") },
    },
    {
      key: "glass",
      subKey: "totalKg",
      wrapperProps: { label: trans("Glass") },
    },
    {
      key: "paper",
      subKey: "totalKg",
      wrapperProps: { label: trans("Paper") },
    },
    {
      key: "plastic",
      subKey: "totalKg",
      wrapperProps: { label: trans("Plastic") },
    },
  ];

  const inputComponents = wasteTypes.map(({ key, subKey, wrapperProps }) => {
    const value = state[key][subKey];
    return (
      // @ts-expect-error
      <InputWrapper {...{ ...wrapperProps }}>
        <InputGroup>
          <InputNumber
            disabled={status === REQUEST_STATUS.PENDING}
            min={0}
            value={value}
            onChange={(value) => {
              if (value !== null) onChange(key, subKey, toNumber(value));
            }}
          />
          <InputGroup.Addon>kg</InputGroup.Addon>
        </InputGroup>
      </InputWrapper>
    );
  });

  return (
    <div>
      <Grid fluid>
        {chunk(inputComponents, 2).map((batch, i) => {
          return (
            <Row key={i} gutter={16}>
              {batch.map((elem, k) => (
                <Col key={k} sm={12}>
                  {elem}
                </Col>
              ))}
            </Row>
          );
        })}
      </Grid>
    </div>
  );
};

export default Waste;
