import Flex from "@/components/Flex";
import AddPaymentMethodModal from "@/components/Modals/AddPaymentMethodModal";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useParseSearchParams from "@/hooks/useParseSearchParams";
import { _omit } from "@/lodash-utils";
import { COLORS } from "@/utils/colors";
import queryString from "query-string";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "rsuite";
import styles from "../../styles.module.css";
import ManagePaymentMethodsModal from "./managePaymentMethodsModal";

const PaymentMethods: React.FC = () => {
  const { trans } = useLocalizationState();
  const [manageModal, setManageModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { activeProperty } = usePropertyState();

  const searchParams = useParseSearchParams();

  useEffect(() => {
    if (searchParams.paymentMethods) setManageModal(true);
  }, [searchParams.paymentMethods]);

  return (
    <Fragment>
      <ManagePaymentMethodsModal
        openAddModal={() => {
          setManageModal(false);
          setAddModal(true);
        }}
        open={manageModal}
        onClose={() => {
          setSearchParams(queryString.stringify(_omit(searchParams, ["paymentMethods"])));
          setManageModal(false);
        }}
      />
      <AddPaymentMethodModal
        property={activeProperty}
        open={addModal}
        onClose={(action: "create" | undefined) => {
          setAddModal(false);
          setManageModal(true);
        }}
      />
      <Flex
        id="settings-billing-payment-methods"
        row
        between
        middle
        className={[styles["settings-item"], styles["settings-item--not-last"]].join(" ")}
      >
        <InterTag size={14} bold text={trans("general.payment_methods")} color={COLORS.secondary} />
        <Button
          appearance="subtle"
          className="inter"
          style={{ color: COLORS.primary }}
          onClick={() => setManageModal(true)}
        >
          {trans("general.manage")}
        </Button>
      </Flex>
    </Fragment>
  );
};

export default PaymentMethods;
