import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import Flex from "@/components/Flex";
import PageSection from "@/components/PageSection";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { useProfileState } from "@/context/Profile/hooks";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import { _reverse } from "@/lodash-utils";
import { tPropertyId } from "@/models/property";
import { tPropertyActionLog } from "@/models/propertyActionLog";
import { tManager, tManagerId } from "@/models/manager";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import {
  translateHotelActionsAction,
  translateHotelActionsHotelProperty,
} from "@/utils/hotelActions";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { defaultManager } from "@/utils/managers";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Timeline } from "rsuite";

interface iProps {
  staffId: tManagerId;
}

const UserActivitiesWrapped: React.FC<iProps> = ({ staffId }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const getRequest = useGetRequest<{
    staffMember: tManager;
    actions: tPropertyActionLog[];
  }>({ staffMember: { ...defaultManager }, actions: [] });
  const { activeProperty } = usePropertyState();

  const run = useCallback(
    (hotelId: tPropertyId) => {
      getRequest.pending();
      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${hotelId}/staff/${staffId}/actions`,
            USE_MONOLITH_SERVERLESS
          ),
          {
            params: { sortDirection: "desc" },
          }
        )
        .then((res) => {
          const {
            data: { actions, staffMember },
          } = res;
          getRequest.resolve({ staffMember, actions });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getRequest.reject(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [staffId, trans]
  );

  useEffect(() => {
    if (activeProperty._id) run(activeProperty._id);
  }, [activeProperty._id, run]);

  if (getRequest.isLoading)
    return <Loader size="md" style={{ marginLeft: "165px", marginBottom: "10px" }} />;

  if (getRequest.isResolved)
    return (
      <Flex column>
        {getRequest.data.actions.length ? (
          <Timeline
            align="left"
            endless
            isItemActive={(index, totalITemsCount) => {
              return false;
            }}
            style={{ width: "fit-content" }}
          >
            <Timeline.Item style={{ paddingBottom: "12px" }} dot={<></>}></Timeline.Item>
            {_reverse(getRequest.data.actions).map(
              ({
                _id,
                action,
                managerId,
                timestamp,
                details: { hotelProperty, hotelPropertyId },
              }) => {
                return (
                  <Timeline.Item
                    key={_id}
                    time={moment(timestamp).format("MMMM DD, YYYY HH:mm")}
                    style={{ paddingBottom: "12px" }}
                  >
                    <Flex style={{ paddingTop: "3px" }} row gap={4}>
                      <InterTag
                        size={14}
                        color={COLORS.secondary}
                        text={getRequest.data.staffMember?.name}
                      />
                      <InterTag
                        size={14}
                        color={COLORS.secondary}
                        text={translateHotelActionsAction(action, trans)}
                      />
                      <InterTag
                        hoverUnderline
                        style={{ cursor: "pointer" }}
                        size={14}
                        color={COLORS.primary}
                        text={translateHotelActionsHotelProperty(hotelProperty, trans)}
                        onClick={() => {
                          switch (hotelProperty) {
                            case "awards":
                              return navigate(`/awards/${hotelPropertyId}`);
                            default:
                              break;
                          }
                        }}
                      />
                    </Flex>
                  </Timeline.Item>
                );
              }
            )}
          </Timeline>
        ) : (
          <div>
            <InterTag
              text={trans("No recent activities")}
              size={12}
              color={COLORS.secondary}
              style={{ marginLeft: "130px", marginTop: "10px" }}
            />
          </div>
        )}
      </Flex>
    );

  return <InterTag text={getRequest.error} />;
};

interface iUserActivitiesProps {
  staffId: tManagerId;
}

const UserActivities: React.FC<iUserActivitiesProps> = ({ staffId }) => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const permissionsList = usePermissions();

  return (
    <Flex column gap={30}>
      <PageSection
        title={trans("Recent User Activities")}
        description={trans(
          "Monitor login instances, setting changes, and other key actions taken by the user."
        )}
        icon={{ Element: QueryStatsIcon, fill: COLORS.secondary, size: 24 }}
      />
      {hasPermission(permissionsList, "read:staff:activities") || staffId === profile._id ? (
        <UserActivitiesWrapped {...{ staffId }} />
      ) : (
        <InterTag text={trans("Unauthorized to view this user's activities")} />
      )}
    </Flex>
  );
};

export default UserActivities;
