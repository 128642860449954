import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as EventLeavingIcon } from "@/assets/icons/event_leaving.svg";
import { ReactComponent as EventRepeatIcon } from "@/assets/icons/event_repeat.svg";
import { ReactComponent as EventUpcomingIcon } from "@/assets/icons/event_upcoming.svg";
import { ReactComponent as LocalLaundryServiceIcon } from "@/assets/icons/local_laundry_service.svg";
import TrendingComparison from "@/components/Comparions/trendingComparison";
import ManualDataModal from "@/components/Modals/ManualDataModal";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import useScreenSize from "@/hooks/useScreenSize";
import { tWithRequired } from "@/interfaces/others";
import { tPropertyManualData } from "@/models/property";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { propertyGetManualDataOrderedWithoutZeroValues } from "@/utils/property/manualData";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { min } from "lodash";
import React, { useMemo, useState } from "react";
import { Table } from "rsuite";

export type tTableItem = {
  previousPeriodKgOutsourced: number | null;
  homologousPeriodKgOutsourced: number | null;
} & Pick<tPropertyManualData, "from" | "to" | "_id"> &
  tWithRequired<tPropertyManualData, "laundry">["laundry"];

interface iProps {
  containerHeight: number;
  manualData: tPropertyManualData[];
}

const ListWrapped2: React.FC<iProps> = ({ manualData, containerHeight }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const deleteRequest = useDeleteRequest();
  const permissionsList = usePermissions();

  const [manualDataModal, setManualDataModal] = useState<
    | {
        open: true;
        manualDataId: tPropertyManualData["_id"];
      }
    | { open: false; manualDataId: undefined }
  >({ open: false, manualDataId: undefined });

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (hasPermission(permissionsList, "create:manual-data"))
      options.push(
        {
          key: "update entry",
          label: trans("components.manual_data.global_edit_entry.label"),
          icon: LocalLaundryServiceIcon,
          onClick() {
            setManualDataModal({ open: true, manualDataId: item._id });
          },
        },
        {
          key: "remove",
          icon: { Element: DeleteIcon, fill: COLORS.error },
          label: {
            text: trans("components.manual_data.global_remove_entry.label"),
            color: COLORS.error,
          },
          onClick() {
            deleteRequest.pending();
            axios
              .delete(
                constructApiAddress(
                  `/v2/hotels/${activeProperty._id}/manual-data/${item._id}/properties/laundry`,
                  USE_MONOLITH_SERVERLESS
                )
              )
              .then((res) => {
                const {
                  data: { hotel },
                } = res;
                updateProperty(propertyDispatch, activeProperty._id, hotel);
                deleteRequest.resolve();
              })
              .catch((err) => {
                const error = getErrorMessage(err, trans);
                deleteRequest.reject(error);
              });
          },
        }
      );

    return options;
  };

  const tableData: tTableItem[] = useMemo(() => {
    return manualData
      .filter((item) => item.laundry?.kgOutsourced)
      .map((md, i, self) => {
        const { laundry, from, to, _id } = md;
        return {
          _id,
          from,
          to,
          ...laundry,
          previousPeriodKgOutsourced: i > 0 ? self[i - 1].laundry?.kgOutsourced || null : null,
          homologousPeriodKgOutsourced: null,
        } as tTableItem;
      });
  }, [manualData]);

  const tableHeight =
    tableData.length === 1
      ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
      : (min([
          tableData.length * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT,
          containerHeight - 270,
        ]) as number);

  return (
    <>
      <ManualDataModal
        initialStep="laundry"
        open={manualDataModal.open}
        onClose={() => {
          setManualDataModal({ open: false, manualDataId: undefined });
        }}
        manualDataId={manualDataModal.manualDataId}
      />
      <div className="table-wrapper">
        <Table
          id="TABLE.CONSUMPTION.LAUNDRY"
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={tableData}
          loading={deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.from_date")} icon={EventUpcomingIcon} />
            <SimpleDateCell showDiff={false} dataKey="from" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.to_date")} icon={EventLeavingIcon} />
            <SimpleDateCell showDiff={false} dataKey="to" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.laundry")} icon={LocalLaundryServiceIcon} />
            <SimpleKeyValueCell
              dataKey="kgOutsourced"
              textFunction={(item: tTableItem) => {
                return `${item.kgOutsourced} kg`;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.period_previous")} icon={EventRepeatIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                if (item.previousPeriodKgOutsourced === null) return "-";
                return (
                  <TrendingComparison
                    size={12}
                    value={item.kgOutsourced}
                    baseValue={item.previousPeriodKgOutsourced}
                    description={trans("components.trending_comparison.last_period_tooltip")}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iListWrappedProps {
  containerHeight: number;
}

const ListWrapped: React.FC<iListWrappedProps> = ({ containerHeight }) => {
  const { activeProperty } = usePropertyState();

  const manualData = useMemo(
    () => propertyGetManualDataOrderedWithoutZeroValues(activeProperty, ["laundry"]),
    [activeProperty]
  );

  return <ListWrapped2 {...{ containerHeight, manualData }} />;
};

interface iListProps {}

const List: React.FC<iListProps> = () => {
  const { outlet: roc } = useScreenSize();

  if (!roc) return null;

  return <ListWrapped {...{ containerHeight: roc.height }} />;
};

export default List;
