export type tPropertyAwardDescription = {
  language: string;
  shortDescription: string;
  longDescription: string;
};

export const PROPERTY_AWARD_TYPES = [
  "Adventure",
  "Cultural",
  "Food & Drinks",
  "Hospitality",
  "Leisure",
  "Products",
  "Services",
  "Wellness",
] as const;
export type tPropertyAwardType = (typeof PROPERTY_AWARD_TYPES)[number];

export type tPropertyAwardId = string;
export type tPropertyAward = {
  _id: tPropertyAwardId;

  type: tPropertyAwardType;

  descriptions: tPropertyAwardDescription[];
  description: string;

  location: string;

  toBeUsedUntil: Date | number | string;
  validityAfterAwarding: number;
  validityAfterAwardingAward: {
    years: number;
    months: number;
    days: number;
  };

  discount: number;
  discountType: "percentage" | "nominal";

  images: string[];

  timesUsed: number;
  timesAwarded: number;

  active: boolean;
  removed: boolean;

  timestamp: Date | number | string;
  createdBy: string;
};
