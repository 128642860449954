import { ReactComponent as LocalLaundryServiceIcon } from "@/assets/icons/local_laundry_service.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import AddManualDataButton from "@/components/Buttons/addManualDataButton";
import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import ManualDataModal from "@/components/Modals/ManualDataModal";
import PageBottomPadding from "@/components/PageBottomPadding";
import PageSection from "@/components/PageSection";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import { tWithRequired } from "@/interfaces/others";
import { tPropertyManualData } from "@/models/property";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const LaundryWrapped: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const permissionsList = usePermissions();
  const readyManualData = useGetRequest<{
    list: tWithRequired<tPropertyManualData, "laundry">[];
  }>({ list: [] });

  useEffect(() => {
    const parsed = activeProperty.manualData.filter((md) => md.laundry) as tWithRequired<
      tPropertyManualData,
      "laundry"
    >[];

    const sorted = sortBy(parsed, (pmd) => moment(pmd.from).valueOf());

    readyManualData.resolve({ list: sorted });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProperty.manualData]);

  const segments = [
    {
      key: "overview",
      label: "Overview",
      pageSectionProps: {
        title: trans("pages.laundry.archive.sections.overview.title"),
        description: trans("pages.laundry.archive.sections.overview.description"),
        icon: QueryStatsIcon,
      },
      component: {
        Element: Graph,
        props: {},
      },
    },
    {
      key: "data",
      label: "Data",
      pageSectionProps: {
        title: trans("pages.laundry.archive.sections.list.title"),
        description: trans("pages.laundry.archive.sections.list.description"),
        icon: LocalLaundryServiceIcon,
      },
      component: {
        Element: List,
        props: { readyManualData },
      },
    },
  ];

  return (
    <>
      {hasPermission(permissionsList, "create:manual-data") && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="laundry"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div>
        <SecondaryTopBar>
          <div>
            {hasPermission(permissionsList, "create:manual-data") && (
              <AddManualDataButton onClick={() => setManualDataModal({ open: true })} />
            )}
          </div>
        </SecondaryTopBar>
        <Flex column gap={40}>
          {segments.map(({ component: { Element, props }, key, pageSectionProps }) => (
            <Flex column gap={16} key={key}>
              <PageSection {...pageSectionProps} />
              {/* @ts-ignore */}
              <Element {...props} />
            </Flex>
          ))}
        </Flex>
      </div>
    </>
  );
};

const LaundryGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  if (activeSubscriptionIsStarter) return <Unlock />;

  return <LaundryWrapped />;
};

const Laundry: React.FC = () => {
  return (
    <IpValidatorGuard>
      <LaundryGuarded />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Laundry;
