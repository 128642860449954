import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import ConservationLabsEvents from "@/components/ConservationLabs/ConservationLabsEvents";
import Flex from "@/components/Flex";
import MeasureConsumption from "@/components/MeasureConsumption";
import PageSection from "@/components/PageSection";
import SimpleDateRangePicker from "@/components/RsuiteWrapper/SimpleDateRangePicker";
import SpaceMeasureConsumptionTable from "@/components/Tables/SpaceMeasureConsumptionTable";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import {
  setSensorDataTimeframe,
  useTimeframeDispatch,
  useTimeframeState,
} from "@/context/Timeframe";
import useElementForPortal from "@/hooks/useElementForPortal";
import { MEASURES_WATER_SPECIFIC, tMeasureWaterSpecific, TW } from "@/models/measures";
import { COLORS } from "@/utils/colors";
import { convertVolumeUnit, DEFAULT_WATER_MEASURE_UNIT } from "@/utils/convertUnits";
import { propertyGetMeasuresTracked } from "@/utils/property/measures";
import { ELEMENT_IDS } from "@/utils/portal";
import React from "react";
import { createPortal } from "react-dom";

const buildTransKey = (key: string) => `pages.water.live.sections.${key}`;

const LiveData: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const portalContainer = useElementForPortal(ELEMENT_IDS.waterPageSecondaryTopBar, true);

  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();
  const timeframeDispatch = useTimeframeDispatch();

  const trackedMeasures = propertyGetMeasuresTracked(activeProperty);

  const specificMeasures: { measureKey: tMeasureWaterSpecific }[] = [];
  MEASURES_WATER_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  return (
    <>
      {portalContainer &&
        createPortal(
          <SimpleDateRangePicker
            value={sensorDataTimeframe}
            onChange={(timeframe) => setSensorDataTimeframe(timeframeDispatch, timeframe)}
            style={{ paddingLeft: "8px" }}
          />,
          portalContainer
        )}
      <div>
        <Flex column gap={40}>
          {trackedMeasures.tw ? (
            <>
              <PageSection
                {...{
                  title: trans(buildTransKey("overview.title")),
                  description: trans(buildTransKey("overview.description")),
                  icon: QueryStatsIcon,
                }}
              >
                <MeasureConsumption
                  mainMeasureKey={TW}
                  specificMeasures={specificMeasures}
                  converter={convertVolumeUnit}
                  mainUnit={DEFAULT_WATER_MEASURE_UNIT}
                  binUnit={"day"}
                  binValue={1}
                  from={sensorDataTimeframe[0]}
                  to={sensorDataTimeframe[1]}
                />
              </PageSection>
              <PageSection
                {...{
                  title: trans(buildTransKey("list.title")),
                  description: trans(buildTransKey("list.description")),
                  icon: { Element: WaterDropIcon, fill: COLORS.water },
                }}
              >
                <SpaceMeasureConsumptionTable
                  mainMeasureKey2="w"
                  converter={convertVolumeUnit}
                  from={sensorDataTimeframe[0]}
                  to={sensorDataTimeframe[1]}
                  tableHeaderIcon={WaterDropIcon}
                  tableHeaderIconFill={COLORS.water}
                  tableHeaderLabel={trans("general.measures_.water")}
                />
              </PageSection>
              <PageSection title={trans(buildTransKey("events.title"))}>
                <ConservationLabsEvents from={sensorDataTimeframe[0]} to={sensorDataTimeframe[1]} />
              </PageSection>
            </>
          ) : (
            <Flex>
              <InterTag
                text={trans(buildTransKey("not_measured"))}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
          )}
        </Flex>
      </div>
    </>
  );
};

export default LiveData;
