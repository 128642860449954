import { ReactComponent as ManageAccountIcon } from "@/assets/icons/manage_accounts.svg";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import PageSection from "@/components/PageSection";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tManager } from "@/models/manager";
import React from "react";
import UserManagement from "./management";

export type tStaffData = Pick<
  tManager,
  "_id" | "email" | "images" | "name" | "lastAccess" | "lastLogin"
>;

const UsersWrapped: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <PageSection
      icon={{ Element: ManageAccountIcon }}
      title={trans("pages.users.sections.management.title")}
      description={trans("pages.users.sections.management.description")}
    >
      <UserManagement />
    </PageSection>
  );
};

const Users: React.FC = () => {
  return (
    <IpValidatorGuard>
      <UsersWrapped />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Users;
