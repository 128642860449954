import React, { PropsWithChildren } from "react";
import { ReactComponent as NoytrallSymbol } from "@/assets/noytrall_symbol.svg";
import { ReactComponent as NoytrallWord } from "@/assets/noytrall_word.svg";
import { COLORS } from "@/utils/colors";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import { IconButton } from "rsuite";
import { ReactComponent as ArrowLeft } from "@/assets/icons/arrow_back.svg";

const NoAuthMobileWrapper: React.FC<PropsWithChildren<{ onGoBack?: () => void }>> = ({
  children,
  onGoBack,
}) => {
  return (
    <Flex className="height-100" color={COLORS.light_white} column>
      <Flex>
        <Flex basis={20} bottom center>
          {onGoBack ? (
            <IconButton
              appearance="subtle"
              size="xs"
              circle
              onClick={onGoBack}
              icon={<Icon Element={ArrowLeft} size={24} fill={COLORS.secondary} />}
            />
          ) : null}
        </Flex>
        <Flex one row gap={10} center middle style={{ marginTop: "24px" }}>
          <Icon Element={NoytrallWord} width={100} fill={COLORS.secondary} />
          <Icon Element={NoytrallSymbol} height={50} />
        </Flex>
        <Flex basis={20}></Flex>
      </Flex>
      {children}
    </Flex>
  );
};

export default NoAuthMobileWrapper;
