import { ReactComponent as CheckBoxFilledIcon } from "@/assets/icons/check_box_filled.svg";
import { ReactComponent as NoytrallSymbolIcon } from "@/assets/noytrall_symbol.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import BusinessPaymentModal from "@/components/Modals/BusinessPaymentModal";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import InterTag from "@/components/Text/Inter";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useBusinessSubscriptionPrices } from "@/hooks/subscriptions";
import { tPropertySubscriptionModel } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { propertyFindActiveSubscription } from "@/utils/property/subscriptions";
import { round } from "lodash";
import React, { useState } from "react";
import { Button, Toggle } from "rsuite";

interface iBusinessPriceProps {
  billingTimePeriod: tBillingTimePeriod;
}

const BusinessPrice: React.FC<iBusinessPriceProps> = ({ billingTimePeriod }) => {
  const priceRequest = useBusinessSubscriptionPrices();

  if (priceRequest.isFetching) return <SkeletonText height={40} width={40} />;
  if (priceRequest.isError) return <InterTag size={40} text={""} color={COLORS.secondary} />;
  return (
    <div className="flex flex-row gap-1 items-end">
      <InterTag
        size={40}
        text={round(priceRequest.data.pricesInCents[billingTimePeriod] / 100, 2)}
        color={COLORS.secondary}
      />
      <InterTag size={24} text={"€"} color={COLORS.secondary} />
    </div>
  );
};

type tBillingTimePeriod = "month" | "year";

const SettingsBillingPlanWrapped: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const { trans } = useLocalizationState();
  const [billingTimePeriod, setBillingTimePeriod] = useState<tBillingTimePeriod>("month");
  const [paymentModal, setPaymentModal] = useState(false);
  const propertyDispatch = usePropertyDispatch();

  const activeSubscription = propertyFindActiveSubscription(activeProperty);
  const activeSubscriptionType = activeSubscription?.type || "starter";
  const activeSubscriptionIsStarter = activeSubscriptionType === "starter";

  const renderCards = () => {
    const list: {
      key: tPropertySubscriptionModel["type"];
      price: any;
      priceDescription: Record<tBillingTimePeriod, string>;
      perksCheckColor: string;
      transKeyword: string;
      numberOfPerks: number;
    }[] = [
      {
        key: "starter",
        transKeyword: "starter",
        price: (
          <div className="flex flex-row gap-1 items-end">
            <InterTag size={40} text={0} color={COLORS.secondary} />
            <InterTag size={24} text={"€"} color={COLORS.secondary} />
          </div>
        ),
        priceDescription: {
          month: trans("general.free"),
          year: trans("general.free"),
        },

        numberOfPerks: 3,
        perksCheckColor: COLORS.secondary,
      },
      {
        key: "business",
        transKeyword: "business",
        price: <BusinessPrice billingTimePeriod={billingTimePeriod} />,
        priceDescription: {
          month: trans("pages.billing_plan.billed_monthly"),
          year: trans("pages.billing_plan.per_month_billed_annually"),
        },

        numberOfPerks: 4,
        perksCheckColor: COLORS.primary,
      },
      {
        key: "enterprise",
        transKeyword: "enterprise",
        price: <InterTag size={40} text={trans("general.custom")} color={COLORS.secondary} />,
        priceDescription: {
          month: trans("pages.billing_plan.billed_monthly"),
          year: trans("pages.billing_plan.per_month_billed_annually"),
        },

        numberOfPerks: 5,
        perksCheckColor: COLORS.primary,
      },
    ];

    return list.map(
      ({ key, perksCheckColor, price, priceDescription, numberOfPerks, transKeyword }) => {
        const title = trans(`pages.billing_plan.plans.${transKeyword}.title`);
        const description = trans(`pages.billing_plan.plans.${transKeyword}.description`);

        const perks: string[] = [];
        for (let i = 0; i < numberOfPerks; i++) {
          perks.push(trans(`pages.billing_plan.plans.${transKeyword}.perks.${i}`));
        }

        const renderButton = () => {
          if (activeSubscriptionType === key)
            return (
              <Button appearance="ghost" style={{ width: 200, height: 56, fontSize: 20 }} disabled>
                <strong>{trans("general.plan_current")}</strong>
              </Button>
            );

          if (key === "enterprise")
            return (
              <Button
                onClick={() => {
                  window.open(
                    "https://batchcal.com/miguelrolinhoclemente/noytrall",
                    "_blank",
                    "noreferrer"
                  );
                }}
                appearance="ghost"
                style={{ width: 200, height: 56, fontSize: 20 }}
              >
                <strong>{trans("general.contact_us")}</strong>
              </Button>
            );

          if (activeSubscriptionIsStarter && key === "business")
            return (
              <Button
                appearance="primary"
                style={{ width: 200, height: 56, fontSize: 20 }}
                onClick={() => setPaymentModal(true)}
              >
                <strong>{trans("general.upgrade_now")}</strong>
              </Button>
            );

          return null;
        };

        return (
          <div key={key} className="card-m flex-1 flex flex-col justify-between gap-4">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2 items-center">
                  <Icon Element={NoytrallSymbolIcon} size={36} />
                  <InterTag text={trans(title)} size={24} bold color={COLORS.secondary} />
                </div>
                <InterTag
                  text={trans(description)}
                  color={COLORS.gray}
                  size={16}
                  style={{ textWrap: "wrap", textAlign: "left" }}
                />
              </div>
              <div className="flex flex-col gap-4 items-center">
                {price}
                <InterTag
                  text={priceDescription[billingTimePeriod]}
                  size={16}
                  color={COLORS.gray}
                />
              </div>
              <div className="flex flex-col gap-3">
                {perks.map((perk) => (
                  <div className="flex flex-row gap-4 items-center" key={perk}>
                    <div>
                      <Icon Element={CheckBoxFilledIcon} fill={perksCheckColor} size={24} />
                    </div>
                    <InterTag
                      size={16}
                      colro={COLORS.secondary}
                      text={trans(perk)}
                      style={{ textWrap: "wrap", lineHeight: "18px" }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Flex center middle>
              {renderButton()}
            </Flex>
          </div>
        );
      }
    );
  };

  return (
    <>
      <BusinessPaymentModal
        billingTimePeriod={billingTimePeriod}
        open={paymentModal}
        property={activeProperty}
        onClose={(updatedProperty) => {
          setPaymentModal(false);
          if (updatedProperty) {
            updateProperty(propertyDispatch, activeProperty._id, updatedProperty);
          }
        }}
      />
      <div className="flex flex-col gap-7">
        <div className="flex flex-col gap-2.5">
          <InterTag
            size={36}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.title")}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              margin: "0 80px",
              fontWeight: 700,
            }}
          />
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.description")}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <InterTag
            size={14}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.billed_monthly")}
          />
          <Toggle
            checked={billingTimePeriod === "year"}
            onChange={(checked) => setBillingTimePeriod(checked ? "year" : "month")}
          />
          <InterTag
            size={14}
            color={COLORS.secondary}
            text={`${trans("pages.billing_plan.billed_annually")} (${trans("general.x_%_off", {
              parameters: [10],
            })})`}
          />
        </div>
        <div className="flex flex-row gap-4">{renderCards()}</div>
      </div>
    </>
  );
};

const SettingsBillingPlan: React.FC = () => {
  return (
    <div className="pb-10">
      <SettingsBillingPlanWrapped />
    </div>
  );
};

export default SettingsBillingPlan;
