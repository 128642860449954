import { ReactComponent as ConversionPathIcon } from "@/assets/icons/conversion_path.svg";
import { ReactComponent as LineAxisIcon } from "@/assets/icons/line_axis.svg";
import { ReactComponent as ListIcon } from "@/assets/icons/list.svg";
import DataTypeToggle from "@/components/DataTypeToggle";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import Icon from "@/components/Icons/Icon";
import { toIconProps } from "@/components/Icons/Icon/utils";
import RoadmapTable from "@/components/RoadmapTable";
import SimpleDateRangePicker from "@/components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleDateRangePickerForManualDataPeriods2 from "@/components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { useDataType } from "@/context/DataType";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { usePropertyState } from "@/context/Property/hooks";
import {
  setManualDataIndex,
  setSensorDataTimeframe,
  useTimeframeDispatch,
  useTimeframeState,
} from "@/context/Timeframe";
import { tIconElement } from "@/interfaces/icon";
import { COLORS } from "@/utils/colors";
import { propertyActiveSubscriptionTypeIsEnterprise } from "@/utils/property/subscriptions";
import React, { useState } from "react";
import { Button, ButtonGroup, Tooltip, Whisper } from "rsuite";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const ElectricityGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const { trans } = useLocalizationState();
  const dataTypeContext = useDataType();
  const {
    data: { sensorDataTimeframe, manualDataIndex },
  } = useTimeframeState();

  const timeframeDispatch = useTimeframeDispatch();

  const [selectedSection, setSelectedSection] = useState(0);

  const renderButtons = () => {
    const list: { key: string; label: string; icon: tIconElement }[] = [
      { key: "indicators", label: trans("general.overview"), icon: LineAxisIcon },
      { key: "evolution", label: trans("general.spaces"), icon: ListIcon },
      { key: "roadmap", label: trans("general.roadmap"), icon: ConversionPathIcon },
    ];
    return (
      <ButtonGroup>
        {list.map(({ key, label, icon }, i) => (
          <Whisper
            key={key}
            trigger="hover"
            placement="bottom"
            speaker={<Tooltip>{label}</Tooltip>}
          >
            <Button
              onClick={() => setSelectedSection(i)}
              appearance={selectedSection === i ? "primary" : "ghost"}
            >
              <Icon {...{ ...toIconProps(icon), size: 16, fill: COLORS.secondary }} />
            </Button>
          </Whisper>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 items-center">
            <DataTypeToggle />
            {dataTypeContext.dataTypeIsLive ? (
              <SimpleDateRangePicker
                value={sensorDataTimeframe}
                onChange={(timeframe) => setSensorDataTimeframe(timeframeDispatch, timeframe)}
                style={{ paddingLeft: "8px" }}
              />
            ) : (
              <SimpleDateRangePickerForManualDataPeriods2
                {...{
                  manualDataIndex,
                  setManualDataIndex: (index: number) =>
                    setManualDataIndex(timeframeDispatch, index),
                }}
              />
            )}
          </div>
          <div>{renderButtons()}</div>
        </div>
      </SecondaryTopBar>
      {!propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty }) ||
      !dataTypeContext.dataTypeIsLive ? (
        <HistoricData {...{ selectedSection }} />
      ) : (
        dataTypeContext.dataTypeIsLive && <LiveData {...{ selectedSection }} />
      )}
      <div>{selectedSection === 2 ? <RoadmapTable filterByType="electricity" /> : null}</div>
    </div>
  );
};

const Electricity: React.FC = () => {
  return (
    <IpValidatorGuard>
      <ElectricityGuarded />
    </IpValidatorGuard>
  );
};

export default Electricity;
