import { usePropertyState } from "@/context/Property/hooks";
import { tManager } from "@/models/manager";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function useListStaff() {
  const { activeProperty } = usePropertyState();

  const request = useQuery<{ staff: tManager[] }>({
    queryKey: ["staff", activeProperty._id],
    initialData: { staff: [] },
    queryFn: async ({ signal }) => {
      return axios
        .get(ENDPOINTS.PROPERTIES.STAFF.LIST, {
          signal,
          params: { propertyId: activeProperty._id },
        })
        .then((res) => {
          return { staff: res?.data?.staff || [] };
        });
    },
  });

  return request;
}

export default useListStaff;
