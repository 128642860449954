import { usePropertyState } from "@/context/Property/hooks";
import { tPropertySpaceId } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { QueriesResults, useQueries, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import moment, { MomentInput } from "moment";
import { useMemo } from "react";

export type tConsumptionSeries = Record<"e" | "w", number[]>;

function useGetConsumptionSeries(
  id: string,
  params: {
    startDate: MomentInput;
    endDate: MomentInput;
    enabled?: boolean;
    spaces?: tPropertySpaceId[];
    measures?: ("e" | "w")[];
    binUnit?: "day";
    binSize?: number;
    automaticBin?: boolean;
  }[]
): UseQueryResult<tConsumptionSeries>[] {
  const { activeProperty } = usePropertyState();

  const requests = useQueries<tConsumptionSeries[], QueriesResults<tConsumptionSeries[]>>({
    queries: params.map(
      ({
        startDate,
        endDate,
        enabled = true,
        spaces,
        measures,
        binUnit = "day",
        binSize = 1,
        automaticBin = false,
      }) => ({
        queryKey: [
          `consumption.total|${id}|${automaticBin}|${binUnit}|${binSize}|${activeProperty._id}|${startDate}|${endDate}`,
        ],
        enabled,
        refetchOnWindowFocus: false,
        initialData: { e: [], w: [] },
        select: (data) => data as tConsumptionSeries,
        queryFn: async ({ signal }: { signal: AbortSignal }): Promise<tConsumptionSeries> => {
          return axios
            .post<{ consumption: tConsumptionSeries }>(
              ENDPOINTS.PROPERTIES.CONSUMPTION.SPACE,
              {
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString(),
                spaces,
                measures,
                binUnit,
                binSize,
              },
              { signal, params: { propertyId: activeProperty._id } }
            )
            .then((res) => {
              return { e: [], w: [], ...(res.data.consumption as Partial<tConsumptionSeries>) };
            });
        },
      })
    ),
  }) as UseQueryResult<tConsumptionSeries>[];

  const statuses = JSON.stringify(
    requests.flatMap((r) => [
      r.status,
      r.isFetching,
      r.isRefetching,
      r.dataUpdatedAt,
      r.isPending,
      r.isSuccess,
    ])
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => requests, [statuses]) as UseQueryResult<tConsumptionSeries>[];
}

export default useGetConsumptionSeries;
