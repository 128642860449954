import { ReactComponent as NotesIcon } from "@/assets/icons/notes.svg";
import { ReactComponent as SquareFootIcon } from "@/assets/icons/square_foot.svg";
import Flex from "@/components/Flex";
import ManualDataModal from "@/components/Modals/ManualDataModal";
import PageSection from "@/components/PageSection";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { tWithRequired } from "@/interfaces/others";
import { tPropertyManualData } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { propertyGetManualDataEntryWithDimensionsInTimeframe } from "@/utils/property/manualData";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { min } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";

type tTableItem = { label: string; areaM2: number };

interface iAccommodationMeasuresWrappedProps {
  manualDataEntry: tWithRequired<tPropertyManualData, "space">;
}

const AccommodationMeasuresWrapped: React.FC<iAccommodationMeasuresWrappedProps> = ({
  manualDataEntry,
}) => {
  const { trans } = useLocalizationState();

  const tableData: tTableItem[] = [
    {
      label: trans("pages.spaces.sections.accommodationMeasures.table.columns.totalArea"),
      areaM2: manualDataEntry!.space!.totalAreaM2,
    },
    {
      label: trans("pages.spaces.sections.accommodationMeasures.table.columns.guestRoomsArea"),
      areaM2: manualDataEntry!.space!.guestRoomsAndCorridorsAreaM2,
    },
    {
      label: trans("pages.spaces.sections.accommodationMeasures.table.columns.meetingRoomsArea"),
      areaM2: manualDataEntry!.space!.meetingRoomsAreaM2,
    },
  ];

  const tableHeight = (min([10, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  return (
    <div className="table-wrapper">
      <Table
        id="TABLE.ACCOMMODATION-MEASURES"
        data={tableData}
        height={tableHeight}
        rowHeight={TABLE_ROW_HEIGHT.M}
        headerHeight={TABLE_HEADER_HEIGHT}
      >
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell
            name={trans("pages.spaces.sections.accommodationMeasures.table.headers.description")}
            icon={NotesIcon}
          />
          <SimpleKeyValueCell dataKey="label" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell
            name={trans("pages.spaces.sections.accommodationMeasures.table.headers.area")}
            icon={SquareFootIcon}
          />
          <SimpleKeyValueCell
            dataKey="areaM2"
            textProps={() => ({
              asHTML: true,
            })}
            textFunction={(item: tTableItem) => `${item.areaM2} m<sup>2</sup>`}
          />
        </Table.Column>
      </Table>
    </div>
  );
};

interface iProps {}

const AccommodationMeasures: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const [manualDataModal, setManualDataModal] = useState<{
    open: boolean;
    manualDataId?: tPropertyManualData["_id"];
  }>({ open: false, manualDataId: undefined });
  const manualDataEntry = propertyGetManualDataEntryWithDimensionsInTimeframe(
    activeProperty,
    moment()
  );
  const permissionsList = usePermissions();

  return (
    <>
      <ManualDataModal
        open={manualDataModal.open}
        onClose={() => setManualDataModal({ open: false, manualDataId: undefined })}
        initialStep="space"
        manualDataId={manualDataModal.manualDataId}
      />
      <Flex column gap={16}>
        <PageSection
          title={trans("pages.spaces.sections.accommodationMeasures.title")}
          description={trans("pages.spaces.sections.accommodationMeasures.description")}
          icon={SquareFootIcon}
        />
        {manualDataEntry && manualDataEntry.space ? (
          <Flex column gap={8}>
            <Flex row gap={12}>
              <InterTag
                size={12}
                color={COLORS.secondary}
                text={trans("pages.spaces.sections.accommodationMeasures.data_from_date", {
                  parameters: [moment(manualDataEntry.from).format("MMM YYYY")],
                })}
              />
              {hasPermission(permissionsList, "update:manual-data") && (
                <InterTag
                  size={12}
                  hoverUnderline
                  onClick={() =>
                    setManualDataModal({
                      open: true,
                      manualDataId: manualDataEntry._id,
                    })
                  }
                  color={COLORS.primary}
                  text={trans("pages.spaces.sections.accommodationMeasures.edit_measures")}
                />
              )}
              {hasPermission(permissionsList, "create:manual-data") && (
                <InterTag
                  size={12}
                  hoverUnderline
                  onClick={() =>
                    setManualDataModal({
                      open: true,
                      manualDataId: undefined,
                    })
                  }
                  color={COLORS.primary}
                  text={trans("pages.spaces.sections.accommodationMeasures.set_measures")}
                />
              )}
            </Flex>
            <AccommodationMeasuresWrapped
              {...{
                manualDataEntry: manualDataEntry as tWithRequired<tPropertyManualData, "space">,
              }}
            />
          </Flex>
        ) : (
          <InterTag
            size={12}
            hoverUnderline
            onClick={() =>
              setManualDataModal({
                open: true,
                manualDataId: undefined,
              })
            }
            color={COLORS.primary}
            text={trans("pages.spaces.sections.accommodationMeasures.set_measures")}
          />
        )}
      </Flex>
    </>
  );
};

export default AccommodationMeasures;
