import React from "react";
import { PermissionsContext } from "..";

const usePermissions = () => {
  const state = React.useContext(PermissionsContext);

  if (!state) throw Error("usePermissionsState must be used within PermissionsStateContext");

  return state;
};

export default usePermissions;
