import { tManager } from "@/models/manager";
import { tPropertyId } from "@/models/property";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { defaultManager } from "@/utils/managers";
import React from "react";
import { ProfileDispatchContext, ProfileStateContext } from ".";
import { nProfile } from "./interfaces";

export const useProfileState = () => {
  const state = React.useContext(ProfileStateContext);

  if (!state) throw Error("useProfileState must be used within ProfileStateContext");

  const { data, status } = state;

  const profile = data.profile || defaultManager;
  const profileLoaded = data.profile !== null;

  return {
    profileLoaded,
    profile,
    isResolved: status === REQUEST_STATUS.RESOLVED,
  };
};

export const useProfileDispatch = () => {
  const dispatch = React.useContext(ProfileDispatchContext);

  if (!dispatch) throw Error("useProfileDispatch must be used within ProfileStateContext");
  return dispatch;
};

export const setProfile = (
  dispatch: nProfile.tDispatchContext,
  profile: nProfile.tStateData["profile"]
) => {
  dispatch({ type: "set profile", profile });
};

export const updateProfile = (dispatch: nProfile.tDispatchContext, profile: Partial<tManager>) => {
  dispatch({ type: "update profile", profile });
};

export const setActiveProperty = (
  dispatch: nProfile.tDispatchContext,
  activeProperty: tPropertyId,
  onlyLocalStorage?: boolean
) => {
  dispatch({
    type: "update profile",
    profile: { activeProperty },
    onlyLocalStorage,
  });
};
