import SimpleAddButton from "@/components/RsuiteWrapper/SimpleButton/buttons/addButton";
import { hasAllPermissions } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import React, { Fragment, useState } from "react";
import SpaceModal from "./spaceModal";

interface iProps {}

const AddButton: React.FC<iProps> = () => {
  const permissionsList = usePermissions();
  const [spaceModal, setSpaceModal] = useState<{ open: boolean }>({
    open: false,
  });

  if (!hasAllPermissions(permissionsList, ["create:spaces"])) return null;

  return (
    <Fragment>
      <SpaceModal
        onClose={() => setSpaceModal((prev) => ({ ...prev, open: false }))}
        open={spaceModal.open}
      />
      <SimpleAddButton onClick={() => setSpaceModal((prev) => ({ ...prev, open: true }))} />
    </Fragment>
  );
};

export default AddButton;
