import { forwardRef } from "react";
import { ButtonProps } from "rsuite";
import SimpleButton from "..";
import { ReactComponent as AddIcon } from "@/assets/icons/add.svg";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";

interface iProps extends ButtonProps {
  hideLabel?: boolean;
}

const SimpleAddButton = forwardRef<HTMLButtonElement, iProps>(({ hideLabel, ...props }, ref) => {
  const { trans } = useLocalizationState();
  return (
    <SimpleButton
      ref={ref}
      size="sm"
      appearance="primary"
      icon={{ Element: AddIcon, fill: COLORS.white, size: 20 }}
      {...{
        ...props,
        ...(!hideLabel
          ? {
              text: {
                text: trans("general.add").toUpperCase(),
                color: COLORS.white,
                size: 12,
              },
            }
          : {}),
      }}
    />
  );
});

export default SimpleAddButton;
