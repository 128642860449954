import { _get, _isString } from "@/lodash-utils";
import tProperty from "@/models/property";

export const propertyIsSettingOn = (property: tProperty, path: string | string[]) => {
  const paths = _isString(path) ? [path] : path;
  for (const path of paths) {
    if (!_get(property.settings, path)) return false;
  }
  return true;
};

export const propertyAreAllSettingsOn = (property: tProperty, paths: string[]) => {
  for (const path of paths) {
    if (!_get(property.settings, path)) return false;
  }
  return true;
};

export const propertyIsOneSettingOn = (property: tProperty, paths: string[]) => {
  for (const path of paths) {
    if (_get(property.settings, path)) return true;
  }
  return false;
};
