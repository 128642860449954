import { ReactComponent as CompostIcon } from "@/assets/icons/compost.svg";
import { ReactComponent as DateRangeIcon } from "@/assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as RecyclingIcon } from "@/assets/icons/recycling.svg";
import Flex from "@/components/Flex";
import ManualDataModal from "@/components/Modals/ManualDataModal";
import PageSection from "@/components/PageSection";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { tIconElement } from "@/interfaces/icon";
import { WASTE_TYPES } from "@/interfaces/manualData";
import { tWithRequired } from "@/interfaces/others";
import { tPropertyManualData } from "@/models/property";

import { propertyGetManualDataOrdered } from "@/utils/property/manualData";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { has, min, sum } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";

type tTableItem = tWithRequired<tPropertyManualData, "waste">;

const ListWrapped: React.FC<{
  manualData: tTableItem[];
}> = ({ manualData }) => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState<
    { open: false; manualDataId: undefined } | { open: true; manualDataId: string }
  >({ open: false, manualDataId: undefined });
  const permissionsList = usePermissions();

  const tableData: tTableItem[] = manualData;

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM + TABLE_HEADER_HEIGHT;

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (hasPermission(permissionsList, "update:manual-data")) {
      options.push({
        key: "edit",
        label: trans("components.manual_data.global_edit_entry.label"),
        onClick() {
          setManualDataModal({ open: true, manualDataId: item._id });
        },
      });
    }

    return options;
  };

  const wasteTypeColumn = (
    label: string,
    key: keyof Required<tPropertyManualData>["waste"],
    icon: tIconElement
  ) => (
    <Table.Column flexGrow={1}>
      <SimpleHeaderCell name={label} icon={icon} />
      <SimpleKeyValueCell
        dataKey={`waste.${key}`}
        textFunction={(item: tTableItem) => {
          return `${item.waste![key].totalKg} kg`;
        }}
      />
    </Table.Column>
  );

  return (
    <>
      <ManualDataModal
        singleStep
        initialStep="waste"
        open={manualDataModal.open}
        onClose={() => setManualDataModal({ open: false, manualDataId: undefined })}
        manualDataId={manualDataModal.manualDataId}
      />
      <div className="table-wrapper">
        <Table
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell name={trans("general.period")} icon={DateRangeIcon} />
            <SimpleKeyValueCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.total")} icon={DeleteIcon} />
            <SimpleKeyValueCell
              dataKey="waste"
              textFunction={(item: tTableItem) => {
                const totalKg = sum(WASTE_TYPES.map((t) => item.waste![t].totalKg));

                return `${totalKg} kg`;
              }}
            />
          </Table.Column>
          {wasteTypeColumn(trans("general.waste_types.mixed"), "mixed", DeleteIcon)}
          {wasteTypeColumn(trans("general.waste_types.organic"), "organic", CompostIcon)}
          {wasteTypeColumn(trans("general.waste_types.plastic"), "plastic", RecyclingIcon)}
          {wasteTypeColumn(trans("general.waste_types.paper"), "paper", RecyclingIcon)}
          {wasteTypeColumn(trans("general.waste_types.glass"), "glass", RecyclingIcon)}
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iListProps {}

const List: React.FC<iListProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const manualDataOrdered = propertyGetManualDataOrdered(activeProperty);

  const filteredManualData: tWithRequired<tPropertyManualData, "waste">[] =
    manualDataOrdered.filter(
      (item) => has(item, "waste") && sum(WASTE_TYPES.map((t) => item.waste![t].totalKg))
    ) as tWithRequired<tPropertyManualData, "waste">[];

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.waste.archive.sections.list.title")}
        icon={DeleteIcon}
        description={trans("pages.waste.archive.sections.list.description")}
      />
      <ListWrapped {...{ manualData: filteredManualData }} />
    </Flex>
  );
};

export default React.memo(List);
