import useEffectSafe from "@/hooks/useEffectSafe";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { _find, _has } from "@/lodash-utils";
import { tManager } from "@/models/manager";
import tProperty from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { RUN_CONTEXT } from "@/utils/context";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { defaultManager } from "@/utils/managers";
import { sleep } from "@/utils/others";
import { defaultProperty } from "@/utils/property";
import axios, { AxiosResponse } from "axios";
import { findIndex } from "lodash";
import React, { useMemo, useReducer } from "react";
import useAuthDispatch from "../Auth/hooks/useAuthDispatch";
import { setProfile, useProfileDispatch } from "../Profile/hooks";
import { nPropertyContext } from "./interfaces";

const PropertyDispatchContext = React.createContext<nPropertyContext.tDispatchContext | undefined>(
  undefined
);
PropertyDispatchContext.displayName = "PropertyDispatchContext";
const PropertyStateContext = React.createContext<nPropertyContext.tStateContext | undefined>(
  undefined
);
PropertyStateContext.displayName = "PropertyStateContext";

const LS_DATA = "__h__";

const initialState: nPropertyContext.tState = {
  data: {
    properties: [],
    activeProperty: "",
  },
  status: "pending",
  error: null,
};

const reducer = (
  state: nPropertyContext.tState,
  action: nPropertyContext.tAction
): nPropertyContext.tState => {
  try {
    switch (action.type) {
      case "set data": {
        const { data } = action;
        localStorage.setItem(LS_DATA, JSON.stringify(data));
        return { ...state, status: "resolved", error: null, data };
      }
      case "select property": {
        const { activeProperty } = action;

        const data = { ...state.data, activeProperty };
        localStorage.setItem(LS_DATA, JSON.stringify(data));

        return { ...state, data };
      }
      case "set properties": {
        const { properties } = action;
        const data = {
          ...state.data,
          properties: properties.map((h) => ({
            ...defaultProperty,
            ...h,
            settings: {
              ...defaultProperty.settings,
              ...h.settings,
              display: { ...defaultProperty.settings.display, ...h.settings.display },
            },
          })) as nPropertyContext.tPropertyAugmented[],
        };
        localStorage.setItem(LS_DATA, JSON.stringify(data));

        return { ...state, status: "resolved", error: null, data };
      }
      case "update property": {
        const { propertyId, property } = action;
        const index = findIndex(state.data.properties, (h) => h._id === propertyId);
        if (index >= 0) {
          state.data.properties[index] = { ...state.data.properties[index], ...property };
          state.data.properties = [...state.data.properties];
        }
        localStorage.setItem(LS_DATA, JSON.stringify(state.data));

        return { ...state, status: "resolved", error: null };
      }
      case "resolved": {
        return { ...state, status: "resolved", error: null };
      }
      case "rejected": {
        const { error } = action;
        return { ...state, status: "rejected", error };
      }
      case "pending": {
        return { ...state, status: "pending", error: null };
      }
      default:
        return { ...state };
    }
  } catch (err) {
    return { ...state };
  }
};

const PropertyContextProvider: React.FC<nPropertyContext.iContextProps> = ({ children }) => {
  const toaster = useSimpleToaster();
  const [state, dispatch]: [nPropertyContext.tState, React.Dispatch<nPropertyContext.tAction>] =
    useReducer(reducer, initialState, (initialState) => {
      const data = localStorage.getItem(LS_DATA);

      if (!data) return JSON.parse(JSON.stringify(initialState));

      const parsedData = JSON.parse(data);
      const translatedData = {
        ...parsedData,
        properties: [...(parsedData.properties || []), ...(parsedData.hotels || [])],
      };

      return JSON.parse(
        JSON.stringify({
          ...initialState,
          data: { ...initialState.data, ...translatedData },
        })
      );
    });
  const { logout } = useAuthDispatch();
  const profileDispatch = useProfileDispatch();

  useEffectSafe(() => {
    async function main() {
      dispatch({ type: "pending" });

      let error: any = null;
      for (let tries = 0; tries < RUN_CONTEXT.PROPERTY.max_tries; tries++) {
        try {
          const result: AxiosResponse<{
            properties: (tProperty & { ipValidated: boolean })[];
            manager: tManager;
          }> = await axios.get(ENDPOINTS.MANAGERS.PROPERTIES.LIST);
          const {
            data: { properties, manager },
          } = result;
          setProfile(profileDispatch, manager || defaultManager);
          dispatch({ type: "set properties", properties: properties || [] });
          return;
        } catch (err: any) {
          error = err;
          if (_has(err, "response.status")) {
            if (err.response.status === 403) {
              logout();
              break;
            } else if (err.response.status === 404) {
              if (["Manager not found"].includes(err.response.data.message)) {
                logout();
                break;
              }
            }
          }
          await sleep(3000);
        }
      }
      if (error) dispatch({ type: "rejected", error: getErrorMessage(error) });
    }

    if (
      window.location.pathname.includes("setup-password") ||
      window.location.pathname.includes("forgot-password")
    )
      logout();
    else {
      main();
    }
  }, [logout, toaster]);

  console.log("PROPERTY CONTEXT PROVIDER");

  const activeProperty = useMemo(() => {
    try {
      const found = state.data.properties?.find((h) => h._id === state.data.activeProperty);
      return JSON.parse(
        JSON.stringify({ ...defaultProperty, ...(found || state.data.properties[0]) })
      );
    } catch (err) {
      return JSON.parse(JSON.stringify(defaultProperty));
    }
  }, [state.data.properties, state.data.activeProperty]);

  const activeSubscription = useMemo(() => {
    return (
      _find<tProperty["subscriptions"][0]>(activeProperty.subscriptions, (s) => s.active) ||
      ({
        type: "starter",
        active: true,
        timestamp: new Date(),
        startAt: new Date(),
      } as tProperty["subscriptions"][0])
    );
  }, [activeProperty]);

  const value = useMemo(
    () => ({ state, activeProperty, activeSubscription }),
    [state, activeProperty, activeSubscription]
  );

  return (
    <PropertyStateContext.Provider value={value}>
      <PropertyDispatchContext.Provider value={dispatch}>
        {children}
      </PropertyDispatchContext.Provider>
    </PropertyStateContext.Provider>
  );
};

export { PropertyContextProvider, PropertyDispatchContext, PropertyStateContext };
