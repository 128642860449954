import {
  DATA_TYPE_TOGGLE_ARCHIVES,
  DATA_TYPE_TOGGLE_LIVE,
  DATA_TYPE_TOGGLE_LS,
  tDataTypeToggle,
} from "@/utils/dataTypeToggle";
import { propertyActiveSubscriptionTypeIsEnterprise } from "@/utils/property/subscriptions";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { usePropertyState } from "../Property/hooks";

const DataTypeDispatchContext = React.createContext<{
  setDataType: (dataType: tDataTypeToggle) => void;
  dataTypeIsLive: boolean;
  dataTypeIsArchives: boolean;
}>({
  setDataType: () => {},
  dataTypeIsLive: false,
  dataTypeIsArchives: false,
});

export const useDataType = () => {
  const context = useContext(DataTypeDispatchContext);
  if (!context) {
    throw new Error("useDataType must be used within a DataTypeContextProvider");
  }
  return context;
};

const DataTypeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { activeProperty } = usePropertyState();
  const [dataType, setDataType] = useState<tDataTypeToggle>(() => {
    const get = () => {
      const stored = localStorage.getItem(DATA_TYPE_TOGGLE_LS);
      switch (stored) {
        case DATA_TYPE_TOGGLE_ARCHIVES:
          return DATA_TYPE_TOGGLE_ARCHIVES;
        case DATA_TYPE_TOGGLE_LIVE:
          return DATA_TYPE_TOGGLE_LIVE;
      }
      return DATA_TYPE_TOGGLE_LIVE;
    };
    const dataType = propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty })
      ? get()
      : DATA_TYPE_TOGGLE_ARCHIVES;
    return dataType;
  });

  useEffect(() => {
    const get = () => {
      const stored = localStorage.getItem(DATA_TYPE_TOGGLE_LS);
      switch (stored) {
        case DATA_TYPE_TOGGLE_ARCHIVES:
          return DATA_TYPE_TOGGLE_ARCHIVES;
        case DATA_TYPE_TOGGLE_LIVE:
          return DATA_TYPE_TOGGLE_LIVE;
      }
      return DATA_TYPE_TOGGLE_LIVE;
    };
    if (!propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty })) {
      const newDataType = get();
      if (newDataType !== dataType) {
        localStorage.setItem(DATA_TYPE_TOGGLE_LS, newDataType);
        setDataType(newDataType);
      }
    }
  }, [activeProperty, dataType]);

  const value = useMemo(() => {
    const handleSetDataType = (dataType: tDataTypeToggle) => {
      localStorage.setItem(DATA_TYPE_TOGGLE_LS, dataType);
      setDataType(dataType);
    };
    return {
      dataTypeIsLive: dataType === DATA_TYPE_TOGGLE_LIVE,
      dataTypeIsArchives: dataType === DATA_TYPE_TOGGLE_ARCHIVES,
      setDataType: handleSetDataType,
    };
  }, [dataType]);

  return (
    <DataTypeDispatchContext.Provider value={value}>{children}</DataTypeDispatchContext.Provider>
  );
};

export default DataTypeContextProvider;
