import moment, { MomentInput } from "moment";
import { _chunk, _round, _sum } from "@/lodash-utils";

export function binCalculation(startDate: MomentInput, endDate: MomentInput) {
  let binUnit: "day" | "week" | "month" | "year" = "day";
  let binSize = 1;
  if (moment(endDate).diff(moment(startDate), "days") > 60) {
    binUnit = "day";
    binSize = 14;
  } else if (moment(endDate).diff(moment(startDate), "days") > 31) {
    binUnit = "day";
    binSize = 7;
  }
  return { binUnit, binSize };
}

export function generateBinData(data: number[], binSize: number) {
  if (data.length === 0) return [];
  return _chunk(data, binSize).map((chunk) => _round(_sum(chunk), 2));
}
