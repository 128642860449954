import { isAxiosError } from "axios";
import { _has } from "@/lodash-utils";

export const getErrorMessage = (
  error: any,
  trans: (text: string) => string = (text: string) => text
) => {
  if (isAxiosError(error)) {
    switch (error.code) {
      case "ERR_NETWORK":
        return trans("Network Error");
    }
    // return error.message;
  }
  if (_has(error, "response.data.message")) return error.response.data.message;

  if (_has(error, "message")) return error.message;

  return trans("Something went wrong");
};
