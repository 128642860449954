import Flex from "@/components/Flex";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { usePropertyState } from "@/context/Property/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import useGetConsumptionSeries, {
  tConsumptionSeries,
} from "@/hooks/consumption/useGetConsumptionSeries";
import { COLORS } from "@/utils/colors";
import { propertyGetBaseMeasuresTrackedV2 } from "@/utils/property/measures";
import { UseQueryResult } from "@tanstack/react-query";
import moment from "moment";
import { createContext, useContext } from "react";

const SustainabilityLiveContext = createContext<{
  mainRequest: UseQueryResult<tConsumptionSeries>;
  homoRequest: UseQueryResult<tConsumptionSeries>;
} | null>(null);

const SustainabilityLiveContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();
  const trackedMeasures = propertyGetBaseMeasuresTrackedV2(activeProperty);

  const doRequest = trackedMeasures.e || trackedMeasures.w;

  const [mainRequest, homoRequest] = useGetConsumptionSeries("sustainability.live", [
    {
      enabled: doRequest,
      startDate: sensorDataTimeframe[0],
      endDate: sensorDataTimeframe[1],
      spaces: activeProperty.spaces.filter((s) => s.isAccommodation).map((s) => s._id),
      binUnit: "day",
      binSize: 1,
    },
    {
      enabled: false,
      startDate: moment(sensorDataTimeframe[0]).subtract(1, "year").toISOString(),
      endDate: moment(sensorDataTimeframe[1]).subtract(1, "year").toISOString(),
      spaces: activeProperty.spaces.filter((s) => s.isAccommodation).map((s) => s._id),
      binUnit: "day",
      binSize: 1,
    },
  ]);

  return (
    <SustainabilityLiveContext.Provider value={{ mainRequest, homoRequest }}>
      {doRequest ? (
        children
      ) : (
        <Flex>
          <InterTag stize={14} color={COLORS.error} text={trans("No data from sensors")} />
        </Flex>
      )}
    </SustainabilityLiveContext.Provider>
  );
};

const useSustainabilityLiveContext = () => {
  const context = useContext(SustainabilityLiveContext);
  if (!context) {
    throw new Error(
      "useSustainabilityLiveContext must be used within a SustainabilityLiveContextProvider"
    );
  }
  return context;
};

export { SustainabilityLiveContext, useSustainabilityLiveContext };

export default SustainabilityLiveContextProvider;
