import { useTimeframeState } from "@/context/Timeframe";
import { tUseGetRequest } from "@/hooks/apiRequests/useGetRequest";
import useGetConsumptionSeries, {
  tConsumptionSeries,
} from "@/hooks/consumption/useGetConsumptionSeries";
import useGetDefaultConverionFactors, {
  tConversionFactors,
} from "@/hooks/useGetDefaultConverionFactors";
import { _round } from "@/lodash-utils";
import { add2Arrays } from "@/utils/arrays";
import { binCalculation, generateBinData } from "@/utils/binning";
import { UseQueryResult } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const ConsumptionLiveDataContext = createContext<
  | {
      binnedSeries: {
        w: number[];
        e: number[];
        co2Total: number[];
        eCo2: number[];
        wCo2: number[];
      };
      binInfo: { binUnit: "day" | "week" | "month" | "year"; binSize: number };
      mainRequest: UseQueryResult<tConsumptionSeries>;
      setBinInfo: (binInfo: {
        binUnit: "day" | "week" | "month" | "year";
        binSize: number;
      }) => void;
      conversionFactors: tUseGetRequest<tConversionFactors>;
    }
  | undefined
>(undefined);

const ConsumptionLiveDataContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const conversionFactors = useGetDefaultConverionFactors("PT");
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();

  const [binInfo, setBinInfo] = useState<{
    binUnit: "day" | "week" | "month" | "year";
    binSize: number;
  }>(() => {
    const { binUnit, binSize } = binCalculation(sensorDataTimeframe[0], sensorDataTimeframe[1]);
    return { binUnit, binSize };
  });
  useEffect(() => {
    const { binUnit, binSize } = binCalculation(sensorDataTimeframe[0], sensorDataTimeframe[1]);
    if (binUnit !== binInfo.binUnit || binSize !== binInfo.binSize) {
      setBinInfo({ binUnit, binSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorDataTimeframe]);

  const [mainRequest] = useGetConsumptionSeries("consumption.live", [
    {
      startDate: sensorDataTimeframe[0],
      endDate: sensorDataTimeframe[1],
      automaticBin: true,
      binSize: 1,
      binUnit: "day",
      enabled: true,
    },
  ] as any);

  const co2Series = useMemo(() => {
    if (!mainRequest.isSuccess || conversionFactors.isLoading)
      return { totalCo2: [], eCo2: [], wCo2: [] };
    const eCo2 = (mainRequest.data.e || []).map((e: number) =>
      _round((e / 1000) * conversionFactors.data.electricity_kWh_to_kgCO2e, 2)
    );
    const wCo2 = (mainRequest.data.w || []).map((w: number) =>
      _round((w / 1000) * conversionFactors.data.water_m3_to_kgCO2e, 2)
    );
    const totalCo2 = add2Arrays(eCo2, wCo2);
    return { totalCo2, eCo2, wCo2 };
  }, [
    conversionFactors.data.electricity_kWh_to_kgCO2e,
    conversionFactors.data.water_m3_to_kgCO2e,
    conversionFactors.isLoading,
    mainRequest,
  ]);

  const binnedCo2Series = useMemo(() => {
    return {
      total: generateBinData(co2Series.totalCo2, binInfo.binSize),
      e: generateBinData(co2Series.eCo2, binInfo.binSize),
      w: generateBinData(co2Series.wCo2, binInfo.binSize),
    };
  }, [co2Series, binInfo.binSize]);

  const binnedConsumptionSeries = useMemo(() => {
    return {
      w: generateBinData(mainRequest.data?.w || [], binInfo.binSize),
      e: generateBinData(mainRequest.data?.e || [], binInfo.binSize),
    };
  }, [binInfo.binSize, mainRequest]);

  const binnedSeries = useMemo(() => {
    return {
      co2Total: binnedCo2Series.total,
      eCo2: binnedCo2Series.e,
      wCo2: binnedCo2Series.w,
      e: binnedConsumptionSeries.e,
      w: binnedConsumptionSeries.w,
    };
  }, [binnedCo2Series, binnedConsumptionSeries]);

  return (
    <ConsumptionLiveDataContext.Provider
      value={{
        binnedSeries,
        binInfo,
        setBinInfo,
        mainRequest,
        conversionFactors,
      }}
    >
      {children}
    </ConsumptionLiveDataContext.Provider>
  );
};

export const useConsumptionLiveDataContext = () => {
  const context = useContext(ConsumptionLiveDataContext);
  if (!context) {
    throw new Error(
      "useConsumptionLiveDataContext must be used within a ConsumptionLiveDataContextProvider"
    );
  }
  return context;
};

export default ConsumptionLiveDataContextProvider;
