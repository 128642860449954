import { tProperty, tPropertySubscriptionModel } from "@/models/property";

export const BILLING_TYPES: tPropertySubscriptionModel["type"][] = [
  "starter",
  "business",
  "enterprise",
];

export const defaultProperty: tProperty = {
  _id: "",
  certifiedPropertyId: "",
  guestApp: { name: "", description: "", images: [] },

  waterToCurrency: [],
  waterToFootprint: [],
  energyToCurrency: [],
  energyToFootprint: [],
  currency: "EUR",

  loyaltyProgram: [],
  performanceProgram: [],

  tourismRegister: { type: "", _id: 0 },

  awards: [],
  challenges: [],

  spaces: [],
  spaceAggregates: [],

  conservationLabs: {
    devices: [],
  },
  shelly: {
    devices: [],
  },
  devicesLocation: [],

  staffRoles: [],
  staff: [],

  roadmap: [],

  name: "",
  email: "",
  phoneNumber: "",

  accommodationType: "",
  accommodationNumberOfUnits: 0,
  bills: [],
  country: "",
  description: "",
  guestRecords: [],
  images: [],
  isDummyHotel: false,
  location: {
    address: "",
    mapsUrl: "",
    coordinates: [0, 0],
    country: "",
    county: "",
    district: "",
    postalCode: "",
  },
  readings: { energy: [], gas: [], water: [] },
  fleet: [],
  reports: [],
  website: "",
  timestreamDBName: "",
  hasTimestreamDB: false,

  manualData: [],
  isSetup: false,

  settings: {
    consumptionDataQueryMethod: "multitable-v1",

    display: {
      numbers: {
        thousandSeparator: ".",
        decimalSeparator: ",",
      },
    },

    menu: {
      properties: { show: false },
      overview: { show: true },
      sustainability: { show: true },
      spaces: { show: true },
      guests: { show: true },
      occupancy: { show: true },
      consumption: { show: true },
      water: { show: true },
      electricity: { show: true },
      fuels: { show: true },
      waste: { show: true },
      laundry: { show: true },
      awards: { show: true },
      challenges: { show: true },
      loyalty: { show: true },
      staff: { show: true },
    },

    pages: {
      roadmap: { show: true },
      stays: { writeStays: false },
      sustainability: { guests: { show: true } },
      dashboard: {
        checkIn: { show: true },
        checkOut: { show: true },
        occupancy: { show: true },
        guests: { show: true },
      },
      spaces: {
        consumption: {
          co2: { show: true },
          electricity: { show: true },
          water: { show: true },
          costs: { show: true },
        },
      },

      consumption: {
        water: { show: true },
        electricity: { show: true },
      },

      water: {
        live: {
          events: {
            show: true,
            table: {
              columns: {
                source: { show: true },
              },
            },
          },
        },
      },

      guestAppProfile: { show: false },
    },
  },

  subscriptions: [],
  firstSubscriptionIsSet: false,
  billing: [],

  ipRestrictions: {
    whiteList: { active: false, ips: [] },
    blackList: { active: false, ips: [] },
  },

  invoices: [],
  paymentMethods: [],

  stripe: { customerId: "", defaultCard: "", invoices: [] },
  stripeLive: { customerId: "", defaultCard: "", invoices: [] },
  stripeTest: { customerId: "", defaultCard: "", invoices: [] },

  createdAt: new Date(),
  updatedAt: new Date(),
  isHotel: true,

  isVerified: false,

  metadata: {
    measuresCalculation: {
      electricity: { locationIdsForTotalConsumption: [] },
      water: { locationIdsForTotalConsumption: [] },
    },
  },

  calculations: {
    electricity: { meanTE_kWh: 0 },
    water: { meanTW_m3: 0 },
  },
};
