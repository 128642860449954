import React, { useReducer, useEffect } from "react";
import { nAuth } from "./interfaces";
import axios from "axios";

const AuthDispatchContext = React.createContext<nAuth.tDispatchContext | undefined>(undefined);
AuthDispatchContext.displayName = "AuthDispatchContext";
const AuthStateContext = React.createContext<nAuth.tStateContext | undefined>(undefined);
AuthStateContext.displayName = "AuthStateContext";

const LS_DATA = "__t__";

const initialState: nAuth.tState = {
  data: {
    token: null,
  },
  status: "idle",
  error: null,
};

const reducer = (state: nAuth.tState, action: nAuth.tAction): nAuth.tState => {
  switch (action.type) {
    case "set data": {
      const {
        data,
        data: { token },
      } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));

      if (token) {
        axios.defaults.headers.common["token"] = token;
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
      }

      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const AuthContextProvider: React.FC<nAuth.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nAuth.tState, React.Dispatch<nAuth.tAction>] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    try {
      dispatch({ type: "pending" });

      const stored = localStorage.getItem(LS_DATA);

      if (!stored) {
        return dispatch({ type: "set data", data: { token: null } });
      }
      const data = JSON.parse(stored) as nAuth.tStateData;
      return dispatch({ type: "set data", data });
    } catch (err) {
      return dispatch({ type: "set data", data: { token: null } });
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export { AuthContextProvider, AuthDispatchContext, AuthStateContext };
