import { Badge, Nav } from "rsuite";
import useContentDimensionsState from "@/context/ContentDimensions/hooks/useContentDimensions";
import { COLORS } from "@/utils/colors";
import { _isNumber } from "@/lodash-utils";
import Flex from "../Flex";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";

const Segmentation = ({
  active,
  onSelect,
  options,
  ...props
}: {
  active: string;
  onSelect(eventKey: string, event: any): void;
  options: {
    key: string;
    label: string | iTextProps;
    count?: number | null;
    disabled?: boolean;
    onClick?(eventKey: string | undefined, event: React.SyntheticEvent<Element, Event>): void;
  }[];
  [key: string]: any;
}) => {
  const contentDimensions = useContentDimensionsState();
  return (
    <Nav
      {...{ ...props, className: `${props.className} width-100` }}
      activeKey={active}
      onSelect={onSelect}
    >
      <Flex row>
        {options.map(({ label, key, count, disabled, onClick }, i) => {
          return (
            <Nav.Item
              eventKey={key}
              key={key}
              disabled={disabled}
              style={{ padding: "8px 8px" }}
              onSelect={(eventKey, event) => {
                onClick && onClick(eventKey, event);
              }}
            >
              <Flex row gap={8}>
                <InterTag
                  defaultLineHeight
                  {...{
                    size: contentDimensions.segmentation.label,
                    ...toTextProps(label),
                    color: "",
                  }}
                />
                {_isNumber(count) && (
                  <Badge
                    style={{
                      backgroundColor: active === key ? COLORS.primary : COLORS.gray_300,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    content={
                      <InterTag
                        {...{ size: contentDimensions.segmentation.count }}
                        defaultLineHeight
                        text={`${count}`}
                        color={active === key ? COLORS.white : COLORS.secondary}
                      />
                    }
                  />
                )}
              </Flex>
            </Nav.Item>
          );
        })}
      </Flex>
    </Nav>
  );
};

export default Segmentation;
