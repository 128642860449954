import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as EventRepeatIcon } from "@/assets/icons/event_repeat.svg";
import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/meeting_room.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/icons/visibility.svg";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useGetConsumptionTotalBySpace, {
  tConsumptionTotalBySpace,
} from "@/hooks/consumption/useGetConsumptionTotalBySpace";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import useScreenSize from "@/hooks/useScreenSize";
import { tIconElement } from "@/interfaces/icon";
import { _has, _round, _sum } from "@/lodash-utils";
import { tPropertySpace } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { convertMassUnit } from "@/utils/convertUnits";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { UseQueryResult } from "@tanstack/react-query";
import { floor, min } from "lodash";
import moment, { MomentInput } from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "rsuite";
import TrendingComparison from "../Comparions/trendingComparison";
import SimpleTableWrapper from "../RsuiteWrapper/SimpleTable";
import BaseCell from "../RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../Text/Inter";

interface iSpaceMeasureConsumptionTableWrappedProps extends iSpaceMeasureConsumptionTableProps {
  outletContainerWidth: number;
}

const SpaceMeasureConsumptionTableWrapped: React.FC<iSpaceMeasureConsumptionTableWrappedProps> = ({
  from,
  to,
  outletContainerWidth,
  mainMeasureKey2,
  converter,
  tableHeaderIcon,
  tableHeaderIconFill,
  tableHeaderLabel,
}) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const { activeProperty } = usePropertyState();
  const conversionFactors = useGetDefaultConverionFactors("PT");

  const hoursToSubtract = moment(to).diff(moment(from), "hours");

  const [mainRequest, homoRequest, prevRequest] = useGetConsumptionTotalBySpace(
    activeProperty._id,
    [
      {
        enabled: true,
        measures: [mainMeasureKey2],
        timeframe: [from, to],
      },
      {
        enabled: true,
        measures: [mainMeasureKey2],
        timeframe: [from, to].map((d) => moment(d).subtract(1, "year").toISOString()) as [
          MomentInput,
          MomentInput
        ],
      },
      {
        enabled: true,
        measures: [mainMeasureKey2],
        timeframe: [from, to].map((d) =>
          moment(d).subtract(hoursToSubtract, "hours").toISOString()
        ) as [MomentInput, MomentInput],
      },
    ]
  ) as UseQueryResult<tConsumptionTotalBySpace>[];

  const options = (
    _dataKey: string,
    space: tPropertySpace
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "view",
      label: trans("components.consumption_page_specific.space_list.table.options.view_space"),
      icon: VisibilityIcon,
      onClick() {
        navigate(`/spaces/${space._id}`);
      },
    });

    return options;
  };

  type tTableData = Omit<tPropertySpace, "consumptionMetadata" | "tags" | "tagList"> & {
    isTotal?: boolean;
  };
  const tableData: tTableData[] = [
    {
      _id: "0",
      isAccommodation: true,
      timestamp: new Date(),
      isTotal: true,
      name: trans("general.all_spaces"),
      areaInSquareMeters: undefined,
      removable: false,
      measures: {
        ac: { isMeasured: true, add: [], subtract: [] },
        cw: { isMeasured: true, add: [], subtract: [] },
        el: { isMeasured: true, add: [], subtract: [] },
        es: { isMeasured: true, add: [], subtract: [] },
        gw: { isMeasured: true, add: [], subtract: [] },
        hw: { isMeasured: true, add: [], subtract: [] },
        dw: { isMeasured: true, add: [], subtract: [] },
        ntw: { isMeasured: true, add: [], subtract: [] },
        te: { isMeasured: true, add: [], subtract: [] },
        tw: { isMeasured: true, add: [], subtract: [] },
        wh: { isMeasured: true, add: [], subtract: [] },
      },
    },
    ...activeProperty.spaces,
  ];

  const tableHeight = (min([10, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  const spaceWidth = floor(0.15 * outletContainerWidth);
  const valueWidth = floor(0.15 * outletContainerWidth);
  const paymentsWidth = floor(0.15 * outletContainerWidth);
  const ecoWidth = floor(0.15 * outletContainerWidth);
  const lastPeriodWidth = floor(0.15 * outletContainerWidth);
  const homologousWidth = floor(0.15 * outletContainerWidth);

  return (
    <div>
      <SimpleTableWrapper
        id="TABLE.CONSUMPTION.SPACES.LIST"
        height={tableHeight}
        rowHeight={TABLE_ROW_HEIGHT.M}
        headerHeight={TABLE_HEADER_HEIGHT}
        data={tableData}
      >
        <Table.Column width={spaceWidth}>
          <SimpleHeaderCell icon={MeetingRoomIcon} name={trans("general.space")} />
          <SimpleKeyValueCell dataKey="name" />
        </Table.Column>
        <Table.Column width={valueWidth}>
          <SimpleHeaderCell
            {...{
              icon: { Element: tableHeaderIcon, fill: tableHeaderIconFill },
              name: { text: tableHeaderLabel, asHTML: true },
            }}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (mainRequest.isError)
                return <InterTag text={trans("general.error")} size={12} color={COLORS.error} />;

              if (mainRequest.isFetching) return <Loader size="sm" />;

              let total = 0;
              if (tableItem.isTotal) {
                total = _sum(Object.values(mainRequest.data!).map((d) => d[mainMeasureKey2]));
              } else {
                if (!_has(mainRequest.data!, tableItem._id))
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                total = mainRequest.data![tableItem._id][mainMeasureKey2];
              }

              const measureValue = converter(total);

              return (
                <InterTag
                  text={`${_round(measureValue.value, 2)} ${measureValue.unitInHTML}`}
                  asHTML
                  size={12}
                  color={COLORS.secondary}
                />
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={ecoWidth}>
          <SimpleHeaderCell
            icon={{ Element: EcoIcon, fill: COLORS.emissions }}
            name={{ text: trans("general.co_2_emissions"), asHTML: true }}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (mainRequest.isError || conversionFactors.isRejected)
                return <InterTag text={trans("general.error")} size={12} color={COLORS.error} />;

              if (mainRequest.isFetching || conversionFactors.isLoading)
                return <Loader size="sm" />;

              let total = 0;

              if (tableItem.isTotal) {
                total = _sum(Object.values(mainRequest.data!).map((d) => d[mainMeasureKey2]));
              } else {
                if (!_has(mainRequest.data!, tableItem._id))
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                total = mainRequest.data![tableItem._id][mainMeasureKey2];
              }

              const footprint = convertMassUnit(
                total *
                  conversionFactors.data[
                    mainMeasureKey2 === "w" ? "water_m3_to_kgCO2e" : "electricity_kWh_to_kgCO2e"
                  ]
              );

              return (
                <InterTag
                  text={`${_round(footprint.value, 2)} ${footprint.unitInHTML}`}
                  asHTML
                  size={12}
                  color={COLORS.secondary}
                />
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={lastPeriodWidth}>
          <SimpleHeaderCell icon={EventRepeatIcon} name={trans("general.period_previous")} />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (mainRequest.isError || prevRequest.isError)
                return <InterTag text={trans("general.error")} size={12} color={COLORS.error} />;

              if (mainRequest.isFetching || prevRequest.isFetching) return <Loader size="sm" />;

              let mainTotal = 0;
              let prevTotal = 0;

              if (tableItem.isTotal) {
                mainTotal = _sum(Object.values(mainRequest.data!).map((d) => d[mainMeasureKey2]));
                prevTotal = _sum(Object.values(prevRequest.data!).map((d) => d[mainMeasureKey2]));
              } else {
                if (
                  !_has(mainRequest.data, tableItem._id) ||
                  !_has(prevRequest.data, tableItem._id)
                )
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                mainTotal = mainRequest.data![tableItem._id][mainMeasureKey2];
                prevTotal = prevRequest.data![tableItem._id][mainMeasureKey2];
              }

              return <TrendingComparison baseValue={prevTotal} value={mainTotal} size={12} />;
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={homologousWidth}>
          <SimpleHeaderCell icon={EventRepeatIcon} name={trans("general.period_homologue")} />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (mainRequest.isError || homoRequest.isError)
                return <InterTag text={trans("general.error")} size={12} color={COLORS.error} />;

              if (mainRequest.isFetching || homoRequest.isFetching) return <Loader size="sm" />;

              let mainTotal = 0;
              let homoTotal = 0;

              if (tableItem.isTotal) {
                mainTotal = _sum(Object.values(mainRequest.data!).map((d) => d[mainMeasureKey2]));
                homoTotal = _sum(Object.values(homoRequest.data!).map((d) => d[mainMeasureKey2]));
              } else {
                if (
                  !_has(mainRequest.data, tableItem._id) ||
                  !_has(homoRequest.data, tableItem._id)
                )
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                mainTotal = mainRequest.data![tableItem._id][mainMeasureKey2];
                homoTotal = homoRequest.data![tableItem._id][mainMeasureKey2];
              }
              return <TrendingComparison baseValue={homoTotal} value={mainTotal} size={12} />;
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column flexGrow={1} align="right">
          <SimpleHeaderCell />
          <SimpleActionCell options={options} dataKey="_id" />
        </Table.Column>
      </SimpleTableWrapper>
    </div>
  );
};

interface iSpaceMeasureConsumptionTableProps {
  from: MomentInput;
  to: MomentInput;
  mainMeasureKey2: "w" | "e";
  converter: any;
  tableHeaderIcon: tIconElement;
  tableHeaderIconFill: string;
  tableHeaderLabel: string;
}

const SpaceMeasureConsumptionTable: React.FC<iSpaceMeasureConsumptionTableProps> = (props) => {
  const { outlet: oc } = useScreenSize();

  if (!oc) return null;
  return <SpaceMeasureConsumptionTableWrapped {...props} outletContainerWidth={oc.width} />;
};

export default React.memo(SpaceMeasureConsumptionTable);
