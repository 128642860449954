import { tPropertyManualData } from "@/models/property";

export type tManualDataFuel = keyof Pick<
  tPropertyManualData,
  "naturalGas" | "biomass" | "gasoline" | "diesel" | "propane" | "butane" | "ethanol"
>;

export const FUEL_TYPES: tManualDataFuel[] = [
  "biomass",
  "butane",
  "diesel",
  "gasoline",
  "propane",
  "naturalGas",
  "ethanol",
];

export type tManualDataWaste = keyof Required<tPropertyManualData>["waste"];

export const WASTE_TYPES: tManualDataWaste[] = ["glass", "mixed", "organic", "paper", "plastic"];
