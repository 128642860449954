import React from "react";
import { WindowSizeStateContext } from "../";

const useWindowSizeState = () => {
  const state = React.useContext(WindowSizeStateContext);

  if (!state) throw Error("useWindowSizeState must be used within WindowSizeStateContext");

  return state.data;
};

export default useWindowSizeState;
