import { ENDPOINTS } from "@/utils/api/endpoints";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useBusinessSubscriptionPrices = () => {
  const result = useQuery<{ pricesInCents: { month: number; year: number } }>({
    queryKey: ["business-subscription-prices"],
    initialData: { pricesInCents: { month: 0, year: 0 } },
    queryFn: async () => {
      return axios
        .get<{ pricesInCents: { month: number; year: number } }>(
          ENDPOINTS.PROPERTIES.SUBSCRIPTIONS.BUSINESS.PRICES
        )
        .then((res) => res.data);
    },
  });

  return result;
};
