import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as ReadinessScoreIcon } from "@/assets/icons/readiness_score.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import Gauge from "@/components/Graphs/Gauge";
import Icon from "@/components/Icons/Icon";
import IconBg from "@/components/Icons/IconBg";
import PageSection from "@/components/PageSection";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useProfileState } from "@/context/Profile/hooks";
import { usePropertyState } from "@/context/Property/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import { tConsumptionSeries } from "@/hooks/consumption/useGetConsumptionSeries";
import { tBenchmarkData, useGetBenchmarkData } from "@/hooks/useGetBenchmarkData";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { tIconElement } from "@/interfaces/icon";
import { _round, _sum } from "@/lodash-utils";
import { COLORS, hex2hexA } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { numberFormatter } from "@/utils/numbers";
import { propertyCountAccommodationSpaces } from "@/utils/property/spaces";
import moment from "moment";
import React from "react";
import { calcGrade } from "../utils";
import { useSustainabilityLiveContext } from "./context";

const InidicatorsWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const benchmarkDataRequest = useGetBenchmarkData();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {});
  const { profile } = useProfileState();
  const { activeProperty } = usePropertyState();
  const accommodationSpacesCount = propertyCountAccommodationSpaces(activeProperty);
  const { mainRequest } = useSustainabilityLiveContext();
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();
  const days =
    moment(sensorDataTimeframe[1])
      .endOf("day")
      .diff(moment(sensorDataTimeframe[0]).startOf("day"), "days") + 1;

  const cards: {
    key: string;
    label: string;
    icon: tIconElement;
    iconColor: string;
    isLoading: boolean;
    calcValue: (data: tConsumptionSeries) => number;
    benchmarkKey: keyof tBenchmarkData["perAccommodation"];
    converter: nUnitConverter.tFunction;
    benchmarkUnitMultiplier: number;
  }[] = [
    {
      key: "co2",
      label: trans("general.co_2_emissions"),
      icon: EcoIcon,
      iconColor: COLORS.emissions,
      isLoading: conversionFactorsRequest.isLoading || mainRequest.isFetching,
      calcValue: (data) =>
        (_sum(data.e) / 1000) * conversionFactorsRequest.data.electricity_kWh_to_kgCO2e +
        (_sum(data.w) / 1000) * conversionFactorsRequest.data.water_m3_to_kgCO2e,
      converter: convertMassUnit,
      benchmarkKey: "co2_kg",
      benchmarkUnitMultiplier: 1,
    },
    {
      key: "electricity",
      label: trans("general.measures_.electricity"),
      icon: ElectricBoltIcon,
      iconColor: COLORS.energy,
      isLoading: mainRequest.isFetching,
      calcValue: (data) => _sum(data.e),
      converter: convertEnergyUnit,
      benchmarkKey: "electricity_kWh",
      benchmarkUnitMultiplier: 1_000,
    },
    {
      key: "water",
      label: trans("general.measures_.water"),
      icon: WaterDropIcon,
      iconColor: COLORS.water,
      isLoading: mainRequest.isFetching,
      calcValue: (data) => _sum(data.w),
      converter: convertVolumeUnit,
      benchmarkKey: "water_m3",
      benchmarkUnitMultiplier: 1_000,
    },
  ];

  return (
    <div className="flex flex-row gap-4">
      {cards.map(
        ({
          key,
          label,
          icon,
          iconColor,
          isLoading,
          calcValue,
          converter,
          benchmarkKey,
          benchmarkUnitMultiplier,
        }) => {
          const value = calcValue(mainRequest.data!);
          const convertedValue = converter(value);
          const benchmarkValue =
            benchmarkDataRequest.data.perAccommodation[benchmarkKey] *
            accommodationSpacesCount *
            days *
            benchmarkUnitMultiplier;
          const [gradeTransKey, ratio, absolutePercentageDiff, highlight] = calcGrade(
            value,
            benchmarkValue
          );

          return (
            <div key={key} className="flex flex-col bg-white rounded-lg p-4 gap-2">
              <div className="flex flex-row items-center gap-2">
                <IconBg Element={icon} fill={iconColor} size={24} circle />
                <InterTag text={label} size={16} bold asHTML />
              </div>
              <div className="flex flex-col gap-3">
                {isLoading ? (
                  <>
                    <Gauge valueLoading={true} labelLoading={true} />
                    <SkeletonText width={"100%"} height={16} />
                    <SkeletonText width={"100%"} height={28} />
                  </>
                ) : (
                  <>
                    <Gauge
                      value={numberFormatter(convertedValue.value, profile)}
                      label={convertedValue.unit}
                      ratio={benchmarkDataRequest.isLoading ? undefined : ratio}
                    />

                    <div
                      className="flex flex-row rounded-md p-0.5 gap-1 items-center justify-center"
                      style={{ backgroundColor: hex2hexA(highlight.color, 20) }}
                    >
                      <Icon Element={highlight.icon} fill={highlight.color} size={16} />
                      <p className="inter text-xs" style={{ color: highlight.color }}>{`${_round(
                        absolutePercentageDiff,
                        2
                      )}% ${trans(highlight.labelTransKey)}`}</p>
                    </div>
                    <p className="inter text-lg text-center">{trans(gradeTransKey)}</p>
                  </>
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

const Indicators: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <PageSection
      icon={ReadinessScoreIcon}
      title={trans("pages.sustainability.sections.indicators.title")}
      description={trans("pages.sustainability.sections.indicators.description")}
    >
      <InidicatorsWrapped />
    </PageSection>
  );
};

export default Indicators;
