import React from "react";
import { IconButton } from "rsuite";
import { ReactComponent as SettingsIcon } from "@/assets/icons/more_horiz.svg";
import { COLORS } from "@/utils/colors";
import Icon from "../Icons/Icon";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../RsuiteWrapper/SimpleWhisperPopoverDropdown";

interface iProps {
  options: tSimpleWhisperPopoverDropdownOptions[];
}

const ActionsCell: React.FC<iProps> = ({ options }) => {
  return (
    <SimpleWhisperPopoverDropdown
      {...{
        options,
        placement: "autoHorizontalEnd",
      }}
    >
      <IconButton
        style={{ padding: 0, height: "fit-content" }}
        appearance="subtle"
        icon={<Icon Element={SettingsIcon} size={20} fill={COLORS.secondary} />}
      />
    </SimpleWhisperPopoverDropdown>
  );
};

export default ActionsCell;
