import moment from "moment";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";
import { _get } from "@/lodash-utils";
import Flex from "@/components/Flex";
import { iTextProps } from "@/components/Text";
import InterTag from "@/components/Text/Inter";
import BaseCell from "../BaseCell";

interface iProps {
  rowData?: Record<string, any>;
  dataKey: string;
  textProps?: (rowData: any, dataKey: string) => Omit<iTextProps, "text">;
  formatString?: string;
  showDiff?: boolean;
  [key: string]: any;
}

const SimpleDateCell = ({
  textProps,
  formatString = "DD MMM YYYY",
  showDiff = true,
  ...props
}: iProps) => {
  const { trans } = useLocalizationState();

  const { rowData, dataKey } = props;

  const targetDate = moment(_get(rowData, dataKey));
  const formattedDate = moment(targetDate).format(formatString).toLowerCase();

  const renderDiff = () => {
    if (!showDiff) return null;

    const now = moment();
    const diffText = (() => {
      if (targetDate.valueOf() > now.valueOf()) {
        const diff = targetDate.endOf("day").diff(now.startOf("day"), "days");
        if (diff > 0) {
          if (diff === 1) return trans("general.in_1_day");
          return trans("general.in_x_days", { parameters: [diff] });
        }
      } else if (targetDate.valueOf() < now.valueOf()) {
        const diff = now.endOf("day").diff(targetDate.startOf("day"), "days");
        if (diff > 0) {
          if (diff === 1) return trans("general.1_day_ago");
          return trans("general.x_days_ago", { parameters: [diff] });
        }
      }

      return trans("general.today");
    })();

    return <InterTag text={diffText} {...{ size: 10, color: COLORS.gray }} />;
  };

  return (
    <BaseCell {...{ ...props }}>
      <Flex column gap={4}>
        <InterTag
          text={formattedDate}
          {...{
            size: 12,
            color: COLORS.secondary,
            ...(textProps ? textProps(rowData, dataKey) : {}),
          }}
        />
        {renderDiff()}
      </Flex>
    </BaseCell>
  );
};

export default SimpleDateCell;
