import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

const LS_DATA = "__cnv-f__";

export type tConversionFactors = {
  electricity_kWh_to_kgCO2e: number;
  water_m3_to_kgCO2e: number;
  natural_gas_kWh_to_kgCO2e: number;
  gasoline_kWh_to_kgCO2e: number;
  gasoline_L_to_kgCO2e: number;
  diesel_kWh_to_kgCO2e: number;
  diesel_L_to_kgCO2e: number;
  ethanol_kWh_to_kgCO2e: number;
  ethanol_L_to_kgCO2e: number;
  butane_kWh_to_kgCO2e: number;
  butane_kg_to_kgCO2e: number;
  propane_kWh_to_kgCO2e: number;
  propane_kg_to_kgCO2e: number;
  biomass_kWh_to_kgCO2e: number;
  biomass_kg_to_kgCO2e: number;
};

type tStoredData = {
  data: tConversionFactors;
  timestamp: number;
};

const useGetDefaultConverionFactors = (
  country: string,
  {
    doRequest = true,
    initialStatus = REQUEST_STATUS.PENDING,
  }: { doRequest?: boolean; initialStatus?: nRequestStatus.tStatus } = {
    doRequest: true,
    initialStatus: REQUEST_STATUS.PENDING,
  }
) => {
  const { trans } = useLocalizationState();
  const factorsRequest = useGetRequest<tConversionFactors>(
    {
      electricity_kWh_to_kgCO2e: 0,
      water_m3_to_kgCO2e: 0,
      natural_gas_kWh_to_kgCO2e: 0,
      gasoline_kWh_to_kgCO2e: 0,
      gasoline_L_to_kgCO2e: 0,
      diesel_kWh_to_kgCO2e: 0,
      diesel_L_to_kgCO2e: 0,
      ethanol_kWh_to_kgCO2e: 0,
      ethanol_L_to_kgCO2e: 0,
      butane_kWh_to_kgCO2e: 0,
      butane_kg_to_kgCO2e: 0,
      propane_kWh_to_kgCO2e: 0,
      propane_kg_to_kgCO2e: 0,
      biomass_kWh_to_kgCO2e: 0,
      biomass_kg_to_kgCO2e: 0,
    },
    { status: initialStatus }
  );

  useEffectSafe(() => {
    if (doRequest) {
      factorsRequest.pending();
      try {
        const lsData = sessionStorage.getItem(LS_DATA);
        if (lsData) {
          const { data, timestamp } = JSON.parse(lsData) as tStoredData;
          if (Date.now() - timestamp < 1000 * 60 * 60 * 24) {
            factorsRequest.resolve(data);
            return;
          }
        }
      } catch (err) {}

      axios
        .get(ENDPOINTS.OTHERS.DEFAULT_CONVERSION_FACTORS)
        .then((res) => {
          const {
            data: {
              electricity_kWh_to_kgCO2e,
              water_m3_to_kgCO2e,
              natural_gas_kWh_to_kgCO2e,
              gasoline_kWh_to_kgCO2e,
              gasoline_L_to_kgCO2e,
              diesel_kWh_to_kgCO2e,
              diesel_L_to_kgCO2e,
              ethanol_kWh_to_kgCO2e,
              ethanol_L_to_kgCO2e,
              butane_kWh_to_kgCO2e,
              butane_kg_to_kgCO2e,
              propane_kWh_to_kgCO2e,
              propane_kg_to_kgCO2e,
              biomass_kWh_to_kgCO2e,
              biomass_kg_to_kgCO2e,
            },
          } = res;

          sessionStorage.setItem(
            LS_DATA,
            JSON.stringify({
              data: {
                electricity_kWh_to_kgCO2e,
                water_m3_to_kgCO2e,
                natural_gas_kWh_to_kgCO2e,
                gasoline_kWh_to_kgCO2e,
                gasoline_L_to_kgCO2e,
                diesel_kWh_to_kgCO2e,
                diesel_L_to_kgCO2e,
                ethanol_kWh_to_kgCO2e,
                ethanol_L_to_kgCO2e,
                butane_kWh_to_kgCO2e,
                butane_kg_to_kgCO2e,
                propane_kWh_to_kgCO2e,
                propane_kg_to_kgCO2e,
                biomass_kWh_to_kgCO2e,
                biomass_kg_to_kgCO2e,
              },
              timestamp: Date.now(),
            } as tStoredData)
          );
          factorsRequest.resolve({
            electricity_kWh_to_kgCO2e,
            water_m3_to_kgCO2e,
            natural_gas_kWh_to_kgCO2e,
            gasoline_kWh_to_kgCO2e,
            gasoline_L_to_kgCO2e,
            diesel_kWh_to_kgCO2e,
            diesel_L_to_kgCO2e,
            ethanol_kWh_to_kgCO2e,
            ethanol_L_to_kgCO2e,
            butane_kWh_to_kgCO2e,
            butane_kg_to_kgCO2e,
            propane_kWh_to_kgCO2e,
            propane_kg_to_kgCO2e,
            biomass_kWh_to_kgCO2e,
            biomass_kg_to_kgCO2e,
          });
        })
        .catch((err) => {
          factorsRequest.reject(getErrorMessage(err, trans));
        });
    }
  }, [doRequest, trans]);

  return factorsRequest;
};

export default useGetDefaultConverionFactors;
