import { ReactComponent as AddPhotoAlternateIcon } from "@/assets/icons/add_photo_alternate.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as MoreVertIcon } from "@/assets/icons/more_vert.svg";
import { ReactComponent as OpenInNewIcon } from "@/assets/icons/open_in_new.svg";
import { ReactComponent as PhotoIcon } from "@/assets/icons/photo.svg";
import Icon from "@/components/Icons/Icon";
import PageSection from "@/components/PageSection";
import SimpleWhisperPopoverDropdown from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import WithContextProvider from "@/hoc/withProvider";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import usePostRequest from "@/hooks/apiRequests/usePostRequest";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { openNewTab } from "@/utils/others";
import {
  MAX_NUMBER_OF_PHOTOS_GUEST_APP,
  propertyFindActiveSubscriptionType,
  propertyHasMaxGuestAppImages,
} from "@/utils/property/subscriptions";
import axios from "axios";
import React, { useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { IconButton, Loader } from "rsuite";
import PropertyProfileContextProvider, {
  toggleSelectedImage,
  usePropertyProfileContext,
} from "./context";

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface iImageCheckboxProps {
  imageId: string;
}

const ImageCheckbox: React.FC<iImageCheckboxProps> = ({ imageId }) => {
  const { state, setState } = usePropertyProfileContext();
  return (
    <input
      className="size-[16px]"
      type="checkbox"
      checked={state.selectedImages.includes(imageId)}
      onChange={() => {
        toggleSelectedImage(setState, imageId);
      }}
    />
  );
};

interface iPhotoGalleryWrappedProps extends iProps {}

const PhotoGalleryWrapped: React.FC<iPhotoGalleryWrappedProps> = ({ canWrite }) => {
  const { trans } = useLocalizationState();
  const deleteRequest = useDeleteRequest();
  const postRequest = usePostRequest();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const activeSubscriptionType = propertyFindActiveSubscriptionType({ property: activeProperty });

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (!canWrite) return;

      if (acceptedFiles.length) {
        postRequest.pending();

        const file = acceptedFiles[0];

        axios
          .get(ENDPOINTS.PROPERTIES.PROFILE.IMAGES.GET_POST_PRESIGNED_URL, {
            params: { propertyId: activeProperty._id, fileName: file.name, fileType: file.type },
          })
          .then(async (res) => {
            const {
              data: { clientUrl },
            } = res;

            const binaryData = await file.arrayBuffer(); // Convert file to binary
            try {
              const response = await fetch(clientUrl, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/octet-stream", // Indicate binary content
                },
                body: binaryData,
              });

              const imageUrl = response.url.split("?")[0];
              updateProperty(propertyDispatch, activeProperty._id, {
                guestApp: {
                  ...activeProperty.guestApp,
                  images: [...activeProperty.guestApp.images, imageUrl],
                },
              });
              postRequest.resolve();
            } catch (err) {
              const error = getErrorMessage(err, trans);
              postRequest.reject(error, true);
            }
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            postRequest.reject(error, true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    onDrop,
    disabled:
      postRequest.isLoading ||
      deleteRequest.isLoading ||
      propertyHasMaxGuestAppImages(activeProperty),
  });

  const renderImages = () => {
    const thumbs = activeProperty.guestApp.images.map((image) => (
      <div
        className="flex flex-row justify-center items-center"
        color={COLORS.gray_300}
        style={{
          borderRadius: 8,
          width: 272,
          height: 272,
          overflow: "hidden",
          position: "relative",
        }}
        key={image}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <div style={{ position: "absolute", right: 8, top: 8 }}>
          <ImageCheckbox imageId={image} />
        </div>
        <img src={image} style={img} alt="img" />
      </div>
    ));

    return (
      <div
        {...{
          // TODO: uncomment
          // ...getRootProps(),
          style: {
            border: `1px dashed ${COLORS.gray_300}`,
            // borderRadius: "8px",
            padding: "16px 16px",
          },
        }}
      >
        {/* TODO: uncomment */}
        {/* <input {...getInputProps()} /> */}
        {(() => {
          const renderThumbnails = () => {
            const list: JSX.Element[] = [];

            for (
              let i = thumbs.length;
              i < MAX_NUMBER_OF_PHOTOS_GUEST_APP[activeSubscriptionType];
              i++
            ) {
              list.push(
                <div
                  className="flex items-center justify-center"
                  key={i}
                  style={{
                    border: `1px dashed ${COLORS.gray_400}`,
                    height: "272px",
                    width: "272px",
                  }}
                >
                  <Icon Element={AddPhotoAlternateIcon} size={48} color={COLORS.gray_400} />
                </div>
              );
            }

            return list;
          };

          return (
            <div className="flex flex-row flex-wrap items-center justify-center gap-6">
              {thumbs}
              {renderThumbnails()}
            </div>
          );
        })()}
      </div>
    );
  };

  const renderNoInputImages = () => {
    const thumbs = activeProperty.guestApp.images.map((image) => (
      <div
        className="flex flex-row items-center justify-center"
        color={COLORS.gray_300}
        style={{
          borderRadius: 8,
          width: 272,
          height: 272,
          overflow: "hidden",
          position: "relative",
        }}
        key={image}
      >
        <img src={image} style={img} alt="img" />
      </div>
    ));

    return (
      <div
        {...{
          style: {
            border: `1px dashed ${COLORS.gray_300}`,
            padding: "16px 16px",
            width: "fit-content",
          },
        }}
      >
        <div className="flex flex-row flex-wrap gap-6">{thumbs}</div>
      </div>
    );
  };

  const renderLoader = () => {
    if (!postRequest.isLoading && !deleteRequest.isLoading) return null;

    return (
      <>
        <div
          style={{
            position: "absolute",
            backgroundColor: COLORS.secondary,
            opacity: 0.2,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
        ></div>
        <div
          className="flex flex-row justify-center items-center"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 2,
          }}
        >
          <Loader size={"lg"} />
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2" style={{ position: "relative" }}>
        {canWrite ? renderImages() : renderNoInputImages()}
        {renderLoader()}
        {canWrite && (
          <InterTag
            size={12}
            color={COLORS.gray_400}
            text={trans("pages.hotel_profile.sections.photos.number_of_photos", {
              parameters: [
                activeProperty.guestApp.images.length,
                MAX_NUMBER_OF_PHOTOS_GUEST_APP[activeSubscriptionType],
              ],
            })}
          />
        )}
      </div>
      {canWrite && (
        <div className="flex flex-col gap-2">
          {[0, 1, 2, 3].map((index) => (
            <InterTag
              wrap
              key={index}
              text={trans(`pages.hotel_profile.sections.photos.upload_description.[${index}]`)}
              color={COLORS.gray_400}
              size={14}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ActionsButton: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const { trans } = useLocalizationState();
  const deleteRequest = useDeleteRequest();
  const { state } = usePropertyProfileContext();

  const disabled = state.selectedImages.length === 0;

  return (
    <SimpleWhisperPopoverDropdown
      placement="leftStart"
      disabled={disabled}
      options={[
        {
          key: "open",
          label: trans("pages.hotel_profile.sections.photos.image_options.open"),
          icon: OpenInNewIcon,
          onClick: async () => {
            state.selectedImages.forEach((image: string) => {
              openNewTab(image);
            });
          },
        },
        // TODO: uncomment
        // {
        //   key: "remove",
        //   label: { text: trans("general.remove"), color: COLORS.error },
        //   icon: { Element: DeleteIcon, fill: COLORS.error },
        //   onClick() {
        //     deleteRequest.pending();
        //     axios
        //       .delete(ENDPOINTS.PROPERTIES.PROFILE.IMAGES.DELETE, {
        //         data: { imageIds: state.selectedImages },
        //         params: { propertyId: activeProperty._id },
        //       })
        //       .then((res) => {
        //         const {
        //           data: { property },
        //         } = res;
        //         updateProperty(propertyDispatch, activeProperty._id, property);
        //         deleteRequest.resolve();
        //       })
        //       .catch((err) => {
        //         const error = getErrorMessage(err, trans);
        //         deleteRequest.reject(error, true);
        //       });
        //   },
        // },
      ]}
    >
      <IconButton
        disabled={disabled}
        size="xs"
        circle
        style={{ padding: 2, backgroundColor: COLORS.gray_300 }}
      >
        <Icon Element={MoreVertIcon} fill={COLORS.secondary} size={24} />
      </IconButton>
    </SimpleWhisperPopoverDropdown>
  );
};

interface iProps {
  canWrite: boolean;
}

const PhotoGallery: React.FC<iProps> = WithContextProvider(
  PropertyProfileContextProvider,
  (props: iProps) => {
    const { trans } = useLocalizationState();
    return (
      <PageSection
        title={trans("pages.hotel_profile.sections.photos.title")}
        icon={PhotoIcon}
        description={trans("pages.hotel_profile.sections.photos.description")}
        sideComponent={
          <div className="flex flex-row justify-end">
            <ActionsButton />
          </div>
        }
      >
        <PhotoGalleryWrapped {...{ ...props }} />
      </PageSection>
    );
  }
);

export default PhotoGallery;
