import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

const LS_DATA = "__bnmr-d__";

export type tBenchmarkData = Record<
  "perGuest" | "perAccommodation",
  {
    co2_kg: number;
    electricity_kWh: number;
    water_m3: number;
    fuels_kWh: number;
    waste_kg: number;
  }
> & { perM2: { electricity_kWh: number } };

export const useGetBenchmarkData = (
  { doRequest = true }: { doRequest?: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const benchmarkRequest = useGetRequest<tBenchmarkData>(
    {
      perGuest: {
        co2_kg: 0,
        electricity_kWh: 0,
        water_m3: 0,
        fuels_kWh: 0,
        waste_kg: 0,
      },
      perAccommodation: {
        co2_kg: 0,
        electricity_kWh: 0,
        water_m3: 0,
        fuels_kWh: 0,
        waste_kg: 0,
      },
      perM2: { electricity_kWh: 0 },
    },
    { ...(doRequest ? {} : { status: REQUEST_STATUS.IDLE }) }
  );

  useEffectSafe(() => {
    if (doRequest) {
      benchmarkRequest.pending();

      const lsData = localStorage.getItem(LS_DATA);
      if (lsData) {
        benchmarkRequest.resolve(JSON.parse(lsData));
        return;
      }

      axios
        .get(ENDPOINTS.PROPERTIES.BENCHMARK)
        .then((res) => {
          const {
            data: { perAccommodation, perGuest, perM2 },
          } = res;
          localStorage.setItem(LS_DATA, JSON.stringify({ perAccommodation, perGuest, perM2 }));
          benchmarkRequest.resolve({ perAccommodation, perGuest, perM2 });
        })
        .catch((err) => {
          benchmarkRequest.reject(getErrorMessage(err, trans));
        });
    }
  }, [trans]);

  return benchmarkRequest;
};
