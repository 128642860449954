import { _uniq } from "@/lodash-utils";
import { tPropertyRolePermission } from "@/models/property/roles";
import { propertyStaffMemberRolesList } from "@/utils/property/roles";
import { createContext, useMemo } from "react";
import { usePropertyState } from "../Property/hooks";
import { useProfileState } from "../Profile/hooks";

const PermissionsContext = createContext<tPropertyRolePermission[] | undefined>(undefined);

const PermissionsContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { profile } = useProfileState();
  const { activeProperty } = usePropertyState();

  const permissionsList = useMemo(() => {
    const roles = propertyStaffMemberRolesList(activeProperty, profile._id);

    const permissions = roles.reduce((acc, role) => {
      return _uniq([...acc, ...(role.permissionsList || [])]);
    }, [] as tPropertyRolePermission[]);

    return permissions;
  }, [activeProperty, profile._id]);

  return (
    <PermissionsContext.Provider value={permissionsList}>{children}</PermissionsContext.Provider>
  );
};

export const hasPermission = (
  permissionsList: tPropertyRolePermission[],
  permission: tPropertyRolePermission
) => {
  return permissionsList.includes(permission);
};

export const hasAllPermissions = (
  permissionsList: tPropertyRolePermission[],
  permissions: tPropertyRolePermission[]
) => {
  return permissions.every((p) => permissionsList.includes(p));
};
export const hasAnyPermissions = (
  permissionsList: tPropertyRolePermission[],
  permissions: tPropertyRolePermission[]
) => {
  return permissions.some((p) => permissionsList.includes(p));
};

export { PermissionsContext };

export default PermissionsContextProvider;
