import React, { useState } from "react";
import { COLORS } from "@/utils/colors";
import DesktopRootContent from "./content";
import DesktopRootMenu from "./menu";

const SIDEBAR_EXPANDED_LS = "__sbe__";

const DesktopRoot: React.FC = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(() => {
    const stored = localStorage.getItem(SIDEBAR_EXPANDED_LS);
    return !(stored === "false");
  });

  return (
    <div className="flex flex-row h-full">
      <DesktopRootMenu sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
      <div
        className="flex-1 h-full"
        style={{
          marginLeft: `${sidebarExpanded ? 255 : 88}px`,
          backgroundColor: COLORS.app_background,
        }}
      >
        <DesktopRootContent />
      </div>
    </div>
  );
};

export default DesktopRoot;
