import React, { useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { InputGroup, InputNumber, Toggle } from "rsuite";
import { tState2 } from ".";
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
import pdfThumbnail from "@/assets/pdf-thumbail.png";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import InputWrapper from "@/components/InputWrapper";
import InterTag from "@/components/Text/Inter";

interface iProps {
  status: nRequestStatus.tStatus;
  electricityState: tState2["data"]["electricity"];
  electricityErrors: tState2["errors"]["electricity"];
  onChange(
    key: keyof Pick<tState2["data"]["electricity"], "isElectricityGreen" | "totalKWh" | "price">,
    value: any
  ): void;
  onSelectFile(
    key: keyof Pick<tState2["data"]["electricity"], "greenElectricityCertificate">,
    file: File
  ): void;
  greenElectricityCertificate: File | null;
}

const Energy: React.FC<iProps> = ({
  onChange,
  onSelectFile,
  electricityErrors,
  electricityState,
  greenElectricityCertificate,
  status,
}) => {
  const { trans } = useLocalizationState();

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (acceptedFiles.length) {
        onSelectFile("greenElectricityCertificate", acceptedFiles[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    onDrop,
  });

  return (
    <Flex column gap={20}>
      <InputWrapper
        {...{
          label: trans("Electricity Consumption"),
          description: trans("Enter your total electricity usage for the selected time period."),
          error: electricityErrors["totalKWh"],
        }}
      >
        <Flex row gap={12} style={{ width: "70%" }}>
          <InputGroup>
            <InputNumber
              disabled={status === REQUEST_STATUS.PENDING}
              min={0}
              value={electricityState["totalKWh"]}
              placeholder={"Total electricity consumption"}
              onChange={(value) => {
                if (value !== null) onChange("totalKWh", value);
              }}
            />
            <InputGroup.Addon>kWh</InputGroup.Addon>
          </InputGroup>
          <InputGroup>
            <InputNumber
              disabled={status === REQUEST_STATUS.PENDING}
              min={0}
              value={electricityState["price"]}
              placeholder={"Total electricity Cost"}
              onChange={(value) => {
                if (value !== null) onChange("price", value);
              }}
            />
            <InputGroup.Addon>€</InputGroup.Addon>
          </InputGroup>
        </Flex>
      </InputWrapper>
      <Flex row gap={16} middle>
        <InterTag
          bold
          size={14}
          color={COLORS.secondary}
          text={trans("Is your electricity green?")}
        />
        <Toggle
          disabled={status === REQUEST_STATUS.PENDING}
          checked={electricityState["isElectricityGreen"]}
          onChange={(checked) => onChange("isElectricityGreen", checked)}
          checkedChildren={trans("Yes")}
          unCheckedChildren={trans("No")}
          size="sm"
        ></Toggle>
      </Flex>
      {electricityState.isElectricityGreen && (
        <InputWrapper label={trans("Upload a green electricity certificate")}>
          <Flex
            column
            {...{
              ...getRootProps(),
              style: {
                border: `1px dashed ${COLORS.gray_500}`,
                borderRadius: "8px",
                padding: "16px 16px",
              },
            }}
          >
            <input {...getInputProps()} />
            {(() => {
              if (greenElectricityCertificate) {
                // @ts-ignore
                const { path, lastModified } = greenElectricityCertificate;
                return (
                  <Flex center>
                    <Flex
                      column
                      middle
                      center
                      key={`${path}-${lastModified}`}
                      style={{ height: "fit-content", padding: "8px" }}
                    >
                      <img src={pdfThumbnail} alt="pdf" style={{ height: "64px", width: "64px" }} />
                      <InterTag size={12} color={COLORS.secondary} text={path.slice(0, 20)} />
                    </Flex>
                  </Flex>
                );
              }
              return (
                <Flex row between gap={16}>
                  <div>
                    <Icon size={48} Element={UploadIcon} fill={COLORS.gray_400} />
                  </div>
                  <InterTag
                    style={{ textWrap: "wrap", lineHeight: "16px" }}
                    size={14}
                    color={COLORS.gray_400}
                    text={trans(
                      "Please upload a recent electricity bill (PDF only) that verifies your purchase of green electricity. Ensure that the document clearly shows your hotel name, the billing period, and details of the energy source. Click to browse or drag and drop your file here."
                    )}
                  />
                </Flex>
              );
            })()}
          </Flex>
        </InputWrapper>
      )}
    </Flex>
  );
};

export default Energy;
