import React, { useCallback } from "react";
import { AuthDispatchContext } from "../";
import { cleanStorage } from "@/utils/storage";

export const LS_VERSION = "version";

const useAuthDispatch = () => {
  const dispatch = React.useContext(AuthDispatchContext);

  if (!dispatch) throw Error("useAuthDispatch must be used within AuthStateContext");

  const login = useCallback(
    (token: string) => {
      localStorage.setItem(LS_VERSION, "1");
      dispatch({ type: "set data", data: { token } });
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    cleanStorage();
    dispatch({ type: "set data", data: { token: null } });
  }, [dispatch]);

  return { login, logout };
};

export default useAuthDispatch;
