import { _find, _get, _toString } from "@/lodash-utils";
import { LANGAUGES } from "@/utils/languages";
import { replace } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import translation from "./translations";
import { DEFAULT_LANGAUGE, tTrans } from "./utils";

export const LS_DATA_LANGUAGE = "__l__";

const LocalizationDispatchContext = React.createContext<
  React.Dispatch<React.SetStateAction<string>> | undefined
>(undefined);
LocalizationDispatchContext.displayName = "LocalizationDispatchContext";

const LocalizationStateContext = React.createContext<{
  language: string;
  trans: tTrans;
}>({ language: DEFAULT_LANGAUGE, trans: () => "" });
LocalizationStateContext.displayName = "LocalizationStateContext";

const LocalizationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>(() => {
    const storage = localStorage.getItem(LS_DATA_LANGUAGE);

    if (!storage || !_find(LANGAUGES, (l) => l.code === storage)) return DEFAULT_LANGAUGE;

    return storage;
  });

  const trans: tTrans = useCallback(
    (
      key: string,
      { lang, parameters = [] }: { lang?: string; parameters?: (string | number)[] } = {
        parameters: [],
      }
    ): string => {
      try {
        const selectedLanguage = lang || language || DEFAULT_LANGAUGE || "en";
        // @ts-ignore
        let word: string | null =
          _get(translation[selectedLanguage] || {}, key) ||
          _get(translation[DEFAULT_LANGAUGE] || {}, key) ||
          _get(translation["en"] || {}, key) ||
          null;

        if (!word) {
          for (let i = 0; i < parameters.length; i++) {
            const param = parameters[i];
            key = replace(key, `$${i}`, _toString(param));
          }
          return key;
        }

        for (let i = 0; i < parameters.length; i++) {
          const param = parameters[i];
          word = replace(word, `$${i}`, _toString(param));
        }
        return word;
      } catch (err) {
        return key;
      }
    },
    [language]
  );

  const value = useMemo(() => ({ language, trans }), [language, trans]);

  return (
    <LocalizationStateContext.Provider value={value}>
      <LocalizationDispatchContext.Provider value={setLanguage}>
        {children}
      </LocalizationDispatchContext.Provider>
    </LocalizationStateContext.Provider>
  );
};

export { LocalizationContextProvider, LocalizationDispatchContext, LocalizationStateContext };
