import { ReactComponent as PaymentsIcon } from "@/assets/icons/payments.svg";
import PageSection from "@/components/PageSection";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tProperty } from "@/models/property";
import { DEFAULT_MODAL_PROPS } from "@/utils/rsuite/modals";
import { PROPERTY_STRIPE_ATTRIBUTE } from "@/utils/stripe";
import React, { useState } from "react";
import { Modal, ModalProps } from "rsuite";
import ConfirmButton from "./ConfirmButton";
import InfoCard from "./InfoCard";
import SelectPaymentMethodCard from "./SelectPaymentMethodCard";
import VoucherCard from "./VoucherCard";
import { buildTransKey } from "./utils";

interface iBusinessPaymentModalWrappedProps extends Pick<iProps, "onClose"> {
  billingTimePeriod: "year" | "month";
  property: tProperty;
}

const BusinessPaymentModalWrapped: React.FC<iBusinessPaymentModalWrappedProps> = ({
  billingTimePeriod,
  onClose,
  property,
}) => {
  const { trans } = useLocalizationState();
  const [selectedMethod, setSelectedMethod] = useState(() => {
    return property[PROPERTY_STRIPE_ATTRIBUTE].defaultCard;
  });
  const [promotion, setPromotion] = useState<{
    code: string;
    amountOff: number;
    percentOff: number;
  } | null>(null);

  return (
    <>
      <Modal.Header />
      <Modal.Body>
        <PageSection
          title={trans("general.payment")}
          description={trans(buildTransKey("description"))}
          icon={PaymentsIcon}
        >
          <div className="flex flex-row gap-3">
            <div className="flex-1 h-fit">
              <InfoCard
                removePromotion={() => setPromotion(null)}
                billingTimePeriod={billingTimePeriod}
                promotion={promotion}
              />
            </div>
            <div className="flex flex-1 flex-col justify-between gap-3">
              <SelectPaymentMethodCard
                property={property}
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
              />
              <VoucherCard
                property={property}
                setPromotion={setPromotion}
                billingTimePeriod={billingTimePeriod}
              />
              <ConfirmButton
                property={property}
                billingTimePeriod={billingTimePeriod}
                promotion={promotion}
                selectedMethod={selectedMethod}
                onClose={onClose}
              />
            </div>
          </div>
        </PageSection>
      </Modal.Body>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose: (updatedProperty?: tProperty) => void;
  billingTimePeriod: "year" | "month";
  property: tProperty;
}

const BusinessPaymentModal: React.FC<iProps> = ({ onClose, open, billingTimePeriod, property }) => {
  return (
    <Modal
      {...{
        ...DEFAULT_MODAL_PROPS,
        open,
        onClose: () => {
          onClose();
        },
        size: "lg",
        overflow: false,
      }}
    >
      {open && <BusinessPaymentModalWrapped {...{ billingTimePeriod, onClose, property }} />}
    </Modal>
  );
};

export default BusinessPaymentModal;
