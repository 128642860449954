import { tIcon, tIconElement } from "@/interfaces/icon";

export const TABLE_ROW_HEIGHT = {
  M: 60,
  SM: 55,
  S: 50,
};
export const TABLE_HEADER_HEIGHT = 50;

export const TABLE_HEADER_CLASSNAMES = "w-full flex flex-row sm:px-4 px-3 py-2 rounded-lg";
export const TABLE_ROW_CLASSNAMES = "w-full flex flex-row sm:px-4 px-3 py-2 rounded-md bg-white";
export const TABLE_ROWS_CONTAINER_CLASSNAMES =
  "overflow-auto mt-3 sm:mt-2 sm:pt-2 flex flex-col w-full gap-2";

export type tTableColumn = {
  key: string;
  icon?: tIconElement | tIcon;
  label: string;
  className: string;
  hide?: boolean;
};
