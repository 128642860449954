import React from "react";
import { iInterTagProps } from "@/components/Text/Inter";
import { toTextProps } from "@/components/Text/utils";
import SimpleButton, { iSimpleButtonProps } from "../SimpleButton";

interface iProps extends Omit<iSimpleButtonProps, "color"> {
  text: string | iInterTagProps;
  color: string;
}

const SimpleColoredButton: React.FC<iProps> = ({ text, color, ...props }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: "6px",
        width: "100%",
      }}
    >
      <SimpleButton
        appearance="subtle"
        text={{ ...toTextProps(text) }}
        {...{ ...props, style: { ...props.style, width: "100%" } }}
      />
    </div>
  );
};

export default SimpleColoredButton;
