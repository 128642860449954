import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "@/components/Flex";
import DMSeriffTag from "@/components/Text/DMSeriff";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";

const Unlock: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={32} style={{ margin: "0 100px" }}>
      <DMSeriffTag
        size={36}
        color={COLORS.secondary}
        text={trans("pages.waste.unlock.title")}
        style={{ textWrap: "wrap", textAlign: "center" }}
        bold
      />
      {[0, 1].map((i) => (
        <InterTag
          key={i}
          size={20}
          color={COLORS.secondary}
          text={trans(`pages.waste.unlock.description.${i}`)}
          style={{ textWrap: "wrap", textAlign: "center" }}
        />
      ))}
      <Button
        style={{
          backgroundColor: COLORS.primary_900,
          color: COLORS.white,
          fontWeight: "bold",
        }}
        onClick={() => navigate("/settings/billing/plan")}
      >
        {trans("components.unlock.upgrade_now")}
      </Button>
      {[0, 1, 2].map((i) => (
        <Flex column gap={16} key={i}>
          <DMSeriffTag
            text={trans(`pages.waste.unlock.points.${i}.title`)}
            color={COLORS.secondary}
            size={24}
            style={{ textWrap: "wrap", textAlign: "center" }}
            bold
          />
          <InterTag
            text={trans(`pages.waste.unlock.points.${i}.description`)}
            color={COLORS.secondary}
            size={20}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default Unlock;
