import { ReactComponent as GaugeIndicatorIcon } from "@/assets/icons/gauge-indicator.svg";
import { ReactComponent as GaugeIcon } from "@/assets/icons/gauge.svg";
import Icon from "@/components/Icons/Icon";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import { _round } from "@/lodash-utils";
import React from "react";

interface iProps {
  ratio?: number;
  value?: string | number;
  valueLoading?: boolean;
  label?: string;
  labelLoading?: boolean;
}

const Gauge: React.FC<iProps> = ({ ratio, value, label, valueLoading, labelLoading }) => {
  const gaugeWidth = 200;
  const circleRadius = gaugeWidth / 2;
  const indicatorSize = gaugeWidth / 10;

  const renderPointer = () => {
    if (typeof ratio !== "number") return null;

    const degrees = (1 - ratio) * 180;

    const radians = (degrees * Math.PI) / 180;
    const rotation = 180 - degrees;

    const indicatorOffsetHorizontal = _round(Math.cos(radians), 2) * circleRadius - gaugeWidth / 20;
    const indicatorOffsetVertical = _round(Math.sin(radians), 2) * circleRadius - gaugeWidth / 20;
    return (
      <div
        className="absolute"
        style={{
          bottom: 0 + indicatorOffsetVertical,
          left: 0 + circleRadius + indicatorOffsetHorizontal,
          transform: `rotate(${rotation}deg)`,
        }}
      >
        <Icon size={indicatorSize} Element={GaugeIndicatorIcon} />
      </div>
    );
  };

  return (
    <div className="relative">
      <Icon width={gaugeWidth} Element={GaugeIcon} />
      {renderPointer()}
      {valueLoading ? (
        <div
          className="absolute "
          style={{ top: "60%", left: "50%", transform: "translate(-50%, -50%)" }}
        >
          <SkeletonText width={70} height={28} />
        </div>
      ) : (
        value !== undefined && (
          <div
            className="absolute "
            style={{ top: "60%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            <p
              className={`inter font-bold ${(() => {
                const length = `${value}`.length;
                switch (true) {
                  case length > 13:
                    return "text-sm";
                  case length > 9:
                    return "text-lg";
                  default:
                    return "text-xl";
                }
              })()}`}
            >
              {value}
            </p>
          </div>
        )
      )}
      {labelLoading ? (
        <div
          className="absolute "
          style={{ top: "85%", left: "50%", transform: "translate(-50%, -50%)" }}
        >
          <SkeletonText width={70} height={20} />
        </div>
      ) : (
        label !== undefined && (
          <div
            className="absolute "
            style={{ top: "85%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            <p className="inter text-sm">{label}</p>
          </div>
        )
      )}
    </div>
  );
};

export default Gauge;
