import React from "react";
import { Loader } from "rsuite";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import Flex from "@/components/Flex";
import PageSection from "@/components/PageSection";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useScreenSize from "@/hooks/useScreenSize";
import ShowcasingWrapped from "./wrapped1";

interface iProps {}

const Showcasing: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.fuels.archive.sections.showcase.title")}
        description={trans("pages.fuels.archive.sections.showcase.description")}
        icon={ModeHeatIcon}
      />
      {!outlet ? (
        <Flex>
          <Loader size="lg" />
        </Flex>
      ) : (
        <ShowcasingWrapped outletContainerWidth={outlet.width} />
      )}
    </Flex>
  );
};

export default Showcasing;
