import { ReactComponent as BadgeIcon } from "@/assets/icons/badge.svg";
import { ReactComponent as DashbaordIcon } from "@/assets/icons/dashboard.svg";
import { ReactComponent as EnergySavingIcon } from "@/assets/icons/energy_program_saving.svg";
import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/meeting_room.svg";
import { ReactComponent as MoreHorizIcon } from "@/assets/icons/more_horiz.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import DataTypeContextProvider from "@/context/DataType";
import { PropertyContextProvider } from "@/context/Property";
import { usePropertyState } from "@/context/Property/hooks";
import { LocalizationContextProvider } from "@/context/Localization";
import PermissionsContextProvider from "@/context/Permissions";
import TimeframeContextProvider from "@/context/Timeframe";
import useWindowSizeState from "@/context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "@/utils/colors";
import { renderRequestedData } from "@/utils/render";
import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Button, Loader } from "rsuite";
import DesktopRoot from "./desktop";
import styles from "./styles.module.css";

const RootMobile: React.FC = () => {
  const location = useLocation();
  const {
    state: { status },
  } = usePropertyState();

  const renderBottom = () => {
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          height: "70px",
          left: 0,
          right: 0,
          backgroundColor: COLORS.secondary,
          zIndex: 43,
        }}
      >
        <Flex color={COLORS.secondary} row style={{ height: "100%" }} gap={8}>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive || location.pathname === "/" ? styles["mobile-navlink-active"] : ""
              }`
            }
            to="/overview"
          >
            <Button style={{ height: "100%", width: "100%" }} appearance="subtle">
              <Icon Element={DashbaordIcon} fill={COLORS.light_white} width={24} height={24} />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${isActive ? styles["mobile-navlink-active"] : ""}`
            }
            to="/sustainability"
          >
            <Button appearance="subtle" style={{ height: "100%", width: "100%" }}>
              <Icon Element={EnergySavingIcon} fill={COLORS.light_white} width={24} height={24} />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${isActive ? styles["mobile-navlink-active"] : ""}`
            }
            to="/spaces"
          >
            <Button appearance="subtle" style={{ height: "100%", width: "100%" }}>
              <Icon Element={MeetingRoomIcon} fill={COLORS.light_white} width={24} height={24} />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${isActive ? styles["mobile-navlink-active"] : ""}`
            }
            to="/users"
          >
            <Button appearance="subtle" style={{ height: "100%", width: "100%" }}>
              <Icon Element={BadgeIcon} fill={COLORS.light_white} width={24} height={24} />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${isActive ? styles["mobile-navlink-active"] : ""}`
            }
            to="/more"
          >
            <Button appearance="subtle" style={{ height: "100%", width: "100%" }}>
              <Icon Element={MoreHorizIcon} fill={COLORS.light_white} width={24} height={24} />
            </Button>
          </NavLink>
        </Flex>
      </div>
    );
  };

  return (
    <div
      id="outlet"
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundColor: COLORS.app_background,
      }}
    >
      {renderRequestedData(status, {
        resolved: () => {
          const props =
            location.pathname === "/more" ? {} : { style: { padding: "20px 20px 80px 20px" } };
          return (
            <Flex column {...{ ...props, style: { ...props.style } }}>
              <Outlet />
              <div />
            </Flex>
          );
        },
        pending: () => (
          <Flex style={{ height: "100%", width: "100%" }} center middle>
            <Loader size="lg" />
          </Flex>
        ),
        idle: () => (
          <Flex style={{ height: "100%", width: "100%" }} center middle>
            <Loader size="lg" />
          </Flex>
        ),
        rejected: () => <Flex></Flex>,
      })}
      {renderBottom()}
    </div>
  );
};

const RootWrapped: React.FC = () => {
  const { isLaptop } = useWindowSizeState();
  return isLaptop ? <DesktopRoot /> : <RootMobile />;
};

const Root: React.FC = () => {
  return (
    <PropertyContextProvider>
      <PermissionsContextProvider>
        <LocalizationContextProvider>
          <TimeframeContextProvider>
            <DataTypeContextProvider>
              <RootWrapped />
            </DataTypeContextProvider>
          </TimeframeContextProvider>
        </LocalizationContextProvider>
      </PermissionsContextProvider>
    </PropertyContextProvider>
  );
};

export default Root;
