import { ReactComponent as AreaIcon } from "@/assets/icons/area_chart.svg";
import { ReactComponent as BarIcon } from "@/assets/icons/bar_chart.svg";
import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import ReloadButton from "@/components/Buttons/ReloadButton";
import BasicCard, { iBasicCardProps } from "@/components/Cards/BasicCard";
import AreaChart, {
  iAreaChartProps,
  Y_AXIS_BASE_OPTIONS,
} from "@/components/Graphs/Apex/AreaChart";
import ColumnChart, { iColumnChartProps } from "@/components/Graphs/Apex/ColumnChart";
import Icon from "@/components/Icons/Icon";
import PageSectionBG from "@/components/PageSection/PageSectionBG";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useProfileState } from "@/context/Profile/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import { _round, _sum } from "@/lodash-utils";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { generateGraphCategories } from "@/utils/graphs";
import { numberFormatter } from "@/utils/numbers";
import { cloneDeep } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useContext, useState } from "react";
import { IconButton } from "rsuite";
import { useConsumptionLiveDataContext } from "./context/dataContext";
import { ConsumptionLiveSelectedSegmentContext } from "./context/selectedSegmentContext";

const LiveDataGraph: React.FC = () => {
  const { profile } = useProfileState();
  const { trans } = useLocalizationState();
  const { binInfo, binnedSeries, mainRequest, conversionFactors } = useConsumptionLiveDataContext();
  const { selectedSegment, setSelectedSegment } = useContext(
    ConsumptionLiveSelectedSegmentContext
  )!;
  const [graphType, setGraphType] = useState<"column" | "area">("column");

  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();

  const [isLoading] = (() => {
    switch (true) {
      case selectedSegment === "w":
        return [mainRequest.isFetching];
      case selectedSegment === "e":
        return [mainRequest.isFetching];
      case selectedSegment === "co2":
      default:
        return [mainRequest.isFetching || conversionFactors.isLoading];
    }
  })();

  const cards: ({
    key: "e" | "w";
    hide: boolean;
    converter: nUnitConverter.tFunction;
  } & Pick<iBasicCardProps, "icon" | "label" | "valueLoading">)[] = [
    {
      key: "w",
      hide: false,
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      label: trans("general.water"),
      valueLoading: mainRequest.isFetching,
      converter: convertVolumeUnit,
    },
    {
      key: "e",
      hide: false,
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      label: trans("general.electricity"),
      valueLoading: mainRequest.isFetching,
      converter: convertEnergyUnit,
    },
  ];

  const timeframe_0 = moment(sensorDataTimeframe[0]).valueOf();
  const timeframe_1 = moment(sensorDataTimeframe[1]).valueOf();
  const categories = generateGraphCategories(
    binInfo.binUnit,
    binInfo.binSize,
    timeframe_0,
    timeframe_1
  );

  const renderGraph = () => {
    if (graphType === "column") {
      const params: Partial<iColumnChartProps> = {
        categories,
        tooltipDateFormat:
          binInfo.binSize === 1
            ? "DD/MM/YYYY"
            : (date: MomentInput) =>
                `${moment(date).format("DD/MM/YYYY")} - ${moment(date)
                  .add(binInfo.binSize - 1, binInfo.binUnit)
                  .format("DD/MM/YYYY")}`,
      };
      if (selectedSegment === "co2") {
        params.id = "live-data-co2";
        params.series = [
          {
            name: trans("general.measures_.water"),
            // data: co2Series.wCo2 ? generateBinData(co2Series.wCo2, binInfo.binSize) : [],
            data: binnedSeries.wCo2,
            color: COLORS.water,
          },
          {
            name: trans("general.measures_.electricity"),
            // data: co2Series.eCo2 ? generateBinData(co2Series.eCo2, binInfo.binSize) : [],
            data: binnedSeries.eCo2,
            color: COLORS.energy,
          },
        ];
        params.tooltipValueFormatter = (val: number) => `${val} kg`;
        params.yAxisTitle = `${trans("general.co2")} (kg)`;
      }
      if (selectedSegment === "w") {
        params.id = "live-data-w";
        params.series = [
          {
            name: trans("general.measures_.water"),
            // data: requests[0].data?.w ? generateBinData(requests[0].data.w, binInfo.binSize) : [],
            data: binnedSeries.w,
            color: COLORS.water,
          },
        ];
        params.tooltipValueFormatter = (val: number) => `${val} l`;
        params.yAxisTitle = `${trans("general.measures_.water")} (l)`;
      }
      if (selectedSegment === "e") {
        params.id = "live-data-e";
        params.series = [
          {
            name: trans("general.measures_.electricity"),
            // data: requests[0].data?.e ? generateBinData(requests[0].data.e, binInfo.binSize) : [],
            data: binnedSeries.e,
            color: COLORS.energy,
          },
        ];
        params.tooltipValueFormatter = (val: number) => `${val} Wh`;
        params.yAxisTitle = `${trans("general.measures_.electricity")} (Wh)`;
      }
      return (
        <div key={params.id}>
          <ColumnChart {...(params as iColumnChartProps)} />
        </div>
      );
    }

    if (graphType === "area") {
      const params: Partial<iAreaChartProps> = {
        id: selectedSegment,
        categories,
      };

      if (selectedSegment === "co2") {
        params.yAxis = { ...Y_AXIS_BASE_OPTIONS, title: { text: `${trans("general.co2")} (kg)` } };
        params.series = [
          {
            name: trans("general.co_2_emissions"),
            // data: co2Series.wCo2 ? generateBinData(co2Series.wCo2, binInfo.binSize) : [],
            data: binnedSeries.co2Total,
            color: COLORS.primary_500,
          },
          {
            name: trans("general.measures_.water"),
            // data: co2Series.wCo2 ? generateBinData(co2Series.wCo2, binInfo.binSize) : [],
            data: binnedSeries.wCo2,
            color: COLORS.water,
          },
          {
            name: trans("general.measures_.electricity"),
            // data: co2Series.eCo2 ? generateBinData(co2Series.eCo2, binInfo.binSize) : [],
            data: binnedSeries.eCo2,
            color: COLORS.energy,
          },
        ];
      } else if (selectedSegment === "w") {
        params.yAxis = [
          {
            ...cloneDeep(Y_AXIS_BASE_OPTIONS),
            title: { text: `${trans("general.measures_.water")} (l)` },
          },
          {
            opposite: true,
            ...cloneDeep(Y_AXIS_BASE_OPTIONS),
            title: { text: `${trans("general.co2")} (kg)` },
          },
        ];
        params.series = [
          {
            name: trans("general.measures_.water"),
            data: binnedSeries.w,
            color: COLORS.water,
            type: "line",
          },
          {
            name: trans("general.co_2_emissions"),
            data: binnedSeries.wCo2,
            color: COLORS.primary_500,
            type: "line",
          },
        ];
      } else if (selectedSegment === "e") {
        params.yAxis = [
          {
            ...cloneDeep(Y_AXIS_BASE_OPTIONS),
            title: { text: `${trans("general.measures_.electricity")} (Wh)` },
          },
          {
            opposite: true,
            ...cloneDeep(Y_AXIS_BASE_OPTIONS),
            title: { text: `${trans("general.co2")} (kg)` },
          },
        ];
        params.series = [
          {
            name: trans("general.measures_.electricity"),
            data: binnedSeries.e,
            color: COLORS.energy,
          },
          {
            name: trans("general.co_2_emissions"),
            data: binnedSeries.eCo2,
            color: COLORS.primary_500,
          },
        ];
      }
      return <AreaChart {...(params as iAreaChartProps)} />;
    }

    return null;
  };

  return (
    <PageSectionBG
      title={trans("general.usage")}
      sideComponent={
        <div className="flex flex-row justify-end gap-2">
          <div className="flex flex-row items-center justify-center">
            <IconButton
              icon={
                <Icon
                  Element={graphType === "column" ? AreaIcon : BarIcon}
                  fill={COLORS.secondary}
                  size={16}
                />
              }
              onClick={() => setGraphType((prev) => (prev === "column" ? "area" : "column"))}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ReloadButton onClick={() => mainRequest.refetch()} disabled={mainRequest.isFetching} />
          </div>
        </div>
      }
    >
      <div className="flex flex-row 2xl:flex-col gap-4">
        <div className="flex flex-col 2xl:flex-row justify-center 2xl:justify-start gap-2 basis-1/3">
          <BasicCard
            showBorder={selectedSegment === "co2"}
            hover
            onClick={() => setSelectedSegment("co2")}
            icon={{ Element: EcoIcon, fill: COLORS.primary }}
            label={{ text: trans("general.co_2_emissions"), asHTML: true }}
            className={"p-2"}
            valueLoading={mainRequest.isFetching || conversionFactors.isLoading}
            value={
              mainRequest.isFetching || conversionFactors.isLoading
                ? 0
                : (() => {
                    const converted = convertMassUnit(_sum(binnedSeries.co2Total || []));
                    return `${numberFormatter(_round(converted.value, 2), profile)} ${
                      converted.unit
                    }`;
                  })()
            }
          />
          {cards
            .filter((c) => !c.hide)
            .map(({ converter, icon, key, label, valueLoading }) => {
              return (
                <BasicCard
                  key={key}
                  showBorder={selectedSegment === key}
                  hover
                  onClick={() => setSelectedSegment(key)}
                  icon={icon}
                  label={label}
                  className={"p-2"}
                  valueLoading={valueLoading}
                  value={
                    valueLoading || !mainRequest.isSuccess
                      ? 0
                      : (() => {
                          const converted = converter(_sum(mainRequest.data?.[key] || []));
                          return `${numberFormatter(_round(converted.value, 2), profile)} ${
                            converted.unit
                          }`;
                        })()
                  }
                />
              );
            })}
        </div>
        <div className="basis-2/3 bg-neutral-300/50 rounded-lg">
          {isLoading ? <SkeletonText style={{ height: 303, width: "100%" }} /> : renderGraph()}
        </div>
      </div>
    </PageSectionBG>
  );
};

export default LiveDataGraph;
