import AddManualDataButton from "@/components/Buttons/addManualDataButton";
import GettingStarted, { GettingStartedFinalModal } from "@/components/GettingStarted";
import ManualDataTreePickerMulti from "@/components/ManualData/ManualDataTreePickerMulti";
import { usePropertyState } from "@/context/Property/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import useElementForPortal from "@/hooks/useElementForPortal";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { SUSTAINABILITY_TOPBAR_ELEMENT_ID } from "../sustainability";
import Indicators from "./indicators";

export const SUSTAINABILITY_MANUAL_DATA_OPTIONS_ELEMENT_ID = "manual-data-options";

const ManualData: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataIndex },
  } = useTimeframeState();
  const portalContainer = useElementForPortal(SUSTAINABILITY_TOPBAR_ELEMENT_ID, true);

  const [gettingStartedFinalModal, setGettingStartedFinalModal] = useState(false);

  return (
    <>
      {portalContainer &&
        createPortal(
          <div className="flex flex-row gap-1 items-center">
            <AddManualDataButton />
            <div style={{ maxWidth: "300px" }}>
              <ManualDataTreePickerMulti />
            </div>
            <div id={SUSTAINABILITY_MANUAL_DATA_OPTIONS_ELEMENT_ID}></div>
          </div>,
          portalContainer
        )}
      <GettingStartedFinalModal
        open={gettingStartedFinalModal}
        onClose={() => setGettingStartedFinalModal(false)}
      />

      {activeProperty.manualData.length === 0 ? (
        <GettingStarted startModalOpen={true} onInsert={() => setGettingStartedFinalModal(true)} />
      ) : manualDataIndex >= 0 ? (
        <div className="flex flex-col gap-10">
          <Indicators />
        </div>
      ) : null}
    </>
  );
};

export default ManualData;
