import { _find } from "@/lodash-utils";
import tProperty, { tPropertySubscriptionModel } from "@/models/property";

export const MAX_NUMBER_OF_PHOTOS_GUEST_APP: Record<tPropertySubscriptionModel["type"], number> = {
  starter: 1,
  business: 3,
  enterprise: 5,
};

export const propertyFindActiveSubscription = (
  property: tProperty
): tProperty["subscriptions"][0] | undefined => {
  return _find<tProperty["subscriptions"][0]>(property.subscriptions, (s) => s.active);
};

export const propertyFindActiveSubscriptionType = ({
  property,
  subscription,
}: {
  property?: tProperty;
  subscription?: tProperty["subscriptions"][0];
}): tProperty["subscriptions"][0]["type"] => {
  if (property) {
    const activeSubscription = propertyFindActiveSubscription(property);
    return activeSubscription?.type || "starter";
  }
  if (subscription) {
    return subscription.type;
  }
  return "starter";
};

export const propertyActiveSubscriptionTypeIsStarter = ({
  property,
  subscription,
}: {
  property?: tProperty;
  subscription?: tProperty["subscriptions"][0];
}): boolean => {
  if (property) {
    const activeSubscription = propertyFindActiveSubscription(property);
    return activeSubscription ? activeSubscription.type === "starter" : true;
  }
  if (subscription) {
    return subscription.type === "starter";
  }
  return true;
};

export const propertyActiveSubscriptionTypeIsBusiness = ({
  property,
  subscription,
}: {
  property?: tProperty;
  subscription?: tProperty["subscriptions"][0];
}): boolean => {
  if (property) {
    const activeSubscription = propertyFindActiveSubscription(property);
    return activeSubscription ? activeSubscription.type === "business" : false;
  }
  if (subscription) {
    return subscription.type === "business";
  }
  return false;
};

export const propertyActiveSubscriptionTypeIsEnterprise = ({
  property,
  subscription,
}: {
  property?: tProperty;
  subscription?: tProperty["subscriptions"][0];
}): boolean => {
  if (property) {
    const activeSubscription = propertyFindActiveSubscription(property);
    return activeSubscription ? activeSubscription.type === "enterprise" : false;
  }
  if (subscription) {
    return subscription.type === "enterprise";
  }
  return false;
};

export const propertyHasMaxGuestAppImages = (
  property: tProperty,
  subscription?: tProperty["subscriptions"][0]
): boolean => {
  const activeSubscriptionType = subscription
    ? subscription.type
    : propertyFindActiveSubscriptionType({ property });
  return property.guestApp.images.length === MAX_NUMBER_OF_PHOTOS_GUEST_APP[activeSubscriptionType];
};
