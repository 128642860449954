import { _uniq } from "@/lodash-utils";
import { createContext, useContext, useState } from "react";

type tState = {
  selectedImages: string[];
};

const initialState: tState = {
  selectedImages: [],
};

const PropertyProfileContext = createContext<
  | {
      state: tState;
      setState: React.Dispatch<React.SetStateAction<tState>>;
    }
  | undefined
>(undefined);

const PropertyProfileContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [state, setState] = useState({ ...initialState });

  return (
    <PropertyProfileContext.Provider value={{ state, setState }}>
      {children}
    </PropertyProfileContext.Provider>
  );
};

export const addSelectedImage = (
  setState: React.Dispatch<
    React.SetStateAction<{
      selectedImages: string[];
    }>
  >,
  image: string
) => {
  setState((prev) => ({ selectedImages: _uniq([...prev.selectedImages, image]) }));
};

export const removeSelectedImage = (
  setState: React.Dispatch<
    React.SetStateAction<{
      selectedImages: string[];
    }>
  >,
  image: string
) => {
  setState((prev) => ({ selectedImages: prev.selectedImages.filter((i) => i !== image) }));
};

export const toggleSelectedImage = (
  setState: React.Dispatch<
    React.SetStateAction<{
      selectedImages: string[];
    }>
  >,
  image: string
) => {
  setState((prev) => ({
    selectedImages: prev.selectedImages.includes(image)
      ? prev.selectedImages.filter((i) => i !== image)
      : _uniq([...prev.selectedImages, image]),
  }));
};

export const usePropertyProfileContext = () => {
  const context = useContext(PropertyProfileContext);
  if (!context) {
    throw new Error(
      "usePropertyProfileContext must be used within a PropertyProfileContextProvider"
    );
  }
  return context;
};

export default PropertyProfileContextProvider;
