import SimpleAddButton from "@/components/RsuiteWrapper/SimpleButton/buttons/addButton";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePostRequest from "@/hooks/apiRequests/usePostRequest";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React, { Fragment, useState } from "react";
import CreateEditAwardModal, { tDataOnSubmit } from "./createEditAwardModal";

interface iProps {}

const AddAward: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const propertyDispatch = usePropertyDispatch();
  const { activeProperty } = usePropertyState();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createAward = (award: tDataOnSubmit) => {
    postRequest.pending();
    const { descriptions, discount, discountType, type, validityAfterAwarding } = award;

    axios
      .post(
        ENDPOINTS.PROPERTIES.AWARDS.CREATE,
        {
          type,
          discount,
          discountType,
          validityAfterAwarding,
          descriptions,
        },
        { params: { propertyId: activeProperty._id } }
      )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        toaster.success(trans("pages.awards.notifications.award_created"));
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error);
        toaster.error(error);
      });
  };

  return (
    <Fragment>
      <CreateEditAwardModal
        onRequest={createAward}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <SimpleAddButton onClick={() => setModal({ open: true })} />
    </Fragment>
  );
};

export default AddAward;
