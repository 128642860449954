import { ReactComponent as GroupsIcon } from "@/assets/icons/groups.svg";
import { ReactComponent as PersonAddIcon } from "@/assets/icons/person_add.svg";
import SimpleAddButton from "@/components/RsuiteWrapper/SimpleButton/buttons/addButton";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useListStaff from "@/hooks/useListStaff";
import React, { Fragment, useState } from "react";
import CreateEditRole from "./createEditRole";
import CreateEditUserModal from "./createEditUserModal";

interface iProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const AddButton: React.FC<iProps> = ({ staffRequest }) => {
  const { trans } = useLocalizationState();
  const [modalsState, setModalsState] = useState<{
    user: boolean;
    staffRoles: boolean;
  }>({ user: false, staffRoles: false });
  const permissionsList = usePermissions();
  const options: tSimpleWhisperPopoverDropdownOptions[] = [];

  if (hasPermission(permissionsList, "create:staff"))
    options.push({
      key: "users",
      label: trans("general.user"),
      onClick() {
        setModalsState((prev) => ({ ...prev, user: true, staffRoles: false }));
      },
      icon: PersonAddIcon,
    });

  if (hasPermission(permissionsList, "create:roles"))
    options.push({
      key: "roles",
      label: trans("general.role"),
      onClick() {
        setModalsState((prev) => ({ ...prev, user: false, staffRoles: true }));
      },
      icon: GroupsIcon,
    });

  if (options.length === 0) return null;

  return (
    <Fragment>
      <CreateEditUserModal
        open={modalsState.user}
        onClose={() => setModalsState((prev) => ({ ...prev, user: false }))}
      />
      <CreateEditRole
        staffRequest={staffRequest}
        open={modalsState.staffRoles}
        onClose={() => setModalsState((prev) => ({ ...prev, staffRoles: false }))}
      />
      <SimpleWhisperPopoverDropdown options={options} placement="bottomEnd">
        <SimpleAddButton />
      </SimpleWhisperPopoverDropdown>
    </Fragment>
  );
};

export default AddButton;
