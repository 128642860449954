import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import fuelsConsumptionPlaceholder from "@/assets/placeholders/consumption-fuels-palceholder.png";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "@/context/WindowSize/hooks/useWindowSizeState";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { _round, _toInteger } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { GRAPH_COLORS_ELECTRICITY } from "@/utils/electricity";
import { GRAPH_COLORS_FUELS } from "@/utils/fuels";
import { generateGraphCategories } from "@/utils/graphs";
import { propertyGetManualDataWithoutZeroValues } from "@/utils/property/manualData";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { numberFormatter } from "@/utils/numbers";
import { GRAPH_COLORS_WATER } from "@/utils/water";
import { groupBy, sortBy, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Flex from "../Flex";
import ApexChart from "../Graphs/Apex/Chart";
import PageSection from "../PageSection";
import SkeletonText from "../Skeleton/SkeletonText";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";
import UnlockPageSection from "../UnlockPageSection";

interface iHistoricDataConsumptionGraphSectionWrappedProps {
  manualDataByYear: Record<string, tPropertyManualData[]>;
  conversionFactorsRequest: ReturnType<typeof useGetDefaultConverionFactors>;
  calc: (md: tPropertyManualData) => number[];
  measureConverter: nUnitConverter.tFunction;
  measureMainUnit: string;
  graphColors: string[];
}

const HistoricDataConsumptionGraphSectionWrapped: React.FC<
  iHistoricDataConsumptionGraphSectionWrappedProps
> = ({
  manualDataByYear,
  conversionFactorsRequest,
  calc,
  graphColors,
  measureConverter,
  measureMainUnit,
}) => {
  const { isMobile } = useWindowSizeState();
  const [graphType, setGraphType] = useState<"line" | "bar">("line");
  const { trans } = useLocalizationState();
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [selectedYears, setSelectedYears] = useState<number[]>([
    ...sortBy(Object.keys(manualDataByYear).map((y) => _toInteger(y))),
  ]);
  const selectedYearsRef = useRef<number[]>([]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  useEffect(() => {
    setGraphType("bar");
  }, []);

  const { consumptionSeries, co2Series, costsSeries } = useMemo(() => {
    const consumptionSeries: any[] = [];
    const co2Series: any[] = [];
    const costsSeries: any[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const consumptionData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const co2Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const costsData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      list.forEach((md) => {
        const { from } = md;
        const index = moment(from).month();
        const [consumption, co2, costs] = calc(md);
        consumptionData[index] = consumption;
        co2Data[index] = co2;
        costsData[index] = costs;
      });

      consumptionSeries.push({ name: year, data: consumptionData });
      co2Series.push({ name: year, data: co2Data });
      costsSeries.push({ name: year, data: costsData });
    });

    return { consumptionSeries, co2Series, costsSeries };
  }, [calc, manualDataByYear]);

  const [consumptionValue, co2Value, costsValue] = useMemo(() => {
    let consumptionValue = 0;
    let co2Value = 0;
    let costsValue = 0;

    selectedYears.forEach((year) => {
      manualDataByYear[year]?.forEach((md) => {
        const [value, co2, costs] = calc(md);
        consumptionValue += value;
        co2Value += co2;
        costsValue += costs;
      });
    });

    return [consumptionValue, co2Value, costsValue];
  }, [calc, manualDataByYear, selectedYears]);

  const [segmentValueSize, segmentUnitSize] = isMobile ? [16, 12] : [36, 16];

  const consumptionConverted = measureConverter(consumptionValue, {
    from: measureMainUnit,
    forceUnit: null,
  });
  const co2Converted = convertMassUnit(co2Value, {
    from: "kg",
    forceUnit: null,
  });

  const segments: {
    label: iTextProps | string;
    key: string;
    value: number;
    valueUnit: string;
    mainUnit: string;
    series: any[];
    loading: boolean;
  }[] = [
    {
      label: trans("general.consumption"),
      key: "consumption",
      value: consumptionConverted.value,
      valueUnit: consumptionConverted.unitInHTML,
      mainUnit: measureMainUnit,
      series: consumptionSeries,
      loading: false,
    },
    {
      label: {
        text: trans("general.co_2_emissions"),
        asHTML: true,
      },
      key: "co2",
      value: co2Converted.value,
      valueUnit: co2Converted.unitInHTML,
      mainUnit: "kg",
      series: co2Series,
      loading: conversionFactorsRequest.isLoading,
    },
    {
      label: trans("general.costs"),
      key: "costs",
      value: costsValue,
      valueUnit: "€",
      mainUnit: "€",
      series: costsSeries,
      loading: false,
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row>
        {segments.map(({ key, label, valueUnit, value, loading }, i) => {
          let labelColor = COLORS.gray_400;
          let valueColor = COLORS.secondary;

          if (selectedSegment === i) {
            labelColor = valueColor = COLORS.primary;
          }

          return (
            <Flex
              column
              gap={4}
              key={key}
              basis={15}
              style={{ cursor: "pointer", padding: "6px", borderRadius: "8px" }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegment !== i) setSelectedSegment(i);
              }}
            >
              <InterTag size={12} color={labelColor} {...{ ...toTextProps(label) }} />
              {loading ? (
                <SkeletonText width={100} height={segmentValueSize} />
              ) : (
                <Flex row gap={4} bottom>
                  <InterTag
                    text={numberFormatter(_round(value, 2))}
                    color={valueColor}
                    size={segmentValueSize}
                  />
                  <InterTag text={valueUnit} asHTML color={valueColor} size={segmentUnitSize} />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const categories = useMemo(
    () => generateGraphCategories("month", 1, moment().startOf("year"), moment().endOf("year")),
    []
  );

  const renderGraph = () => {
    const { series, mainUnit } = segments[selectedSegment];

    return (
      <ApexChart
        {...{
          type: graphType,
          colors: graphColors,
          categories,
          series,
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${_round(val, 2)} ${mainUnit}`;
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
              formatter(value: any, timestamp: any, opts: any) {
                if (value === null) return "";
                return moment(value).format("MMM");
              },
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
          },
          chart: {
            events: {
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  const year = toNumber(options.globals.seriesNames[seriesIndex]);
                  if (selectedYearsRef.current.includes(year)) {
                    setSelectedYears((prev) => [...prev.filter((y) => y !== year)]);
                  } else {
                    setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                  }
                }
              },
            },
          },
        }}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4 bg-white rounded-md p-4 pb-0">
      {renderSegments()}
      {renderGraph()}
    </div>
  );
};

interface iProps
  extends Pick<iHistoricDataConsumptionGraphSectionWrappedProps, "conversionFactorsRequest"> {
  measure: "electricity" | "water" | "fuels";
}

const HistoricDataConsumptionGraphSection: React.FC<iProps> = ({
  conversionFactorsRequest,
  measure,
}) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();

  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  const calc = useMemo(() => {
    switch (measure) {
      case "electricity":
        return (md: tPropertyManualData) =>
          md.electricity
            ? [
                md.electricity.totalKWh,
                md.electricity.totalKWh * conversionFactorsRequest.data.electricity_kWh_to_kgCO2e,
                md.electricity.price,
              ]
            : [0, 0, 0];
      case "water":
        return (md: tPropertyManualData) =>
          md.water
            ? [
                md.water.totalM3,
                md.water.totalM3 * conversionFactorsRequest.data.water_m3_to_kgCO2e,
                md.water.price,
              ]
            : [0, 0, 0];
      case "fuels":
        return (md: tPropertyManualData) => {
          return md.naturalGas
            ? [
                md.naturalGas?.totalKWh,
                md.naturalGas?.totalKWh * conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e,
                md.naturalGas.price,
              ]
            : [0, 0, 0];
        };
    }
  }, [
    conversionFactorsRequest.data.electricity_kWh_to_kgCO2e,
    conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e,
    conversionFactorsRequest.data.water_m3_to_kgCO2e,
    measure,
  ]);

  const [
    manualDataByYear,
    measureConverter,
    measureMainUnit,
    graphColors,
    PageSectionProps,
    unlockComponentProperties,
  ] = useMemo(() => {
    switch (measure) {
      case "electricity":
        return [
          groupBy(propertyGetManualDataWithoutZeroValues(activeProperty, ["electricity"]), (i) =>
            moment(i.from).year()
          ),
          convertEnergyUnit,
          "kWh",
          GRAPH_COLORS_ELECTRICITY,
          {
            title: trans("components.historic_data_consumption_graph_section.electricity.title"),
            description: trans(
              "components.historic_data_consumption_graph_section.electricity.description"
            ),
            icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
          },
          null,
        ];
      case "water":
        return [
          groupBy(propertyGetManualDataWithoutZeroValues(activeProperty, ["water"]), (i) =>
            moment(i.from).year()
          ),
          convertVolumeUnit,
          "m3",
          GRAPH_COLORS_WATER,
          {
            title: trans("components.historic_data_consumption_graph_section.water.title"),
            description: trans(
              "components.historic_data_consumption_graph_section.water.description"
            ),
            icon: { Element: WaterDropIcon, fill: COLORS.water },
          },
          null,
        ];
      case "fuels":
        return [
          groupBy(
            propertyGetManualDataWithoutZeroValues(activeProperty, [
              "naturalGas",
              "biomass",
              "butane",
              "diesel",
              "ethanol",
              "gasoline",
              "propane",
            ]),
            (i) => moment(i.from).year()
          ),
          convertEnergyUnit,
          "kWh",
          GRAPH_COLORS_FUELS,
          {
            title: trans("components.historic_data_consumption_graph_section.fuels.title"),
            description: trans(
              "components.historic_data_consumption_graph_section.fuels.description"
            ),
            icon: { Element: ModeHeatIcon, fill: COLORS.fuels },
          },
          {
            title: trans("components.historic_data_consumption_graph_section.fuels.unlock.title"),
            description: [0, 1].map((i) =>
              trans(
                `components.historic_data_consumption_graph_section.fuels.unlock.description.${i}`
              )
            ),
            image: fuelsConsumptionPlaceholder,
          },
        ];
    }
  }, [activeProperty, measure, trans]);

  return (
    <PageSection {...{ ...PageSectionProps }}>
      {activeSubscriptionIsStarter && unlockComponentProperties ? (
        <UnlockPageSection {...{ ...unlockComponentProperties }} />
      ) : (
        <HistoricDataConsumptionGraphSectionWrapped
          {...{
            manualDataByYear,
            conversionFactorsRequest,
            measure,
            calc,
            graphColors,
            measureConverter,
            measureMainUnit,
          }}
        />
      )}
    </PageSection>
  );
};

export default HistoricDataConsumptionGraphSection;
