import SimpleDateRangePicker from "@/components/RsuiteWrapper/SimpleDateRangePicker";
import {
  setSensorDataTimeframe,
  useTimeframeDispatch,
  useTimeframeState,
} from "@/context/Timeframe";
import WithContextProvider from "@/hoc/withProvider";
import useElementForPortal from "@/hooks/useElementForPortal";
import React from "react";
import { createPortal } from "react-dom";
import { SUSTAINABILITY_TOPBAR_ELEMENT_ID } from "../sustainability";
import SustainabilityLiveContextProvider from "./context";
import Indicators from "./indicators";

interface iProps {}

const SensorData: React.FC<iProps> = () => {
  const timeframeContext = useTimeframeState();
  const timeframeDispatch = useTimeframeDispatch();
  const portalContainer = useElementForPortal(SUSTAINABILITY_TOPBAR_ELEMENT_ID, true);

  return (
    <>
      {portalContainer &&
        createPortal(
          <div style={{ maxWidth: "300px" }}>
            <SimpleDateRangePicker
              value={timeframeContext.data.sensorDataTimeframe}
              onChange={(timeframe) => setSensorDataTimeframe(timeframeDispatch, timeframe)}
              style={{ paddingLeft: "8px" }}
            />
          </div>,
          portalContainer
        )}
      <div className="flex flex-col gap-10">
        <Indicators />
      </div>
    </>
  );
};

export default WithContextProvider(SustainabilityLiveContextProvider, SensorData);
