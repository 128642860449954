import { MomentInput } from "moment";
import React, { useEffect, useState } from "react";
import { Button, IconButton, InputGroup, InputNumber } from "rsuite";
import { tState2 } from ".";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { tManualDataFuel } from "@/interfaces/manualData";
import { tPropertyManualData } from "@/models/property";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { _toNumber, _uniq } from "@/lodash-utils";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import InputWrapper from "@/components/InputWrapper";
import SimpleWhisperPopoverDropdown from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";

const fuelTypes: ({
  wrapperProps: { labelTransKey: string; description: string };
} & (
  | {
      key: Extract<tManualDataFuel, "naturalGas">;
      labelTransKey: string;
      valueKey: "totalKWh";
      valueKeys: ["totalKWh", "totalM3"];
      unit: "kWh";
    }
  | {
      key: Extract<tManualDataFuel, "biomass" | "butane" | "propane">;
      labelTransKey: string;
      valueKey: "totalKg";
      valueKeys: ["totalKWh", "totalKg"];
      unit: "kg";
    }
  | {
      key: Extract<tManualDataFuel, "diesel" | "ethanol" | "gasoline">;
      labelTransKey: string;
      valueKey: "totalLiters";
      valueKeys: ["totalLiters", "totalKWh"];
      unit: "L";
    }
))[] = [
  {
    key: "naturalGas",
    labelTransKey: "general.fuel_types.natural_gas",
    valueKey: "totalKWh",
    valueKeys: ["totalKWh", "totalM3"],
    wrapperProps: {
      labelTransKey: "Natural Gas Consumption",
      description: "Enter your total natural gas usage for the selected time period.",
    },
    unit: "kWh",
  },
  {
    key: "biomass",
    labelTransKey: "general.fuel_types.biomass",
    valueKey: "totalKg",
    valueKeys: ["totalKWh", "totalKg"],
    wrapperProps: {
      labelTransKey: "Biomass Consumption",
      description: "Enter your total biomass usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    key: "butane",
    labelTransKey: "general.fuel_types.butane",
    valueKey: "totalKg",
    valueKeys: ["totalKWh", "totalKg"],
    wrapperProps: {
      labelTransKey: "Butane Consumption",
      description: "Enter your total butane usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    key: "propane",
    labelTransKey: "general.fuel_types.propane",
    valueKey: "totalKg",
    valueKeys: ["totalKWh", "totalKg"],
    wrapperProps: {
      labelTransKey: "Propane Consumption",
      description: "Enter your total propane usage for the selected time period.",
    },
    unit: "kg",
  },
  {
    key: "diesel",
    labelTransKey: "general.fuel_types.diesel",
    valueKey: "totalLiters",
    valueKeys: ["totalLiters", "totalKWh"],
    wrapperProps: {
      labelTransKey: "Diesel Consumption",
      description: "Enter your total diesel usage for the selected time period.",
    },
    unit: "L",
  },
  {
    key: "gasoline",
    labelTransKey: "general.fuel_types.gasoline",
    valueKey: "totalLiters",
    valueKeys: ["totalLiters", "totalKWh"],
    wrapperProps: {
      labelTransKey: "Gasoline Consumption",
      description: "Enter your total gasoline usage for the selected time period.",
    },
    unit: "L",
  },
  {
    key: "ethanol",
    labelTransKey: "general.fuel_types.ethanol",
    valueKey: "totalLiters",
    valueKeys: ["totalLiters", "totalKWh"],
    wrapperProps: {
      labelTransKey: "Ethanol Consumption",
      description: "Enter your total ethanol usage for the selected time period.",
    },
    unit: "L",
  },
];

interface iProps {
  from: MomentInput;
  to: MomentInput;
  status: nRequestStatus.tStatus;
  state: Pick<tState2["data"], tManualDataFuel>;
  errors: Pick<tState2["errors"], tManualDataFuel>;
  onChange: <
    T extends keyof Pick<Required<tPropertyManualData>, tManualDataFuel> = keyof Pick<
      tState2["data"],
      tManualDataFuel
    >
  >(
    key: T
  ) => (subKey: keyof tState2["data"][T]) => (value: number) => void;
  onRemove: <
    T extends keyof Pick<Required<tPropertyManualData>, tManualDataFuel> = keyof Pick<
      tState2["data"],
      tManualDataFuel
    >
  >(
    key: T,
    subKey: (keyof tState2["data"][T])[]
  ) => void;
}

const Fuels: React.FC<iProps> = ({ state, errors, status, from, to, onChange, onRemove }) => {
  const { trans } = useLocalizationState();
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<tManualDataFuel[]>([]);

  useEffect(() => {
    const keys = (Object.keys(state) as tManualDataFuel[]).filter(
      (k) => state[k].totalKWh || state[k].price
    );
    setSelectedFuelTypes(keys.length ? keys : ["naturalGas"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const unselectedFuelTypes = fuelTypes.filter((ft) => !selectedFuelTypes.includes(ft.key));

  return (
    <Flex column gap={20}>
      {selectedFuelTypes.map((type) => {
        const {
          key,
          valueKey,
          valueKeys,
          wrapperProps: { labelTransKey, description },
          unit,
        } = fuelTypes.find((ft) => ft.key === type)!;

        // @ts-expect-error
        const value = (state[key] as tState2["data"][tManualDataFuel])[valueKey];
        const priceValue = (state[key] as tState2["data"][tManualDataFuel])["price"];

        return (
          <InputWrapper
            key={key}
            {...{
              label: trans(labelTransKey),
              description: trans(description),
            }}
          >
            <Flex row gap={12} style={{ width: "70%" }}>
              <InputGroup>
                <InputNumber
                  disabled={status === REQUEST_STATUS.PENDING}
                  min={0}
                  value={value}
                  onChange={(value) => {
                    if (value !== null)
                      // @ts-expect-error
                      onChange(key)(valueKey)(toNumber(value));
                  }}
                />
                <InputGroup.Addon>{unit}</InputGroup.Addon>
              </InputGroup>
              <InputGroup>
                <InputNumber
                  disabled={status === REQUEST_STATUS.PENDING}
                  min={0}
                  value={priceValue}
                  onChange={(value) => {
                    if (value !== null) onChange<typeof key>(key)("price")(_toNumber(value));
                  }}
                />
                <InputGroup.Addon>€</InputGroup.Addon>
              </InputGroup>
              <div>
                <IconButton
                  onClick={() => {
                    // @ts-expect-error
                    onRemove(key, valueKeys);
                    setSelectedFuelTypes((prev) => prev.filter((k) => k !== key));
                  }}
                  icon={<Icon Element={DeleteIcon} fill={COLORS.error} size={16} />}
                />
              </div>
            </Flex>
          </InputWrapper>
        );
      })}
      {unselectedFuelTypes.length > 0 && (
        <Flex left>
          <SimpleWhisperPopoverDropdown
            options={unselectedFuelTypes.map(({ key, labelTransKey }) => ({
              key,
              label: trans(labelTransKey),
              onClick() {
                setSelectedFuelTypes((prev) => _uniq([...prev, key]));
              },
            }))}
          >
            <Button className="button-gray">{trans("Add Type of Fuel")}</Button>
          </SimpleWhisperPopoverDropdown>
        </Flex>
      )}
    </Flex>
  );
};

export default Fuels;
