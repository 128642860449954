import { ReactComponent as BadgeIcon } from "@/assets/icons/badge.svg";
import { ReactComponent as ContentCopyIcon } from "@/assets/icons/content_copy.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as GroupsIcon } from "@/assets/icons/groups.svg";
import { ReactComponent as PersonIcon } from "@/assets/icons/person.svg";
import { ReactComponent as PunchClockIcon } from "@/assets/icons/punch_clock.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/icons/visibility.svg";
import Flex from "@/components/Flex";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import SimpleWhisperTooltip from "@/components/RsuiteWrapper/SimpleWhisperTooltip";
import InterTag from "@/components/Text/Inter";
import {
  updateProperty,
  updatingProperty,
  usePropertyDispatch,
  usePropertyState,
} from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { useProfileState } from "@/context/Profile/hooks";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import useListStaff from "@/hooks/useListStaff";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { _has } from "@/lodash-utils";
import { tManager, tManagerId } from "@/models/manager";
import { tPropertyStaffRole } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { apiAddress, SERVERS_HOSTNAMES, USE_SERVER } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { copyToClipboard } from "@/utils/others";
import { propertyAccountStaffRolesList, propertyStaffMemberIsAdmin } from "@/utils/property/staff";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { min } from "lodash";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { tStaffData } from "../users";
import CreateEditUserModal from "./createEditUserModal";

interface iProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const UsersTab: React.FC<iProps> = ({ staffRequest }) => {
  const toaster = useSimpleToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const [editUserModal, setEditUserModal] = useState<
    { open: false } | { open: true; staffMember: Pick<tManager, "name" | "email" | "_id"> }
  >({ open: false });
  const deleteRequest = useDeleteRequest();
  const permissionsList = usePermissions();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();

  const options = (
    dataKey: string,
    staffMember: tStaffData
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const staffMemberIsAdmin = propertyStaffMemberIsAdmin(activeProperty, staffMember._id);

    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "show",
        label: trans("pages.users.sections.management.segments.users.table.options.view_user"),
        icon: VisibilityIcon,
        onClick() {
          navigate(`/users/${staffMember._id}`);
        },
      },
      {
        key: "copy-id",
        label: trans("pages.users.sections.management.segments.users.table.options.copy_user_id"),
        icon: ContentCopyIcon,
        onClick() {
          copyToClipboard(staffMember._id);
        },
        show: apiAddress(USE_SERVER.monolith.local) === SERVERS_HOSTNAMES.monolith.local,
      },
    ];

    if (
      hasPermission(permissionsList, "update:staff") &&
      hasPermission(permissionsList, "update:roles")
    ) {
      options.push({
        key: "update",
        label: trans("pages.users.sections.management.segments.users.table.options.edit_user"),
        icon: PersonIcon,
        onClick(value) {
          setEditUserModal({ open: true, staffMember: staffMember });
        },
      });
    }

    if (
      hasPermission(permissionsList, "delete:staff") &&
      staffMember._id !== profile._id &&
      !staffMemberIsAdmin
    ) {
      options.push({
        key: "delete",
        label: { text: trans("general.remove"), color: COLORS.error },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick(value) {
          deleteRequest.pending();
          updatingProperty(propertyDispatch);
          axios
            .delete(ENDPOINTS.PROPERTIES.STAFF.DELETE, {
              params: { propertyId: activeProperty._id, staffMemberId: staffMember._id },
            })
            .then((res) => {
              const {
                data: { property },
              } = res;
              updateProperty(propertyDispatch, activeProperty._id, property);
              deleteRequest.resolve();
              toaster.success(
                trans("pages.users.sections.management.segments.users.notifications.user_removed")
              );
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              deleteRequest.reject(error);
              toaster.error(error);
            });
        },
      });
    }

    return options;
  };

  const tableHeight = staffRequest.isLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([10, staffRequest.data.staff.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  const rolesPerUser = useMemo(() => {
    const rolesPerUser: Record<tManagerId, tPropertyStaffRole[]> = {};

    staffRequest.data.staff.forEach((s) => {
      const roles = propertyAccountStaffRolesList(activeProperty, s._id);

      rolesPerUser[s._id] = roles;
    });
    return rolesPerUser;
  }, [activeProperty, staffRequest.data.staff]);

  return (
    <Fragment>
      {editUserModal.open && (
        <CreateEditUserModal
          open={editUserModal.open}
          staffMember={editUserModal.staffMember}
          onClose={() => setEditUserModal({ open: false })}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={`USERS.TABLE`}
          data={staffRequest.data.staff}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          loading={staffRequest.isFetching}
        >
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell icon={{ Element: BadgeIcon }} name={trans("general.user")} />
            <BaseCell>
              {(staffMember: tStaffData) => {
                const { name, email } = staffMember;
                return (
                  <Flex column gap={4}>
                    <InterTag text={name} size={12} color={COLORS.secondary} />
                    <InterTag text={email} size={12} color={COLORS.primary_400} />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell icon={{ Element: GroupsIcon }} name={trans("general.role")} />
            <BaseCell>
              {(staffMember: tStaffData) => {
                const { _id } = staffMember;
                const roles = rolesPerUser[_id];

                if (roles.length === 0) return null;
                if (roles.length === 1)
                  return <InterTag size={12} color={COLORS.secondary} text={roles[0].name} />;

                return (
                  <SimpleWhisperTooltip
                    id={`USERS.TAB.${staffMember._id}.ROLES`}
                    tooltip={roles.map((r) => r.name).join(", ")}
                  >
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${roles.map((r) => r.name).join(", ")}`}
                    />
                  </SimpleWhisperTooltip>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell
              name={trans(
                "pages.users.sections.management.segments.users.table.headers.last_access"
              )}
              icon={{
                Element: PunchClockIcon,
                size: 18,
                fill: COLORS.secondary,
              }}
            />
            <SimpleKeyValueCell
              dataKey="lastAccess"
              textFunction={(staffMember: tStaffData) => {
                if (
                  !hasPermission(permissionsList, "read:staff:last-access") &&
                  staffMember._id !== profile._id
                )
                  return null;

                return _has(staffMember, "lastAccess")
                  ? moment(staffMember.lastAccess).format("dddd, MMMM DD, YYYY, HH:mm:ss")
                  : trans(
                      "pages.users.sections.management.segments.users.table.columns.last_access.no_user_activities"
                    );
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Fragment>
  );
};

export default UsersTab;
