import { IconButton } from "rsuite";
import { ReactComponent as SettingsIcon } from "@/assets/icons/more_horiz.svg";
import { COLORS } from "@/utils/colors";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../../../SimpleWhisperPopoverDropdown";
import BaseCell from "../BaseCell";

export type tSimpleActionOption = (
  dataKey: string,
  rowData: any,
  rowIndex: number
) => tSimpleWhisperPopoverDropdownOptions[];

interface iSimpleActionCellProps {
  options: tSimpleActionOption;
  propsFunction?(dataKey: string, rowData: any, rowIndex: number): Record<string, any>;
  showIf?(rowData: any): boolean;
  [key: string]: any;
}

const SimpleActionCell = ({
  options,
  propsFunction,
  showIf = () => true,
  ...props
}: iSimpleActionCellProps) => {
  const { dataKey, rowData, rowIndex } = props;
  return (
    <BaseCell
      {...{
        ...props,
        ...(propsFunction ? propsFunction(dataKey, rowData, rowIndex) : {}),
      }}
      className="link-group"
    >
      {showIf(rowData)
        ? (() => {
            const optionsList = options(dataKey, rowData, rowIndex);
            if (optionsList.length === 0) return <></>;
            return (
              <Flex right style={{ marginRight: "8px" }}>
                <SimpleWhisperPopoverDropdown
                  {...{
                    options: optionsList,
                    placement: "autoHorizontalEnd",
                  }}
                >
                  <IconButton
                    style={{ padding: 0, height: "fit-content" }}
                    appearance="subtle"
                    icon={<Icon Element={SettingsIcon} size={20} fill={COLORS.secondary} />}
                  />
                </SimpleWhisperPopoverDropdown>
              </Flex>
            );
          })()
        : ""}
    </BaseCell>
  );
};

export default SimpleActionCell;
