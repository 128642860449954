import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import { ReactComponent as ReadinessScoreIcon } from "@/assets/icons/readiness_score.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import Gauge from "@/components/Graphs/Gauge";
import Icon from "@/components/Icons/Icon";
import IconBg from "@/components/Icons/IconBg";
import PageSection from "@/components/PageSection";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tTransKey } from "@/context/Localization/utils";
import { useProfileState } from "@/context/Profile/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import WithContextProvider from "@/hoc/withProvider";
import useElementForPortal from "@/hooks/useElementForPortal";
import { tBenchmarkData, useGetBenchmarkData } from "@/hooks/useGetBenchmarkData";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { tIconElement } from "@/interfaces/icon";
import { _round, _sum } from "@/lodash-utils";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { COLORS, hex2hexA } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { numberFormatter, tNumberFormatted } from "@/utils/numbers";
import moment from "moment";
import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import { Toggle } from "rsuite";
import { SUSTAINABILITY_MANUAL_DATA_OPTIONS_ELEMENT_ID } from ".";
import { calcGrade } from "../utils";
import DisplayValueContextProvider, { useDisplayValueContext } from "./context/DisplayValueContext";

interface iLoadedGaugeProps {
  valueFormatted: tNumberFormatted;
  meanValueFormatted: tNumberFormatted;
  label: string;
  ratio: number | undefined;
}

const LoadedGauge: React.FC<iLoadedGaugeProps> = ({
  valueFormatted,
  meanValueFormatted,
  label,
  ratio,
}) => {
  const { displayValue } = useDisplayValueContext();

  return (
    <Gauge
      value={displayValue === "mean" ? meanValueFormatted : valueFormatted}
      label={label}
      ratio={ratio}
    />
  );
};

const IndicatorsWrapped: React.FC = () => {
  const {
    data: { manualDataMultiIds },
  } = useTimeframeState();
  const { profile } = useProfileState();
  const { activeProperty } = usePropertyState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: activeProperty.manualData.length > 0,
    initialStatus: activeProperty.manualData.length ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });
  const benchmarkDataRequest = useGetBenchmarkData();
  const { trans } = useLocalizationState();

  const accommodationSpacesCount = activeProperty.accommodationNumberOfUnits;

  const [manualData, days] = useMemo(() => {
    const manualData = activeProperty.manualData.filter((md) =>
      manualDataMultiIds.includes(md._id)
    );
    const days = _sum(
      manualData.map(
        ({ from, to }) => moment(to).endOf("day").diff(moment(from).startOf("day"), "days") + 1
      )
    );
    return [manualData, days];
  }, [activeProperty.manualData, manualDataMultiIds]);

  const realValues = useMemo(() => {
    let realElectricity_kWh = 0;
    let realWater_m3 = 0;
    let realNaturalGas_kWh = 0;

    manualData.forEach((md) => {
      realElectricity_kWh += md.electricity?.totalKWh || 0;
      realWater_m3 += md.water?.totalM3 || 0;
      realNaturalGas_kWh += md.naturalGas?.totalKWh || 0;
    });

    return {
      realElectricity_kWh,
      realWater_m3,
      realNaturalGas_kWh,
    };
  }, [manualData]);

  const realCo2_kg = useMemo(() => {
    if (!conversionFactorsRequest.isResolved) return 0;

    const { realElectricity_kWh, realWater_m3, realNaturalGas_kWh } = realValues;
    const { water_m3_to_kgCO2e, electricity_kWh_to_kgCO2e, natural_gas_kWh_to_kgCO2e } =
      conversionFactorsRequest.data;

    const co2_kg =
      realElectricity_kWh * electricity_kWh_to_kgCO2e +
      realWater_m3 * water_m3_to_kgCO2e +
      realNaturalGas_kWh * natural_gas_kWh_to_kgCO2e;

    return co2_kg;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realValues, conversionFactorsRequest.status]);

  const { realElectricity_kWh, realWater_m3, realNaturalGas_kWh } = realValues;
  const cards: {
    key: string;
    labelTransKey: tTransKey;
    icon: tIconElement;
    iconColor: string;
    isLoading?: boolean;
    value: number;
    benchmarkKey: keyof tBenchmarkData["perAccommodation"];
    converter: nUnitConverter.tFunction;
  }[] = [
    {
      key: "co2",
      labelTransKey: "general.co_2_emissions",
      icon: EcoIcon,
      iconColor: COLORS.emissions,
      isLoading: benchmarkDataRequest.isLoading || conversionFactorsRequest.isLoading,
      value: realCo2_kg,
      converter: convertMassUnit,
      benchmarkKey: "co2_kg",
    },
    {
      key: "electricity",
      labelTransKey: "general.measures_.electricity",
      icon: ElectricBoltIcon,
      iconColor: COLORS.energy,
      value: realElectricity_kWh,
      converter: convertEnergyUnit,
      benchmarkKey: "electricity_kWh",
    },
    {
      key: "water",
      labelTransKey: "general.measures_.water",
      icon: WaterDropIcon,
      iconColor: COLORS.water,
      value: realWater_m3,
      converter: convertVolumeUnit,
      benchmarkKey: "water_m3",
    },
    {
      key: "fuels",
      labelTransKey: "general.fuels",
      icon: ModeHeatIcon,
      iconColor: COLORS.fuels,
      value: realNaturalGas_kWh,
      converter: convertEnergyUnit,
      benchmarkKey: "fuels_kWh",
    },
  ];

  const meanDivider = accommodationSpacesCount * days;

  return (
    <div className="flex flex-row gap-4 flex-wrap">
      {cards.map(
        ({ benchmarkKey, converter, icon, iconColor, key, labelTransKey, value, isLoading }) => {
          const meanValue = value / meanDivider;
          const convertedValue = converter(value);
          const benchmarkValue = benchmarkDataRequest.data.perAccommodation[benchmarkKey];

          const [gradeTransKey, ratio, absolutePercentageDiff, highlight] = calcGrade(
            meanValue,
            benchmarkValue
          );
          return (
            <div key={key} className="flex flex-col bg-white rounded-lg p-4 gap-2">
              <div className="flex flex-row items-center gap-2">
                <IconBg Element={icon} fill={iconColor} size={24} circle />
                <InterTag text={trans(labelTransKey)} size={16} bold asHTML />
              </div>
              <div className="flex flex-col gap-3">
                {isLoading || benchmarkDataRequest.isLoading ? (
                  <>
                    <Gauge valueLoading={true} labelLoading={true} />
                    <SkeletonText width={"100%"} height={16} />
                    <SkeletonText width={"100%"} height={28} />
                  </>
                ) : (
                  <>
                    <LoadedGauge
                      valueFormatted={numberFormatter(value, profile)}
                      meanValueFormatted={numberFormatter(meanValue, profile)}
                      label={convertedValue.unit}
                      ratio={benchmarkDataRequest.isLoading ? undefined : ratio}
                    />

                    <div
                      className="flex flex-row rounded-md p-0.5 gap-1 items-center justify-center"
                      style={{ backgroundColor: hex2hexA(highlight.color, 20) }}
                    >
                      <Icon Element={highlight.icon} fill={highlight.color} size={16} />
                      <p className="inter text-xs" style={{ color: highlight.color }}>{`${_round(
                        absolutePercentageDiff,
                        2
                      )}% ${trans(highlight.labelTransKey)}`}</p>
                    </div>
                    <p className="inter text-lg text-center">{trans(gradeTransKey)}</p>
                  </>
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

const RenderIndicatorsOptions: React.FC = () => {
  const { trans } = useLocalizationState();
  const { displayValue, setDisplayValue } = useDisplayValueContext();
  const portalContainer = useElementForPortal(SUSTAINABILITY_MANUAL_DATA_OPTIONS_ELEMENT_ID, true);

  if (!portalContainer) return null;
  return createPortal(
    <div>
      <Toggle
        onChange={(checked) => {
          setDisplayValue(checked ? "mean" : "total");
        }}
        checked={displayValue === "mean"}
        checkedChildren={trans("Média")}
        unCheckedChildren={trans("Total")}
      />
    </div>,
    portalContainer
  );
};

const Indicators: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <>
      <RenderIndicatorsOptions />
      <PageSection
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans("pages.sustainability.sections.indicators.description")}
      >
        <IndicatorsWrapped />
      </PageSection>
    </>
  );
};

export default WithContextProvider(DisplayValueContextProvider, Indicators);
