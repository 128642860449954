import { usePropertyState } from "@/context/Property/hooks";
import { createContext, useState } from "react";

type tState = {
  selectedSpaces: { [spaceId: string]: boolean };
};

const initialState: tState = {
  selectedSpaces: {},
};

const SpacesContext = createContext<{ state: tState; toggleSpace: (spaceId: string) => void }>({
  state: { ...initialState },
  toggleSpace: () => {},
});

const SpacesContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { activeProperty } = usePropertyState();
  const [state, setState] = useState(() => {
    const selectedSpaces = activeProperty.spaces.reduce((acc, space) => {
      acc[space._id] = true;
      return acc;
    }, {} as tState["selectedSpaces"]);
    return { selectedSpaces };
  });

  const toggleSpace = (spaceId: string) => {
    setState((prev) => ({
      ...prev,
      selectedSpaces: { ...prev.selectedSpaces, [spaceId]: !prev.selectedSpaces[spaceId] },
    }));
  };

  return <SpacesContext.Provider value={{ state, toggleSpace }}>{children}</SpacesContext.Provider>;
};

export { SpacesContext };

export default SpacesContextProvider;
