import React from "react";
import { Modal, ModalProps } from "rsuite";
import { ReactComponent as BuildIcon } from "@/assets/icons/build.svg";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "@/context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "@/utils/colors";
import Flex from "../Flex";
import SimpleButton from "../RsuiteWrapper/SimpleButton";
import InterTag from "../Text/Inter";

const buildTransKey = (keyword: string) => `components.getting_started.modals.init.${keyword}`;

interface iInitModalWrappedProps extends Pick<iProps, "onClose" | "onContinue"> {}

const InitModalWrapped: React.FC<iInitModalWrappedProps> = ({ onClose, onContinue }) => {
  const { trans } = useLocalizationState();

  const { isMobile } = useWindowSizeState();

  const [descriptiveTextSize, titleSize, startNowContainerProps, skipContainerProps, skipTextSize] =
    isMobile
      ? [14, 28, { column: true, gap: 8 }, { column: true, gap: 8 }, 10]
      : [16, 32, { row: true, gap: 40 }, { row: true, gap: 30 }, 12];
  const lineHeight = descriptiveTextSize + 6;

  const divider = (
    <div
      style={{
        width: "100%",
        height: "1px",
        margin: "16px 0",
        backgroundColor: COLORS.gray_400,
      }}
    />
  );

  const titleContent = (
    <InterTag text={trans(buildTransKey("title"))} bold color={COLORS.secondary} size={titleSize} />
  );

  const descriptionsInterTags = [0, 1, 2, 3].map((i) => (
    <InterTag
      key={i}
      style={{
        lineHeight: `${lineHeight}px`,
        marginTop: "6px",
      }}
      wrap
      text={trans(buildTransKey(`description.${i}`))}
      color={COLORS.secondary}
      size={descriptiveTextSize}
    />
  ));

  const skipContainer = (
    <Flex {...{ ...skipContainerProps }}>
      <div>
        <InterTag
          style={{ lineHeight: `16px` }}
          wrap
          text={trans(buildTransKey("skip.description"))}
          color={COLORS.secondary}
          size={skipTextSize}
        />
      </div>
      <Flex column>
        <SimpleButton
          appearance="link"
          onClick={onClose}
          text={{
            text: trans(buildTransKey("skip.button")),
            size: 20,
            color: COLORS.primary,
          }}
        />
      </Flex>
    </Flex>
  );

  const startContainer = (
    <Flex {...{ ...startNowContainerProps }} style={{ marginTop: "24px" }}>
      <div>
        <InterTag
          style={{ lineHeight: `${lineHeight}px` }}
          wrap
          text={trans(buildTransKey("start_now.description"))}
          color={COLORS.secondary}
          size={descriptiveTextSize}
        />
      </div>
      <Flex column>
        <SimpleButton
          appearance="primary"
          onClick={onContinue}
          text={{
            text: trans(buildTransKey("start_now.button")),
            size: 20,
            color: COLORS.white,
            bold: true,
          }}
          icon={{ Element: BuildIcon, size: 24, fill: COLORS.white }}
        />
      </Flex>
    </Flex>
  );

  const renderMobile = () => {
    return (
      <Modal.Body>
        <Flex column style={{ height: "100%" }}>
          {titleContent}
          {descriptionsInterTags[0]}
          {divider}
          <Flex column between one>
            <Flex column>
              {descriptionsInterTags[1]}
              {descriptionsInterTags[2]}
              {descriptionsInterTags[3]}
              {startContainer}
            </Flex>
          </Flex>
          {divider}
          {skipContainer}
        </Flex>
      </Modal.Body>
    );
  };

  const renderDesktop = () => {
    return (
      <Modal.Body>
        <Flex column gap={8}>
          {titleContent}
          {descriptionsInterTags[0]}
          {divider}
          {descriptionsInterTags[1]}
          {descriptionsInterTags[2]}
          {descriptionsInterTags[3]}
        </Flex>
        {startContainer}
        {divider}
        {skipContainer}
      </Modal.Body>
    );
  };

  return (
    <>
      <Modal.Header></Modal.Header>
      {isMobile ? renderMobile() : renderDesktop()}
    </>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onContinue(): void;
}

const InitModal: React.FC<iProps> = ({ onClose, onContinue, ...rest }) => {
  const { isMobile } = useWindowSizeState();

  const modalSize = isMobile ? "full" : "md";

  return (
    <Modal
      {...{
        onClose,
        size: modalSize,
        overflow: false,
        keyboard: false,
        backdrop: "static",
        ...rest,
      }}
    >
      <InitModalWrapped
        {...{
          onClose,
          onContinue,
        }}
      />
    </Modal>
  );
};

export default InitModal;
