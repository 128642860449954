import { createContext, useContext, useState } from "react";

type tState = "mean" | "total";

const initialState: tState = "total";

const DisplayValueContext = createContext<
  | {
      displayValue: tState;
      setDisplayValue: (displayValue: tState) => void;
    }
  | undefined
>(undefined);

const DisplayValueContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [displayValue, setDisplayValue] = useState<tState>(initialState);

  return (
    <DisplayValueContext.Provider value={{ displayValue, setDisplayValue }}>
      {children}
    </DisplayValueContext.Provider>
  );
};

const useDisplayValueContext = () => {
  const context = useContext(DisplayValueContext);
  if (!context) {
    throw new Error("useDisplayValueContext must be used within a DisplayValueContextProvider");
  }
  return context;
};

export { useDisplayValueContext };

export default DisplayValueContextProvider;
