import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import { _orderBy } from "@/lodash-utils";
import { tConservationLabsEvent } from "@/models/conservationLabs";
import { tPropertySpace, tPropertySpaceId } from "@/models/property";
import { tMeasure } from "@/models/measures";
import { constructApiAddress, USE_SERVER } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { propertyIsSettingOn } from "@/utils/property/settings";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { getMeasureInfo } from "@/utils/measures";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios, { AxiosResponse } from "axios";
import { min } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useEffect } from "react";
import { Table } from "rsuite";
import SimpleTableWrapper from "../RsuiteWrapper/SimpleTable";
import BaseCell from "../RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleDateCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";

const buildTransKey = (key: string) => `components.conservation_labs.events.${key}`;

type tTableItem = {
  space: tPropertySpace;
  measureLabel: tMeasure;
} & tConservationLabsEvent;

interface iProps {
  from: MomentInput;
  to: MomentInput;
  spaces?: tPropertySpaceId[];
}

const ConservationLabsEvents: React.FC<iProps> = ({ from, to, spaces }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const request = useGetRequest<{ events: tTableItem[] }>({
    events: [],
  });

  useEffect(() => {
    request.pending();
    axios
      .get(
        constructApiAddress(
          `/v2/hotels/${activeProperty._id}/conservation-labs/events`,
          USE_SERVER.monolith.local
        ),
        {
          params: {
            from: moment(to).subtract(1, "day").startOf("day").toISOString(),
            to: moment(to).toISOString(),
            spaces: spaces,
          },
        }
      )
      .then((res: AxiosResponse<{ events: tConservationLabsEvent[] }>) => {
        const {
          data: { events },
        } = res;

        const parsedEvents = events
          .map((event) => {
            const conservationLabsSensor = activeProperty.conservationLabs.devices.find(
              (s) => s.deviceId === event.device_id
            );
            if (!conservationLabsSensor) return { space: undefined, measureLabel: "", ...event };

            let spaceFound = undefined;

            for (const space of activeProperty.spaces) {
              if (
                space.consumptionMetadata.measures.some((m) =>
                  m.add.some((a) => a.locationId === conservationLabsSensor.locationId)
                )
              ) {
                spaceFound = space;
                break;
              }
            }

            if (!spaceFound) return { space: undefined, measureLabel: "", ...event };

            const measureInfo = getMeasureInfo(
              conservationLabsSensor.measureLabel as tMeasure,
              trans
            );

            return {
              space: spaceFound,
              measureLabel: measureInfo.label,
              sorter: moment(event.event_begin).valueOf(),
              ...event,
            };
          })
          .filter((e) => e.space) as tTableItem[];

        const orderedEvents = _orderBy(parsedEvents, ["sorter"], ["asc"]);

        request.resolve({ events: orderedEvents });
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        request.reject(error, true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProperty, from, to, spaces, trans]);

  const tableHeight =
    ((min([7, request.data.events.length]) as number) || 1) * TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;
  return (
    <SimpleTableWrapper
      virtualized
      data={request.data.events}
      loading={request.isLoading}
      height={tableHeight}
      rowHeight={TABLE_ROW_HEIGHT.M}
      headerHeight={TABLE_HEADER_HEIGHT}
    >
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans("general.space")} />
        <SimpleKeyValueCell dataKey="space.name" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans("general.measure")} />
        <SimpleKeyValueCell dataKey="measureLabel" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans(buildTransKey("table.headers.event_date"))} />
        <SimpleDateCell showDiff={false} dataKey="event_date" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans(buildTransKey("table.headers.event_begin"))} />
        <SimpleDateCell showDiff={false} dataKey="event_begin" formatString="HH:mm:ss" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans(buildTransKey("table.headers.event_end"))} />
        <SimpleDateCell showDiff={false} dataKey="event_end" formatString="HH:mm:ss" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans(buildTransKey("table.headers.day_part"))} />
        <SimpleKeyValueCell dataKey="event_day_part" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans(buildTransKey("table.headers.event_duration"))} />
        <SimpleKeyValueCell dataKey="event_duration" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={`${trans(buildTransKey("table.headers.usage"))} (L)`} />
        <SimpleKeyValueCell dataKey="event_usage" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={`${trans("Leak Score")}`} />
        <BaseCell>
          {(item: tTableItem) => {
            let bgColor = "";

            switch (true) {
              case item.leak_score === null || item.leak_score! < 0.7: {
                bgColor = COLORS.esg_environment;
                break;
              }
              case 0.7 <= item.leak_score! && item.leak_score! < 0.8: {
                bgColor = COLORS.energy;
                break;
              }
              case 0.8 <= item.leak_score! && item.leak_score! < 0.9: {
                bgColor = COLORS.esg_social;
                break;
              }
              case 0.9 <= item.leak_score! && item.leak_score! < 1: {
                bgColor = COLORS.error;
                break;
              }
            }

            return (
              <div
                style={{
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  backgroundColor: bgColor,
                }}
              ></div>
            );
          }}
        </BaseCell>
      </Table.Column>
      {propertyIsSettingOn(activeProperty, "pages.water.live.events.table.columns.source.show") ? (
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell name={`${trans(buildTransKey("table.headers.source"))}`} />
          <SimpleKeyValueCell dataKey="event_source" />
        </Table.Column>
      ) : null}
    </SimpleTableWrapper>
  );
};

export default ConservationLabsEvents;
