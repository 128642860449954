import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import Segmentation from "@/components/Segmentation";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { findIndex } from "lodash";
import React, { useState } from "react";
import Add from "./add";
import Entries from "./entries";
import Unlock from "./unlock";
import Vehicles from "./vehicles";

const FleetWrapped2: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const { trans } = useLocalizationState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const segments: {
    key: string;
    label: string;
    Component: React.FC;
    count: number;
  }[] = [
    {
      key: "vehicles",
      label: "general.vehicles",
      Component: Vehicles,
      count: Object.keys(activeProperty.fleet).length,
    },
    {
      key: "usage",
      label: "general.usage",
      Component: Entries,
      count: Object.values(activeProperty.fleet).flatMap((f) => f.entries).length,
    },
  ];

  const renderSegmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={segments[selectedSegmentIndex].key}
            onSelect={(key) => {
              const index = findIndex(segments, (s) => s.key === key);
              if (index >= 0) setSelectedSegmentIndex(index);
            }}
            options={segments.map((f) => {
              const { key, label, count } = f;
              return {
                key,
                label: { text: trans(label) },
                count,
              };
            })}
          />
        </Flex>
        <Flex
          middle
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
        >
          <Add />
        </Flex>
      </Flex>
    );
  };

  const { Component } = segments[selectedSegmentIndex];

  return (
    <>
      <Flex column gap={16}>
        {renderSegmentation()}
        <Component />
      </Flex>
    </>
  );
};

const FleetWrapped: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  if (activeSubscriptionIsStarter) return <Unlock />;

  return <FleetWrapped2 />;
};

const Fleet: React.FC = () => {
  return (
    <IpValidatorGuard>
      <FleetWrapped />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Fleet;
