import Flex from "@/components/Flex";
import Segmentation from "@/components/Segmentation";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useProfileState } from "@/context/Profile/hooks";
import useListStaff from "@/hooks/useListStaff";
import { COLORS } from "@/utils/colors";
import {
  propertyStaffMemberIsAdmin,
  propertyVisibleRolesList,
  propertyVisibleStaffList,
} from "@/utils/property/staff";
import { findIndex } from "lodash";
import React, { useState } from "react";
import AddButton from "./addButton";
import PermissionsTab from "./permissionsTab";
import RolesTab from "./rolesTab";
import UsersTab from "./usersTab";

type tSegment = {
  key: string;
  label: string;
  count?: number;
};

const UserManagementWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const { profile } = useProfileState();
  const staffRequest = useListStaff();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);

  const onSelectSegment = (key: string) => {
    if (staffRequest.isSuccess) {
      setSelectedSegmentIndex(findIndex(segments, (f) => f.key === key));
    }
  };

  const segments: tSegment[] = [
    {
      key: "users",
      label: trans("general.users"),
      count: propertyVisibleStaffList(activeProperty, profile._id).length,
    },
    {
      key: "roles",
      label: trans("general.roles"),
      count: propertyVisibleRolesList(activeProperty, profile._id).length,
    },
  ];

  if (propertyStaffMemberIsAdmin(activeProperty, profile._id))
    segments.push({
      key: "permissions",
      label: trans("general.permissions"),
    });

  const renderTable = () => {
    switch (segments[selectedSegmentIndex].key) {
      case "users":
        return (
          <UsersTab
            {...{
              staffRequest,
            }}
          />
        );
      case "roles":
        return (
          <RolesTab
            {...{
              staffRequest,
            }}
          />
        );
      case "permissions":
        return <PermissionsTab />;
      default:
        break;
    }
  };

  const segment = segments[selectedSegmentIndex];

  return (
    <Flex column gap={16}>
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={segment.key}
            onSelect={onSelectSegment}
            options={segments.map((f) => {
              const { key, label, count } = f;
              return {
                key,
                label: { text: label },
                count: count,
              };
            })}
          />
        </Flex>
        <Flex
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
          middle
        >
          <AddButton {...{ staffRequest }} />
        </Flex>
      </Flex>
      <div>{renderTable()}</div>
    </Flex>
  );
};

const UserManagement: React.FC = () => {
  return <UserManagementWrapped />;
};

export default UserManagement;
