import IpNotPermitted from "@/components/Pages/IpNotPermitted";
import { usePropertyState } from "@/context/Property/hooks";
import { requestStatusIsLoading } from "@/utils/apiCall";
import React from "react";

interface iProps {
  children: any;
}

const IpValidatorGuard: React.FC<iProps> = ({ children }) => {
  const {
    activeProperty,
    state: { status },
  } = usePropertyState();

  if (requestStatusIsLoading(status) || !activeProperty._id.length) return null;

  if (!activeProperty.ipValidated) {
    return <IpNotPermitted />;
  }

  return <>{children}</>;
};

export default IpValidatorGuard;
