import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { AuthContextProvider } from "./context/Auth";
import useAuthDispatch from "./context/Auth/hooks/useAuthDispatch";
import useAuthState from "./context/Auth/hooks/useAuthState";
import { ContentDimensionsContextProvider } from "./context/ContentDimensions";
import { ProfileContextProvider } from "./context/Profile";
import { WindowSizeContextProvider } from "./context/WindowSize";
import ActivitiesHistory from "./pages/Auth/activitiesHistory";
import Awards from "./pages/Auth/awards";
import Challenges from "./pages/Auth/challenges";
import Consumables from "./pages/Auth/consumables";
import Consumption from "./pages/Auth/consumption";
import Dashboard from "./pages/Auth/dashboard";
import Electricity from "./pages/Auth/electricity";
import Fleet from "./pages/Auth/fleet";
import Fuels from "./pages/Auth/fuels";
import GotoSetupPassword from "./pages/Auth/gotoSetupPassword";
import Guest from "./pages/Auth/guest";
import HotelProfile from "./pages/Auth/hotelProfile";
import Laundry from "./pages/Auth/laundry";
import Loyalty from "./pages/Auth/loyalty";
import MorePages from "./pages/Auth/mobile/morePages";
import Occupancy from "./pages/Auth/occupancy";
import Properties from "./pages/Auth/properties";
import Reports from "./pages/Auth/reports";
import Reservation from "./pages/Auth/reservation";
import Reservations from "./pages/Auth/reservations";
import Roadmap from "./pages/Auth/roadmap";
import RootAuth from "./pages/Auth/root";
import Settings from "./pages/Auth/settings/";
import SettingsBillingInvoices from "./pages/Auth/settingsBillingInvoices";
import SettingsBillingPlan from "./pages/Auth/settingsBillingPlan";
import Spaces from "./pages/Auth/spaces";
import Sustainability from "./pages/Auth/sustainability";
import User from "./pages/Auth/user";
import Users from "./pages/Auth/users";
import Waste from "./pages/Auth/waste";
import Water from "./pages/Auth/water";
import ForgotPasswordWrapped from "./pages/noAuth/forgotPassword/wrapped";
import Login from "./pages/noAuth/login";
import Register from "./pages/noAuth/register";
import RootNoAuth from "./pages/noAuth/root";
import SelectPlanWrapped from "./pages/noAuth/selectPlan/wrapped";
import SetupPassword from "./pages/noAuth/setupPassword";
import "./styles/background.css";
import "./styles/global.css";
import "./styles/rsuite/index.css";
import { apiAddress } from "./utils/apiCall";
import { authConfig } from "./utils/azure/authconfig";
import { SETTINGS_PAGE_PATHNAME } from "./utils/routing";

const routerNoAuth = createBrowserRouter([
  {
    path: "/",
    element: <RootNoAuth />,
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "forgot-password", element: <ForgotPasswordWrapped /> },
      { path: "setup-password/:token", element: <SetupPassword /> },
      { path: "register", element: <Register /> },
      { path: "plan", element: <SelectPlanWrapped /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ],
  },
]);

const routerAuth = createBrowserRouter([
  {
    path: "/",
    element: <RootAuth />,
    children: [
      { path: "activities-history", element: <ActivitiesHistory /> },
      { path: "awards", element: <Awards /> },
      { path: "challenges", element: <Challenges /> },
      { path: "consumption", element: <Consumption /> },
      { path: "consumption/consumables", element: <Consumables /> },
      { path: "consumption/electricity", element: <Electricity /> },
      { path: "consumption/fleet", element: <Fleet /> },
      { path: "consumption/fuels", element: <Fuels /> },
      { path: "consumption/laundry", element: <Laundry /> },
      { path: "consumption/waste", element: <Waste /> },
      { path: "consumption/water", element: <Water /> },
      { path: "consumption/fleet", element: <Fleet /> },
      { path: "guests", element: <Reservations /> },
      { path: "guests/:idName", element: <Guest /> },
      { path: "hotel-profile", element: <HotelProfile /> },
      { path: "loyalty", element: <Loyalty /> },
      { path: "more", element: <MorePages /> },
      { path: "occupancy", element: <Occupancy /> },
      { path: "overview", element: <Dashboard /> },
      { path: "properties", element: <Properties /> },
      { path: "reports", element: <Reports /> },
      { path: "reservations/:idCode", element: <Reservation /> },
      { path: "roadmap", element: <Roadmap /> },
      { path: SETTINGS_PAGE_PATHNAME, element: <Settings /> },
      {
        path: `${SETTINGS_PAGE_PATHNAME}/billing/invoices`,
        element: <SettingsBillingInvoices />,
      },
      {
        path: `${SETTINGS_PAGE_PATHNAME}/billing/plan`,
        element: <SettingsBillingPlan />,
      },
      { path: "spaces", element: <Spaces /> },
      { path: "users/:staffId", element: <User /> },
      { path: "users", element: <Users /> },
      { path: "sustainability", element: <Sustainability /> },
      { path: "setup-password/:token", element: <GotoSetupPassword /> },

      {
        index: true,
        element: <Dashboard />,
      },
      { path: "*", element: <Navigate to="/overview" replace /> },
    ],
  },
]);

const AppWrapped: React.FC = () => {
  const { token, isResolved } = useAuthState();

  if (!isResolved) return null;

  if (!token) return <RouterProvider router={routerNoAuth} />;

  return <RouterProvider router={routerAuth} />;
};

function App1() {
  const { logout } = useAuthDispatch();
  useEffect(() => {
    axios.defaults.baseURL = apiAddress();

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError<any>) => {
        if (error.response?.data?.error === "TOKEN_EXPIRED") {
          return logout();
        }
        return Promise.reject(error);
      }
    );
  }, [logout]);

  return (
    <ProfileContextProvider>
      <AppWrapped />
    </ProfileContextProvider>
  );
}

const msalInstance = new PublicClientApplication(authConfig);
const queryClient = new QueryClient();

function App() {
  return (
    <div id="noytrall" style={{ height: "100%" }}>
      <ErrorBoundary>
        <MsalProvider instance={msalInstance}>
          <WindowSizeContextProvider>
            <ContentDimensionsContextProvider>
              <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                  <App1 />
                </AuthContextProvider>
              </QueryClientProvider>
            </ContentDimensionsContextProvider>
          </WindowSizeContextProvider>
        </MsalProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
