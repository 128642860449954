import { tProperty, tPropertyId } from "@/models/property";
import React from "react";
import { PropertyDispatchContext, PropertyStateContext } from "..";
import { nPropertyContext } from "../interfaces";

export const usePropertyState = () => {
  const state = React.useContext(PropertyStateContext);

  if (!state) throw Error("usePropertyState must be used within PropertyStateContext");

  return state;
};

export const usePropertyDispatch = () => {
  const dispatch = React.useContext(PropertyDispatchContext);

  if (!dispatch) throw Error("usePropertyDispatch must be used within PropertyDispatchContext");

  return dispatch;
};

export const updateProperty = (
  dispatch: nPropertyContext.tDispatchContext,
  propertyId: string,
  property: Partial<tProperty>
) => {
  dispatch({ type: "update property", propertyId, property });
};

export const updatingProperty = (dispatch: nPropertyContext.tDispatchContext) => {
  dispatch({ type: "pending" });
};

export const resolveProperty = (dispatch: nPropertyContext.tDispatchContext) => {
  dispatch({ type: "resolved" });
};

export const setActiveProperty = (
  dispatch: nPropertyContext.tDispatchContext,
  activeProperty: tPropertyId
) => {
  dispatch({ type: "select property", activeProperty });
};
