import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import BasicCard from "@/components/Cards/BasicCard";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useTimeframeState } from "@/context/Timeframe";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import useGetDefaultFuelsToEnergy from "@/hooks/useGetDefaultFuelsToEnergy";
import { tIcon } from "@/interfaces/icon";
import { _round } from "@/lodash-utils";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import {
  propertyFindPreviousAvailableYearManualDataEntry,
  propertyGetManualDataOrdered,
} from "@/utils/property/manualData";
import { numberFormatter } from "@/utils/numbers";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type tSimpleCard = {
  key: string;
  to: string;
  label: string;
  icon: tIcon;
  value: number;
  homologueValue: number;
  unit: string;
  converter: nUnitConverter.tFunction;
};

interface iProps {}

const ArchivesConsumptionCardsWrapped: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataIndex },
  } = useTimeframeState();
  const manualDataOrdered = useMemo(
    () => propertyGetManualDataOrdered(activeProperty),
    [activeProperty]
  );

  const selectedManualData = manualDataOrdered[manualDataOrdered.length - 1 - manualDataIndex];

  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: selectedManualData !== undefined,
  });
  const fuelsToEnergyRequest = useGetDefaultFuelsToEnergy("PT", {
    doRequest: selectedManualData !== undefined,
  });

  if (!selectedManualData) return null;

  const homologueEntry = propertyFindPreviousAvailableYearManualDataEntry(
    activeProperty,
    selectedManualData,
    "occupancy"
  );

  const [
    homologueTotalElectricity,
    homologueTotalWater,
    homologueTotalNaturalGasKWh,
    homologueTotalButaneKg,
    homologueTotalPropaneKg,
    homologueTotalBiomassKg,
    homologueTotalEthanolLiters,
    homologueTotalGasolineLiters,
    homologueTotalDieselLiters,
    homologueTotalWaste,
  ] = homologueEntry
    ? [
        (homologueEntry.electricity?.totalKWh || 0) +
          (homologueEntry.laundry?.isLaundryOutsourced &&
          homologueEntry.laundry?.outsourcedElectricityConsumptionIsKnown
            ? homologueEntry.laundry?.outsourcedElectricityConsumptionKWh || 0
            : 0),
        (homologueEntry.water?.totalM3 || 0) +
          (homologueEntry.laundry?.isLaundryOutsourced &&
          homologueEntry.laundry?.outsourcedWaterConsumptionM3
            ? homologueEntry.laundry?.outsourcedWaterConsumptionM3 || 0
            : 0),
        homologueEntry.naturalGas?.totalKWh || 0,
        homologueEntry.propane?.totalKg || 0,
        homologueEntry.butane?.totalKg || 0,
        homologueEntry.biomass?.totalKg || 0,
        homologueEntry.ethanol?.totalLiters || 0,
        homologueEntry.gasoline?.totalLiters || 0,
        homologueEntry.diesel?.totalLiters || 0,
        homologueEntry.waste
          ? homologueEntry.waste.glass.totalKg +
            homologueEntry.waste.mixed.totalKg +
            homologueEntry.waste.organic.totalKg +
            homologueEntry.waste.paper.totalKg +
            homologueEntry.waste.plastic.totalKg
          : 0,
      ]
    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const totalElectricity =
    (selectedManualData.electricity?.totalKWh || 0) +
    (selectedManualData.laundry?.isLaundryOutsourced &&
    selectedManualData.laundry?.outsourcedElectricityConsumptionIsKnown
      ? selectedManualData.laundry?.outsourcedElectricityConsumptionKWh || 0
      : 0);
  const totalWater =
    (selectedManualData.water?.totalM3 || 0) +
    (selectedManualData.laundry?.isLaundryOutsourced &&
    selectedManualData.laundry?.outsourcedWaterConsumptionM3
      ? selectedManualData.laundry?.outsourcedWaterConsumptionM3 || 0
      : 0);

  const totalNaturalGasKWh = selectedManualData.naturalGas?.totalKWh || 0;
  const totalPropaneKg = selectedManualData.propane?.totalKg || 0;
  const totalButaneKg = selectedManualData.butane?.totalKg || 0;
  const totalBiomassKg = selectedManualData.biomass?.totalKg || 0;
  const totalEthanolLiters = selectedManualData.ethanol?.totalLiters || 0;
  const totalGasolineLiters = selectedManualData.gasoline?.totalLiters || 0;
  const totalDieselLiters = selectedManualData.diesel?.totalLiters || 0;

  const totalWaste = selectedManualData.waste
    ? selectedManualData.waste.glass.totalKg +
      selectedManualData.waste.mixed.totalKg +
      selectedManualData.waste.organic.totalKg +
      selectedManualData.waste.paper.totalKg +
      selectedManualData.waste.plastic.totalKg
    : 0;

  const listMain: tSimpleCard[] = [
    {
      key: "electricity",
      to: "/consumption/electricity",
      label: trans("general.measures_.electricity"),
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      value: totalElectricity,
      homologueValue: homologueTotalElectricity,
      unit: "kWh",
      converter: convertEnergyUnit,
    },
    {
      key: "water",
      to: "/consumption/water",
      label: trans("general.measures_.water"),
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      value: totalWater,
      homologueValue: homologueTotalWater,
      unit: "m3",
      converter: convertVolumeUnit,
    },
  ];

  const wasteCardInfo: tSimpleCard = {
    key: "waste",
    to: "/consumption/waste",
    label: trans("general.waste"),
    icon: { Element: DeleteIcon, fill: COLORS.waste },
    value: totalWaste,
    homologueValue: homologueTotalWaste,
    unit: "kg",
    converter: convertMassUnit,
  };

  const renderSimpleCard = (info: tSimpleCard) => {
    const { converter, homologueValue, icon, key, label, to, unit, value } = info;
    const converted = converter(value, { from: unit, forceUnit: null });

    return (
      <BasicCard
        key={key}
        hover
        onClick={() => {
          navigate(to);
        }}
        value={_round(converted.value, 2)}
        valueUnit={converted.unitInHTML}
        valueProps={{ asHTML: true }}
        icon={icon}
        label={label}
        comparison={{
          value: value,
          baseValue: homologueValue,
        }}
      />
    );
  };

  return (
    <>
      <BasicCard
        label={{ text: trans("general.co_2_emissions"), asHTML: true }}
        icon={{ Element: EcoIcon, fill: COLORS.esg_environment }}
        onClick={() => {
          navigate("/consumption");
        }}
        hover
        {...{
          ...(conversionFactorsRequest.isLoading
            ? {
                value: "",
                valueLoading: true,
                comparisonLoading: true,
              }
            : (() => {
                const {
                  data: {
                    electricity_kWh_to_kgCO2e,
                    water_m3_to_kgCO2e,
                    natural_gas_kWh_to_kgCO2e,
                    biomass_kg_to_kgCO2e,
                    butane_kg_to_kgCO2e,
                    diesel_L_to_kgCO2e,
                    ethanol_L_to_kgCO2e,
                    gasoline_L_to_kgCO2e,
                    propane_kg_to_kgCO2e,
                  },
                } = conversionFactorsRequest;
                const value =
                  totalElectricity * electricity_kWh_to_kgCO2e +
                  totalWater * water_m3_to_kgCO2e +
                  totalNaturalGasKWh * natural_gas_kWh_to_kgCO2e +
                  totalBiomassKg * biomass_kg_to_kgCO2e +
                  totalButaneKg * butane_kg_to_kgCO2e +
                  totalPropaneKg * propane_kg_to_kgCO2e +
                  totalDieselLiters * diesel_L_to_kgCO2e +
                  totalEthanolLiters * ethanol_L_to_kgCO2e +
                  totalGasolineLiters * gasoline_L_to_kgCO2e;
                const homologueValue =
                  homologueTotalElectricity * electricity_kWh_to_kgCO2e +
                  homologueTotalWater * water_m3_to_kgCO2e +
                  homologueTotalNaturalGasKWh * natural_gas_kWh_to_kgCO2e +
                  homologueTotalBiomassKg * biomass_kg_to_kgCO2e +
                  homologueTotalButaneKg * butane_kg_to_kgCO2e +
                  homologueTotalPropaneKg * propane_kg_to_kgCO2e +
                  homologueTotalDieselLiters * diesel_L_to_kgCO2e +
                  homologueTotalEthanolLiters * ethanol_L_to_kgCO2e +
                  homologueTotalGasolineLiters * gasoline_L_to_kgCO2e;
                const converted = convertMassUnit(value, {
                  from: "kg",
                  forceUnit: null,
                });

                return {
                  value: `${numberFormatter(_round(converted.value, 2))} ${converted.unitInHTML}`,
                  comparison: {
                    value: value,
                    baseValue: homologueValue,
                  },
                };
              })()),
        }}
      />
      {listMain.map((elem) => renderSimpleCard(elem))}
      <BasicCard
        hover
        onClick={() => {
          navigate("/consumption/fuels");
        }}
        icon={{ Element: ModeHeatIcon, fill: COLORS.fuels }}
        label={trans("general.fuels")}
        {...{
          ...(fuelsToEnergyRequest.isLoading
            ? {
                valueLoading: true,
                value: "",
                comparisonLoading: true,
              }
            : (() => {
                const {
                  data: {
                    biomass_kg_to_kWh,
                    butane_kg_to_kWh,
                    diesel_L_to_kWh,
                    ethanol_L_to_kWh,
                    gasoline_L_to_kWh,
                    propane_kg_to_kWh,
                  },
                } = fuelsToEnergyRequest;

                const value =
                  totalNaturalGasKWh +
                  totalBiomassKg * biomass_kg_to_kWh +
                  totalButaneKg * butane_kg_to_kWh +
                  totalDieselLiters * diesel_L_to_kWh +
                  totalEthanolLiters * ethanol_L_to_kWh +
                  totalGasolineLiters * gasoline_L_to_kWh +
                  totalPropaneKg * propane_kg_to_kWh;
                const homologueValue =
                  homologueTotalNaturalGasKWh +
                  homologueTotalBiomassKg * biomass_kg_to_kWh +
                  homologueTotalButaneKg * butane_kg_to_kWh +
                  homologueTotalDieselLiters * diesel_L_to_kWh +
                  homologueTotalEthanolLiters * ethanol_L_to_kWh +
                  homologueTotalGasolineLiters * gasoline_L_to_kWh +
                  homologueTotalPropaneKg * propane_kg_to_kWh;

                const converted = convertEnergyUnit(value, {
                  from: "kWh",
                  forceUnit: null,
                });

                return {
                  value: `${numberFormatter(_round(converted.value, 2))} ${converted.unitInHTML}`,
                  comparison: {
                    value: value,
                    baseValue: homologueValue,
                  },
                };
              })()),
        }}
      />
      {renderSimpleCard(wasteCardInfo)}
    </>
  );
};

const ArchivesConsumptionCards: React.FC<iProps> = () => {
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataIndex },
  } = useTimeframeState();

  if (
    activeProperty.manualData.length === 0 ||
    manualDataIndex < 0 ||
    manualDataIndex + 1 > activeProperty.manualData.length
  )
    return null;
  return <ArchivesConsumptionCardsWrapped />;
};

export default ArchivesConsumptionCards;
