import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import { usePropertyState } from "@/context/Property/hooks";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import React from "react";
import LoyaltyProgram from "./loyaltyProgram";
import PerformanceProgram from "./performanceProgram";
import Unlock from "./unlock";

const LoyaltyWrapped: React.FC = () => {
  return (
    <Flex column gap={40}>
      <PerformanceProgram />
      <LoyaltyProgram />
    </Flex>
  );
};

const LoyaltyGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  return (
    <>
      <LoyaltyWrapped />
      <PageBottomPadding />
    </>
  );
};

const Loyalty: React.FC = () => {
  return (
    <IpValidatorGuard>
      <LoyaltyGuarded />
    </IpValidatorGuard>
  );
};

export default Loyalty;
