import { ReactComponent as EcoIcon } from "@/assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/payments.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import PageSection from "@/components/PageSection";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import { tIcon } from "@/interfaces/icon";
import { _round } from "@/lodash-utils";
import { TE, tMeasureTE, tMeasureTW, TW } from "@/models/measures";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { getMeasureInfo } from "@/utils/measures";
import axios from "axios";
import React, { useEffect } from "react";
import { tGuestRequest } from "./guest";

type tGetRequestData = Record<tMeasureTE | tMeasureTW | "co2" | "costs" | "numberOfNights", number>;

interface iProps {
  guestRequest: tGuestRequest;
}

const AverageGlobalConsumption: React.FC<iProps> = ({ guestRequest }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const getRequest = useGetRequest<tGetRequestData>({
    co2: 0,
    costs: 0,
    te: 0,
    tw: 0,
    numberOfNights: 0,
  });

  useEffect(() => {
    if (activeProperty._id && guestRequest.isResolved && guestRequest.data.guest._id) {
      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${activeProperty._id}/guests/${guestRequest.data.guest._id}/global-average-consumption`,
            USE_MONOLITH_SERVERLESS
          )
        )
        .then((res) => {
          const {
            data: {
              data: { te, tw, co2, costs, numberOfNights },
            },
          } = res;

          getRequest.resolve({ te, tw, co2, costs, numberOfNights });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getRequest.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestRequest.data.guest._id, guestRequest.isResolved, activeProperty._id, trans]);

  const teInfo = getMeasureInfo(TE, trans);
  const twInfo = getMeasureInfo(TW, trans);

  const cards: {
    key: keyof tGetRequestData;
    label: string;
    icon: tIcon;
    converter: nUnitConverter.tFunction;
  }[] = [
    {
      key: "co2",
      label: trans("Footprint"),
      icon: { Element: EcoIcon, fill: COLORS.emissions },
      converter: convertMassUnit,
    },
    {
      key: "costs",
      label: trans("Costs"),
      icon: { Element: PaymentsIcon, fill: COLORS.secondary },
      converter: (value: number) => ({
        value,
        unit: "€",
        unitInHTML: "€",
        string: `${value} €`,
        stringInHTML: `${value} €`,
      }),
    },
    {
      key: "te",
      label: trans("Electricity"),
      icon: { Element: ElectricBoltIcon, fill: teInfo.color },
      converter: convertEnergyUnit,
    },
    {
      key: "tw",
      label: trans("Water"),
      icon: { Element: WaterDropIcon, fill: twInfo.color },
      converter: convertVolumeUnit,
    },
  ];

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("Average Guest Consumption")}
        icon={QueryStatsIcon}
        description={trans(
          "Review the guest's average resource use for informed sustainability planning."
        )}
      />
      <Flex row gap={16}>
        {getRequest.isLoading
          ? cards.map(({ icon, key, label }) => {
              return (
                <Flex one column gap={8} key={key} className="card-sm">
                  <Flex row gap={4} middle>
                    <Icon {...{ size: 24, ...icon }} />
                    <InterTag size={16} text={label} color={COLORS.secondary} />
                  </Flex>
                  <SkeletonText width={"100px"} height={36} />
                </Flex>
              );
            })
          : getRequest.isResolved
          ? cards.map(({ icon, key, label, converter }) => {
              const converted = converter(getRequest.data[key]);

              return (
                <Flex one column gap={8} key={key} className="card-sm">
                  <Flex row gap={4} middle>
                    <Icon {...{ size: 24, ...icon }} />
                    <InterTag size={16} text={label} color={COLORS.secondary} />
                  </Flex>
                  <Flex row gap={4} bottom>
                    <InterTag
                      size={36}
                      text={_round(converted.value, 2)}
                      color={COLORS.secondary}
                    />
                    <InterTag
                      size={14}
                      text={converted.unitInHTML}
                      asHTML
                      color={COLORS.secondary}
                    />
                  </Flex>
                </Flex>
              );
            })
          : null}
      </Flex>
    </Flex>
  );
};

export default AverageGlobalConsumption;
