import React from "react";
import { InputGroup, InputNumber, Toggle } from "rsuite";
import { tState2 } from ".";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { REQUEST_STATUS } from "@/utils/apiCall";
import Flex from "@/components/Flex";
import InputWrapper from "@/components/InputWrapper";

interface iProps {
  status: nRequestStatus.tStatus;
  errors: tState2["errors"]["laundry"];
  state: tState2["data"]["laundry"];
  onChange(key: keyof tState2["data"]["laundry"], value: any): void;
}

const Laundry: React.FC<iProps> = ({ onChange, errors, state, status }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={20}>
      <InputWrapper row label={trans("Do you outsource your laundry?")}>
        <Toggle
          disabled={status === REQUEST_STATUS.PENDING}
          checked={state["isLaundryOutsourced"]}
          onChange={(checked) => onChange("isLaundryOutsourced", checked)}
          checkedChildren={trans("Yes")}
          unCheckedChildren={trans("No")}
          size="sm"
        ></Toggle>
      </InputWrapper>
      {state.isLaundryOutsourced && (
        <>
          <InputWrapper
            {...{
              label: trans("Laundry"),
              description: trans(
                "Enter your total laundry weight outsourced from your hotel over the selected time period"
              ),
              error: errors["kgOutsourced"],
              footer: trans("If left blank, we will do an estimate"),
            }}
          >
            <div style={{ width: "50%" }}>
              <InputGroup>
                <InputNumber
                  disabled={status === REQUEST_STATUS.PENDING}
                  min={0}
                  value={state["kgOutsourced"]}
                  placeholder={trans("Total laundry outsourced")}
                  onChange={(value) => {
                    if (value !== null) {
                      onChange("kgOutsourced", value);
                    }
                  }}
                />
                <InputGroup.Addon>kg</InputGroup.Addon>
              </InputGroup>
            </div>
          </InputWrapper>
          <InputWrapper
            row
            label={trans("Can you obtain electricity consumption from your laundry contractor?")}
          >
            <Toggle
              disabled={status === REQUEST_STATUS.PENDING}
              checked={state["outsourcedElectricityConsumptionIsKnown"]}
              onChange={(checked) => onChange("outsourcedElectricityConsumptionIsKnown", checked)}
              checkedChildren={trans("Yes")}
              unCheckedChildren={trans("No")}
              size="sm"
            />
          </InputWrapper>
          {state["outsourcedElectricityConsumptionIsKnown"] && (
            <InputWrapper
              {...{
                label: trans("Electricity Consumption"),
                description: trans(
                  "Enter your contractor's electricity usage for your laundry in the selected time period."
                ),
                error: errors["outsourcedElectricityConsumptionKWh"],
              }}
            >
              <div style={{ width: "50%" }}>
                <InputGroup>
                  <InputNumber
                    disabled={status === REQUEST_STATUS.PENDING}
                    min={0}
                    value={state["outsourcedElectricityConsumptionKWh"]}
                    placeholder={trans("Laundry's electricity consumption")}
                    onChange={(value) => {
                      if (value !== null) onChange("outsourcedElectricityConsumptionKWh", value);
                    }}
                  />
                  <InputGroup.Addon>kWh</InputGroup.Addon>
                </InputGroup>
              </div>
            </InputWrapper>
          )}
          <InputWrapper
            row
            label={trans("Can you obtain water consumption from your laundry contractor?")}
          >
            <Toggle
              disabled={status === REQUEST_STATUS.PENDING}
              checked={state["outsourcedWaterConsumptionIsKnown"]}
              onChange={(checked) => onChange("outsourcedWaterConsumptionIsKnown", checked)}
              checkedChildren={trans("Yes")}
              unCheckedChildren={trans("No")}
              size="sm"
            />
          </InputWrapper>
          {state["outsourcedWaterConsumptionIsKnown"] && (
            <InputWrapper
              {...{
                label: trans("Water Consumption"),
                description: trans(
                  "Enter your contractor's water usage for your laundry in the selected time period."
                ),
                error: errors["outsourcedWaterConsumptionM3"],
              }}
            >
              <div style={{ width: "50%" }}>
                <InputGroup>
                  <InputNumber
                    disabled={status === REQUEST_STATUS.PENDING}
                    min={0}
                    value={state["outsourcedWaterConsumptionM3"]}
                    placeholder={trans("Laundry's water consumption")}
                    onChange={(value) => {
                      if (value !== null) onChange("outsourcedWaterConsumptionM3", value);
                    }}
                  />
                  <InputGroup.Addon>m3</InputGroup.Addon>
                </InputGroup>
              </div>
            </InputWrapper>
          )}
        </>
      )}
    </Flex>
  );
};

export default Laundry;
