import React from "react";
import RoadmapTable from "@/components/RoadmapTable";

interface iProps {}

const Roadmap: React.FC<iProps> = () => {
  return (
    <RoadmapTable tableHeight={(rootOutletContainerHeight) => rootOutletContainerHeight - 250} />
  );
};

export default Roadmap;
