import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import React, { useState } from "react";
import { InlineEdit, Loader } from "rsuite";
import { BASE_ROW_CLASSNAMES, BASE_ROW_CLASSNAMES_WITH_BORDER } from "../utils";

interface iProps {
  label: string;
  isLoading?: boolean;
  initValue: string;
  onSave: (value: string) => any;
  lastInPanel?: boolean;
}

const TextInputRow: React.FC<iProps> = ({ label, isLoading, initValue, onSave, lastInPanel }) => {
  const { trans } = useLocalizationState();
  const [input, setInput] = useState(initValue);

  return (
    <div className={lastInPanel ? BASE_ROW_CLASSNAMES : BASE_ROW_CLASSNAMES_WITH_BORDER}>
      <p className="text-sm font-bold text-secondary inter">{trans(label)}</p>
      {isLoading ? (
        <Loader size="xs" />
      ) : (
        <InlineEdit
          className="inter"
          value={input}
          onChange={setInput}
          onCancel={() => setInput(initValue)}
          onSave={(event: any) => {
            if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
              if (input !== initValue) {
                if (onSave(input)) setInput(initValue);
                return;
              }
            }
            setInput(initValue);
          }}
          disabled={isLoading}
        />
      )}
    </div>
  );
};

export default TextInputRow;
