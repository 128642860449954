import { tManagerId } from "@/models/manager";
import { tProperty } from "@/models/property";

export const propertyAccountStaffRolesList = (property: tProperty, staffId: tManagerId) => {
  return property.staffRoles.filter((r) => r.staff.includes(staffId));
};

export const propertyVisibleRolesList = (property: tProperty, profileId: tManagerId) => {
  const roles = propertyAccountStaffRolesList(property, profileId);
  const rolesIds = roles.map((r) => r._id);

  return property.staffRoles.filter((sr) => !sr.isShadowRole || rolesIds.includes(sr._id));
};

export const propertyVisibleStaffList = (property: tProperty, profileId: tManagerId) => {
  const roles = propertyAccountStaffRolesList(property, profileId);
  const rolesStaffIds = roles.flatMap((r) => r.staff);

  return property.staff.filter((s) => !s.shadowAccount || rolesStaffIds.includes(s.staffId));
};

export const propertyStaffMemberIsAdmin = (property: tProperty, staffId: tManagerId) => {
  const roles = propertyAccountStaffRolesList(property, staffId);

  for (const role of roles) {
    if (role.isAdmin) return true;
  }
  return false;
};

export const propertyCheckAccountsShareRoles = (
  property: tProperty,
  member1: tManagerId,
  member2: tManagerId
) => {
  const roles1 = propertyAccountStaffRolesList(property, member1);
  const roles2 = propertyAccountStaffRolesList(property, member2);

  for (const r of roles1) {
    if (roles2.includes(r)) return true;
  }
  return false;
};
