import axios from "axios";
import React, { useCallback } from "react";
import { Col, Grid, Loader, Row } from "rsuite";
import { ReactComponent as GroupIcon } from "@/assets/icons/group.svg";
import { ReactComponent as PersonIcon } from "@/assets/icons/person.svg";
import BasicCard from "@/components/Cards/BasicCard";
import Flex from "@/components/Flex";
import PageSection from "@/components/PageSection";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import useEffectSafe from "@/hooks/useEffectSafe";
import { tGuest } from "@/models/guest";
import { tReservation } from "@/models/reservation";
import { constructApiAddress } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";

const buildTransKey = (key: string) => `pages.reservation.sections.guest_information.${key}`;

type tLocalGuest = Pick<tGuest, "_id" | "email" | "name" | "images" | "username">;

interface iProps {
  reservationId: tReservation["_id"];
  mainGuest?: tReservation["mainGuest"];
  additionalGuests?: tReservation["additionalGuests"];
}

const GuestInformationWrapped: React.FC<iProps> = ({
  reservationId,
  mainGuest,
  additionalGuests,
}) => {
  const getRequest = useGetRequest<{
    mainGuest: tLocalGuest | null;
    additionalGuests: tLocalGuest[];
  }>({ mainGuest: null, additionalGuests: [] });
  const { trans } = useLocalizationState();

  const load = useCallback(() => {
    getRequest.pending();
    axios
      .get(constructApiAddress(`/v2/stays/${reservationId}/guests`))
      .then((res) => {
        const {
          data: { mainGuest, additionalGuests },
        } = res;
        getRequest.resolve({ mainGuest, additionalGuests });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        getRequest.reject(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId, trans]);

  useEffectSafe(() => {
    load();
  }, [load]);

  if (getRequest.isLoading)
    return (
      <Flex middle style={{ marginLeft: "80px" }}>
        <Loader size="md" />
      </Flex>
    );

  if (!getRequest.data.mainGuest) {
    return (
      <Flex>
        <InterTag
          text={getRequest.error || trans(buildTransKey("messages.no_information"))}
          color={COLORS.hot}
        />
      </Flex>
    );
  }

  return (
    <div>
      <Grid fluid>
        <Row gutter={16}>
          {getRequest.data.mainGuest && (
            <Col>
              <BasicCard
                {...{ icon: PersonIcon, label: trans("general.guest_main") }}
                value={getRequest.data.mainGuest.name || getRequest.data.mainGuest.username}
              />
            </Col>
          )}
          {getRequest.data.additionalGuests?.map((ag, i) => (
            <Col key={i}>
              <BasicCard {...{ icon: GroupIcon, label: "" }} value={ag.name || ag.username} />
            </Col>
          ))}
        </Row>
      </Grid>
    </div>
  );
};

const GuestInformation: React.FC<iProps> = ({ reservationId, additionalGuests, mainGuest }) => {
  const { trans } = useLocalizationState();
  return (
    <PageSection title={trans(buildTransKey("title"))} icon={GroupIcon}>
      <GuestInformationWrapped {...{ reservationId, additionalGuests, mainGuest }} />
    </PageSection>
  );
};

export default GuestInformation;
