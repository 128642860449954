import { _find } from "@/lodash-utils";
import tProperty, { tPropertySpaceId } from "@/models/property";

export const propertyFindSpace = (property: tProperty, spaceId: tPropertySpaceId) => {
  return _find(property.spaces, (s) => s._id === spaceId);
};

export const propertyFindSpaces = (property: tProperty, spaceIds: tPropertySpaceId[]) => {
  return property.spaces.filter((s) => spaceIds.includes(s._id));
};

export const propertyFindSpaceByName = (property: tProperty, spaceName: string) => {
  return _find(property.spaces, (s) => s.name === spaceName);
};

export const propertyCountAccommodationSpaces = (property: tProperty) => {
  return property.spaces.filter((s) => s.isAccommodation).length;
};
