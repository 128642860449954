import { ReactComponent as GroupIcon } from "@/assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "@/assets/icons/hotel.svg";
import BasicCard from "@/components/Cards/BasicCard";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useTimeframeState } from "@/context/Timeframe";
import { tIconElement } from "@/interfaces/icon";
import { tWithRequired } from "@/interfaces/others";
import { _round } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import {
  propertyFindPreviousAvailableYearManualDataEntry,
  propertyGetManualDataOrdered,
} from "@/utils/property/manualData";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface iProps {}

const ArchivesOccupancyCardWrapped: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataIndex },
  } = useTimeframeState();
  const manualDataOrdered = useMemo(
    () => propertyGetManualDataOrdered(activeProperty),
    [activeProperty]
  );

  const selectedManualData = manualDataOrdered[manualDataOrdered.length - 1 - manualDataIndex];

  if (!selectedManualData) return null;

  const { occupancy } = selectedManualData;

  if (!occupancy) return null;

  const homologueEntry = propertyFindPreviousAvailableYearManualDataEntry(
    activeProperty,
    selectedManualData,
    "occupancy"
  );

  const list: {
    key: keyof tWithRequired<tPropertyManualData, "occupancy">["occupancy"];
    label: string;
    icon: tIconElement;
    to: string;
    unit?: string;
  }[] = [
    {
      key: "numberOfGuests",
      label: trans("Guests"),
      icon: GroupIcon,
      to: "/guests",
    },
    {
      key: "occupancyRate",
      label: trans("general.occupancy"),
      icon: HotelIcon,
      to: "/occupancy",
      unit: "%",
    },
  ];

  return (
    <>
      {list.map(({ key, label, to, icon, unit }) => {
        return (
          <BasicCard
            hover
            onClick={() => {
              navigate(to);
            }}
            {...{
              key,
              icon,
              label,

              value: `${_round(selectedManualData.occupancy![key], 2)}${unit ? ` ${unit}` : ""}`,

              ...(homologueEntry && homologueEntry.occupancy
                ? {
                    comparison: {
                      baseValue: homologueEntry.occupancy![key],
                      value: selectedManualData.occupancy![key],
                    },
                  }
                : {}),
            }}
          />
        );
      })}
    </>
  );
};

const ArchivesOccupancyCard: React.FC<iProps> = () => {
  const { activeProperty } = usePropertyState();
  const {
    data: { manualDataIndex },
  } = useTimeframeState();
  if (
    activeProperty.manualData.length === 0 ||
    manualDataIndex < 0 ||
    manualDataIndex + 1 > activeProperty.manualData.length
  )
    return null;
  return <ArchivesOccupancyCardWrapped />;
};

export default ArchivesOccupancyCard;
