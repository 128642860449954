import { ReactComponent as LaundryIcon } from "@/assets/icons/local_laundry_service.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import wasteConsumptionPlaceholder from "@/assets/placeholders/consumption-waste-data-placeholder.png";
import laundryPlaceholder from "@/assets/placeholders/laundry-placeholder.png";
import Flex from "@/components/Flex";
import HistoricDataConsumptionGraphSection from "@/components/HistoricDataConsumptionGraphSection";
import PageSection, { iPageSectionProps } from "@/components/PageSection";
import SimpleDateRangePickerForManualDataPeriods2 from "@/components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import UnlockPageSection, { iUnlockPageSectionProps } from "@/components/UnlockPageSection";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { usePropertyState } from "@/context/Property/hooks";
import { setManualDataIndex, useTimeframeDispatch, useTimeframeState } from "@/context/Timeframe";
import useElementForPortal from "@/hooks/useElementForPortal";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import React from "react";
import { createPortal } from "react-dom";
import LaundryGraph from "../../laundry/graph";
import WasteGraph from "../../waste/graph";
import { CONSUMPTION_PAGE_SECONDARY_TOP_BAR_ELEMENT_ID } from "../consumption";

const HistoricDataWrappedNonStarter: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  const list: {
    key: string;
    pageSectionProps: iPageSectionProps;
    Component: React.FC<any>;
    unlockProps: iUnlockPageSectionProps;
  }[] = [
    {
      key: "waste",
      pageSectionProps: {
        title: trans("pages.waste.archive.sections.overview.title"),
        icon: QueryStatsIcon,
        description: trans("pages.waste.archive.sections.overview.description"),
      },
      Component: WasteGraph,
      unlockProps: {
        image: wasteConsumptionPlaceholder,
        title: trans("pages.waste.unlock.title"),
        description: trans("pages.waste.unlock.description") as unknown as string[],
      },
    },
    {
      key: "laundry",
      pageSectionProps: {
        title: trans("pages.consumption.archive.sections.laundry.title"),
        description: trans("pages.consumption.archive.sections.laundry.description"),
        icon: LaundryIcon,
      },
      Component: LaundryGraph,
      unlockProps: {
        image: laundryPlaceholder,
        title: trans("pages.laundry.unlock.title"),
        description: trans("pages.laundry.unlock.description") as unknown as string[],
      },
    },
  ];

  if (activeSubscriptionIsStarter)
    return (
      <>
        {list.map(({ key, pageSectionProps, unlockProps }) => (
          <Flex column gap={20} key={key}>
            <PageSection {...{ ...pageSectionProps }} />
            <UnlockPageSection {...{ ...unlockProps }} />
          </Flex>
        ))}
      </>
    );

  return (
    <>
      {list.map(({ Component, key, pageSectionProps }) => (
        <PageSection {...{ ...pageSectionProps }} key={key}>
          <Component />
        </PageSection>
      ))}
    </>
  );
};

const HistoricDataWithConversionFactors: React.FC = () => {
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {});

  return (
    <>
      {["electricity", "water", "fuels"].map((key) => (
        <HistoricDataConsumptionGraphSection
          {...{
            key,
            conversionFactorsRequest,
            measure: key as "electricity" | "water" | "fuels",
          }}
        />
      ))}
    </>
  );
};

interface iHistoricDataProps {}

const HistoricData: React.FC<iHistoricDataProps> = () => {
  const {
    data: { manualDataIndex },
  } = useTimeframeState();
  const timeframeDispatch = useTimeframeDispatch();
  const portalContainer = useElementForPortal(CONSUMPTION_PAGE_SECONDARY_TOP_BAR_ELEMENT_ID, true);

  return (
    <>
      {portalContainer &&
        createPortal(
          <SimpleDateRangePickerForManualDataPeriods2
            {...{
              manualDataIndex,
              setManualDataIndex: (index: number) => setManualDataIndex(timeframeDispatch, index),
            }}
          />,
          portalContainer
        )}
      <div className="flex flex-col gap-10">
        <HistoricDataWithConversionFactors />
        <HistoricDataWrappedNonStarter />
        <div />
      </div>
    </>
  );
};

export default HistoricData;
