import Flex from "@/components/Flex";
import ApexChart from "@/components/Graphs/Apex/Chart";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { _round } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { generateGraphCategories } from "@/utils/graphs";
import { propertyGetManualDataOrdered } from "@/utils/property/manualData";
import { calcOccupancyRate } from "@/utils/property/occupancy";
import { groupBy, has, sortBy, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

type tCalculatedState = Record<keyof Required<tPropertyManualData>["occupancy"], number>;

interface iProps {}

const Graph: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedSegmentIndex, setSelectedSegment] = useState(0);
  const [valuesCalculated, setValuesCalculated] = useState<tCalculatedState>({
    numberOfGuests: 0,
    numberOfStays: 0,
    occupancyRate: 0,
  });
  const graphRef = useRef<any>();
  const selectedYearsRef = useRef<number[]>([]);

  const manualDataByYear: Record<number, tPropertyManualData[]> = useMemo(() => {
    return groupBy(propertyGetManualDataOrdered(activeProperty), (mdo) => moment(mdo.from).year());
  }, [activeProperty]);

  useEffect(() => {
    setSelectedYears([
      ...sortBy(Object.keys(manualDataByYear).map((y) => toNumber(y)) as number[]),
    ]);
  }, [manualDataByYear]);

  useEffect(() => {
    const summed: tCalculatedState = {
      numberOfGuests: 0,
      numberOfStays: 0,
      occupancyRate: 0,
    };
    selectedYears.forEach((year) => {
      manualDataByYear[year].forEach(({ occupancy, from, to }) => {
        if (occupancy) {
          const { numberOfGuests, numberOfStays, occupancyRate } = occupancy;
          summed.numberOfGuests += numberOfGuests;
          summed.numberOfStays += numberOfStays;
          summed.occupancyRate +=
            occupancyRate ||
            calcOccupancyRate(activeProperty.accommodationNumberOfUnits, numberOfStays, from, to);
        }
      });
    });

    if (summed.occupancyRate) {
      summed.occupancyRate /= propertyGetManualDataOrdered(activeProperty).filter((md) =>
        has(md, "occupancy")
      ).length;
    }

    setValuesCalculated(summed);
  }, [activeProperty, activeProperty.accommodationNumberOfUnits, manualDataByYear, selectedYears]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  const segments: {
    label: string;
    unit?: string;
    key: keyof Required<tPropertyManualData>["occupancy"];
  }[] = [
    {
      label: trans("general.guests"),
      key: "numberOfGuests",
    },
    {
      label: trans("general.stays"),
      key: "numberOfStays",
    },
    {
      label: trans("general.occupancy_rate"),
      unit: "%",
      key: "occupancyRate",
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row gap={40}>
        {segments.map(({ label, unit, key }, i) => {
          let valueColor = COLORS.secondary;
          let labelColor = COLORS.gray_400;

          if (selectedSegmentIndex === i) {
            valueColor = COLORS.primary;
            labelColor = COLORS.primary;
          }

          return (
            <Flex
              key={key}
              basis={12}
              column
              gap={6}
              className="hover-darken-white-bg"
              style={{ cursor: "pointer", padding: "6px", borderRadius: "8px" }}
              onClick={() => {
                if (selectedSegmentIndex !== i) setSelectedSegment(i);
              }}
            >
              <InterTag size={12} text={label} color={labelColor} />
              <Flex row gap={4} bottom>
                <InterTag size={36} text={_round(valuesCalculated[key], 2)} color={valueColor} />
                {unit && <InterTag size={12} color={valueColor} text={unit} />}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const renderGraph = () => {
    const series = Object.entries(manualDataByYear).map((entry) => {
      const [year, manualDataList] = entry;

      const data: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      const { key } = segments[selectedSegmentIndex];
      if (key === "occupancyRate") {
        manualDataList.forEach(({ occupancy, from, to }) => {
          if (occupancy) {
            const index = moment(from).month();
            data[index] = _round(
              occupancy.occupancyRate ||
                calcOccupancyRate(
                  activeProperty.accommodationNumberOfUnits,
                  occupancy.numberOfStays,
                  from,
                  to
                ),
              2
            );
          }
        });
      } else {
        manualDataList.forEach(({ occupancy, from }) => {
          if (occupancy) {
            const index = moment(from).month();
            data[index] = occupancy[segments[selectedSegmentIndex].key];
          }
        });
      }

      return {
        name: `${year}`,
        data,
      };
    });

    const categories = generateGraphCategories(
      "month",
      1,
      moment().startOf("year"),
      moment().endOf("year")
    ).map((d) => moment(d).format("MMM"));

    return (
      <ApexChart
        ref={graphRef}
        {...{
          categories,
          type: "bar",
          colors: [
            COLORS.primary_900,
            COLORS.primary_700,
            COLORS.primary_500,
            COLORS.primary_400,
            COLORS.primary_200,
          ],
          series,
          legend: {
            show: true,
            position: "bottom",
            showForSingleSeries: true,
          },
          chart: {
            events: {
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  const year = toNumber(options.globals.seriesNames[seriesIndex]);
                  if (selectedYearsRef.current.includes(year)) {
                    setSelectedYears((prev) => [...prev.filter((y) => y !== year)]);
                  } else {
                    setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                  }
                }
              },
            },
          },
        }}
      />
    );
  };

  return (
    <Flex column gap={16} className="card-m">
      {renderSegments()}
      {renderGraph()}
    </Flex>
  );
};

export default Graph;
