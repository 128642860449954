import { ceil, extend, includes, random } from "lodash";
import moment, { MomentInput } from "moment";
import { tBinUnitSingular } from "@/interfaces/sensorData";
import { tPropertySpaceId } from "@/models/property";
import {
  MEASURES_ELECTRICITY,
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  tMeasure,
} from "@/models/measures";
import { add2Arrays } from "../../arrays";

export const createMockMeasuresBySpace = (
  spaces: tPropertySpaceId[],
  measures: tMeasure[],
  _from: MomentInput,
  _to: MomentInput,
  binUnit?: tBinUnitSingular,
  binValue: number = 1
): Record<tPropertySpaceId, Record<tMeasure, number[]>> => {
  const returnData: Record<tPropertySpaceId, Record<tMeasure, number[]>> = {};

  const from = moment(_from);
  const to = moment(_to);

  const bySpaceDetailed: Record<
    tPropertySpaceId,
    Partial<Record<tMeasure, { value: number; index: number }[]>>
  > = {};
  const spacesIds = spaces;

  const _binnedData = binUnit && binValue;

  const measurementsListLength = _binnedData
    ? ceil(
        (moment(moment.min(moment(), moment(to)))
          .endOf(binUnit)
          .diff(moment(from).startOf(binUnit), binUnit) +
          1) /
          binValue
      )
    : 1;

  if (_binnedData) {
    spacesIds.forEach((id) => {
      bySpaceDetailed[id] = {};
      measures.forEach((key) => {
        bySpaceDetailed[id][key] = [];
        for (let i = 0; i < measurementsListLength; i++) {
          bySpaceDetailed[id][key]!.push({
            value: includes(MEASURES_ELECTRICITY, key) ? random(1000, 9000) : random(750, 1500),
            index: i,
          });
        }
        extend(returnData, {
          [id]: {
            ...returnData[id],
            [key]: bySpaceDetailed[id][key]!.map((e) => e.value),
          },
        });
      });
    });
  } else {
    spacesIds.forEach((id) => {
      // @ts-expect-error
      returnData[id] = {};
      measures.forEach((key) => {
        returnData[id][key] = [
          includes(MEASURES_ELECTRICITY, key)
            ? random(1000, 9000)
            : random(750, 1500) * (Math.abs(moment(to).diff(moment(from), "days")) + 1),
        ];
      });
    });
  }

  if (MEASURES_ELECTRICITY_SPECIFIC.map((k) => measures.includes(k)).filter((v) => v).length)
    spacesIds.forEach((id) => {
      returnData[id]["te"] = MEASURES_ELECTRICITY_SPECIFIC.reduce((acc: number[], current) => {
        return add2Arrays(acc, returnData[id][current] || []);
      }, []);
    });
  if (MEASURES_WATER_SPECIFIC.map((k) => measures.includes(k)).filter((v) => v).length)
    spacesIds.forEach((id) => {
      returnData[id]["tw"] = MEASURES_WATER_SPECIFIC.reduce((acc: number[], current) => {
        return add2Arrays(acc, returnData[id][current] || []);
      }, []);
    });
  return returnData;
};
