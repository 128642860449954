import React from "react";
import { Outlet } from "react-router-dom";
import { LocalizationContextProvider } from "@/context/Localization";

const Root: React.FC = () => {
  return (
    <LocalizationContextProvider>
      <Outlet />
    </LocalizationContextProvider>
  );
};

export default Root;
