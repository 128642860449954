import DOMPurify from "dompurify";
import React, { forwardRef } from "react";
import { COLORS } from "@/utils/colors";
import SkeletonText from "../Skeleton/SkeletonText";
import styles from "./styles.module.css";

export interface iTextProps {
  text: any;
  size?: number;
  color?: string;
  className?: string;
  bold?: boolean;
  onClick?(): void;
  hoverUnderline?: boolean;
  wrap?: boolean;
  alignCenter?: boolean;
  style?: React.CSSProperties;
  fontWeight?: number;
  loading?: boolean;
  skeletonWidth?: number;
  skeletonHeight?: number;
  defaultLineHeight?: boolean;

  asHTML?: boolean;

  asSpan?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
}

interface iTextTagProps extends iTextProps {
  font: "inter";
}

const TextTag = forwardRef<HTMLParagraphElement, iTextTagProps>((props, ref) => {
  const {
    font,
    text,
    size,
    wrap,
    alignCenter,
    color = COLORS.secondary,
    className: classname,
    bold,
    onClick,
    hoverUnderline,
    asHTML,
    asSpan,

    loading,
    skeletonWidth,
    skeletonHeight,

    defaultLineHeight,

    style,
    fontWeight: _fontWeight,

    children,
    ...rest
  } = props;

  if (loading)
    return <SkeletonText width={skeletonWidth || size} height={skeletonHeight || size} />;

  const className = `${font}${classname ? ` ${classname}` : ""}${
    hoverUnderline ? ` ${styles.underline}` : ""
  }`;
  const fontWeight = bold
    ? { fontWeight: "600" }
    : _fontWeight
    ? { fontWeight: `${_fontWeight}` }
    : {};

  const final: Record<string, any> = {
    ref,
    className,
    onClick,
    style: {
      ...(defaultLineHeight
        ? {}
        : {
            lineHeight: `${size}px`,
          }),
      color,
      fontSize: `${size}px`,
      ...fontWeight,
      ...(onClick ? { cursor: "pointer" } : {}),
      whiteSpace: "pre",
      margin: 0,
      ...(wrap ? { textWrap: "wrap" } : {}),
      ...(alignCenter ? { textAlign: "center" } : {}),
      ...style,
    },
    ...rest,
  };

  const content = children || text;

  if (asSpan) {
    if (asHTML) {
      return (
        <span
          {...{
            ...final,
            dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(content) },
          }}
        />
      );
    }

    return <span {...final}>{content}</span>;
  }

  if (asHTML) {
    return (
      <p
        {...{
          ...final,
          dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(content) },
        }}
      />
    );
  }

  return <p {...final}>{content}</p>;
});

export default TextTag;
