import React from "react";
import Flex from "@/components/Flex";
import { COLORS } from "@/utils/colors";
import InterTag from "@/components/Text/Inter";

interface iProps {
  title: string;
  description: string;
}

const Header: React.FC<iProps> = ({ description, title }) => {
  return (
    <Flex column gap={8}>
      <InterTag size={20} color={COLORS.secondary} text={title} bold />
      <InterTag
        style={{ textWrap: "wrap", lineHeight: "18px" }}
        size={14}
        color={COLORS.secondary}
        text={description}
      />
    </Flex>
  );
};

export default Header;
