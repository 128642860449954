import DataTypeToggle from "@/components/DataTypeToggle";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import { useDataType } from "@/context/DataType";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import WithContextProvider from "@/hoc/withProvider";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import React, { useContext } from "react";
import PageTitle from "../root/components/pageTitle";
import Archives from "./archives";
import OccupancyContextProvider, { OccupancyContext } from "./context";
import Manual from "./manual";

const OccupancyGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const context = useContext(OccupancyContext);
  const dataTypeContext = useDataType();

  if (activeSubscriptionIsStarter) return <Archives dataTypeToggle={<></>} />;

  const dataTypeToggle = (
    <DataTypeToggle loading={Object.values(context.state.dataTypeToggleDisabled).some((v) => v)} />
  );

  if (dataTypeContext.dataTypeIsLive) return <Manual {...{ dataTypeToggle }} />;

  if (dataTypeContext.dataTypeIsArchives) return <Archives {...{ dataTypeToggle }} />;

  return null;
};

const Occupancy: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <PageTitle page={trans("general.occupancy")} />
      <IpValidatorGuard>
        <OccupancyGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(OccupancyContextProvider, Occupancy);
