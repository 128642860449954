import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useApiRequest from "@/hooks/apiRequests/useApiRequest";
import { tProperty } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { preventDefault } from "@/utils/others";
import axios from "axios";
import React, { useState } from "react";
import { Button, Input } from "rsuite";

interface iProps {
  setPromotion: (promotion: { code: string; amountOff: number; percentOff: number } | null) => void;
  billingTimePeriod: "year" | "month";
  property: tProperty;
}

const VoucherCard: React.FC<iProps> = ({ setPromotion, billingTimePeriod, property }) => {
  const { trans } = useLocalizationState();
  const apiRequest = useApiRequest();
  const [input, setInput] = useState("");

  const submitPromotionCode = () => {
    apiRequest.pending();

    axios
      .get(ENDPOINTS.PROPERTIES.STRIPE.PROMOTION_CODES.BUSINESS, {
        params: {
          propertyId: property._id,
          promotionCode: input,
          billingTimePeriod,
        },
      })
      .then((res) => {
        const { amountOff, percentOff } = res.data;
        setPromotion({ code: input, amountOff, percentOff });
        apiRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        apiRequest.reject(error, true);
      });
  };

  return (
    <div className="card-m flex flex-col justify-between gap-2">
      <p className="inter font-bold text-xl text-secondary">{trans("general.voucher")}</p>
      <form onSubmit={preventDefault(submitPromotionCode)} className="flex flex-row gap-2">
        <Input value={input} onChange={(value) => setInput(value)} placeholder={"Voucher code"} />
        <Button
          appearance="primary"
          disabled={input.length === 0}
          loading={apiRequest.isLoading}
          onClick={submitPromotionCode}
        >
          <p>{trans("general.apply")}</p>
        </Button>
        <input
          type="submit"
          className="hidden
        "
        />
      </form>
    </div>
  );
};

export default React.memo(VoucherCard);
