import AddManualDataButton from "@/components/Buttons/addManualDataButton";
import HistoricDataConsumptionGraphSection from "@/components/HistoricDataConsumptionGraphSection";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { usePropertyState } from "@/context/Property/hooks";
import useElementForPortal from "@/hooks/useElementForPortal";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { ELEMENT_IDS } from "@/utils/portal";
import { propertyGetMeasuresTrackedInManualData } from "@/utils/property/manualData";
import React from "react";
import { createPortal } from "react-dom";
import Showcasing from "./showcasing";

const HistoricData: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const measuresTrackedInManualData = propertyGetMeasuresTrackedInManualData(activeProperty);
  const permissionsList = usePermissions();

  const { trans } = useLocalizationState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.water,
    initialStatus: measuresTrackedInManualData.water ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });
  const portalContainer = useElementForPortal(ELEMENT_IDS.waterPageSecondaryTopBar, true);

  return (
    <>
      {portalContainer &&
        createPortal(
          <div className="flex flex-row gap-1">
            {hasPermission(permissionsList, "create:manual-data") && (
              <AddManualDataButton modalProps={{ initialStep: "water", singleStep: true }} />
            )}
            {/* <SimpleButton
              appearance="primary"
              size={"md"}
              text={{
                text: trans("general.insert_bill"),
                color: COLORS.white,
                size: 14,
              }}
            /> */}
          </div>,
          portalContainer
        )}
      <div className="flex flex-col gap-10">
        <HistoricDataConsumptionGraphSection {...{ conversionFactorsRequest, measure: "water" }} />
        <Showcasing />
      </div>
    </>
  );
};

export default HistoricData;
