import Flex from "@/components/Flex";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleCheckCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleCheckCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useListPaymentMethods, { tPaymentMethod } from "@/hooks/useListPaymentMethods";
import { tProperty } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { min } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Table } from "rsuite";
import AddPaymentMethodModal from "../AddPaymentMethodModal";
import { buildTransKey } from "./utils";

interface iProps {
  selectedMethod: string;
  setSelectedMethod: (method: string) => void;
  property: tProperty;
}

const SelectPaymentMethodCard: React.FC<iProps> = ({
  selectedMethod,
  setSelectedMethod,
  property,
}) => {
  const { trans } = useLocalizationState();
  const { request, run } = useListPaymentMethods(property._id);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState({
    open: false,
  });

  const tableHeight = request.isLoading
    ? TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT.SM
    : (min([3, request.data.paymentMethods.length]) as number) * TABLE_ROW_HEIGHT.SM +
      TABLE_HEADER_HEIGHT;

  return (
    <>
      <AddPaymentMethodModal
        property={property}
        open={addPaymentMethodModal.open}
        onClose={(action) => {
          if (action === "create") run();
          setAddPaymentMethodModal({ open: false });
        }}
      />
      <div className="card-m flex flex-col justify-between gap-3">
        <InterTag
          size={20}
          text={trans(buildTransKey("select_payment_method"))}
          color={COLORS.secondary}
          bold
        />
        <div className="flex flex-col gap-2 flex-1">
          <div style={{ backgroundColor: COLORS.gray_100 }} className="table-wrapper">
            <Table
              rowHeight={TABLE_ROW_HEIGHT.SM}
              headerHeight={TABLE_HEADER_HEIGHT}
              data={request.data.paymentMethods}
              loading={request.isLoading}
              height={tableHeight}
            >
              <Table.Column>
                <SimpleHeaderCell />
                <SimpleCheckCell
                  isChecked={(item: tPaymentMethod) => item.id === selectedMethod}
                  onChange={(item: tPaymentMethod) => {
                    if (item.id === selectedMethod) return null;
                    setSelectedMethod(item.id);
                  }}
                />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.card")} />
                <BaseCell>
                  {(item: tPaymentMethod) => {
                    return (
                      <Flex row gap={4}>
                        <InterTag
                          text={trans("Visa **** $0", {
                            parameters: [item.card?.last4 || ""],
                          })}
                          size={12}
                          color={COLORS.secondary}
                        />
                      </Flex>
                    );
                  }}
                </BaseCell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.expires")} />
                <SimpleKeyValueCell
                  dataKey="expires"
                  textFunction={(item: tPaymentMethod) => {
                    if (item.card) {
                      const date = moment().set({
                        month: item.card.exp_month,
                        year: item.card.exp_year,
                      });
                      return trans("$0/$1", {
                        parameters: [moment(date).format("MM"), moment(date).format("YYYY")],
                      });
                    }
                  }}
                />
              </Table.Column>
            </Table>
          </div>
          <Button
            onClick={() => {
              setAddPaymentMethodModal({ open: true });
            }}
          >
            {trans(buildTransKey("add_payment_methods"))}
          </Button>
        </div>
      </div>
    </>
  );
};

export default React.memo(SelectPaymentMethodCard);
