import PageSectionBG from "@/components/PageSection/PageSectionBG";
import TableHeaders from "@/components/Tables/TableHeaders";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useProfileState } from "@/context/Profile/hooks";
import { useTimeframeState } from "@/context/Timeframe";
import { propertyIsSettingOn } from "@/utils/property/settings";
import { numberFormatter } from "@/utils/numbers";
import {
  TABLE_ROW_CLASSNAMES,
  TABLE_ROWS_CONTAINER_CLASSNAMES,
  tTableColumn,
} from "@/utils/tables";
import moment from "moment";
import React from "react";
import { useConsumptionLiveDataContext } from "./context/dataContext";

const Rows: React.FC<{ columns: tTableColumn[] }> = ({ columns }) => {
  const { binnedSeries, binInfo } = useConsumptionLiveDataContext();
  //   const { selectedSegment } = useContext(ConsumptionLiveSelectedSegmentContext)!;
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();
  const { profile } = useProfileState();

  const selectedColumns = columns;

  const specificColumnRenderers = (() => {
    // if (selectedSegment === "co2")
    return (index: number) => {
      return (
        <>
          <div className={selectedColumns[1].className}>
            {`${numberFormatter(binnedSeries.co2Total[index] || 0, profile)} kg`}
          </div>
          {!selectedColumns[2].hide && (
            <div className={selectedColumns[2].className}>{`${numberFormatter(
              binnedSeries.eCo2[index] || 0,
              profile
            )} kg`}</div>
          )}
          <div className={selectedColumns[3].className}>{`${numberFormatter(
            binnedSeries.wCo2[index] || 0,
            profile
          )} kg`}</div>
          {!selectedColumns[4].hide && (
            <div className={selectedColumns[4].className}>{`${numberFormatter(
              binnedSeries.e[index] || 0,
              profile
            )} Wh`}</div>
          )}
          <div className={selectedColumns[5].className}>{`${numberFormatter(
            binnedSeries.w[index] || 0,
            profile
          )} l`}</div>
        </>
      );
    };
  })();

  return (
    <>
      {binnedSeries.co2Total.map((series, i) => {
        const startDate = moment(sensorDataTimeframe[0]).add(i * binInfo.binSize, binInfo.binUnit);
        const endDateFormatted = moment(startDate)
          .add(binInfo.binSize - 1, binInfo.binUnit)
          .format("DD/MM/YYYY");
        const startDateFormatted = startDate.format("DD/MM/YYYY");

        return (
          <div key={`${startDateFormatted}-${endDateFormatted}`} className={TABLE_ROW_CLASSNAMES}>
            <div className={selectedColumns[0].className}>{`${startDateFormatted}${
              binInfo.binSize > 1 ? ` - ${endDateFormatted}` : ""
            }`}</div>
            {specificColumnRenderers(i)}
          </div>
        );
      })}
    </>
  );
};

const LiveDataTable: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();

  const COLUMNS: Record<"co2" | "w" | "e", tTableColumn[]> = {
    co2: [
      { label: "general.period", key: "co2.period", className: "basis-2/12" },
      { label: "general.co_2_emissions", key: "co2.total", className: "flex-1" },
      {
        label: "general.co_2_electricity",
        key: "co2.eco2",
        className: "flex-1",
        hide: !propertyIsSettingOn(activeProperty, "pages.consumption.electricity.show"),
      },
      { label: "general.co_2_water", key: "co2.wco2", className: "flex-1" },
      {
        label: "Electricity",
        key: "co2.e",
        className: "flex-1",
        hide: !propertyIsSettingOn(activeProperty, "pages.consumption.electricity.show"),
      },
      { label: "Water", key: "co2.w", className: "flex-1" },
    ],
    w: [
      { label: "general.period", key: "w.period", className: "basis-1/3" },
      { label: "general.consumption", key: "w.consumption", className: "" },
    ],
    e: [
      { label: "general.period", key: "e.period", className: "basis-1/3" },
      { label: "general.consumption", key: "e.consumption", className: "" },
    ],
  };

  return (
    <PageSectionBG title={trans("general.table")}>
      <div>
        <TableHeaders columns={COLUMNS["co2"]} />
        <div className={TABLE_ROWS_CONTAINER_CLASSNAMES + " h-fit max-h-[calc(100vh-290px)]"}>
          <Rows columns={COLUMNS["co2"]} />
        </div>
      </div>
    </PageSectionBG>
  );
};

export default LiveDataTable;
