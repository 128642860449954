import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePostRequest from "@/hooks/apiRequests/usePostRequest";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { tProperty } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React from "react";
import { Button } from "rsuite";
import { buildTransKey } from "./utils";

interface iProps {
  billingTimePeriod: "year" | "month";
  promotion: { code: string; amountOff: number; percentOff: number } | null;
  selectedMethod: string;
  onClose: (updatedProperty: tProperty) => void;
  property: tProperty;
}

const ConfirmButton: React.FC<iProps> = ({
  billingTimePeriod,
  promotion,
  selectedMethod,
  onClose,
  property,
}) => {
  const { trans } = useLocalizationState();
  const toaster = useSimpleToaster();
  const paymentRequest = usePostRequest();

  const handlePayment = () => {
    if (selectedMethod.length === 0) {
      toaster.error(trans(buildTransKey("errors.select_payment_method")));
      return;
    }
    paymentRequest.pending();

    axios
      .post(
        ENDPOINTS.PROPERTIES.SUBSCRIPTIONS.BUSINESS.SUBSCRIBE,
        {
          billingTimePeriod,
          paymentMethodId: selectedMethod,
          promotionCode: promotion?.code,
        },
        { params: { propertyId: property._id } }
      )
      .then((res) => {
        const {
          data: { property: updatedProperty },
        } = res;
        paymentRequest.resolve();
        onClose(updatedProperty);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        paymentRequest.reject(error, true);
      });
  };

  return (
    <Button
      onClick={handlePayment}
      loading={paymentRequest.isLoading}
      appearance="primary"
      disabled={selectedMethod.length === 0}
    >
      {trans("general.confirm_x", {
        parameters: [trans("general.subscription")],
      })}
    </Button>
  );
};

export default ConfirmButton;
