import DataTypeToggle from "@/components/DataTypeToggle";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { useDataType } from "@/context/DataType";
import { usePropertyState } from "@/context/Property/hooks";
import { ELEMENT_IDS } from "@/utils/portal";
import { propertyActiveSubscriptionTypeIsEnterprise } from "@/utils/property/subscriptions";
import React from "react";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const WaterGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();

  const dataTypeContext = useDataType();
  const activeSubscriptionIsEnterprise = propertyActiveSubscriptionTypeIsEnterprise({
    property: activeProperty,
  });

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row gap-2 items-center">
          {activeSubscriptionIsEnterprise && <DataTypeToggle />}
          <div id={ELEMENT_IDS.waterPageSecondaryTopBar}></div>
        </div>
      </SecondaryTopBar>
      <div className="flex flex-col gap-10">
        {!activeSubscriptionIsEnterprise || !dataTypeContext.dataTypeIsLive ? (
          <HistoricData />
        ) : (
          <LiveData />
        )}
        <div />
      </div>
    </div>
  );
};

const Water: React.FC = () => {
  return (
    <IpValidatorGuard>
      <WaterGuarded />
    </IpValidatorGuard>
  );
};

export default Water;
