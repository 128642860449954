import React from "react";
import { COLORS } from "@/utils/colors";
import Flex from "../Flex";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";
import { tIcon, tIconElement } from "@/interfaces/icon";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";

export interface iInputWrapperProps {
  icon?: tIcon | tIconElement;
  label: string | iTextProps;
  error?: string | null | iTextProps;
  description?: string | null | iTextProps;
  footer?: string | null | iTextProps;
  required?: boolean;
  optional?: boolean;
  children: React.ReactNode;
  row?: boolean;
  [key: string]: any;
}

const InputWrapper: React.FC<iInputWrapperProps> = ({
  icon,
  label,
  error,
  description,
  footer,
  required,
  optional,
  children,
  row,
  ...rest
}) => {
  const labelProps = toTextProps(label);
  const labelPropsFinal = {
    ...labelProps,
    ...(required ? { text: `${labelProps.text} *` } : {}),
    ...(optional ? { text: `${labelProps.text} (optional)` } : {}),
  };

  if (row) {
    return (
      <Flex column gap={8}>
        <Flex row gap={8}>
          <Flex row gap={8} middle>
            {icon && <Icon {...{ fill: COLORS.secondary, size: 18, ...toIconProps(icon) }} />}
            <InterTag
              bold
              color={COLORS.secondary}
              size={14}
              {...{
                ...labelPropsFinal,
              }}
            />
          </Flex>
          {children}
        </Flex>
        {error && <InterTag wrap color={COLORS.error} size={10} {...toTextProps(error)} />}
      </Flex>
    );
  }

  return (
    <Flex gap={8} column {...{ ...rest, style: { ...rest.style } }}>
      <Flex row gap={8} middle>
        {icon && <Icon {...{ fill: COLORS.secondary, size: 18, ...toIconProps(icon) }} />}
        <InterTag
          bold
          color={COLORS.secondary}
          size={14}
          {...{
            ...labelPropsFinal,
          }}
        />
      </Flex>
      {description && (
        <InterTag
          {...{
            size: 12,
            color: COLORS.gray_400,
            ...toTextProps(description),
          }}
        />
      )}
      {children}
      {footer && (
        <InterTag
          {...{
            size: 12,
            color: COLORS.gray_400,
            ...toTextProps(footer),
          }}
        />
      )}
      {error && <InterTag wrap color={COLORS.error} size={10} {...toTextProps(error)} />}
    </Flex>
  );
};

export default InputWrapper;
