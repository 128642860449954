import { ReactComponent as DateRangeIcon } from "@/assets/icons/date_range.svg";
import { ReactComponent as GroupIcon } from "@/assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "@/assets/icons/hotel.svg";
import { ReactComponent as PercentIcon } from "@/assets/icons/percent.svg";
import TrendingComparison from "@/components/Comparions/trendingComparison";
import Flex from "@/components/Flex";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useScreenSize from "@/hooks/useScreenSize";
import { _round } from "@/lodash-utils";
import { tPropertyManualData } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { propertyGetManualDataOrdered } from "@/utils/property/manualData";
import { calcOccupancyRate } from "@/utils/property/occupancy";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { floor, min } from "lodash";
import moment from "moment";
import React from "react";
import { Table } from "rsuite";

type tTableItem = tPropertyManualData & { index: number };

interface iListWrappedProps {
  outletContainerWidth: number;
}

const ListWrapped: React.FC<iListWrappedProps> = ({ outletContainerWidth }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();

  const options = (
    dataKey: string,
    award: tPropertyManualData
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];
    return options;
  };

  const manualDataOrdered = propertyGetManualDataOrdered(activeProperty);

  const data: tTableItem[] = manualDataOrdered.map((mdo, index) => ({
    ...mdo,
    index,
  }));

  const tableHeight =
    (min([5, manualDataOrdered.length]) as number) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  const periodWidth = floor(0.25 * outletContainerWidth);
  const guestsWidth = floor(0.2 * outletContainerWidth);
  const staysWidth = floor(0.2 * outletContainerWidth);
  const occupancyWidth = floor(0.2 * outletContainerWidth);

  return (
    <Flex column gap={16}>
      <div className="table-wrapper">
        <Table
          style={{ borderRadius: "8px" }}
          data={data}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell name={trans("general.period")} icon={DateRangeIcon} />
            <SimpleKeyValueCell
              dataKey="from"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [from, to].map((d) => moment(d).format("DD MMM YYYY")),
                })
              }
            />
          </Table.Column>
          <Table.Column width={guestsWidth}>
            <SimpleHeaderCell name={trans("general.guests")} icon={GroupIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index } = item;

                if (!occupancy) return null;
                const { numberOfGuests } = occupancy;

                let comparison: any = null;
                if (index > 0) {
                  let previous: Partial<tPropertyManualData> = {};
                  let i = index;
                  while (!previous.occupancy && i > 0) {
                    previous = data[i - 1];
                    i--;
                  }
                  if (previous.occupancy) {
                    comparison = (
                      <TrendingComparison
                        moreIsGood
                        size={12}
                        value={occupancy.numberOfGuests}
                        baseValue={previous.occupancy.numberOfGuests}
                      />
                    );
                  }
                }

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag size={12} color={COLORS.secondary} text={numberOfGuests} />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={staysWidth}>
            <SimpleHeaderCell name={trans("general.stays")} icon={HotelIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index } = item;

                if (!occupancy) return null;
                const { numberOfStays } = occupancy;

                let comparison: any = null;
                if (index > 0) {
                  let previous: Partial<tPropertyManualData> = {};
                  let i = index;
                  while (!previous.occupancy && i > 0) {
                    previous = data[i - 1];
                    i--;
                  }
                  if (previous.occupancy) {
                    comparison = (
                      <TrendingComparison
                        moreIsGood
                        size={12}
                        value={occupancy.numberOfStays}
                        baseValue={previous.occupancy.numberOfStays}
                      />
                    );
                  }
                }

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag size={12} color={COLORS.secondary} text={numberOfStays} />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={occupancyWidth}>
            <SimpleHeaderCell name={trans("general.occupancy_rate")} icon={PercentIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index, from, to } = item;

                if (!occupancy) return null;

                const occupancyRate =
                  occupancy.occupancyRate ||
                  calcOccupancyRate(
                    activeProperty.accommodationNumberOfUnits,
                    occupancy.numberOfStays,
                    from,
                    to
                  );
                if (index === 0)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${_round(occupancyRate, 2)} %`}
                    />
                  );

                const previous = data[index - 1];
                const previousOccupancyRate =
                  previous.occupancy?.occupancyRate ||
                  calcOccupancyRate(
                    activeProperty.accommodationNumberOfUnits,
                    previous.occupancy?.numberOfStays || 0,
                    previous.from,
                    previous.to
                  );
                const comparison = (
                  <TrendingComparison
                    moreIsGood
                    size={12}
                    value={occupancyRate}
                    baseValue={previousOccupancyRate}
                  />
                );

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${_round(occupancyRate, 2)} %`}
                    />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Flex>
  );
};

interface iProps {}

const List: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();

  if (!outlet) return null;

  return <ListWrapped outletContainerWidth={outlet.width} />;
};

export default List;
