import { ReactComponent as KeyboardArrowDownIcon } from "@/assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "@/assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as KeyboardDoubleArrowLeftIcon } from "@/assets/icons/keyboard_double_arrow_left.svg";
import { ReactComponent as KeyboardDoubleArrowRightIcon } from "@/assets/icons/keyboard_double_arrow_right.svg";
import { ReactComponent as NoytrallLogo } from "@/assets/noytrall_logo.svg";
import { ReactComponent as NoytrallSymbol } from "@/assets/noytrall_symbol.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import SimpleWhisperPopoverDropdown from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import { setActiveProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { useProfileState } from "@/context/Profile/hooks";
import { tIconElement } from "@/interfaces/icon";
import { COLORS } from "@/utils/colors";
import { MENU_MAP } from "@/utils/pages";
import { propertyIsSettingOn } from "@/utils/property/settings";
import { deburr, sortBy, toLower } from "lodash";
import React, { useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, IconButton, Tooltip, Whisper } from "rsuite";
import styles from "../styles.module.css";

const SIDEBAR_EXPANDED_LS = "__sbe__";

type tMenuElement = {
  key: string;
  icon: tIconElement;
  transKey: string;
  to: string;
  index?: boolean;
  children?: {
    key: string;
    icon: tIconElement;
    transKey: string;
    to: string;
  }[];
};

interface iProps {
  sidebarExpanded: boolean;
  setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesktopRootMenu: React.FC<iProps> = ({ sidebarExpanded, setSidebarExpanded }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const location = useLocation();
  const permissionsList = usePermissions();

  const {
    profile: { name, images, hotels },
    isResolved: isProfileResolved,
  } = useProfileState();

  const {
    activeProperty,
    state: {
      data: { properties: propertiesList },
    },
  } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  const LIST = useMemo(() => {
    const LIST: tMenuElement[][] = [];

    if (propertiesList.length > 1) {
      LIST.push([
        {
          key: "properties",
          ...MENU_MAP.properties,
        },
      ]);
    }
    LIST.push(
      [
        {
          key: "overview",
          ...MENU_MAP["overview"],
          index: true,
        },
        {
          key: "sustainability",
          ...MENU_MAP["sustainability"],
        },
        { key: "roadmap", ...MENU_MAP["roadmap"] },
      ],
      [
        {
          key: "spaces",
          ...MENU_MAP["spaces"],
        },

        {
          key: "guests",
          ...MENU_MAP["guests"],
        },
        {
          key: "occupancy",
          ...MENU_MAP["occupancy"],
        },
        {
          key: "consumption",
          ...MENU_MAP["consumption"],
          children: [
            {
              key: "consumption.electricity",
              ...MENU_MAP["consumption.electricity"],
            },
            {
              key: "consumption.water",
              ...MENU_MAP["consumption.water"],
            },
            {
              key: "consumption.fuels",
              ...MENU_MAP["consumption.fuels"],
            },
            {
              key: "consumption.waste",
              ...MENU_MAP["consumption.waste"],
            },
            {
              key: "consumption.laundry",
              ...MENU_MAP["consumption.laundry"],
            },
          ],
        },
      ],
      [
        {
          key: "awards",
          ...MENU_MAP["awards"],
        },
        {
          key: "challenges",
          ...MENU_MAP["challenges"],
        },
        {
          key: "loyalty",
          ...MENU_MAP["loyalty"],
        },
      ],
      [
        {
          key: "users",
          ...MENU_MAP["users"],
        },
      ]
    );

    if (
      propertyIsSettingOn(activeProperty, "pages.guestAppProfile.show") &&
      hasPermission(permissionsList, "update:guest-app-data")
    ) {
      LIST[LIST.length - 1].push({
        key: "profile",
        ...MENU_MAP["profile"],
      });
    }

    return LIST;
  }, [propertiesList.length, activeProperty, permissionsList]);

  const toggleSidebarExpanded = () => {
    localStorage.setItem(SIDEBAR_EXPANDED_LS, `${sidebarExpanded ? "false" : "true"}`);
    setSidebarExpanded((prev: boolean) => !prev);
    setExpanded({});
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  };

  const divider = (props: any = {}) => (
    <div {...props} style={{ margin: "8px 0" }}>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_400,
        }}
      />
    </div>
  );

  const renderDesktopMenu = () => {
    const renderMenu = () => {
      return LIST.flatMap((compartment, i) =>
        [
          divider({ key: i }),
          (compartment as tMenuElement[]).map((routeElement, i) => {
            const { icon, key, transKey, children, to, index } = routeElement;
            const color = COLORS.light_white;

            if (children && children.length)
              return (
                <Flex
                  onMouseEnter={() => {
                    if (!sidebarExpanded)
                      setExpanded((prev) => ({
                        ...prev,
                        [key]: true,
                      }));
                  }}
                  onMouseLeave={() => {
                    if (!sidebarExpanded)
                      setExpanded((prev) => ({
                        ...prev,
                        [key]: false,
                      }));
                  }}
                  column
                  key={`${transKey}.has-child`}
                >
                  <Whisper
                    trigger="hover"
                    disabled={sidebarExpanded}
                    placement="right"
                    speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                  >
                    <Flex
                      key={key}
                      center={!sidebarExpanded}
                      className={`${styles["navlink"]} ${styles["navlink-content"]} ${
                        sidebarExpanded
                          ? styles["navlink-content-expanded"]
                          : styles["navlink-content-not-expanded"]
                      } `}
                      style={{
                        cursor: "pointer",
                        padding: 0,
                        ...(location.pathname.slice(1) === to
                          ? {
                              backgroundColor: COLORS.primary,
                            }
                          : {}),
                      }}
                    >
                      {sidebarExpanded ? (
                        <Flex
                          row
                          style={{
                            width: "100%",
                          }}
                        >
                          <Flex
                            row
                            middle
                            basis={80}
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "24px",
                            }}
                            gap={16}
                            onClick={() => {
                              navigate("/consumption");
                            }}
                          >
                            <Icon Element={icon} fill={color} width={24} height={24} />
                            <InterTag text={trans(transKey)} color={color} size={16} bold />
                          </Flex>
                          <Flex
                            center
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                            basis={20}
                            className={`${styles["expandable-menu-item-arrow"]}`}
                            onClick={() =>
                              setExpanded((prev) => ({
                                ...prev,
                                [key]: !!!expanded[key],
                              }))
                            }
                          >
                            <Icon
                              Element={expanded[key] ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                              fill={COLORS.light_white}
                              size={24}
                            />
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex
                          center
                          onClick={() => {
                            navigate("/consumption");
                          }}
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            width: "100%",
                            borderRadius: "8px",
                          }}
                        >
                          <Icon Element={icon} fill={color} width={24} height={24} />
                        </Flex>
                      )}
                    </Flex>
                  </Whisper>
                  {expanded[key] === true &&
                    children.map((routeElement) => {
                      const { icon, key, transKey, to } = routeElement;
                      return (
                        <NavLink
                          key={key}
                          to={to}
                          className={({ isActive }) => {
                            return `${styles["navlink"]} ${
                              isActive || (location.pathname === "/" && index)
                                ? ` ${styles["active-link"]}`
                                : ""
                            }`;
                          }}
                        >
                          <Whisper
                            speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                            disabled={sidebarExpanded}
                            trigger="hover"
                            placement="right"
                          >
                            <Flex
                              row
                              gap={16}
                              middle
                              center={!sidebarExpanded}
                              className={`${styles["navlink-content"]} ${
                                sidebarExpanded
                                  ? styles["navlink-content-child-expanded"]
                                  : styles["navlink-content-not-expanded"]
                              }`}
                            >
                              <Icon Element={icon} fill={color} width={24} height={24} />
                              {sidebarExpanded && (
                                <InterTag bold text={trans(transKey)} color={color} size={16} />
                              )}
                            </Flex>
                          </Whisper>
                        </NavLink>
                      );
                    })}
                </Flex>
              );

            if (to)
              return (
                <NavLink
                  key={key}
                  to={to}
                  className={({ isActive }) => {
                    return `${styles["navlink"]}${
                      isActive || (location.pathname === "/" && index)
                        ? ` ${styles["active-link"]}`
                        : ""
                    }`;
                  }}
                >
                  <Whisper
                    trigger="hover"
                    placement="right"
                    disabled={sidebarExpanded}
                    speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                  >
                    <Flex
                      row
                      gap={16}
                      middle
                      center={!sidebarExpanded}
                      className={`${styles["navlink-content"]} ${
                        sidebarExpanded
                          ? styles["navlink-content-expanded"]
                          : styles["navlink-content-not-expanded"]
                      }`}
                    >
                      <Icon Element={icon} fill={color} width={24} height={24} />
                      {sidebarExpanded && (
                        <InterTag bold text={trans(transKey)} color={color} size={16} />
                      )}
                    </Flex>
                  </Whisper>
                </NavLink>
              );

            return null;
          }),
        ].flatMap((e) => e)
      );
    };

    const expandedSidemenu = () => {
      return (
        <Flex
          className={`${styles["sidemenu"]} ${styles["sidemenu-expanded"]}`}
          column
          color={COLORS.secondary}
        >
          <Flex gap={30} row middle center style={{ marginTop: "16px" }}>
            <Icon Element={NoytrallLogo} width={120} height={40} />
            <IconButton
              className={styles["sidemenu-toggle-button"]}
              onClick={toggleSidebarExpanded}
              icon={
                <Icon size={24} fill={COLORS.light_white} Element={KeyboardDoubleArrowLeftIcon} />
              }
              circle
              size="xs"
              appearance="subtle"
            />
          </Flex>
          <div style={{ margin: "0 16px" }}>{divider()}</div>
          <Flex column center middle gap={12}>
            {images && images.length > 0 && <Avatar circle src={images[0]} alt="" size={"lg"} />}
            <Flex column middle gap={18}>
              <InterTag size={16} text={name} bold color={COLORS.light_white} />
              <SimpleWhisperPopoverDropdown
                options={sortBy(propertiesList, (h) => toLower(deburr(h.name))).map(
                  ({ name, _id }) => ({
                    key: "name",
                    label: name,
                    onClick() {
                      setActiveProperty(propertyDispatch, _id);
                      // window.location.reload();
                    },
                  })
                )}
                disabled={!isProfileResolved || hotels.length <= 1}
              >
                <InterTag
                  hoverUnderline={!(!isProfileResolved || hotels.length <= 1)}
                  {...{ text: activeProperty.name, size: 14, color: COLORS.light_white }}
                />
              </SimpleWhisperPopoverDropdown>
            </Flex>
          </Flex>
          <Flex column gap={4} style={{ margin: "0 16px" }}>
            {renderMenu()}
          </Flex>
          <div style={{ width: "100%", padding: "8px 0" }}></div>
        </Flex>
      );
    };

    const notExpandedSidemenu = () => {
      return (
        <Flex
          className={`${styles["sidemenu"]} ${styles["sidemenu-not-expanded"]}`}
          column
          color={COLORS.secondary}
        >
          <Flex middle center style={{ marginTop: "16px" }}>
            <Icon Element={NoytrallSymbol} width={120} height={40} />
          </Flex>
          <div style={{ margin: "0 16px" }}>{divider()}</div>
          <Flex gap={12} column center middle>
            {images && images.length > 0 && <Avatar circle src={images[0]} alt="" size={"lg"} />}
            <IconButton
              className={styles["sidemenu-toggle-button"]}
              onClick={toggleSidebarExpanded}
              icon={
                <Icon size={24} fill={COLORS.light_white} Element={KeyboardDoubleArrowRightIcon} />
              }
              circle
              size="xs"
              appearance="subtle"
            />
          </Flex>
          <div style={{ margin: "0 16px" }}>{renderMenu()}</div>
        </Flex>
      );
    };
    if (sidebarExpanded) return expandedSidemenu();
    return notExpandedSidemenu();
  };

  return renderDesktopMenu();
};

export default DesktopRootMenu;
