import { ReactComponent as ApprovalDelegationIcon } from "@/assets/icons/approval_delegation.svg";
import { ReactComponent as CalendarClockIcon } from "@/assets/icons/calendar_clock.svg";
import { ReactComponent as CategoryIcon } from "@/assets/icons/category.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as SellIcon } from "@/assets/icons/sell.svg";
import { ReactComponent as SettingsIcon } from "@/assets/icons/settings.svg";
import { ReactComponent as ToggleOffIcon } from "@/assets/icons/toggle_off.svg";
import { ReactComponent as ToggleOnIcon } from "@/assets/icons/toggle_on.svg";
import { ReactComponent as VerifiedIcon } from "@/assets/icons/verified.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/icons/visibility.svg";
import { ReactComponent as VolunteerActivismIcon } from "@/assets/icons/volunteer_activism.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import { toIconProps } from "@/components/Icons/Icon/utils";
import PageSection from "@/components/PageSection";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import Segmentation from "@/components/Segmentation";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import usePutRequest from "@/hooks/apiRequests/usePutRequest";
import useEffectSafe from "@/hooks/useEffectSafe";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { tPropertyAward, tPropertyAwardId } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { getValidity } from "@/utils/dates";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { getAwardDescription, getAwardTypeInfo } from "@/utils/property/awards";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { findIndex, floor, min, orderBy, remove } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { Table, TableProps } from "rsuite";
import AddAward from "./add";
import CreateEditAwardModal, { tDataOnSubmit } from "./createEditAwardModal";
import NoAwards from "./noAwards";

const splitAwards = (awards: tPropertyAward[]) => {
  const availableAwards = awards.filter((a) => !a.removed);
  const active = [...availableAwards];
  const inactive = remove(active, (a) => !a.active);
  return { active, inactive };
};

const buildTransKey = (key: string) => `pages.awards.sections.list.${key}`;

const TOP_LEVEL_FILTERS: { key: "active" | "inactive"; label: string }[] = [
  {
    key: "active",
    label: "Active",
  },
  {
    key: "inactive",
    label: "Inactive",
  },
];

interface iAwardsListWrappedProps {}

const AwardsListWrapped: React.FC<iAwardsListWrappedProps> = () => {
  const { language } = useLocalizationState();
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const [editAwardModal, setEditAwardModal] = useState<{
    open: boolean;
    awardId?: tPropertyAward["_id"];
  }>({
    open: false,
    awardId: undefined,
  });
  const tableRef = useRef<any>();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const permissionsList = usePermissions();

  const getRequest = useGetRequest<{
    active: tPropertyAward[];
    inactive: tPropertyAward[];
  }>({ active: [], inactive: [] });
  const putRequest = usePutRequest();
  const deleteRequest = useDeleteRequest();

  const [{ sortColumn, sortType }, setSort] = useState<
    Pick<TableProps<tPropertyAward, string>, "sortType" | "sortColumn">
  >({ sortColumn: "type", sortType: "asc" });
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);

  useEffectSafe(() => {
    const { active, inactive } = splitAwards(activeProperty.awards);
    getRequest.resolve({ active, inactive });
  }, [activeProperty.awards, topLevelFilterIndex]);

  const isLoading = getRequest.isLoading || deleteRequest.isLoading || putRequest.isLoading;

  const onSelectTopLevelFilter = (key: string) => {
    if (!isLoading) {
      setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key));
    }
  };

  const segmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={TOP_LEVEL_FILTERS[topLevelFilterIndex].key}
            onSelect={onSelectTopLevelFilter}
            options={TOP_LEVEL_FILTERS.map((f) => {
              const { key, label } = f;
              return {
                key,
                label: { text: trans(label) },
                count: getRequest.data[key].length,
                disabled: isLoading,
              };
            })}
          />
        </Flex>
        {hasPermission(permissionsList, "create:awards") && (
          <Flex
            middle
            style={{
              borderBottom: `2px ${COLORS.gray} solid`,
            }}
          >
            <AddAward />
          </Flex>
        )}
      </Flex>
    );
  };

  const toggleAwardActive = (awardId: tPropertyAwardId, body: Pick<tPropertyAward, "active">) => {
    putRequest.pending();
    axios
      .patch(ENDPOINTS.PROPERTIES.AWARDS.UPDATE, body, {
        params: { propertyId: activeProperty._id, awardId },
      })
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        putRequest.resolve();
        toaster.success(
          body.active
            ? trans("generall.deactivated_x", {
                parameters: [trans("general.award")],
              })
            : trans("generall.activated_x", {
                parameters: [trans("general.award")],
              })
        );
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.error(error);
      });
  };

  const deleteAward = (awardId: tPropertyAwardId) => {
    deleteRequest.pending();
    axios
      .delete(ENDPOINTS.PROPERTIES.AWARDS.DELETE, {
        params: { propertyId: activeProperty._id, awardId },
      })
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        deleteRequest.resolve();
        toaster.success(trans("general.removed_x", { parameters: [trans("general.award")] }));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        deleteRequest.reject(error);
        toaster.error(error);
      });
  };

  const options = (
    dataKey: string,
    award: tPropertyAward
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "view",
        label: trans("general.view"),
        icon: { Element: VisibilityIcon },
        onClick() {},
      },
    ];

    options.push({
      key: "configure",
      label: trans("general.configure"),
      icon: { Element: SettingsIcon },
      onClick() {
        setEditAwardModal({ open: true, awardId: award._id });
      },
    });

    if (hasPermission(permissionsList, "update:awards")) {
      if (award.active) {
        options.push({
          key: "inactivate",
          label: { text: trans("general.deactivate"), color: COLORS.info },
          icon: { Element: ToggleOffIcon, fill: COLORS.info },
          onClick() {
            toggleAwardActive(award._id, { active: false });
          },
        });
      } else
        options.push({
          key: "activate",
          label: { text: trans("general.activate"), color: COLORS.info },
          icon: { Element: ToggleOnIcon, fill: COLORS.info },
          onClick() {
            toggleAwardActive(award._id, { active: true });
          },
        });
    }

    if (hasPermission(permissionsList, "delete:awards"))
      options.push({
        key: "delete",
        label: { text: trans("general.remove"), color: COLORS.error },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick() {
          deleteAward(award._id);
        },
      });

    return options;
  };

  const selectedTopLevelFilter = TOP_LEVEL_FILTERS[topLevelFilterIndex];

  const tableHeight = isLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([5, getRequest.data[selectedTopLevelFilter.key].length]) as number) *
        TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  const tableWidth = tableRef.current?.body?.offsetWidth || 0;
  const noActionWidth = 0.92;
  const typeWidth = floor(noActionWidth * 0.15 * tableWidth);
  const timesUsedWidth = floor(noActionWidth * 0.1 * tableWidth);
  const timesGivenWidth = floor(noActionWidth * 0.1 * tableWidth);
  const validityAfterAwardingWidth = floor(noActionWidth * 0.2 * tableWidth);
  const discountWidth = floor(noActionWidth * 0.15 * tableWidth);
  const descriptionWidth = floor(noActionWidth * 0.3 * tableWidth);

  const closeEditAwardModal = useCallback(() => {
    setEditAwardModal({ open: false, awardId: undefined });
  }, []);

  const onConfirmEditAward = (awardId: tPropertyAward["_id"]) => (award: tDataOnSubmit) => {
    putRequest.pending();
    axios
      .patch(
        ENDPOINTS.PROPERTIES.AWARDS.UPDATE,
        {
          ...award,
        },
        {
          params: {
            propertyId: activeProperty._id,
            awardId,
          },
        }
      )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        putRequest.resolve();
        setEditAwardModal({ open: false, awardId: undefined });
        toaster.success(trans("general.updated_x", { parameters: [trans("general.award")] }));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.error(error);
      });
  };

  return (
    <>
      {editAwardModal.open && editAwardModal.awardId !== undefined && (
        <CreateEditAwardModal
          open={true}
          awardId={editAwardModal.awardId}
          onClose={closeEditAwardModal}
          requestError={putRequest.error}
          requestStatus={putRequest.status}
          onRequest={onConfirmEditAward(editAwardModal.awardId)}
        />
      )}
      <Flex column gap={16}>
        {segmentation()}
        <div className="table-wrapper">
          <Table
            ref={tableRef}
            height={tableHeight}
            data={getRequest.data[selectedTopLevelFilter.key]}
            id={`AWARDS.TABLE`}
            rowHeight={TABLE_ROW_HEIGHT.M}
            headerHeight={TABLE_HEADER_HEIGHT}
            loading={isLoading}
            onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
              if (sortType) {
                let _sortType = sortType;
                if (dataKey !== sortColumn) {
                  _sortType = "asc";
                }
                setSort({ sortColumn: dataKey, sortType: _sortType });
                getRequest.resolve(
                  splitAwards(orderBy(activeProperty.awards, [dataKey], [_sortType]))
                );
              } else {
                const { active, inactive } = splitAwards(activeProperty.awards);
                setSort({ sortColumn: undefined, sortType: undefined });
                getRequest.resolve({ active, inactive });
              }
            }}
            {...{
              sortColumn,
              sortType,
            }}
          >
            <Table.Column sortable={!isLoading} width={typeWidth}>
              <SimpleHeaderCell icon={CategoryIcon} name={trans("general.type")} />
              <BaseCell>
                {(rowData: tPropertyAward) => {
                  const { type } = rowData;
                  const { icon, transKey } = getAwardTypeInfo(type);
                  return (
                    <Flex
                      style={{
                        padding: "4px",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                      middle
                      gap={4}
                    >
                      <Icon
                        {...{
                          fill: COLORS.secondary,
                          size: 14,
                          ...toIconProps(icon),
                        }}
                      />
                      <InterTag size={12} text={trans(transKey)} color={COLORS.secondary} />
                    </Flex>
                  );
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column sortable={!isLoading} width={discountWidth}>
              <SimpleHeaderCell
                icon={SellIcon}
                name={trans(buildTransKey("table.headers.discount.label"))}
              />
              <SimpleKeyValueCell
                dataKey="discount"
                textFunction={(rowData: tPropertyAward) => {
                  const { discount, discountType } = rowData;
                  if (discountType === "nominal") return `${discount}€`;

                  if (discount === 100) return trans("general.offer");
                  return `${discount}%`;
                }}
              />
            </Table.Column>
            <Table.Column width={timesUsedWidth} sortable={!isLoading}>
              <SimpleHeaderCell
                icon={ApprovalDelegationIcon}
                name={trans(buildTransKey("table.headers.used.label"))}
              />
              <SimpleKeyValueCell dataKey="timesUsed" />
            </Table.Column>
            <Table.Column width={timesGivenWidth} sortable={!isLoading}>
              <SimpleHeaderCell
                icon={VolunteerActivismIcon}
                name={trans(buildTransKey("table.headers.given.label"))}
              />
              <SimpleKeyValueCell dataKey="timesAwarded" />
            </Table.Column>
            <Table.Column width={validityAfterAwardingWidth}>
              <SimpleHeaderCell
                icon={CalendarClockIcon}
                name={trans("general.validity")}
                tooltip={trans(buildTransKey("table.headers.validity.tooltip"))}
              />
              <SimpleKeyValueCell
                dataKey="validityAfterAwarding"
                textFunction={(rowData, dataKey: string) => {
                  const { years, months, days } = getValidity(rowData[dataKey]);
                  return [
                    {
                      value: years,
                      label: ["general.1_year", "general.x_years"],
                    },
                    {
                      value: months,
                      label: ["general.1_month", "general.x_months"],
                    },
                    { value: days, label: ["general.1_day", "general.x_days"] },
                  ]
                    .filter(({ value }) => value)
                    .map(({ value, label }) =>
                      trans(value === 1 ? label[0] : label[1], {
                        parameters: [value],
                      })
                    )
                    .join(", ");
                }}
              />
            </Table.Column>
            <Table.Column width={descriptionWidth}>
              <SimpleHeaderCell icon={VerifiedIcon} name={trans("general.award")} />
              <SimpleKeyValueCell
                tooltipDisplay
                tooltipTextFunction={(rowData) =>
                  `${getAwardDescription(rowData, language)?.longDescription}`
                }
                dataKey="shortDescription"
                textFunction={(rowData) =>
                  `${getAwardDescription(rowData, language)?.shortDescription}`
                }
              />
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <SimpleHeaderCell />
              <SimpleActionCell options={options} dataKey="_id" />
            </Table.Column>
          </Table>
          {getRequest.data[selectedTopLevelFilter.key].length === 0 && !getRequest.isLoading && (
            <NoAwards>{hasPermission(permissionsList, "create:awards") && <AddAward />}</NoAwards>
          )}
        </div>
      </Flex>
    </>
  );
};
interface iProps {}

const AwardsList: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={{ text: trans(buildTransKey("title")) }}
        description={{
          text: trans(buildTransKey("description")),
        }}
        icon={{ Element: VerifiedIcon }}
      />
      <AwardsListWrapped />
    </Flex>
  );
};

export default AwardsList;
