import { ReactComponent as TrendingDownIcon } from "@/assets/icons/trending_down.svg";
import { ReactComponent as TrendingUpIcon } from "@/assets/icons/trending_up.svg";
import { tTransKey } from "@/context/Localization/utils";
import { tIconElement } from "@/interfaces/icon";
import { COLORS } from "@/utils/colors";

type tRatio = number;
type tGrade = tTransKey;
type tAbsolutePercentageDiff = number;

export const calcGrade = (
  value: number,
  benchmarkValue: number
): [
  tGrade,
  tRatio,
  tAbsolutePercentageDiff,
  { icon: tIconElement; color: string; labelTransKey: tTransKey }
] => {
  if (value === 0) {
    return [
      "general.excellent",
      0 / 180,
      0,
      { icon: TrendingDownIcon, color: COLORS.success, labelTransKey: "general.benchmark_below" },
    ];
  }
  if (benchmarkValue === 0) {
    if (value === 0)
      return [
        "general.excellent",
        0 / 180,
        0,
        { icon: TrendingDownIcon, color: COLORS.success, labelTransKey: "general.benchmark_below" },
      ];
    return [
      "general.excessive",
      180 / 180,
      Infinity,
      { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
    ];
  }

  const ratio = value / benchmarkValue;
  const absolutePercentageDiff = Math.abs(1 - ratio) * 100;

  switch (true) {
    case ratio <= 0.9:
      return [
        "general.excellent",
        0 / 180,
        absolutePercentageDiff,
        { icon: TrendingDownIcon, color: COLORS.success, labelTransKey: "general.benchmark_below" },
      ];
    case ratio <= 1:
      return [
        "general.very_good",
        30 / 180,
        absolutePercentageDiff,
        { icon: TrendingDownIcon, color: COLORS.success, labelTransKey: "general.benchmark_below" },
      ];
    case ratio <= 1.1:
      return [
        "general.good",
        60 / 180,
        absolutePercentageDiff,
        { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
      ];
    case ratio <= 1.25:
      return [
        "general.fair",
        90 / 180,
        absolutePercentageDiff,
        { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
      ];
    case ratio <= 1.5:
      return [
        "general.poor",
        120 / 180,
        absolutePercentageDiff,
        { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
      ];
    case ratio <= 1.75:
      return [
        "general.critical",
        150 / 180,
        absolutePercentageDiff,
        { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
      ];
    default:
      return [
        "general.excessive",
        180 / 180,
        absolutePercentageDiff,
        { icon: TrendingUpIcon, color: COLORS.error, labelTransKey: "general.benchmark_above" },
      ];
  }
};
