import AddManualDataButton from "@/components/Buttons/addManualDataButton";
import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import HistoricDataConsumptionGraphSection from "@/components/HistoricDataConsumptionGraphSection";
import PageBottomPadding from "@/components/PageBottomPadding";
import RoadmapTable from "@/components/RoadmapTable";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { usePropertyState } from "@/context/Property/hooks";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "@/utils/apiCall";
import { propertyGetMeasuresTrackedInManualData } from "@/utils/property/manualData";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import React from "react";
import Showcasing from "./showcasing";
import Unlock from "./unlock";

interface iFuelsWrappedProps {}

const FuelsWrapped: React.FC<iFuelsWrappedProps> = () => {
  const { activeProperty } = usePropertyState();
  const measuresTrackedInManualData = propertyGetMeasuresTrackedInManualData(activeProperty);

  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.water,
    initialStatus: measuresTrackedInManualData.water ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });

  return (
    <Flex column gap={40}>
      <HistoricDataConsumptionGraphSection {...{ conversionFactorsRequest, measure: "fuels" }} />
      <Showcasing />
      <RoadmapTable filterByType="fuels" />
    </Flex>
  );
};

const FuelsGuarded: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const permissionsList = usePermissions();

  return (
    <>
      {propertyActiveSubscriptionTypeIsStarter({ property: activeProperty }) ? (
        <Unlock />
      ) : (
        <div>
          <SecondaryTopBar>
            <div>
              {hasPermission(permissionsList, "create:manual-data") && (
                <AddManualDataButton modalProps={{ initialStep: "naturalGas", singleStep: true }} />
              )}
            </div>
          </SecondaryTopBar>
          <FuelsWrapped />
        </div>
      )}
    </>
  );
};

const Fuels: React.FC = () => {
  return (
    <IpValidatorGuard>
      <FuelsGuarded />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Fuels;
