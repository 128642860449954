import Flex from "@/components/Flex";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { updateProfile, useProfileDispatch, useProfileState } from "@/context/Profile/hooks";
import usePutRequest from "@/hooks/apiRequests/usePutRequest";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React, { useState } from "react";
import { Button } from "rsuite";
import TextInputRow from "../components/TextInputRow";
import styles from "../styles.module.css";
import ChangePasswordModal from "./changePasswordModal";

interface iChangeProps {
  property: "name" | "email";
  labelTransKey: string;
}

const Change: React.FC<iChangeProps> = ({ property, labelTransKey }) => {
  const putRequest = usePutRequest();
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const dispatch = useProfileDispatch();

  const initValue = profile[property];

  const doSaveRequest = (value: string) => {
    putRequest.pending();
    axios
      .patch(ENDPOINTS.MANAGERS.ACCOUNT.UPDATE_ACCOUNT, {
        [property]: value,
      })
      .then((res) => {
        const {
          data: { account },
        } = res;
        updateProfile(dispatch, account);
        putRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
      });
  };

  const onSave = (input: string) => {
    if (input.length < 3) {
      return true;
    }
    doSaveRequest(input);
    return null;
  };

  return (
    <TextInputRow
      label={labelTransKey}
      initValue={initValue}
      isLoading={putRequest.isLoading}
      onSave={onSave}
    />
  );
};

interface iChangePasswordProps {}

const ChangePassword: React.FC<iChangePasswordProps> = () => {
  const { trans } = useLocalizationState();
  const [passwordModal, setPasswordModal] = useState(false);
  return (
    <>
      <ChangePasswordModal open={passwordModal} onClose={() => setPasswordModal(false)} />
      <Flex
        row
        between
        middle
        className={[styles["settings-input-item"], styles["settings-item"]].join(" ")}
      >
        <InterTag size={14} text={trans("general.password")} bold color={COLORS.secondary} />
        <Button onClick={() => setPasswordModal(true)} appearance="ghost">
          {trans("general.change")}
        </Button>
      </Flex>
    </>
  );
};

const PersonalSettingsWrapped: React.FC = () => {
  return (
    <div>
      <Change property="name" labelTransKey="general.name" />
      <Change property="email" labelTransKey="general.email" />
      <ChangePassword />
    </div>
  );
};

const PersonalSettings: React.FC = () => {
  return <PersonalSettingsWrapped />;
};

export default PersonalSettings;
