import { tPropertyAwardId, tPropertySpaceId } from ".";

export const PROPERTY_CHALLENGE_TYPES = [
  "Footprint Frenzy",
  "Water Saving Heroes",
  "Energy Boost",
] as const;
export type tPropertyChallengeType = (typeof PROPERTY_CHALLENGE_TYPES)[number];

export type tPropertyChallengeAudience = {
  spaces: tPropertySpaceId[];
};

export type tPropertyChallengeId = string;

export type tPropertyChallenge = {
  _id: tPropertyChallengeId;
  type: tPropertyChallengeType;
  from: Date | number | string;
  to: Date | number | string;
  target: tPropertyChallengeAudience;
  awards: {
    awardId: tPropertyAwardId;
    count: number;
    availableFrom: Date | number | string;
    availableUntil: Date | number | string;
  }[];
  createdBy: string;
  timestamp: Date | number | string;
  winner: string[];
  removed: boolean;
};
