import { ReactComponent as AvgPaceIcon } from "@/assets/icons/avg_pace.svg";
import { ReactComponent as CategoryIcon } from "@/assets/icons/category.svg";
import { ReactComponent as ConversionPathIcon } from "@/assets/icons/conversion_path.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as NotesIcon } from "@/assets/icons/notes.svg";
import { ReactComponent as NumbersIcon } from "@/assets/icons/numbers.svg";
import { ReactComponent as SpeedIcon } from "@/assets/icons/speed.svg";
import { ReactComponent as TargetIcon } from "@/assets/icons/target.svg";
import roadmapBackground from "@/assets/placeholders/roadmap.png";
import {
  updateProperty,
  updatingProperty,
  usePropertyDispatch,
  usePropertyState,
} from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "@/context/WindowSize/hooks/useWindowSizeState";
import useGetRequest from "@/hooks/apiRequests/useGetRequest";
import usePatchRequest from "@/hooks/apiRequests/usePatchRequest";
import useScreenSize from "@/hooks/useScreenSize";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { tPropertyRoadmap } from "@/models/property";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import {
  getRoadmapDescription,
  PROPERTY_ROADMAP_STATUSES,
  propertyRoadmapDifficultyInfo,
  propertyRoadmapPhaseNumberInfo,
  propertyRoadmapStatusInfo,
  propertyRoadmapTypeInfo,
  ROADMAP_DIFFICULTIES,
  ROADMAP_PHASE_NUMBERS,
  ROADMAP_TYPES,
} from "@/utils/property/roadmap";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { cloneDeep, findIndex, isUndefined, min, omit, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Badge, BadgeProps, Table, TableProps } from "rsuite";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";
import ModalSelect from "../Modals/ModalSelect";
import PageSection from "../PageSection";
import SimpleFilterButton from "../RsuiteWrapper/SimpleButton/buttons/filterButton";
import BaseCell from "../RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../Text/Inter";
import UnlockPageSection from "../UnlockPageSection";
import AddButton from "./addButton";
import AddItemModal from "./addItemModal";
import FitlersDrawer from "./filtersDrawer";
import RoadmapDetailsDrawer from "./roadmapDetailsDrawer";

export type tRoadmapFilter = {
  type: tPropertyRoadmap["type"][];
  order: [number | null, number | null];
  phaseNumber: tPropertyRoadmap["phaseNumber"][];
  difficulty: tPropertyRoadmap["difficulty"][];
  status: tPropertyRoadmap["status"][];
};

interface iProps {
  rootOutletContainerHeight: number;
  filterByType?: tPropertyRoadmap["type"];
  tableHeight?(
    rootOutletContainerHeight: number,
    rowHeight: number,
    headerHeight: number,
    numberOfElements: number
  ): any;
}

const RoadmapWrapped: React.FC<iProps> = ({
  rootOutletContainerHeight,
  filterByType: topLevelFilter,
  tableHeight = (
    rootOutletContainerHeight: number,
    rowHeight: number,
    headerHeight: number,
    numberOfElements: number
  ) => min([450, (numberOfElements || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT]),
}) => {
  const toaster = useSimpleToaster();
  const { trans, language } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [updateStatusModal, setUpdateStatusModal] = useState<
    { open: false } | { open: true; roadmapItemId: string; options: tPropertyRoadmap["status"][] }
  >({ open: false });
  const [editItemModalState, setEditItemModalState] = useState<{
    open: boolean;
    roadmapId?: string;
  }>({ open: false, roadmapId: undefined });
  const statusRequest = usePatchRequest();
  const [hotelRoadmapDrawer, setPropertyRoadmapDrawer] = useState<{
    index: number;
  }>({ index: -1 });
  const [hotelRoadmapCopy, setPropertyRoadmapCopy] = useState(cloneDeep(activeProperty.roadmap));
  const [roadmapTopLevelFiltered, setRoadmapTopLevelFiltered] = useState(
    topLevelFilter ? hotelRoadmapCopy.filter((r) => r.type === topLevelFilter) : hotelRoadmapCopy
  );

  useEffect(() => {
    setPropertyRoadmapCopy(cloneDeep(activeProperty.roadmap));
  }, [activeProperty.roadmap]);

  useEffect(() => {
    setRoadmapTopLevelFiltered(
      topLevelFilter ? hotelRoadmapCopy.filter((r) => r.type === topLevelFilter) : hotelRoadmapCopy
    );
  }, [hotelRoadmapCopy, topLevelFilter]);

  const roadmapFiltersRequest = useGetRequest<{ list: tPropertyRoadmap[] }>({
    list: [],
  });
  const orderedRoadmapRequest = useGetRequest<{ list: tPropertyRoadmap[] }>({
    list: [],
  });

  const [{ sortColumn, sortType }, setSort] = useState<
    Pick<TableProps<tPropertyRoadmap, string>, "sortType" | "sortColumn">
  >({ sortColumn: "order", sortType: "asc" });
  const [filtersState, setFiltersState] = useState<tRoadmapFilter>({
    type: topLevelFilter ? [topLevelFilter] : [...ROADMAP_TYPES],
    order: [null, null],
    phaseNumber: [...ROADMAP_PHASE_NUMBERS],
    difficulty: [...ROADMAP_DIFFICULTIES],
    status: [...PROPERTY_ROADMAP_STATUSES],
  });
  const [filtersDrawer, setFiltersDrawer] = useState(false);

  const options = (dataKey: string, item: tPropertyRoadmap) => {
    return [
      {
        key: "update status",
        label: trans("general.update_x", {
          parameters: [trans("general.status")],
        }),
        onClick() {
          setUpdateStatusModal({
            open: true,
            roadmapItemId: item._id,
            options: [...PROPERTY_ROADMAP_STATUSES].filter((v) => v !== item.status),
          });
        },
      },
      {
        key: "edit",
        label: trans("general.edit"),
        onClick() {
          setEditItemModalState({ open: true, roadmapId: item._id });
        },
      },
      {
        key: "details",
        label: trans("general.details"),
        onClick() {
          const index = findIndex(orderedRoadmapRequest.data.list, (o) => o._id === item._id);
          setPropertyRoadmapDrawer((prev) => ({ ...prev, index }));
        },
      },
      {
        key: "remove",
        label: { text: trans("general.remove"), color: COLORS.error },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick() {
          updatingProperty(propertyDispatch);
          axios
            .delete(
              constructApiAddress(
                `/v2/hotels/${activeProperty._id}/roadmap/${item._id}`,
                USE_MONOLITH_SERVERLESS
              )
            )
            .then((res) => {
              const {
                data: { hotel },
              } = res;
              updateProperty(propertyDispatch, activeProperty._id, hotel);
              toaster.success(
                trans("general.removed_x_successfully", {
                  parameters: [trans("general.item")],
                })
              );
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              toaster.error(error);
              updateProperty(propertyDispatch, activeProperty._id, {});
            });
        },
      },
    ];
  };

  useEffect(() => {
    new Promise<tPropertyRoadmap[]>((resolve, reject) => {
      resolve(
        roadmapTopLevelFiltered.filter(({ type, difficulty, status, phaseNumber }) => {
          return (
            filtersState.type.includes(type) &&
            filtersState.difficulty.includes(difficulty) &&
            filtersState.status.includes(status) &&
            filtersState.phaseNumber.includes(phaseNumber)
          );
        })
      );
    }).then((list) => {
      roadmapFiltersRequest.resolve({ list });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersState.difficulty,
    filtersState.phaseNumber,
    filtersState.status,
    filtersState.type,
    roadmapTopLevelFiltered,
  ]);

  useEffect(() => {
    orderedRoadmapRequest.pending();
    new Promise<tPropertyRoadmap[]>((resolve, reject) => {
      if (sortColumn && sortType) {
        if (["type", "order", "phaseNumber"].includes(sortColumn))
          resolve(orderBy(roadmapFiltersRequest.data.list, [sortColumn], [sortType]));

        if (sortColumn === "difficulty") {
          resolve(
            orderBy(
              roadmapFiltersRequest.data.list.map((r) => ({
                ...r,
                difficultyNumbered: r.difficulty === "low" ? 0 : r.difficulty === "medium" ? 1 : 2,
              })),
              ["difficultyNumbered"],
              [sortType]
            ).map((r) => omit(r, ["difficultyNumbered"]))
          );
        }
        if (sortColumn === "status") {
          resolve(
            orderBy(
              roadmapFiltersRequest.data.list.map((r) => ({
                ...r,
                statusNumbered: r.status === "idle" ? 0 : r.status === "in progress" ? 1 : 2,
              })),
              ["statusNumbered"],
              [sortType]
            ).map((r) => omit(r, ["statusNumbered"]))
          );
        }
      }
      resolve(roadmapFiltersRequest.data.list);
    })
      .then((list) => {
        orderedRoadmapRequest.resolve({ list });
      })
      .catch((err) => {
        orderedRoadmapRequest.reject(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadmapFiltersRequest.data.list, sortColumn, sortType]);

  return (
    <>
      {editItemModalState.open && (
        <AddItemModal
          open={editItemModalState.open}
          roadmapId={editItemModalState.roadmapId}
          onClose={() => setEditItemModalState({ roadmapId: undefined, open: false })}
        />
      )}
      <FitlersDrawer
        showTypeFilter={topLevelFilter === undefined}
        initialValues={filtersState}
        open={filtersDrawer}
        onClose={() => setFiltersDrawer(false)}
        onConfirm={(filters) => {
          setFiltersState(filters);
          setFiltersDrawer(false);
        }}
      />
      <RoadmapDetailsDrawer
        item={
          hotelRoadmapDrawer.index >= 0
            ? orderedRoadmapRequest.data.list[hotelRoadmapDrawer.index]
            : null
        }
        onClose={() => {
          setPropertyRoadmapDrawer((prev) => ({ ...prev, index: -1 }));
        }}
        onNext={() => {
          if (hotelRoadmapDrawer.index + 1 < orderedRoadmapRequest.data.list.length)
            setPropertyRoadmapDrawer((prev) => ({
              ...prev,
              index: prev.index + 1,
            }));
        }}
        onPrevious={() => {
          if (hotelRoadmapDrawer.index > 0)
            setPropertyRoadmapDrawer((prev) => ({
              ...prev,
              index: prev.index - 1,
            }));
        }}
        disabledOnPrevious={hotelRoadmapDrawer.index === 0}
        disabledOnNext={hotelRoadmapDrawer.index + 1 >= orderedRoadmapRequest.data.list.length}
      />
      {updateStatusModal.open && (
        <ModalSelect
          beforeConfirm={(value) => {
            if (!value) return trans("components.roadmap.modals.update_status.error_message");
            return null;
          }}
          open={updateStatusModal.open}
          options={updateStatusModal.options.map((v) => ({
            label: trans(propertyRoadmapStatusInfo(v).transKey),
            value: v,
          }))}
          onClose={() => {
            setUpdateStatusModal({ open: false });
          }}
          onConfirm={(newStatus: tPropertyRoadmap["status"]) => {
            const roadmapId = updateStatusModal.roadmapItemId;
            setUpdateStatusModal({ open: false });
            statusRequest.pending();
            axios
              .patch(
                constructApiAddress(
                  `/v2/hotels/${activeProperty._id}/roadmap/${roadmapId}/status`,
                  USE_MONOLITH_SERVERLESS
                ),
                {
                  status: newStatus,
                }
              )
              .then((res) => {
                const {
                  data: { hotel },
                } = res;
                updateProperty(propertyDispatch, activeProperty._id, hotel);
                statusRequest.resolve();
              })
              .catch((err) => {
                const error = getErrorMessage(err, trans);
                statusRequest.reject(error, true);
              });
          }}
          label={trans("Select Status")}
        />
      )}
      <Flex row right gap={8}>
        <AddButton />
        <SimpleFilterButton onClick={() => setFiltersDrawer(true)} />
      </Flex>
      <div className="table-wrapper">
        <Table
          id="SUSTAINABILITY.TABLE"
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={orderedRoadmapRequest.data.list}
          height={tableHeight(
            rootOutletContainerHeight,
            TABLE_ROW_HEIGHT.M,
            TABLE_HEADER_HEIGHT,
            orderedRoadmapRequest.data.list.length
          )}
          loading={statusRequest.isLoading || orderedRoadmapRequest.isLoading}
          onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
            setSort({ sortColumn: dataKey, sortType });
          }}
          {...{
            sortColumn,
            sortType,
          }}
        >
          <Table.Column fullText sortable flexGrow={2}>
            <SimpleHeaderCell
              icon={CategoryIcon}
              name={trans("components.roadmap.item.properties.type")}
            />
            <BaseCell dataKey="type">
              {(rowData: tPropertyRoadmap) => {
                const { type } = rowData;
                const { color, transKey, icon } = propertyRoadmapTypeInfo(type);
                return (
                  <Flex
                    style={{
                      padding: "8px 12px",
                      borderRadius: "6px",
                      width: "fit-content",
                    }}
                    middle
                    row
                    gap={4}
                    color={color}
                  >
                    {icon && (
                      <Icon
                        {...{
                          size: 14,
                          fill: COLORS.white,
                          ...toIconProps(icon),
                        }}
                      />
                    )}
                    {!isUndefined(transKey) && (
                      <InterTag size={12} color={COLORS.white} text={trans(transKey)} />
                    )}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell
              icon={NumbersIcon}
              name={trans("components.roadmap.item.properties.order")}
            />
            <SimpleKeyValueCell dataKey="order" />
          </Table.Column>
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell
              icon={TargetIcon}
              name={trans("components.roadmap.item.properties.phase")}
            />
            <SimpleKeyValueCell
              tooltipDisplay
              tooltipTextFunction={(rowData: tPropertyRoadmap) =>
                trans(propertyRoadmapPhaseNumberInfo(rowData.phaseNumber).transKey)
              }
              dataKey="phaseNumber"
            />
          </Table.Column>
          <Table.Column fullText flexGrow={4}>
            <SimpleHeaderCell
              icon={NotesIcon}
              name={trans("components.roadmap.item.properties.description")}
            />
            <SimpleKeyValueCell
              textProps={() => ({
                style: { textWrap: "wrap" },
              })}
              dataKey="description"
              textFunction={(rowData: tPropertyRoadmap) =>
                getRoadmapDescription(rowData, language)?.description
              }
            />
          </Table.Column>
          <Table.Column sortable flexGrow={2}>
            <SimpleHeaderCell
              icon={SpeedIcon}
              name={trans("components.roadmap.item.properties.difficulty")}
            />
            <BaseCell dataKey="difficulty">
              {(rowData: tPropertyRoadmap) => {
                const { difficulty } = rowData;
                const { badgeColor, transKey } = propertyRoadmapDifficultyInfo(difficulty);
                return (
                  <Flex middle row gap={8} style={{ height: "100%" }}>
                    <Badge color={badgeColor as BadgeProps["color"]} />
                    <InterTag size={12} color={COLORS.secondary} text={trans(transKey)} />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column sortable flexGrow={2}>
            <SimpleHeaderCell
              icon={AvgPaceIcon}
              name={trans("components.roadmap.item.properties.status")}
            />
            <BaseCell dataKey="status">
              {(rowData: tPropertyRoadmap) => {
                const { status } = rowData;
                const { transKey, color, backgroundColor } = propertyRoadmapStatusInfo(status);
                return (
                  <Flex middle style={{ height: "100%" }}>
                    <Flex
                      style={{
                        borderRadius: "6px",
                        height: "fit-content",
                        padding: "8px 12px",
                      }}
                      color={backgroundColor}
                    >
                      <InterTag size={12} {...{ text: trans(transKey), color: color }} />
                    </Flex>
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column align="right" flexGrow={1}>
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iRoadmapWrapped2Props extends Pick<iProps, "filterByType" | "tableHeight"> {}

const RoadmapWrapped2: React.FC<iRoadmapWrapped2Props> = ({ filterByType, tableHeight }) => {
  const { trans } = useLocalizationState();
  const { outlet: o } = useScreenSize();
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  return (
    <PageSection
      icon={ConversionPathIcon}
      title={trans("components.roadmap.title")}
      description={trans("components.roadmap.description")}
    >
      {activeSubscriptionIsStarter ? (
        <UnlockPageSection
          image={roadmapBackground}
          title={trans("components.roadmap.unlock.title")}
          description={[0, 1, 2, 3].map((i) => trans(`components.roadmap.unlock.description.${i}`))}
        />
      ) : o ? (
        <RoadmapWrapped rootOutletContainerHeight={o.height} {...{ filterByType, tableHeight }} />
      ) : null}
    </PageSection>
  );
};

interface iRoadmapProps extends Pick<iProps, "filterByType" | "tableHeight"> {}

const Roadmap: React.FC<iRoadmapProps> = ({ filterByType, tableHeight }) => {
  const { isMobile } = useWindowSizeState();

  if (isMobile) return null;

  return <RoadmapWrapped2 {...{ filterByType, tableHeight }} />;
};

export default React.memo(Roadmap);
