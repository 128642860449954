import { ReactComponent as StoreFrontIcon } from "@/assets/icons/storefront.svg";
import PageSection from "@/components/PageSection";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import usePatchRequest from "@/hooks/apiRequests/usePostRequest";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React, { useState } from "react";
import { InlineEdit, Input, Loader } from "rsuite";

const MAX_NAME_LENGTH = 60;

interface EditNameWrappedProps extends iProps {}

const EditNameWrapped: React.FC<EditNameWrappedProps> = ({ canWrite }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [name, setName] = useState(() => activeProperty.guestApp.name || "");

  const request = usePatchRequest();

  const onSave = (event: any) => {
    if (!canWrite) return;
    if (name.length < 3) {
      return setName(activeProperty.guestApp.name || "");
    }
    if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
      if (name !== activeProperty.guestApp.name) {
        request.pending();
        axios
          .patch(
            ENDPOINTS.PROPERTIES.PROFILE.UPDATE,
            {
              name,
            },
            { params: { propertyId: activeProperty._id } }
          )
          .then((res) => {
            const {
              data: { property },
            } = res;
            updateProperty(propertyDispatch, activeProperty._id, property);
            request.resolve(
              undefined,
              trans("pages.hotel_profile.sections.name.notifications.name_changed")
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error, true);
            setName(activeProperty.guestApp.name || "");
          });
      }
      return;
    }
    return setName(activeProperty.guestApp.name || "");
  };

  return (
    <div className="flex flex-col gap-2">
      {request.isLoading ? (
        <div
          className="flex items-center"
          style={{
            height: "34px",
            backgroundColor: COLORS.gray_300,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
            paddingLeft: "8px",
          }}
        >
          <Loader size="sm" />
        </div>
      ) : (
        <InlineEdit
          disabled={!canWrite}
          className="inter"
          style={{
            backgroundColor: canWrite ? COLORS.white : COLORS.gray_200,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
          }}
          value={name}
          onChange={(value) => setName(value.slice(0, MAX_NAME_LENGTH))}
          onSave={onSave}
          onCancel={() => setName(activeProperty.guestApp.name || "")}
        >
          <Input />
        </InlineEdit>
      )}
      <InterTag
        size={12}
        color={COLORS.gray_400}
        text={trans("pages.hotel_profile.sections.name.characters", {
          parameters: [name.length, MAX_NAME_LENGTH],
        })}
      />
    </div>
  );
};

interface iProps {
  canWrite: boolean;
}

const EditName: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <PageSection
      title={trans("pages.hotel_profile.sections.name.title")}
      icon={StoreFrontIcon}
      description={trans("pages.hotel_profile.sections.name.description")}
    >
      <EditNameWrapped {...props} />
    </PageSection>
  );
};

export default EditName;
