import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import NoPermissions from "@/components/Pages/NoPermissions";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { usePropertyState } from "@/context/Property/hooks";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import React from "react";
import AwardsList from "./list";
import AwardsStatistics from "./statistics";
import Unlock from "./unlock";

const AwardsWrapped: React.FC = () => {
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const permissionsList = usePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!hasPermission(permissionsList, "read:awards")) {
    return <NoPermissions />;
  }

  return (
    <>
      <Flex column gap={40}>
        <AwardsStatistics />
        <AwardsList />
      </Flex>
      <PageBottomPadding />
    </>
  );
};

const Awards: React.FC = () => {
  return (
    <IpValidatorGuard>
      <AwardsWrapped />
    </IpValidatorGuard>
  );
};

export default Awards;
