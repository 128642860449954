import { useToaster } from "rsuite";
import { notification } from "@/utils/notifications";
import { useMemo } from "react";

const useSimpleToaster = () => {
  const toaster = useToaster();

  return useMemo(() => {
    const error = (message: string) =>
      toaster.push(notification("error", message), { placement: "topEnd" });
    const success = (message: string) =>
      toaster.push(notification("success", message), { placement: "topEnd" });
    const info = (message: string) =>
      toaster.push(notification("info", message), { placement: "topEnd" });
    const warning = (message: string) =>
      toaster.push(notification("warning", message), { placement: "topEnd" });
    return { error, success, info, warning };
  }, [toaster]);
};
export default useSimpleToaster;
