import useLocalizationDispatch from "@/context/Localization/hooks/useLocalizationDispatch";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { updateProfile, useProfileDispatch, useProfileState } from "@/context/Profile/hooks";
import useApiRequest from "@/hooks/apiRequests/useApiRequest";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { LANGAUGES } from "@/utils/languages";
import {
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_THOUSANDS_SEPARATOR,
  numberFormatter,
} from "@/utils/numbers";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { InlineEdit, Input, Loader, SelectPicker } from "rsuite";
import { BASE_ROW_CLASSNAMES, BASE_ROW_CLASSNAMES_WITH_BORDER } from "./utils";

const LanguageRow: React.FC = () => {
  const { trans, language } = useLocalizationState();
  const { setLanguage } = useLocalizationDispatch();

  const list = LANGAUGES.filter(({ code }) => ["en", "pt"].includes(code)).map(
    ({ code, language }) => ({
      label: trans(`general.languages_names.${language.toLowerCase()}`),
      value: code,
    })
  );

  return (
    <div className={BASE_ROW_CLASSNAMES_WITH_BORDER}>
      <div className="flex flex-row items-center flex-1">
        <p className="text-sm text-secondary font-bold inter">{trans("general.language")}</p>
      </div>
      <div className="flex flex-row flex-1 items-center justify-end">
        <SelectPicker
          appearance="subtle"
          data={list}
          value={language}
          caretAs={undefined}
          cleanable={false}
          placement="leftStart"
          onChange={(value) => {
            if (value) setLanguage(value);
          }}
        />
      </div>
    </div>
  );
};

interface iChangeThousandDecimalSeparatorsProps {}

const ChangeThousandDecimalSeparators: React.FC<iChangeThousandDecimalSeparatorsProps> = () => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const dispatch = useProfileDispatch();
  const [separators, setSeparators] = useState<{ decimal: string; thousand: string }>(() => ({
    decimal: profile.settings?.display?.numberFormat?.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR,
    thousand:
      profile.settings?.display?.numberFormat?.thousandSeparator || DEFAULT_THOUSANDS_SEPARATOR,
  }));

  useEffect(() => {
    setSeparators({
      decimal:
        profile.settings?.display?.numberFormat?.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR,
      thousand:
        profile.settings?.display?.numberFormat?.thousandSeparator || DEFAULT_THOUSANDS_SEPARATOR,
    });
  }, [profile]);

  const request = useApiRequest();
  const doRequest = () => {
    request.pending();

    axios
      .patch(ENDPOINTS.MANAGERS.SETTINGS.UPDATE, {
        settings: [
          { path: "display.numberFormat.decimalSeparator", value: separators.decimal },
          { path: "display.numberFormat.thousandSeparator", value: separators.thousand },
        ],
      })
      .then((res) => {
        const {
          data: { manager },
        } = res;
        updateProfile(dispatch, manager);
        request.resolve();
      })
      .catch((err) => {
        request.reject(getErrorMessage(err, trans), true);
      });
  };

  return (
    <div className={BASE_ROW_CLASSNAMES}>
      <p className="text-sm text-secondary font-bold inter">
        {trans("pages.settings.panels.general.items.thousand_decimal_separator.label")}
      </p>
      {request.isLoading ? (
        <Loader size="xs" />
      ) : (
        <InlineEdit
          disabled={request.isLoading}
          onCancel={() => {
            setSeparators({
              decimal:
                profile.settings?.display?.numberFormat?.decimalSeparator ||
                DEFAULT_DECIMAL_SEPARATOR,
              thousand:
                profile.settings?.display?.numberFormat?.thousandSeparator ||
                DEFAULT_THOUSANDS_SEPARATOR,
            });
          }}
          onSave={(event: any) => {
            if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
              if (
                profile.settings?.display?.numberFormat?.decimalSeparator !== separators.decimal ||
                profile.settings?.display?.numberFormat?.thousandSeparator !== separators.thousand
              ) {
                doRequest();
                return;
              }
            }
            setSeparators({
              decimal:
                profile.settings?.display?.numberFormat?.decimalSeparator ||
                DEFAULT_DECIMAL_SEPARATOR,
              thousand:
                profile.settings?.display?.numberFormat?.thousandSeparator ||
                DEFAULT_THOUSANDS_SEPARATOR,
            });
          }}
        >
          <Fragment>
            <div className="flex flex-row items-center gap-3">
              <div className="flex flex-row items-center gap-1">
                <p className="text-xs">{trans("general.thousands")}</p>
                <Input
                  disabled={request.isLoading}
                  style={{ width: "60px" }}
                  onChange={(value) => setSeparators({ ...separators, thousand: value })}
                  value={separators.thousand}
                />
              </div>
              <p>{numberFormatter(100000.1, undefined, separators)}</p>
              <div className="flex flex-row items-center gap-1">
                <Input
                  disabled={request.isLoading}
                  style={{ width: "60px" }}
                  onChange={(value) => setSeparators({ ...separators, decimal: value })}
                  value={separators.decimal}
                />
                <p className="text-xs">{trans("general.decimal")}</p>
              </div>
            </div>
          </Fragment>
        </InlineEdit>
      )}
    </div>
  );
};

const GeneralSettingsWrapped: React.FC = () => {
  return (
    <div>
      <LanguageRow />
      <ChangeThousandDecimalSeparators />
    </div>
  );
};

const GeneralSettings: React.FC = () => {
  return <GeneralSettingsWrapped />;
};

export default GeneralSettings;
