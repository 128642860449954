// Combined lodash utilities


// --- chunk.ts ---
export function _chunk<T>(array: T[], size: number): T[][] {
  return array.reduce((result, _, index) => {
    const chunkIndex = Math.floor(index / size);
    result[chunkIndex] = result[chunkIndex] || [];
    result[chunkIndex].push(array[index]);
    return result;
  }, [] as T[][]);
}


// --- every.ts ---
export function _every<T>(
  collection: T[],
  predicate: (value: T, index: number, array: T[]) => boolean
): boolean {
  for (let i = 0; i < collection.length; i++) {
    if (!predicate(collection[i], i, collection)) {
      return false;
    }
  }
  return true;
}


// --- extend.ts ---
export function _extend<T extends Record<string, any>>(
  target: any,
  ...sources: Record<string, any>[]
): T {
  sources.forEach((source) => {
    if (source && typeof source === "object") {
      for (let key in source) {
        // Includes inherited properties
        target[key] = source[key];
      }
    }
  });
  return target;
}


// --- find.ts ---
export function _find<T>(
  collection: string | T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
  fromIndex: number = 0
): T | undefined {
  try {
    if (typeof collection === "string") {
      const stringPredicate = predicate as (
        value: string,
        index: number,
        array: string[]
      ) => boolean;
      return (collection as string).slice(fromIndex).split("").find(stringPredicate) as T;
    }
    return (collection as T[]).slice(fromIndex).find(predicate);
  } catch (error) {
    return undefined;
  }
}


// --- get.ts ---
type _get_GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? "length" extends keyof T
    ? number extends T["length"]
      ? number extends keyof T
        ? T[number]
        : undefined
      : undefined
    : undefined
  : undefined;
type _get_FieldWithPossiblyUndefined<T, Key> =
  | _get_GetFieldType<Exclude<T, undefined>, Key>
  | Extract<T, undefined>;
type _get_IndexedFieldWithPossiblyUndefined<T, Key> =
  | _get_GetIndexedField<Exclude<T, undefined>, Key>
  | Extract<T, undefined>;
type _get_GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof Exclude<T, undefined>
    ? _get_FieldWithPossiblyUndefined<Exclude<T, undefined>[Left], Right> | Extract<T, undefined>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? _get_FieldWithPossiblyUndefined<
          _get_IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>,
          Right
        >
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ? _get_IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
    : undefined
  : _get_IndexedFieldWithPossiblyUndefined<T, P>;

export function _get<TObject, TPath extends string, TDefault = _get_GetFieldType<TObject, TPath>>(
  object: TObject,
  path: TPath,
  defaultValue?: TDefault
): Exclude<_get_GetFieldType<TObject, TPath>, null | undefined> | TDefault | undefined {
  // Convert path to an array if it's a string
  const pathArray = Array.isArray(path)
    ? path
    : path.split(".").map((part) => part.replace(/\[(\d+)\]/g, "$1"));

  // Traverse the object using the path
  let result: any = object;
  for (const key of pathArray) {
    // Handle array indices
    result =
      result && (typeof result === "object" || Array.isArray(result))
        ? result[key as keyof TObject]
        : undefined;
    if (result === undefined) {
      return defaultValue;
    }
  }
  return result === undefined ? defaultValue : result;
}


// --- groupBy.ts ---
export function _groupBy<T extends Record<string, any>>(
  collection: T[],
  iteratee: any
): Record<string, T[]> {
  return collection.reduce((result: Record<string, T[]>, item: T) => {
    // Determine the key based on the iteratee
    const key = typeof iteratee === "function" ? iteratee(item) : item[iteratee as any];

    // Initialize the group if it doesn't exist
    if (!result[key]) {
      result[key] = [];
    }

    // Push the current item to the corresponding group
    result[key].push(item);

    return result;
  }, {});
}


// --- has.ts ---
export function _has<T>(object: T, path: string | (string | number)[]) {
  // Convert path to an array if it's a string
  const pathArray = Array.isArray(path)
    ? path
    : path.split(".").map((part) => part.replace(/\[(\d+)\]/g, "$1"));

  // Traverse the object using the path
  let result: any = object;
  for (const key of pathArray) {
    // Handle array indices
    result =
      result && (typeof result === "object" || Array.isArray(result))
        ? result[key as keyof T]
        : undefined;
    if (result === undefined) {
      return false;
    }
  }
  return result !== undefined;
}


// --- isArray.ts ---
export function _isArray(value: any): value is Array<any> {
  return Array.isArray(value);
}


// --- isNumber.ts ---
export function _isNumber(value: any): value is number {
  return typeof value === "number";
}


// --- isString.ts ---
export function _isString(value: any): value is string {
  return typeof value === "string";
}


// --- omit.ts ---
export function _omit<T extends Object, K extends keyof T>(object: T, paths: K[]): Omit<T, K> {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => !paths.includes(key as K)) as [string, any][]
  ) as Omit<T, K>;
}


// --- orderBy.ts ---
export function _orderBy<T extends Record<string, any> | number | string>(
  collection: T[],
  iteratees: any,
  orders: ("asc" | "desc")[] | ("asc" | "desc")
): T[] {
  // Ensure iteratees and orders are arrays
  if (!Array.isArray(iteratees)) {
    iteratees = [iteratees];
  }
  if (!Array.isArray(orders)) {
    orders = [orders];
  }

  // Default to ascending order if not specified
  orders = orders.map((order) => (order === "desc" ? "desc" : "asc"));

  return collection.sort((a, b) => {
    for (let i = 0; i < iteratees.length; i++) {
      const key = iteratees[i];
      const order = orders[i];

      const valueA = typeof key === "function" ? key(a) : typeof a === "object" ? a[key] : a;
      const valueB = typeof key === "function" ? key(b) : typeof b === "object" ? b[key] : b;

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
    }

    // If all iteratees return equal, maintain the original order
    return 0;
  });
}


// --- pick.ts ---
export function _pick<T extends Object, K extends keyof T>(object: T, paths: K[]): Pick<T, K> {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => paths.includes(key as K)) as [string, any][]
  ) as Pick<T, K>;
}


// --- random.ts ---
export function _random(min: number, max: number, floating: boolean = false) {
  if (floating) {
    return Math.random() * (max - min) + min; // Generate floating-point number
  } else {
    return Math.floor(Math.random() * (max - min + 1)) + min; // Generate integer
  }
}


// --- reverse.ts ---
export function _reverse<TList extends any[]>(array: TList): TList {
  return Array.prototype.reverse.call(array) as TList;
}


// --- round.ts ---
export function _round(number: number, precision: number = 1): number {
  return Math.round(number * 10 ** precision) / 10 ** precision;
}


// --- sortBy.ts ---
export function _sortBy<T extends Record<string, any> | string | number>(
  collection: T[],
  iteratees: any
): T[] {
  // Ensure iteratees is an array
  if (!Array.isArray(iteratees)) {
    iteratees = [iteratees];
  }

  return collection.slice().sort((a, b) => {
    for (let iteratee of iteratees) {
      const valueA =
        typeof iteratee === "function" ? iteratee(a) : typeof a === "object" ? a[iteratee] : a;
      const valueB =
        typeof iteratee === "function" ? iteratee(b) : typeof b === "object" ? b[iteratee] : b;

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    }

    // If all iteratees return equal, maintain the original order
    return 0;
  });
}


// --- sortedIndex.ts ---
export function _sortedIndex(array: number[], value: number) {
  let low = 0;
  let high = array.length;
  while (low < high) {
    const mid = (low + high) >>> 1;
    if (array[mid] < value) low = mid + 1;
    else high = mid;
  }
  return low;
}


// --- sum.ts ---
export function _sum(arr: number[]) {
  return arr.reduce((acc, curr) => acc + curr, 0);
}


// --- toInteger.ts ---
export function _toInteger(value: string | number | null): number {
  if (value === null) return NaN;

  if (typeof value === "number") {
    const remainder = value % 1;
    // eslint-disable-next-line no-self-compare
    return value === value ? (remainder ? value - remainder : value) : 0;
  }

  if (value[0] === ".") {
    return parseInt("0" + value);
  }

  return parseInt(value);
}


// --- toNumber.ts ---
export function _toNumber(value: string | number | null): number {
  if (value === null) return NaN;

  if (typeof value === "number") {
    return value;
  }

  return parseFloat(value);
}


// --- toString.ts ---
export function _toString(value: any): string {
  if (value === null) {
    return "";
  }

  if (value === undefined) {
    return "";
  }

  if (Array.isArray(value)) {
    return value.map(_toString).join(",");
  }

  if (typeof value === "string") {
    return value;
  }

  if (typeof value === "symbol") {
    return value.toString();
  }

  return String(value);
}


// --- uniq.ts ---
export function _uniq<T = string | number | boolean>(array: T[]): T[] {
  const seen = new Set();
  return array.filter((item) => {
    if (!seen.has(item)) {
      seen.add(item);
      return true;
    }
    return false;
  });
}


