import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import MeasureConsumption from "@/components/MeasureConsumption";
import PageSection from "@/components/PageSection";
import SpaceMeasureConsumptionTable from "@/components/Tables/SpaceMeasureConsumptionTable";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useTimeframeState } from "@/context/Timeframe";
import { MEASURES_ELECTRICITY_SPECIFIC, TE, tMeasureEnergySpecific } from "@/models/measures";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, DEFAULT_ENERGY_MEASURE_UNIT } from "@/utils/convertUnits/";
import { propertyGetMeasuresTracked } from "@/utils/property/measures";
import React from "react";

const LiveData: React.FC<{ selectedSection: number }> = ({ selectedSection }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const {
    data: { sensorDataTimeframe },
  } = useTimeframeState();

  const trackedMeasures = propertyGetMeasuresTracked(activeProperty);

  const specificMeasures: { measureKey: tMeasureEnergySpecific }[] = [];
  MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  if (!trackedMeasures.te) {
    return (
      <div>
        <InterTag
          text={trans("pages.electricity.live.not_measured")}
          size={16}
          color={COLORS.secondary}
        />
      </div>
    );
  }

  switch (selectedSection) {
    case 0:
      return (
        <PageSection
          {...{
            title: trans("pages.electricity.live.sections.overview.title"),
            description: trans("pages.electricity.live.sections.overview.description"),
            icon: QueryStatsIcon,
          }}
        >
          <MeasureConsumption
            mainMeasureKey={TE}
            specificMeasures={specificMeasures}
            converter={convertEnergyUnit}
            mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
            binUnit={"day"}
            binValue={1}
            from={sensorDataTimeframe[0]}
            to={sensorDataTimeframe[1]}
          />
        </PageSection>
      );
    case 1:
      return (
        <PageSection
          {...{
            title: trans("pages.electricity.live.sections.list.title"),
            description: trans("pages.electricity.live.sections.list.description"),
            icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
          }}
        >
          <SpaceMeasureConsumptionTable
            mainMeasureKey2="e"
            converter={convertEnergyUnit}
            from={sensorDataTimeframe[0]}
            to={sensorDataTimeframe[1]}
            tableHeaderIcon={ElectricBoltIcon}
            tableHeaderIconFill={COLORS.energy}
            tableHeaderLabel={trans("general.measures_.electricity")}
          />
        </PageSection>
      );

    default:
      return null;
  }
};

export default LiveData;
