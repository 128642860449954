import { ReactComponent as StoreFrontIcon } from "@/assets/icons/storefront.svg";
import PageSection from "@/components/PageSection";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import usePatchRequest from "@/hooks/apiRequests/usePostRequest";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React, { useState } from "react";
import { InlineEdit, Input, Loader } from "rsuite";

const MAX_DESCRIPTION_LENGTH = 2000;

interface EditDescriptionWrappedProps extends iProps {}

const EditDescriptionWrapped: React.FC<EditDescriptionWrappedProps> = ({ canWrite }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [description, setDescription] = useState(() => activeProperty.guestApp.description || "");
  const request = usePatchRequest();

  const onSave = (event: any) => {
    if (!canWrite) return;
    if (description.length < 3) {
      return setDescription(activeProperty.guestApp.description || "");
    }
    if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
      if (description !== activeProperty.guestApp.description) {
        request.pending();
        axios
          .patch(
            ENDPOINTS.PROPERTIES.PROFILE.UPDATE,
            {
              description,
            },
            { params: { propertyId: activeProperty._id } }
          )
          .then((res) => {
            const {
              data: { property },
            } = res;
            updateProperty(propertyDispatch, activeProperty._id, property);
            request.resolve(
              undefined,
              trans("pages.hotel_profile.sections.description.notifications.description_changed")
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error, true);
            setDescription(activeProperty.guestApp.description || "");
          });
      }
      return;
    }
    return setDescription(activeProperty.guestApp.description || "");
  };

  return (
    <div className="flex flex-col gap-2">
      {request.isLoading ? (
        <div
          className="flex items-center"
          style={{
            height: "34px",
            backgroundColor: COLORS.gray_300,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
            paddingLeft: "8px",
          }}
        >
          <Loader size="sm" />
        </div>
      ) : (
        <InlineEdit
          disabled={!canWrite}
          className="inter"
          style={{
            backgroundColor: canWrite ? COLORS.white : COLORS.gray_200,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
          }}
          value={description}
          onChange={(value) => setDescription(value.slice(0, MAX_DESCRIPTION_LENGTH))}
          onCancel={() => setDescription(activeProperty.guestApp.description || "")}
          onSave={onSave}
        >
          <Input as="textarea" rows={5} />
        </InlineEdit>
      )}
      <InterTag
        size={12}
        color={COLORS.gray_400}
        text={trans("pages.hotel_profile.sections.description.characters", {
          parameters: [description.length, MAX_DESCRIPTION_LENGTH],
        })}
      />
    </div>
  );
};

interface iProps {
  canWrite: boolean;
}

const EditDescription: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <PageSection
      title={trans("pages.hotel_profile.sections.description.title")}
      icon={StoreFrontIcon}
      description={trans("pages.hotel_profile.sections.description.description")}
    >
      <EditDescriptionWrapped {...props} />
    </PageSection>
  );
};

export default EditDescription;
