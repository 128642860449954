import { Notification, NotificationProps } from "rsuite";
import InterTag from "@/components/Text/Inter";
import { COLORS } from "./colors";

export const notification = (
  type: NotificationProps["type"],
  message: string,
  { className }: { className: string } = {
    className: "notification-on-white-bg",
  }
) => {
  return (
    <Notification style={{ backgroundColor: "white" }} type={type} header={type} closable>
      <InterTag size={14} text={message} color={COLORS.secondary} />
    </Notification>
  );
};
