import { ReactComponent as ApartmentIcon } from "@/assets/icons/apartment.svg";
import { ReactComponent as LinkIcon } from "@/assets/icons/link.svg";
import { ReactComponent as LogoutIcon } from "@/assets/icons/logout.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/payments.svg";
import { ReactComponent as SecurityIcon } from "@/assets/icons/security.svg";
import { ReactComponent as SettingsIcon } from "@/assets/icons/settings.svg";
import { ReactComponent as SettingsAccountBoxIcon } from "@/assets/icons/settings_account_box.svg";
import { ReactComponent as NoytrallSymbol } from "@/assets/noytrall_symbol.svg";
import Icon from "@/components/Icons/Icon";
import PageBottomPadding from "@/components/PageBottomPadding";
import PageSection from "@/components/PageSection";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tIcon, tIconElement } from "@/interfaces/icon";
import { requestStatusIsFinal } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import React from "react";
import { Loader, Panel, PanelGroup } from "rsuite";
import AccommodationSettings from "./accommodation";
import BillingSettings from "./billing";
import ConnectedAccountsSettings from "./connectedAccounts";
import GeneralSettings from "./general";
import PersonalSettings from "./personal";
import SecuritySettins from "./security";
import SessionSettings from "./session";
import styles from "./styles.module.css";

const Settings: React.FC = () => {
  const { trans } = useLocalizationState();
  const {
    activeProperty,
    state: { status },
  } = usePropertyState();

  const renderPanel = (
    title: string,
    description: string,
    icon: tIcon | tIconElement,
    Component: React.FC,
    id: string,
    loading: boolean
  ) => {
    return (
      <Panel
        id={id}
        key={id}
        defaultExpanded
        header={<PageSection title={title} description={description} icon={icon} />}
      >
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className={styles["settings-panel"]}>
            <Component />
          </div>
        )}
      </Panel>
    );
  };

  const panels: {
    transKeyword: string;
    icon: tIcon | tIconElement;
    Component: React.FC;
    id: string;
    loading: boolean;
    hide?: boolean;
  }[] = [
    {
      transKeyword: "general",
      icon: SettingsIcon,
      Component: GeneralSettings,
      id: "settings-general",
      loading: false,
    },
    {
      transKeyword: "personal",
      icon: SettingsAccountBoxIcon,
      Component: PersonalSettings,
      id: "settings-personal",
      loading: false,
    },
    // {
    //   transKeyword: "connected_accounts",
    //   icon: { Element: LinkIcon, fill: COLORS.app_background },
    //   Component: ConnectedAccountsSettings,
    //   id: "settings-connected_accounts",
    //   loading: false,
    // },
    {
      transKeyword: "accommodation",
      icon: ApartmentIcon,
      Component: AccommodationSettings,
      id: "settings-accommodation",
      hide: !activeProperty.ipValidated,
      loading: !requestStatusIsFinal(status),
    },
    {
      transKeyword: "billing",
      icon: PaymentsIcon,
      Component: BillingSettings,
      id: "settings-billing",
      hide: !activeProperty.ipValidated,
      loading: !requestStatusIsFinal(status),
    },
    {
      transKeyword: "security",
      icon: SecurityIcon,
      Component: SecuritySettins,
      id: "settings-security",
      hide: !activeProperty.ipValidated,
      loading: !requestStatusIsFinal(status),
    },
    {
      transKeyword: "session",
      icon: LogoutIcon,
      Component: SessionSettings,
      id: "settings-session",
      loading: false,
    },
  ];

  return (
    <div className={styles.settings}>
      <div className="flex flex-col gap-10">
        <PanelGroup accordion bordered={false}>
          {panels
            .filter((p) => !p.hide)
            .map(({ Component, icon, id, transKeyword, loading }) => {
              return renderPanel(
                trans(`pages.settings.panels.${transKeyword}.title`),
                trans(`pages.settings.panels.${transKeyword}.description`),
                icon,
                Component,
                id,
                loading
              );
            })}
        </PanelGroup>
        <div className="flex flex-row gap-4 items-center">
          <Icon Element={NoytrallSymbol} height={32} width={23.95} />
          <div className="flex flex-col gap-1">
            <InterTag text={trans("noytrall technologies")} size={12} color={COLORS.secondary} />
            <InterTag
              text={`${trans("general.version_x", { parameters: ["1.0.1"] })}`}
              size={12}
              color={COLORS.secondary}
            />
          </div>
        </div>
      </div>
      <PageBottomPadding />
    </div>
  );
};

export default Settings;
