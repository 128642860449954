import { ReactComponent as DateRangeIcon } from "@/assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as DirectionsCarIcon } from "@/assets/icons/directions_car.svg";
import { ReactComponent as LocalGasStationIcon } from "@/assets/icons/local_gas_station.svg";
import { ReactComponent as SwapDrivingAppsWheelIcon } from "@/assets/icons/swap_driving_apps_wheel.svg";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { min } from "lodash";
import { MomentInput } from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";
import { SortType, TableProps } from "rsuite/esm/Table";
import EntriesModal from "./modal";

export type tTableItem = {
  entryId: string;
  fleetId: string;
  licensePlate: string;
  makeModel: string;
  distanceKm: number;
  fuelLiters: number;
  start: MomentInput;
  end: MomentInput;
};

interface iProps {
  sortColumn: string;
  sortType: SortType;
  setSort: React.Dispatch<
    React.SetStateAction<Required<Pick<TableProps<tTableItem, string>, "sortType" | "sortColumn">>>
  >;
  tableDataSorted: tTableItem[];
}

const List: React.FC<iProps> = ({ tableDataSorted, setSort, sortColumn, sortType }) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [entryModal, setEntryModal] = useState<
    | { open: false; fleetId: undefined; entryId: undefined }
    | { open: true; fleetId: string; entryId: string }
  >({ open: false, fleetId: undefined, entryId: undefined });
  const deleteRequest = useDeleteRequest();

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "edit",
      label: trans("pages.fleet.archive.segments.usage.sections.list.table.options.edit"),
      onClick() {
        setEntryModal({
          open: true,
          entryId: item.entryId,
          fleetId: item.fleetId,
        });
      },
    });

    options.push({
      key: "remove",
      label: {
        color: COLORS.error,
        text: trans("pages.fleet.archive.segments.usage.sections.list.table.options.remove_entry"),
      },
      icon: { Element: DeleteIcon, fill: COLORS.error },
      onClick() {
        deleteRequest.pending();
        axios
          .delete(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/fleet/${item.fleetId}/entries/${item.entryId}`,
              USE_MONOLITH_SERVERLESS
            )
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateProperty(propertyDispatch, activeProperty._id, hotel);
            deleteRequest.resolve();
            toaster.success(
              trans("pages.fleet.archive.segments.usage.sections.list.table.options.removed")
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            deleteRequest.reject(error);
            toaster.error(error);
          });
      },
    });

    return options;
  };

  const tableHeight =
    (min([5, tableDataSorted.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  return (
    <>
      <EntriesModal
        open={entryModal.open}
        fleetId={entryModal.fleetId}
        entryId={entryModal.entryId}
        onClose={() => {
          setEntryModal({
            open: false,
            fleetId: undefined,
            entryId: undefined,
          });
        }}
      />
      <div className="table-wrapper">
        <Table
          id="TABLE.CONSUMPTION.FLEET.USAGE"
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={tableDataSorted}
          loading={deleteRequest.isLoading}
          onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
            if (!sortType) {
              setSort({ sortColumn: dataKey, sortType: "asc" });
            } else {
              let _sortType = sortType;
              if (dataKey !== sortColumn) {
                _sortType = "asc";
              }
              setSort({ sortColumn: dataKey, sortType: _sortType });
            }
          }}
          {...{
            sortColumn,
            sortType,
          }}
        >
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell name={trans("general.from_date")} icon={DateRangeIcon} />
            <SimpleDateCell dataKey="start" formatString="DD MMM YYYY, HH:ss" />
          </Table.Column>
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell name={trans("general.to_date")} icon={DateRangeIcon} />
            <SimpleDateCell dataKey="end" formatString="DD MMM YYYY, HH:ss" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.vehicle")} icon={DirectionsCarIcon} />
            <SimpleKeyValueCell
              dataKey="licensePlate"
              descriptionFunction={(item: tTableItem) => item.makeModel}
            />
          </Table.Column>
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell name={trans("general.fuel_usage")} icon={LocalGasStationIcon} />
            <SimpleKeyValueCell
              dataKey="fuelLiters"
              textFunction={(item: tTableItem) => {
                return `${item.fuelLiters} L`;
              }}
            />
          </Table.Column>
          <Table.Column sortable flexGrow={1}>
            <SimpleHeaderCell name={trans("general.distance")} icon={SwapDrivingAppsWheelIcon} />
            <SimpleKeyValueCell
              dataKey="distanceKm"
              textFunction={(item: tTableItem) => {
                return `${item.distanceKm} km`;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

export default List;
