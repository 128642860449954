import { ReactComponent as DateRangeIcon } from "@/assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as EventRepeatIcon } from "@/assets/icons/event_repeat.svg";
import { ReactComponent as LocalLaundryServiceIcon } from "@/assets/icons/local_laundry_service.svg";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/payments.svg";
import TrendingComparison from "@/components/Comparions/trendingComparison";
import ManualDataModal from "@/components/Modals/ManualDataModal";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import {
  resolveProperty,
  updateProperty,
  updatingProperty,
  usePropertyDispatch,
  usePropertyState,
} from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import { useProfileState } from "@/context/Profile/hooks";
import useDeleteRequest from "@/hooks/apiRequests/useDeleteRequest";
import { tPropertyManualData } from "@/models/property";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { calcNumberOfNights } from "@/utils/dates";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { numberFormatter } from "@/utils/numbers";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import axios from "axios";
import { find, floor, min, toNumber } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";

export type tTableItem = Pick<tPropertyManualData, "from" | "to" | "_id"> & {
  totalPrice: number;
  totalKWh: number;
  homologueId: string;
  index: number;
};

interface iShowcasingWrapped2Props {
  outletContainerWidth: number;
  tableData: tTableItem[];
}

const ShowcasingWrapped2: React.FC<iShowcasingWrapped2Props> = ({
  outletContainerWidth,
  tableData,
}) => {
  const { profile } = useProfileState();
  const { trans } = useLocalizationState();
  const deleteRequest = useDeleteRequest();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const permissionsList = usePermissions();
  const [manualDataModal, setManualDataModal] = useState<
    | {
        open: true;
        manualDataId: tPropertyManualData["_id"];
      }
    | { open: false; manualDataId: undefined }
  >({ open: false, manualDataId: undefined });

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (hasPermission(permissionsList, "update:manual-data")) {
      options.push(
        {
          key: "update entry",
          label: trans("general.edit_x", {
            parameters: [trans("general.entry")],
          }),
          icon: LocalLaundryServiceIcon,
          onClick() {
            setManualDataModal({ open: true, manualDataId: item._id });
          },
        },
        {
          key: "remove",
          icon: { Element: DeleteIcon, fill: COLORS.error },
          label: {
            text: trans("components.manual_data.global_remove_entry.label"),
            color: COLORS.error,
          },
          onClick() {
            deleteRequest.pending();
            updatingProperty(propertyDispatch);
            axios
              .delete(
                constructApiAddress(
                  `/v2/hotels/${activeProperty._id}/manual-data/${item._id}/properties/fuels`,
                  USE_MONOLITH_SERVERLESS
                )
              )
              .then((res) => {
                const {
                  data: { hotel },
                } = res;
                updateProperty(propertyDispatch, activeProperty._id, hotel);
                deleteRequest.resolve();
              })
              .catch((err) => {
                resolveProperty(propertyDispatch);
                const error = getErrorMessage(err, trans);
                deleteRequest.reject(error);
              });
          },
        }
      );
    }

    return options;
  };

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM + TABLE_HEADER_HEIGHT;

  const periodWidth = floor(0.3 * outletContainerWidth);
  const fuelsWidth = floor(0.15 * outletContainerWidth);
  const costsWidth = floor(0.15 * outletContainerWidth);
  const lastPeriodWidth = floor(0.15 * outletContainerWidth);
  const homologueWidth = floor(0.15 * outletContainerWidth);

  return (
    <>
      <ManualDataModal
        singleStep={true}
        initialStep="fuels"
        open={manualDataModal.open}
        onClose={() => {
          setManualDataModal({ open: false, manualDataId: undefined });
        }}
        manualDataId={manualDataModal.manualDataId}
      />
      <div className="table-wrapper">
        <Table
          id="TABLE.FUELS.SHOWCASING"
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell name={trans("general.period")} icon={DateRangeIcon} />
            <SimpleKeyValueCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column width={fuelsWidth}>
            <SimpleHeaderCell name={trans("general.fuels")} icon={ModeHeatIcon} />
            <SimpleKeyValueCell
              textFunction={(item: tTableItem) => {
                return `${numberFormatter(item.totalKWh, profile)} kWh`;
              }}
              descriptionFunction={(item: tTableItem) => {
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${numberFormatter(item.totalKWh / numberOfNights, profile)} kWh/${trans(
                  "general.day"
                )}`;
              }}
              dataKey="fuels"
            />
          </Table.Column>
          <Table.Column width={costsWidth}>
            <SimpleHeaderCell name={trans("general.costs")} icon={PaymentsIcon} />
            <SimpleKeyValueCell
              textProps={() => ({ asHTML: true })}
              textFunction={(item: tTableItem) => {
                return `${numberFormatter(toNumber(item.totalPrice), profile)} €`;
              }}
              descriptionFunction={(item: tTableItem) => {
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${numberFormatter(
                  toNumber(item.totalPrice) / numberOfNights,
                  profile
                )} €/${trans("general.day")}`;
              }}
              dataKey="price"
            />
          </Table.Column>
          <Table.Column width={lastPeriodWidth}>
            <SimpleHeaderCell name={trans("general.period_previous")} icon={EventRepeatIcon} />
            <BaseCell dataKey="lastPeriod">
              {(item: tTableItem) => {
                const { index } = item;
                if (index + 1 === tableData.length)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;
                const previousEntry = tableData[index + 1];

                return (
                  <TrendingComparison
                    value={item.totalKWh}
                    baseValue={previousEntry.totalKWh}
                    size={12}
                    description={trans("components.trending_comparison.last_period_tooltip")}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={homologueWidth}>
            <SimpleHeaderCell name={trans("general.period_homologue")} icon={EventRepeatIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const homologueId = moment(item.from).subtract(1, "year").format("YYYY/MM");
                const homologue = find(tableData, (t) => t.homologueId === homologueId);

                if (!homologue) return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={item.totalKWh}
                    baseValue={homologue.totalKWh}
                    size={12}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

export default ShowcasingWrapped2;
