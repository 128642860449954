import { WASTE_TYPES } from "@/interfaces/manualData";
import { _find, _groupBy, _has, _orderBy, _sortBy, _toInteger } from "@/lodash-utils";
import tProperty, { tPropertyManualData } from "@/models/property";
import moment, { MomentInput } from "moment";

export const propertyGetManualDataOrdered = (property: tProperty) => {
  return _orderBy(
    property.manualData.map((md) => ({
      ...md,
      from: moment(md.from).valueOf(),
      to: moment(md.to).valueOf(),
    })),
    ["from", "to"],
    ["asc", "asc"]
  );
};

export const propertyGetMeasuresTrackedInManualData = (property: tProperty) => {
  const manualDataOrdered = propertyGetManualDataOrdered(property);
  const measuresTracked = {
    electricity: false,
    water: false,
    fuels: false,
    waste: false,
    laundry: false,
  };
  for (const {
    electricity,
    water,
    naturalGas,
    butane,
    biomass,
    diesel,
    ethanol,
    gasoline,
    laundry,
    propane,
    waste,
  } of manualDataOrdered) {
    if (electricity) {
      measuresTracked.electricity = true;
    }
    if (water) {
      measuresTracked.water = true;
    }
    if (naturalGas || butane || biomass || diesel || ethanol || gasoline || propane) {
      measuresTracked.fuels = true;
    }
    if (waste) {
      measuresTracked.waste = true;
    }
    if (laundry) {
      measuresTracked.laundry = true;
    }
    if (Object.values(measuresTracked).filter((v) => !v).length === 0) {
      break;
    }
  }
  return measuresTracked;
};

export const propertyGetManualDataByYear = (property: tProperty) => {
  const manualDataOrdered = propertyGetManualDataOrdered(property);
  return _groupBy(manualDataOrdered, (mdo: tPropertyManualData) => moment(mdo.from).year());
};

export const propertyGetManualDataWithoutZeroValues = (
  property: tProperty,
  types: (keyof Pick<
    tPropertyManualData,
    | "biomass"
    | "butane"
    | "diesel"
    | "electricity"
    | "ethanol"
    | "gasoline"
    | "laundry"
    | "water"
    | "waste"
    | "naturalGas"
    | "propane"
  >)[]
) => {
  const filterFunc = (entry: tPropertyManualData) => {
    for (const type of types) {
      switch (type) {
        case "naturalGas":
          if (entry.naturalGas?.totalKWh || entry.naturalGas?.totalM3) return true;
          break;
        case "biomass":
        case "propane":
        case "butane":
          if (entry[type]?.totalKWh || entry[type]?.totalKg) return true;
          break;

        case "diesel":
        case "ethanol":
        case "gasoline":
          if (entry[type]?.totalKWh || entry[type]?.totalLiters) return true;
          break;

        case "electricity":
          if (entry.electricity?.totalKWh) return true;
          break;

        case "water":
          if (entry.water?.totalM3) return true;
          break;

        case "waste":
          if (
            WASTE_TYPES.map((et) => {
              const elem = entry.waste;
              if (elem) {
                return elem[et].totalKg;
              }
              return null;
            }).filter((v) => v).length > 0
          )
            return true;
          break;

        case "laundry":
          if (entry.laundry?.kgOutsourced) return true;
          break;
      }
    }
    return false;
  };

  return property.manualData.filter((i) => filterFunc(i));
};

export const propertyGetManualDataByYearWithoutZeroValueYears = (
  property: tProperty,
  types: (keyof Pick<
    tPropertyManualData,
    | "biomass"
    | "butane"
    | "diesel"
    | "electricity"
    | "ethanol"
    | "gasoline"
    | "laundry"
    | "water"
    | "waste"
    | "naturalGas"
    | "propane"
  >)[]
) => {
  const noZeros = propertyGetManualDataWithoutZeroValues(property, types);

  const obj = _groupBy(noZeros, (i: tPropertyManualData) => moment(i.from).year());

  Object.keys(obj).forEach(
    (year) => (obj[year] = _sortBy(obj[year], (e: tPropertyManualData) => moment(e.from).valueOf()))
  );

  return obj;
};

export const propertyGetManualDataOrderedWithoutZeroValues = (
  property: tProperty,
  types: (keyof Pick<
    tPropertyManualData,
    | "biomass"
    | "butane"
    | "diesel"
    | "electricity"
    | "ethanol"
    | "gasoline"
    | "laundry"
    | "water"
    | "waste"
    | "naturalGas"
    | "propane"
  >)[],
  order: "asc" | "desc" = "asc"
) => {
  const noZeros = propertyGetManualDataWithoutZeroValues(property, types);

  const sortFunc =
    order === "asc"
      ? (date: MomentInput) => moment(date).valueOf()
      : (date: MomentInput) => -moment(date).valueOf();

  return _sortBy(noZeros, (e: tPropertyManualData) => sortFunc(e.from));
};

export const propertyFindPreviousAvailableYearManualDataEntry = (
  property: tProperty,
  entry: tPropertyManualData,
  attributes?: keyof tPropertyManualData
) => {
  const manualDataByYear = propertyGetManualDataByYear(property);
  const entryMoment = moment((moment(entry.from).valueOf() + moment(entry.to).valueOf()) / 2);
  const entryYear = entryMoment.year();
  const entryMonth = entryMoment.month();

  const yearsDescendingOrdered = _sortBy(
    Object.keys(manualDataByYear).map((y) => _toInteger(y)),
    (y: number) => -y
  );

  if (attributes) {
    for (const year of yearsDescendingOrdered) {
      if (year >= entryYear) continue;

      const monthEntry = _find(
        manualDataByYear[year],
        (md) =>
          moment((moment(md.from).valueOf() + moment(md.to).valueOf()) / 2).month() === entryMonth
      );

      if (monthEntry) {
        if (_has(monthEntry, attributes)) return monthEntry;
        continue;
      }
    }
  } else {
    for (const year of yearsDescendingOrdered) {
      if (year >= entryYear) continue;

      const monthEntry = _find(
        manualDataByYear[year],
        (md) =>
          moment((moment(md.from).valueOf() + moment(md.to).valueOf()) / 2).month() === entryMonth
      );

      if (monthEntry) return monthEntry;
    }
  }

  return undefined;
};

export const propertyGetManualDataEntryWithDimensionsInTimeframe = (
  property: tProperty,
  date: MomentInput
) => {
  const manualDataOrdered = propertyGetManualDataOrdered(property);

  let i = 0;
  for (; i < manualDataOrdered.length; i++) {
    const entry = manualDataOrdered[i];
    if (!moment(date).isBetween(moment(entry.from), moment(entry.to))) continue;

    let dimensions = entry.space;
    if (dimensions) return { ...entry };

    let j = i - 1;
    while (j >= 0) {
      dimensions = manualDataOrdered[j].space;
      if (dimensions) return { ...manualDataOrdered[j] };
      j--;
    }
    j = i + 1;
    while (j < manualDataOrdered.length) {
      dimensions = manualDataOrdered[j].space;
      if (dimensions) return { ...manualDataOrdered[j] };
      j++;
    }
    break;
  }

  i = manualDataOrdered.length - 1;
  for (; i >= 0; i--) {
    if (manualDataOrdered[i].space && manualDataOrdered[i].space!.totalAreaM2) {
      return { ...manualDataOrdered[i] };
    }
  }

  return undefined;
};

export const propertyGetManualDataDimensionsInTimeframe = (
  property: tProperty,
  date: MomentInput
) => {
  const manualDataOrdered = propertyGetManualDataOrdered(property);
  let i = 0;
  for (; i < manualDataOrdered.length; i++) {
    const entry = manualDataOrdered[i];
    if (!moment(date).isBetween(moment(entry.from), moment(entry.to))) continue;

    let dimensions = entry.space;
    if (dimensions) return dimensions;

    let j = i - 1;
    while (!dimensions && j >= 0) {
      dimensions = manualDataOrdered[j].space;
      j--;
    }
    if (dimensions) return dimensions;
    j = i + 1;
    while (!dimensions && j < manualDataOrdered.length) {
      dimensions = manualDataOrdered[j].space;
      j++;
    }
    if (dimensions) return dimensions;
    break;
  }

  i = manualDataOrdered.length - 1;
  for (; i >= 0; i--) {
    if (manualDataOrdered[i].space && manualDataOrdered[i].space!.totalAreaM2)
      return manualDataOrdered[i].space;
  }

  return undefined;
};
