import SimpleAddButton from "@/components/RsuiteWrapper/SimpleButton/buttons/addButton";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePostRequest from "@/hooks/apiRequests/usePostRequest";
import { tPropertySpaceId } from "@/models/property";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { propertyIsSettingOn } from "@/utils/property/settings";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { notification } from "@/utils/notifications";
import axios from "axios";
import React, { useState } from "react";
import { useToaster } from "rsuite";
import CreateUpdateReservationModal from "./createUpdateReservationModal";

interface iProps {
  afterCreate(): void;
}

const AddReservation: React.FC<iProps> = ({ afterCreate }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();
  const { activeProperty } = usePropertyState();

  if (!propertyIsSettingOn(activeProperty, "pages.stays.writeStays")) return null;

  const onCreate = (args: {
    guestEmail: string;
    period: [Date, Date];
    space: tPropertySpaceId;
    numberOfGuests: number;
    guestName?: string;
  }) => {
    const { guestEmail, period, space, numberOfGuests, guestName } = args;

    postRequest.pending();
    axios
      .post(
        constructApiAddress(`/v2/hotels/${activeProperty._id}/stays/`, USE_MONOLITH_SERVERLESS),
        {
          guestEmail,
          period,
          space,
          numberOfGuests,
          guestName,
        }
      )
      .then((res) => {
        postRequest.resolve();
        setModal({ open: false });
        afterCreate();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  return (
    <>
      <CreateUpdateReservationModal
        open={modal.open}
        isLoading={postRequest.isLoading}
        onClose={() => setModal({ open: false })}
        onConfirm={onCreate}
      />
      <SimpleAddButton onClick={() => setModal({ open: true })} />
    </>
  );
};

export default AddReservation;
