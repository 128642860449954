import React, { Fragment, useState } from "react";
import Flex from "@/components/Flex";
import SimpleButton from "@/components/RsuiteWrapper/SimpleButton";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";
import styles from "../styles.module.css";
import IpModal from "./ipModal";

const SecuritySettins: React.FC = () => {
  const { trans } = useLocalizationState();
  const [modalState, setModalState] = useState({ open: false });

  return (
    <Fragment>
      <IpModal open={modalState.open} onClose={() => setModalState({ open: false })} />
      <Flex
        row
        between
        middle
        className={[styles["settings-item"], styles["settings-item--not-last"]].join(" ")}
      >
        <InterTag
          text={trans("pages.settings.panels.security.items.ip_restrictions.label")}
          size={14}
          bold
          color={COLORS.secondary}
        />
        <SimpleButton
          onClick={() => {
            setModalState({ open: true });
          }}
          text={{ text: trans("general.manage"), color: COLORS.primary }}
          appearance="subtle"
        />
      </Flex>
    </Fragment>
  );
};

export default SecuritySettins;
