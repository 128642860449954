import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tPropertyId } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
import useGetRequest from "./apiRequests/useGetRequest";

export type tPaymentMethod = PaymentMethod;

const useListPaymentMethods = (
  propertyId: tPropertyId,
  { doRequest = true }: { doRequest: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const getRequest = useGetRequest<{ paymentMethods: tPaymentMethod[] }>({
    paymentMethods: [],
  });
  const [redoRequest, setRedoRequest] = useState(false);

  useEffect(() => {
    if (propertyId && doRequest) {
      getRequest.pending();
      axios
        .get(ENDPOINTS.PROPERTIES.STRIPE.PAYMENT_METHODS.LIST, {
          params: { propertyId },
        })
        .then((res) => {
          const {
            data: { paymentMethods },
          } = res;
          getRequest.resolve({ paymentMethods });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getRequest.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, trans, redoRequest, doRequest]);

  const run = () => {
    setRedoRequest((prev) => !prev);
  };

  return { request: getRequest, run };
};

export default useListPaymentMethods;
