import { find, has, last, max, sum, zip } from "lodash";
import { MomentInput } from "moment";
import { ReactComponent as AcUnit } from "@/assets/icons/ac_unit.svg";
import { ReactComponent as ElectricBolt } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as Lightbulb } from "@/assets/icons/lightbulb.svg";
import { ReactComponent as SmartOutlet } from "@/assets/icons/smart_outlet.svg";
import { ReactComponent as WaterDrop } from "@/assets/icons/water_drop.svg";
import { ReactComponent as WaterHeater } from "@/assets/icons/water_heater.svg";
import { tTrans } from "@/context/Localization/utils";
import { tIcon } from "@/interfaces/icon";
import { tBinUnitSingular } from "@/interfaces/sensorData";
import { tProperty, tPropertySpaceId } from "@/models/property";
import {
  AC,
  CW,
  EL,
  ES,
  GW,
  HW,
  MEASURES,
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  TE,
  tMeasure,
  tMeasureEnergySpecific,
  tMeasureTotal,
  tMeasureWaterSpecific,
  TW,
  WH,
} from "@/models/measures";
import { add2Arrays } from "./arrays";
import { COLORS, hexLuminance } from "./colors";

export const getMeasureInfo = (
  measureName: tMeasure,
  trans: tTrans = (text: string) => text
): {
  icon: { Element: tIcon["Element"] } & Partial<tIcon>;
  label: string;
  color: string;
} => {
  switch (measureName) {
    case "tw":
      return {
        icon: {
          Element: WaterDrop,
          fill: COLORS.water,
        },
        color: COLORS.water,
        label: trans("general.measures_.water"),
      };
    case "ntw":
      return {
        icon: {
          Element: WaterDrop,
          fill: hexLuminance(COLORS.water, 0.25),
        },
        color: hexLuminance(COLORS.water, 0.25),
        label: trans("general.measures_.raw_water"),
      };
    case "dw":
      return {
        icon: {
          Element: WaterDrop,
          fill: hexLuminance(COLORS.water, -0.3),
        },
        color: hexLuminance(COLORS.water, -0.3),
        label: trans("general.measures_.deironized_water"),
      };
    case "cw":
      return {
        icon: {
          Element: WaterDrop,
          fill: COLORS.cold,
        },
        color: COLORS.cold,
        label: trans("general.measures_.cold_water"),
      };
    case "hw":
      return {
        icon: { Element: WaterHeater, fill: COLORS.hot },
        label: trans("general.measures_.hot_water"),
        color: COLORS.hot,
      };
    case "gw":
      return {
        icon: { Element: WaterDrop, fill: COLORS.gray },
        label: trans("general.measures_.gray_water"),
        color: COLORS.gray,
      };
    case "te":
      return {
        icon: { Element: ElectricBolt, fill: COLORS.energy },
        label: trans("general.measures_.electricity"),
        color: COLORS.energy,
      };
    case "el":
      return {
        icon: { Element: Lightbulb },
        label: trans("general.measures_.lights"),
        color: COLORS.primary_300,
      };
    case "es":
      return {
        icon: { Element: SmartOutlet },
        label: trans("general.measures_.outlets"),
        color: COLORS.primary_600,
      };
    case "wh":
      return {
        icon: { Element: WaterHeater },
        label: trans("general.measures_.water_heating"),
        color: COLORS.primary_900,
      };
    case "ac":
      return {
        icon: { Element: AcUnit },
        label: trans("general.measures_.hvac"),
        color: COLORS.primary_800,
      };
  }
};

export const getMeasureType = (measure: tMeasure) => {
  switch (measure) {
    case TE:
    case ES:
    case EL:
    case AC:
    case WH:
      return "electricity";
    case TW:
    case CW:
    case HW:
    case GW:
      return "water";
  }
};

export const get_co2_costs_total_fromMeasures = (
  hotel: tProperty,
  data: Partial<Record<tMeasure, number[]>>,
  from?: MomentInput,
  to?: MomentInput,
  binUnit?: tBinUnitSingular,
  binValue?: number
): { co2: number[]; costs: number[]; te: number[]; tw: number[] } => {
  const { waterToCurrency, waterToFootprint, energyToCurrency, energyToFootprint } = hotel;

  let te: number[] = [];
  let tw: number[] = [];

  if (data.tw) tw = data.tw;
  else {
    const measure = find(MEASURES_WATER_SPECIFIC, (m) => has(data, m));
    if (measure) {
      tw = data[measure]!.map((v, i) => {
        return sum(MEASURES_WATER_SPECIFIC.map((k) => data[k]![i] || 0));
      });
    }
  }
  if (data.te) te = data.te;
  else {
    const measure = find(MEASURES_ELECTRICITY_SPECIFIC, (m) => has(data, m));
    if (measure) {
      te = data[measure]!.map((v, i) => {
        return sum(MEASURES_ELECTRICITY_SPECIFIC.map((k) => data[k]![i] || 0));
      });
    }
  }

  const w2co2 = (last(waterToFootprint) || { conversionValue: 1 }).conversionValue;
  const w2costs = (last(waterToCurrency) || { conversionValue: 1 }).conversionValue;
  const e2co2 = (last(energyToFootprint) || { conversionValue: 1 }).conversionValue;
  const e2costs = (last(energyToCurrency) || { conversionValue: 1 }).conversionValue;

  const length = max([te.length, tw.length]) || 0;
  const co2: number[] = [];
  const costs: number[] = [];

  for (let i = 0; i < length; i++) {
    co2.push((i < te.length ? te[i] : 0) * e2co2 + (i < tw.length ? tw[i] : 0) * w2co2);
    costs.push((i < te.length ? te[i] : 0) * e2costs + (i < tw.length ? tw[i] : 0) * w2costs);
  }

  return { co2, costs, te, tw };
};

export function getCO2AndCostsPerMeasure<T extends tMeasure | undefined = undefined>(
  hotel: tProperty,
  data: Partial<Record<tMeasure, number[]>>,
  from?: MomentInput,
  to?: MomentInput,
  binUnit?: tBinUnitSingular,
  binValue?: number
) {
  type tReturnType = T extends tMeasure
    ? Partial<Record<tMeasure, { costs: number[]; co2: number[] }>> &
        Record<tMeasure, { costs: number[]; co2: number[] }>
    : Partial<Record<tMeasure, { costs: number[]; co2: number[] }>>;

  const { waterToCurrency, waterToFootprint, energyToCurrency, energyToFootprint } = hotel;
  const w2co2 = (last(waterToFootprint) || { conversionValue: 1 }).conversionValue;
  const w2costs = (last(waterToCurrency) || { conversionValue: 1 }).conversionValue;
  const e2co2 = (last(energyToFootprint) || { conversionValue: 1 }).conversionValue;
  const e2costs = (last(energyToCurrency) || { conversionValue: 1 }).conversionValue;

  const obj: tReturnType = Object.fromEntries(
    Object.entries(data)
      .filter(([measure]) => MEASURES.includes(measure as tMeasure))
      .map((entry) => {
        const [measure, measurements] = entry as [tMeasure, number[]];

        let toCo2: number = 0;
        let toCosts: number = 0;
        switch (getMeasureType(measure)) {
          case "water":
            toCo2 = w2co2;
            toCosts = w2costs;
            break;
          case "electricity":
            toCo2 = e2co2;
            toCosts = e2costs;
            break;
          default:
            break;
        }

        const [co2, costs] = zip(...measurements.map((v) => [v * toCo2, v * toCosts]));

        return [measure, { costs, co2 }];
      })
  ) as tReturnType;

  return obj;
}

export const populateMeasuresObj = (
  data: Partial<Record<tMeasure, number[]>>
): Record<tMeasureTotal, number[]> &
  Partial<Record<tMeasureWaterSpecific | tMeasureEnergySpecific, number[]>> => {
  const obj: ReturnType<typeof populateMeasuresObj> = {
    te: [],
    tw: [],
    ...data,
  };

  if (!has(obj, TW) || obj[TW].length === 0) {
    MEASURES_WATER_SPECIFIC.forEach((m) => {
      obj.tw = add2Arrays(obj.tw || [], obj[m] || []);
    });
  }

  if (!has(obj, TE) || obj[TE].length === 0) {
    MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
      obj.te = add2Arrays(obj.te || [], obj[m] || []);
    });
  }

  return obj;
};

export const groupDataBySpace = (
  bySpace: Record<tPropertySpaceId, Partial<Record<tMeasure, number[]>>>
): Partial<Record<tMeasure, number[]>> => {
  const obj: ReturnType<typeof groupDataBySpace> = {};
  Object.values(bySpace).forEach((measurements) => {
    Object.entries(measurements).forEach(async (entry) => {
      const [measure, values] = entry as [tMeasure, number[]];
      if (!has(obj, measure)) {
        obj[measure] = values;
      } else {
        obj[measure] = add2Arrays(values, obj[measure]!);
      }
    });
  });

  return obj;
};

export const calcVariation = (value: number, baseValue: number) => {
  const diff = baseValue - value;
  const variation = (Math.abs(diff) / Math.abs(baseValue)) * 100;

  return variation;
};
