import React from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyboardArrowRightIcon } from "@/assets/icons/keyboard_arrow_right.svg";
import Icon from "@/components/Icons/Icon";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import { iTextProps } from "@/components/Text";
import InterTag from "@/components/Text/Inter";
import { toTextProps } from "@/components/Text/utils";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useElementForPortal from "@/hooks/useElementForPortal";
import { COLORS } from "@/utils/colors";
import { ELEMENT_IDS } from "@/utils/portal";

interface iProps {
  page: iTextProps | string;
  parentPages?: { transKey: string; to: string }[];
  loading?: boolean;
}

const PageTitle: React.FC<iProps> = ({ page, parentPages, loading }) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const topbarContainer = useElementForPortal(ELEMENT_IDS.topbarContainerPageName);

  if (!topbarContainer) return null;

  const textProps = toTextProps(page);

  let pageJSX = loading ? (
    <SkeletonText width={150} height={24} />
  ) : (
    <InterTag
      {...{
        size: 24,
        bold: true,
        asHTML: true,
        ...textProps,
        text: trans(textProps.text),
      }}
    />
  );

  const title =
    parentPages && parentPages.length ? (
      <div className="flex flex-col gap-2 justify-center">
        <div className="flex flex-row gap-1.5 items-center">
          {parentPages
            .flatMap(({ to, transKey }) => [
              <InterTag
                key={to}
                hoverUnderline
                onClick={() => {
                  navigate(to);
                }}
                text={trans(transKey)}
                size={12}
                color={COLORS.secondary}
              />,
              <Icon Element={KeyboardArrowRightIcon} size={16} />,
            ])
            .slice(0, -1)}
        </div>
        {pageJSX}
      </div>
    ) : (
      pageJSX
    );

  return <>{createPortal(title, topbarContainer)}</>;
};

export default PageTitle;
