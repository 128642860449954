import SimpleAddButton from "@/components/RsuiteWrapper/SimpleButton/buttons/addButton";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePostRequest from "@/hooks/apiRequests/usePostRequest";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { getErrorMessage } from "@/utils/httpResponses/others";
import axios from "axios";
import React, { useState } from "react";
import CreateEditChallengeModal, { tDataOnSubmit } from "./createEditChallengeModal";
import { ENDPOINTS } from "@/utils/api/endpoints";

interface iProps {}

const AddChallenge: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createChallenge = (data: Omit<tDataOnSubmit, "_id">) => {
    postRequest.pending();
    axios
      .post(
        ENDPOINTS.PROPERTIES.CHALLENGES.CREATE,
        { items: [data] },
        { params: { propertyId: activeProperty._id } }
      )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        toaster.success(trans("pages.challenges.sections.list.notifications.challenge_created"));
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        postRequest.reject(error);
      });
  };

  return (
    <>
      <CreateEditChallengeModal
        onRequest={createChallenge}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <SimpleAddButton onClick={() => setModal({ open: true })} />
    </>
  );
};

export default AddChallenge;
