import DataTypeToggle from "@/components/DataTypeToggle";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import SecondaryTopBar from "@/components/TopBar/SecondaryTopBar";
import { useDataType } from "@/context/DataType";
import { usePropertyState } from "@/context/Property/hooks";
import WithContextProvider from "@/hoc/withProvider";
import { propertyActiveSubscriptionTypeIsEnterprise } from "@/utils/property/subscriptions";
import React from "react";
import SustainabilityContextProvider from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";

export const SUSTAINABILITY_TOPBAR_ELEMENT_ID = "sustainability-topbar";

const SustainabilityWrapped: React.FC = () => {
  const dataTypeContext = useDataType();
  const { activeProperty } = usePropertyState();

  const activeSubscriptionIsEnterprise = propertyActiveSubscriptionTypeIsEnterprise({
    property: activeProperty,
  });

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row gap-2 items-center">
          {activeSubscriptionIsEnterprise && <DataTypeToggle />}
          <div id={SUSTAINABILITY_TOPBAR_ELEMENT_ID}></div>
        </div>
      </SecondaryTopBar>
      <div>
        {dataTypeContext.dataTypeIsLive ? (
          <SensorData />
        ) : (
          dataTypeContext.dataTypeIsArchives && <ManualData />
        )}
        <div />
      </div>
    </div>
  );
};

const Sustainability: React.FC = () => {
  return (
    <IpValidatorGuard>
      <SustainabilityWrapped />
    </IpValidatorGuard>
  );
};

export default WithContextProvider(SustainabilityContextProvider, Sustainability);
