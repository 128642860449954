import { ceil } from "lodash";
import moment, { MomentInput } from "moment";
import { ReactComponent as EqualizerIcon } from "@/assets/icons/equalizer.svg";
import { ReactComponent as LineAxisIcon } from "@/assets/icons/line_axis.svg";
import { iApexChartProps } from "@/components/Graphs/Apex/Chart";
import { tBinUnitSingular } from "@/interfaces/sensorData";

export const generateGraphCategories = (
  binUnit: tBinUnitSingular,
  binValue: number,
  from: MomentInput,
  to: MomentInput
) => {
  const categories: any[] = [];
  const numberOfLabels = ceil(
    (moment(to).endOf(binUnit).diff(moment(from).startOf(binUnit), binUnit) + 1) / binValue
  );

  const iter = moment(from).startOf(binUnit);
  for (let i = 0; i < numberOfLabels; i++) {
    categories.push(moment(iter).toDate());
    iter.add(binValue, binUnit);
  }

  return categories;
};

export const graphTypeInfo = (graphType: Extract<iApexChartProps["type"], "line" | "bar">) => {
  switch (graphType) {
    case "bar":
      return {
        transKey: "general.graph_types.bar",
        icon: { Element: EqualizerIcon },
      };
    case "line":
      return {
        transKey: "general.graph_types.line",
        icon: { Element: LineAxisIcon },
      };
  }
};
