import React from "react";
import { InputNumber } from "rsuite";
import { tState2 } from ".";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "@/interfaces/apiCalls";
import { REQUEST_STATUS } from "@/utils/apiCall";
import Flex from "@/components/Flex";
import InputWrapper from "@/components/InputWrapper";

interface iProps {
  status: nRequestStatus.tStatus;
  state: tState2["data"]["occupancy"];
  errors: tState2["errors"]["occupancy"];
  onChange(key: keyof tState2["data"]["occupancy"], value: number | string): void;
}

const Occupancy: React.FC<iProps> = ({ errors, state, onChange, status }) => {
  const { trans } = useLocalizationState();

  const renderInputs = () => {
    const list: {
      label: string;
      description: string;
      placeholder: string;
      key: keyof tState2["data"]["occupancy"];
    }[] = [
      {
        key: "numberOfGuests",
        label: trans("Guests"),
        description: trans("Please provide the total number of guests accommodated."),
        placeholder: trans("Number of guests"),
      },
      {
        key: "numberOfStays",
        description: trans("Please provide the total number of stays"),
        label: trans("Stays"),
        placeholder: trans("Number of stays"),
      },
    ];

    return list.map(({ description, key, label, placeholder }) => {
      return (
        <InputWrapper {...{ label, description, error: errors[key], key }}>
          <div style={{ width: "50%" }}>
            <InputNumber
              disabled={status === REQUEST_STATUS.PENDING}
              min={0}
              value={state[key]}
              placeholder={placeholder}
              onChange={(value) => {
                if (value !== null) {
                  onChange(key, value);
                }
              }}
            />
          </div>
        </InputWrapper>
      );
    });
  };

  return (
    <Flex column gap={20}>
      {renderInputs()}
    </Flex>
  );
};

export default Occupancy;
