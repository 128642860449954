import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as VerifiedIcon } from "@/assets/icons/verified.svg";
import Modal2ActionButtons from "@/components/Buttons/modal2ActionButtons";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import InputWrapper from "@/components/InputWrapper";
import PageSection from "@/components/PageSection";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tPropertyAwardId } from "@/models/property";
import { COLORS } from "@/utils/colors";
import { getAwardDescription } from "@/utils/property/awards";
import { toNumber, uniqueId } from "lodash";
import React, { Fragment, useState } from "react";
import {
  Button,
  IconButton,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";

interface iAwardGuestModalWrappedProps
  extends Pick<iProps, "onClose" | "onConfirm" | "isLoading"> {}

const AwardGuestModalWrapped: React.FC<iAwardGuestModalWrappedProps> = ({
  onClose,
  onConfirm,
  isLoading,
}) => {
  const { trans, language } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const [awardedAwards, setAwardedAwards] = useState<
    { awardId: tPropertyAwardId | null; count: number; key: string }[]
  >([{ awardId: null, count: 1, key: uniqueId() }]);

  const activeAwards = activeProperty.awards.filter((a) => a.active && !a.removed);

  const handleConfirm = () => {
    onConfirm(
      (
        awardedAwards.filter((a) => a.awardId !== null) as {
          awardId: tPropertyAwardId;
          count: number;
        }[]
      ).map((a) => ({ awardId: a.awardId, count: a.count }))
    );
  };

  return (
    <Fragment>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSection
            title={trans("pages.guests.modals.award-guest.title")}
            description={trans("pages.guests.modals.award-guest.description")}
            icon={VerifiedIcon}
          />
          <Flex column gap={12}>
            <InputWrapper label={trans("general.awards")}>
              {awardedAwards.map(({ awardId, count, key }, i) => {
                return (
                  <Flex row key={key} gap={4}>
                    <SelectPicker
                      disabled={isLoading}
                      value={awardId}
                      style={{ flexBasis: "70%" }}
                      onChange={(value) => {
                        const awards = awardedAwards;
                        awards[i] = { ...awards[i], awardId: value };
                        setAwardedAwards([...awards]);
                      }}
                      data={activeAwards.map((a) => {
                        const description = getAwardDescription(a, language);
                        return {
                          label: (
                            <Whisper
                              trigger="hover"
                              placement="topStart"
                              speaker={<Tooltip>{description?.longDescription}</Tooltip>}
                            >
                              <div>{description?.shortDescription}</div>
                            </Whisper>
                          ),
                          value: a._id,
                        };
                      })}
                    />
                    <InputNumber
                      disabled={isLoading}
                      style={{ flexBasis: "20%" }}
                      value={count}
                      min={1}
                      onChange={(value) => {
                        const awards = awardedAwards;
                        awards[i] = {
                          ...awards[i],
                          count: value ? toNumber(value) : 1,
                        };
                        setAwardedAwards([...awards]);
                      }}
                    />
                    <IconButton
                      circle
                      icon={<Icon Element={DeleteIcon} fill={COLORS.error} size={18} />}
                      onClick={() => {
                        const awards = awardedAwards.filter((a) => a.key !== key);
                        setAwardedAwards([...awards]);
                      }}
                    />
                  </Flex>
                );
              })}
            </InputWrapper>
            <Button
              size="sm"
              disabled={isLoading}
              style={{ width: "fit-content" }}
              className="button-gray"
              onClick={() => {
                setAwardedAwards((prev) => [...prev, { awardId: "", count: 1, key: uniqueId() }]);
              }}
              appearance="default"
            >
              {trans("pages.guests.modals.award-guest.add_new_award")}
            </Button>
          </Flex>
          <Modal2ActionButtons onClose={onClose} onConfirm={handleConfirm} isLoading={isLoading} />
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm(awards: { awardId: tPropertyAwardId; count: number }[]): void;
  isLoading: boolean;
}

const AwardGuestModal: React.FC<iProps> = ({ onClose, onConfirm, isLoading, ...rest }) => {
  return (
    <Modal
      {...{
        size: "sm",
        id: "award-guest-modal",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        onClose,
        ...rest,
      }}
    >
      <AwardGuestModalWrapped {...{ onClose, onConfirm, isLoading }} />
    </Modal>
  );
};

export default AwardGuestModal;
