import { ReactComponent as ApprovalDelegationIcon } from "@/assets/icons/approval_delegation.svg";
import { ReactComponent as QueryStatsIcon } from "@/assets/icons/query_stats.svg";
import { ReactComponent as VolunteerActivismIcon } from "@/assets/icons/volunteer_activism.svg";
import BasicCard from "@/components/Cards/BasicCard";
import Flex from "@/components/Flex";
import PageSection from "@/components/PageSection";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { COLORS } from "@/utils/colors";
import React from "react";

const AwardsStatisticsWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();

  const [totalGiven, totalUsed] = activeProperty.awards
    .map((a) => [a.timesAwarded, a.timesUsed])
    .reduce(
      (acc, value) => {
        return [acc[0] + value[0], acc[1] + value[1]];
      },
      [0, 0]
    );

  const info = [
    {
      label: "pages.awards.sections.statistics.cards.given.title",
      Icon: VolunteerActivismIcon,
      value: totalGiven,
    },
    {
      label: "pages.awards.sections.statistics.cards.used.title",
      Icon: ApprovalDelegationIcon,
      value: totalUsed,
    },
  ];

  return (
    <Flex row gap={24}>
      {info.map(({ label, Icon: Element, value }) => (
        <BasicCard
          key={label}
          label={trans(label)}
          value={value}
          icon={{ Element, fill: COLORS.secondary }}
        />
      ))}
    </Flex>
  );
};

const AwardsStatistics: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={{ text: trans("pages.awards.sections.statistics.title") }}
        description={{
          text: trans("pages.awards.sections.statistics.description"),
        }}
        icon={{ Element: QueryStatsIcon }}
      />
      <AwardsStatisticsWrapped />
    </Flex>
  );
};

export default AwardsStatistics;
