export const ENDPOINTS = {
  OTHERS: {
    DEFAULT_CONVERSION_FACTORS:
      process.env.REACT_APP_ENDPOINTS__OTHERS__DEFAULT_CONVERSION_FACTORS!,
    DEFAULT_FUELS_TO_ENERGY: process.env.REACT_APP_ENDPOINTS__OTHERS__DEFAULT_FUELS_TO_ENERGY!,
  },
  MANAGERS: {
    IS_EMAIL_AVAILABLE: process.env.REACT_APP_ENDPOINTS__MANAGERS__IS_EMAIL_AVAILABLE!,
    SETTINGS: {
      UPDATE: process.env.REACT_APP_ENDPOINTS__MANAGERS__SETTINGS__UPDATE!,
    },
    LOGIN: process.env.REACT_APP_ENDPOINTS__MANAGERS__LOGIN!,
    PROPERTIES: {
      LIST: process.env.REACT_APP_ENDPOINTS__MANAGERS__PROPERTIES_LIST!,
      CONSUMPTION: process.env.REACT_APP_ENDPOINTS__MANAGERS__PROPERTIES__CONSUMPTION!,
    },
    ACCOUNT: {
      GET_ACCOUNT: process.env.REACT_APP_ENDPOINTS__MANAGERS__ACCOUNT__GET_ACCOUNT!,
      UPDATE_ACCOUNT: process.env.REACT_APP_ENDPOINTS__MANAGERS__ACCOUNT__UPDATE_ACCOUNT!,
    },
  },
  PROPERTIES: {
    REGISTER: {
      REGISTER_PROPERTY_ACCOUNT_STEP:
        process.env.REACT_APP_ENDPOINTS__PROPERTIES__REGISTER__REGISTER_PROPERTY_ACCOUNT_STEP!,
      TOURISM_REGISTER_VALIDATE_STEP:
        process.env.REACT_APP_ENDPOINTS__PROPERTIES__REGISTER__TOURISM_REGISTER_VALIDATE_STEP!,
    },
    SUBSCRIPTIONS: {
      BUSINESS: {
        SUBSCRIBE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SUBSCRIPTIONS__BUSINESS__SUBSCRIBE!,
        PRICES: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SUBSCRIPTIONS__BUSINESS__PRICES!,
      },
      FAST_UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SUBSCRIPTIONS__FAST_UPDATE!,
      CONFIRM_FIRST_PLAN_STARTER:
        process.env.REACT_APP_ENDPOINTS__PROPERTIES__SUBSCRIPTIONS__CONFIRM_FIRST_PLAN_STARTER!,
    },
    BENCHMARK: process.env.REACT_APP_ENDPOINTS__PROPERTIES__BENCHMARK!,

    AWARDS: {
      CREATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__AWARDS__CREATE!,
      DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__AWARDS__DELETE!,
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__AWARDS__UPDATE!,
    },
    CHALLENGES: {
      CREATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CHALLENGES__CREATE!,
      DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CHALLENGES__DELETE!,
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CHALLENGES__UPDATE!,
    },
    CONSUMPTION: {
      SPACE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CONSUMPTION__SERIES!,
      TOTAL: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CONSUMPTION__TOTAL!,
      TOTAL_BY_SPACE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__CONSUMPTION__TOTAL__BY_SPACE!,
    },
    SPACES: {
      CREATE_SPACES: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SPACES__CREATE_SPACES!,
      DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SPACES__DELETE!,
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__SPACES__UPDATE!,
    },
    STAFF: {
      LIST: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF__LIST!,
      CREATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF__CREATE!,
      DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF__DELETE!,
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF__UPDATE!,
    },
    STAFF_ROLES: {
      DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF_ROLES__DELETE!,
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF_ROLES__UPDATE!,
      CREATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF_ROLES__CREATE!,
      UPDATE_PERMISSIONS:
        process.env.REACT_APP_ENDPOINTS__PROPERTIES__STAFF_ROLES__UPDATE__PERMISSIONS!,
    },
    PROFILE: {
      UPDATE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__PROFILE__UPDATE!,
      IMAGES: {
        GET_POST_PRESIGNED_URL:
          process.env.REACT_APP_ENDPOINTS__PROPERTIES__PROFILE__IMAGES_GET_POST_PRESIGNED_URL!,
        DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__PROFILE__IMAGE_DELETE!,
      },
    },
    STRIPE: {
      PROMOTION_CODES: {
        BUSINESS: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STRIPE__PROMOTION_CODES__BUSINESS!,
      },
      PAYMENT_METHODS: {
        LIST: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STRIPE__PAYMENT_METHODS__LIST!,
        DELETE: process.env.REACT_APP_ENDPOINTS__PROPERTIES__STRIPE__PAYMENT_METHODS__DELETE!,
        SETUP_FUTURE_PAYMENTS: {
          CLIENT_SECRET:
            process.env
              .REACT_APP_ENDPOINTS__PROPERTIES__STRIPE__PAYMENT_METHODS__SETUP_FUTURE_PAYMENTS__CLIENT_SECRET!,
        },
      },
    },
  },
};
