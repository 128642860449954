import { ReactComponent as AddIcon } from "@/assets/icons/add.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/payments.svg";
import { ReactComponent as VisaIcon } from "@/assets/icons/visa.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import PageSection from "@/components/PageSection";
import BaseCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "@/components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "@/components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePutRequest from "@/hooks/apiRequests/usePutRequest";
import useListPaymentMethods from "@/hooks/useListPaymentMethods";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "@/utils/apiCall";
import { COLORS } from "@/utils/colors";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { notification } from "@/utils/notifications";
import { DEFAULT_MODAL_PROPS } from "@/utils/rsuite/modals";
import { PROPERTY_STRIPE_ATTRIBUTE, STRIPE_ENV } from "@/utils/stripe";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "@/utils/tables";
import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import moment, { MomentInput } from "moment";
import React from "react";
import { Button, Modal, ModalProps, Table, useToaster } from "rsuite";

type tPaymentMethod = PaymentMethod;

type tTableItem = tPaymentMethod & { noytrall: { expires: MomentInput } };

interface iManagePaymentMethodsModalWrappedProps extends Pick<iProps, "openAddModal"> {}

const ManagePaymentMethodsModalWrapped: React.FC<iManagePaymentMethodsModalWrappedProps> = ({
  openAddModal,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const setDefaultRequest = usePutRequest();
  const propertyDispatch = usePropertyDispatch();

  const { request } = useListPaymentMethods(activeProperty._id);

  const isLoading = request.isLoading || setDefaultRequest.isLoading;

  const options = (
    dataKey: string,
    item: tPaymentMethod
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (item.id !== activeProperty[PROPERTY_STRIPE_ATTRIBUTE].defaultCard) {
      options.push({
        key: "default",
        label: trans("Set as default method"),
        onClick() {
          setDefaultRequest.pending();
          axios
            .put(
              constructApiAddress(
                `/v2/hotels/${activeProperty._id}/default-payment-method`,
                USE_MONOLITH_SERVERLESS
              ),
              {
                paymentMethodId: item.id,
              }
            )
            .then((res) => {
              const {
                data: { hotel },
              } = res;
              updateProperty(propertyDispatch, activeProperty._id, hotel);
              setDefaultRequest.resolve();
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              setDefaultRequest.reject(error);
              toaster.push(notification("error", error), {
                placement: "topEnd",
              });
            });
        },
      });
    }

    options.push({
      key: "remove",
      label: {
        color: COLORS.error,
        text: trans(
          "pages.settings.panels.billing.items.payment_methods.modals.manage_payment_methods.table.options.remove_method"
        ),
      },
      icon: { fill: COLORS.error, Element: DeleteIcon },
      onClick() {
        request.pending();
        axios
          .delete(ENDPOINTS.PROPERTIES.STRIPE.PAYMENT_METHODS.DELETE, {
            params: {
              stripeEnv: STRIPE_ENV,
              propertyId: activeProperty._id,
              paymentMethodId: item.id,
            },
          })
          .then((res) => {
            const {
              data: { paymentMethods, hotel },
            } = res;
            request.resolve({ paymentMethods });
            updateProperty(propertyDispatch, activeProperty._id, hotel);
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error);
          });
      },
    });

    return options;
  };

  const tableData: tTableItem[] = request.data.paymentMethods.map((pm) => ({
    ...pm,
    noytrall: {
      expires: pm.card
        ? moment().set({
            month: pm.card.exp_month - 1,
            year: pm.card.exp_year,
          })
        : 0,
    },
  }));
  const tableHeight = isLoading
    ? TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT.M
    : (tableData.length || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  return (
    <Flex column gap={20}>
      <PageSection title={trans("general.payment_methods")} icon={PaymentsIcon} />
      <div className="table-wrapper">
        <Table
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={tableData}
          loading={isLoading}
          renderEmpty={() => (
            <Flex center middle style={{ height: "100%" }}>
              <InterTag
                size={14}
                text={trans(
                  "pages.settings.panels.billing.items.payment_methods.modals.manage_payment_methods.no_cards"
                )}
                color={COLORS.secondary}
              />
            </Flex>
          )}
        >
          <Table.Column flexGrow={4}>
            <SimpleHeaderCell name={trans("general.card")} icon={VisaIcon} />
            <BaseCell>
              {(item: tPaymentMethod) => {
                return (
                  <Flex row gap={4}>
                    <InterTag
                      text={trans("Visa **** **** **** $0", {
                        parameters: [item.card?.last4 || ""],
                      })}
                      size={12}
                      color={COLORS.secondary}
                    />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell name={trans("general.expires")} />
            <SimpleKeyValueCell
              dataKey="expires"
              textFunction={(item: tTableItem) =>
                trans("$0/$1", {
                  parameters: [
                    moment(item.noytrall.expires).format("MM"),
                    moment(item.noytrall.expires).format("YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column flexGrow={2}>
            <SimpleHeaderCell name={trans("general.status")} />
            <BaseCell>
              {(item: tTableItem) => {
                if (item.id === activeProperty[PROPERTY_STRIPE_ATTRIBUTE].defaultCard)
                  return (
                    <div>
                      <Flex
                        center
                        middle
                        style={{
                          padding: "8px 8px",
                          borderRadius: "8px",
                          width: "fit-content",
                        }}
                        color={COLORS.gray_200}
                      >
                        <InterTag
                          color={COLORS.gray_600}
                          size={12}
                          text={trans("general.default")}
                        />
                      </Flex>
                    </div>
                  );
                return null;
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={""} />
            <SimpleActionCell options={options} dataKey="id" />
          </Table.Column>
        </Table>
      </div>
      <Button style={{ width: "fit-content" }} onClick={() => openAddModal()}>
        <Flex row gap={8} middle>
          <Icon Element={AddIcon} size={24} />
          <Icon Element={VisaIcon} size={24} />
          <InterTag text={trans("general.add_payment_method")} color={COLORS.secondary} size={14} />
        </Flex>
      </Button>
    </Flex>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  openAddModal(): void;
}

const ManagePaymentMethodsModal: React.FC<iProps> = ({ onClose, openAddModal, open, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, open, onClose, size: "md", ...props }}>
      {open && (
        <>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <ManagePaymentMethodsModalWrapped {...{ openAddModal }} />
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default ManagePaymentMethodsModal;
