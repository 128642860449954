import { ReactComponent as AddIcon } from "@/assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";
import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/meeting_room.svg";
import { ReactComponent as MoreVertIcon } from "@/assets/icons/more_vert.svg";
import Modal2ActionButtons from "@/components/Buttons/modal2ActionButtons";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import InputWrapper from "@/components/InputWrapper";
import PageSection from "@/components/PageSection";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "@/components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "@/components/Text/Inter";
import {
  updateProperty,
  updatingProperty,
  usePropertyDispatch,
  usePropertyState,
} from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePutRequest, { tUsePutRequest } from "@/hooks/apiRequests/usePutRequest";
import useSimpleToaster from "@/hooks/useSimpleToaster";
import { _sortBy, _toNumber, _uniq } from "@/lodash-utils";
import { tPropertySpaceId } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { propertyFindSpace } from "@/utils/property/spaces";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "@/utils/rsuite/modals";
import axios from "axios";
import { isUndefined, sortBy, uniqueId } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  Checkbox,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  ModalProps,
  TagPicker,
} from "rsuite";

const buildTransKey = (keyword: string) => `pages.spaces.modals.space.${keyword}`;

type tData = {
  _id: string;
  name: string;
  areaInSquareMeters: string;
  isAccommodation: boolean;
  tagList: string[];
};

type tErrors = Record<keyof tData, string>;

const initStateData = (name: string): tData => ({
  _id: uniqueId(),
  name,
  areaInSquareMeters: "",
  isAccommodation: false,
  tagList: [],
});
const initStateErrors = (): tErrors => ({
  _id: "",
  name: "",
  areaInSquareMeters: "",
  isAccommodation: "",
  tagList: "",
});

interface iSpaceModalWrappedProps {
  confirmRequest: tUsePutRequest;
  onClose(): void;
  spaceId?: tPropertySpaceId;
  spaceName?: string;
}

const SpaceModalWrapped: React.FC<iSpaceModalWrappedProps> = ({
  confirmRequest,
  onClose,
  spaceId,
  spaceName,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();
  const [state2, setState2] = useState<{
    selected: number;
    items: { data: tData; errors: tErrors }[];
  }>(() => {
    if (spaceId) {
      const space = propertyFindSpace(activeProperty, spaceId);
      if (space) {
        return {
          selected: 0,
          items: [
            {
              data: {
                _id: space._id,
                areaInSquareMeters: `${space.areaInSquareMeters || ""}`,
                isAccommodation: space.isAccommodation,
                measures: [],
                name: space.name,
                tagList: space.tagList || [],
              },
              errors: initStateErrors(),
            },
          ],
        };
      }
    }

    return {
      selected: 0,
      items: [
        {
          data: initStateData(`${trans("general.name")} #${uniqueId()}`),
          errors: initStateErrors(),
        },
      ],
    };
  });

  const onChange = (key: keyof Pick<tData, "name" | "areaInSquareMeters">) => (value: any) => {
    setState2((prev) => {
      const data = prev.items[prev.selected].data;
      data[key] = value;
      return { ...prev };
    });
  };

  const { data: activeSpace, errors: activeErrors } = state2.items[state2.selected];

  useEffect(() => {
    if (confirmRequest.isIdle) {
      if (activeSpace.name !== spaceName) {
        if (activeProperty.spaces.map((s) => s.name).includes(activeSpace.name)) {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSpace.name,
    confirmRequest.isIdle,
    spaceName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(sortBy(activeProperty.spaces.map((s) => s.name))),
  ]);

  const requestPending = confirmRequest.isLoading;

  const handleConfirm = () => {
    updatingProperty(propertyDispatch);
    confirmRequest.pending();

    (spaceId
      ? axios.patch(
          ENDPOINTS.PROPERTIES.SPACES.UPDATE,
          {
            _id: activeSpace._id,
            name: activeSpace.name,
            areaInSquareMeters: _toNumber(activeSpace.areaInSquareMeters || "0"),
            isAccommodation: activeSpace.isAccommodation,
            tagList: activeSpace.tagList,
          },
          { params: { propertyId: activeProperty._id } }
        )
      : axios.post(
          ENDPOINTS.PROPERTIES.SPACES.CREATE_SPACES,
          {
            items: state2.items.map(({ data }) => ({
              name: data.name,
              areaInSquareMeters: _toNumber(data.areaInSquareMeters || "0"),
              isAccommodation: data.isAccommodation,
              tagList: data.tagList,
            })),
          },
          { params: { propertyId: activeProperty._id } }
        )
    )
      .then((res) => {
        const {
          data: { property },
        } = res;
        if (spaceId) {
          toaster.success(trans("general.updated_x", { parameters: [trans("general.space")] }));
        } else {
          if (state2.items.length === 1) {
            toaster.success(
              trans("general.created_x", {
                parameters: [trans("general.space")],
              })
            );
          } else
            toaster.success(
              trans("general.created_multiple_x", {
                parameters: [trans("general.spaces")],
              })
            );
        }
        confirmRequest.resolve();
        updateProperty(propertyDispatch, activeProperty._id, property);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        try {
          const {
            data: { property },
          } = err.response;
          const error = getErrorMessage(err, trans);
          updateProperty(propertyDispatch, activeProperty._id, property);
          confirmRequest.reject(error, true);
        } catch (err) {}
        confirmRequest.reject("");
      });
  };

  const renderTabs = () => {
    const newTab = () => {
      if (state2.items.length === 20) {
        toaster.error(trans(buildTransKey("notifications.max_number_of_items")));
        return;
      }
      setState2((prev) => ({
        ...prev,
        items: [
          ...prev.items,
          {
            data: initStateData(`${trans("general.name")} #${uniqueId()}`),
            errors: initStateErrors(),
          },
        ],
        selected: prev.items.length,
      }));
    };

    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "remove-all-but-1",
        label: trans("components.tab.options.remove_all_but_1"),
        onClick: () => {
          setState2((prev) => {
            const item = prev.items[prev.selected];
            return { ...prev, items: [item], selected: 0 };
          });
        },
        show: state2.items.length > 1,
      },
    ];
    return (
      <div
        style={{ width: "100%", borderBottom: `${COLORS.gray_200} 1px solid` }}
        className={"horizontal-scrollbar"}
      >
        <Flex row>
          <Flex style={{ overflow: "auto", maxWidth: "90%" }} row>
            {state2.items.map((item, i) => {
              return (
                <Flex
                  middle
                  center
                  row
                  color={state2.selected === i ? COLORS.gray_200 : COLORS.gray_50}
                  gap={4}
                  key={item.data._id}
                  onClick={() => {
                    setState2((prev) => ({ ...prev, selected: i }));
                  }}
                  size="xs"
                  className="hover-gray-200"
                  style={{
                    padding: "4px 8px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <InterTag text={trans(item.data.name)} size={12} color={COLORS.secondary} />

                  {state2.items.length > 1 && (
                    <IconButton
                      className="hover-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setState2((prev) => {
                          const items = prev.items;
                          items.splice(i, 1);
                          return {
                            ...prev,
                            items: [...items],
                            selected:
                              prev.selected >= items.length ? prev.selected - 1 : prev.selected,
                          };
                        });
                      }}
                      style={{ padding: "3px" }}
                      circle
                      size="xs"
                      appearance="subtle"
                      icon={<Icon Element={CloseIcon} fill={COLORS.secondary} size={12} />}
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Flex middle center row gap={4} style={{ width: "10%" }}>
            <IconButton
              size="xs"
              icon={<Icon Element={AddIcon} fill={COLORS.secondary} size={16} />}
              onClick={newTab}
            />
            {options.filter((o) => o.show).length > 0 && (
              <SimpleWhisperPopoverDropdown options={options}>
                <IconButton
                  size="xs"
                  icon={<Icon Element={MoreVertIcon} fill={COLORS.secondary} size={16} />}
                />
              </SimpleWhisperPopoverDropdown>
            )}
          </Flex>
        </Flex>
      </div>
    );
  };

  const confirmButtonDisabled =
    Object.values(activeErrors).filter((v) => v.length).length > 0 || activeSpace.name.length === 0;

  return (
    <Fragment>
      <Modal.Header closeButton={!confirmRequest.isLoading}></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <PageSection
            title={trans(spaceId ? "general.update_x" : "general.new_x", {
              parameters: [trans("general.space")],
            })}
            icon={{ Element: MeetingRoomIcon }}
          />
          {isUndefined(spaceId) && (
            <Flex column gap={8}>
              {renderTabs()}
              <Flex column gap={4}></Flex>
            </Flex>
          )}
          <Flex row gap={60}>
            <Flex column gap={8} basis={50}>
              <InputWrapper
                label={{ text: trans("general.name") }}
                error={{ text: activeErrors.name }}
              >
                <Input
                  type="text"
                  size="md"
                  disabled={requestPending}
                  value={activeSpace.name}
                  onChange={onChange("name")}
                  placeholder={trans(buildTransKey("inputs.name.placeholder"))}
                />
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.area") }}
                error={{ text: activeErrors.areaInSquareMeters }}
              >
                <InputGroup>
                  <InputNumber
                    disabled={requestPending}
                    min={0}
                    value={activeSpace.areaInSquareMeters}
                    placeholder={"123"}
                    onChange={onChange("areaInSquareMeters")}
                  />
                  <InputGroup.Addon>
                    m<sup>2</sup>
                  </InputGroup.Addon>
                </InputGroup>
              </InputWrapper>
              <InputWrapper label={trans("general.tags")} error={{ text: activeErrors.tagList }}>
                <TagPicker
                  creatable
                  data={_sortBy(
                    _uniq([
                      ...activeSpace.tagList,
                      ...activeProperty.spaces.flatMap((s) => s.tagList),
                    ]) as string[],
                    (v: string) => v.toLowerCase()
                  ).map((t) => ({ value: t, label: t }))}
                  value={activeSpace.tagList}
                  onChange={(value) => {
                    setState2((prev) => {
                      prev.items[prev.selected].data.tagList = value;
                      return { ...prev };
                    });
                  }}
                />
              </InputWrapper>
              <Checkbox
                disabled={requestPending}
                checked={activeSpace.isAccommodation}
                onChange={(value, checked) => {
                  setState2((prev) => {
                    prev.items[prev.selected].data.isAccommodation = checked;
                    return { ...prev };
                  });
                }}
              >
                {trans("pages.spaces.modals.space.is_accommodation")}
              </Checkbox>
            </Flex>
          </Flex>
          <Modal2ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            isLoading={requestPending}
            confirmButtonDisabled={confirmButtonDisabled}
          />
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iSpaceModalProps extends ModalProps {
  onClose(): void;
  spaceId?: string;
  spaceName?: string;
}

const SpaceModal: React.FC<iSpaceModalProps> = ({ spaceId, spaceName, onClose, ...props }) => {
  const confirmRequest = usePutRequest();

  return (
    <Modal
      id="space-modal"
      size="md"
      {...{
        ...DEFAULT_MODAL_PROPS,
        ...props,
        overflow: true,
        onClose,
      }}
    >
      <SpaceModalWrapped
        {...{
          confirmRequest,
          onClose,
          spaceId,
          spaceName,
        }}
      />
    </Modal>
  );
};

export default SpaceModal;
