import React from "react";
import { Button } from "rsuite";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { tIcon, tIconElement } from "@/interfaces/icon";
import { COLORS } from "@/utils/colors";
import Flex from "../Flex";
import { toIconProps } from "../Icons/Icon/utils";
import SimpleButton from "../RsuiteWrapper/SimpleButton";
import InterTag from "../Text/Inter";

interface iProps {
  onClose(): void;
  onConfirm(): void;
  isLoading?: boolean;
  disabled?: boolean;
  confirmButtonDisabled?: boolean;
  confirmLabel?: string;
  closeLabel?: string;
  confirmIcon?: tIcon | tIconElement;
}

const Modal2ActionButtons: React.FC<iProps> = ({
  onClose,
  onConfirm,
  isLoading,
  disabled,
  confirmButtonDisabled,
  confirmLabel,
  closeLabel,
  confirmIcon,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Flex row center gap={4}>
      <Button onClick={onClose} appearance="link" disabled={disabled || isLoading}>
        <InterTag
          text={closeLabel || trans("general.discard_and_close")}
          color={COLORS.primary}
          size={14}
        />
      </Button>
      <SimpleButton
        appearance="primary"
        onClick={onConfirm}
        text={{
          text: confirmLabel || trans("general.confirm"),
          color: COLORS.white,
          size: 14,
        }}
        loading={isLoading}
        disabled={disabled || confirmButtonDisabled}
        icon={
          confirmIcon
            ? {
                fill: COLORS.white,
                size: 20,
                ...toIconProps(confirmIcon),
              }
            : undefined
        }
      />
    </Flex>
  );
};

export default Modal2ActionButtons;
