import Flex from "@/components/Flex";
import SimpleButton from "@/components/RsuiteWrapper/SimpleButton";
import InterTag from "@/components/Text/Inter";
import { updateProperty, usePropertyDispatch, usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import usePutRequest from "@/hooks/apiRequests/usePutRequest";
import { tPropertyBilling, tPropertySubscriptionModel } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import { COLORS } from "@/utils/colors";
import { BILLING_TYPES } from "@/utils/property";
import { propertyFindActiveBillingInfo } from "@/utils/property/billing";
import {
  propertyFindActiveSubscription,
  propertyFindActiveSubscriptionType,
} from "@/utils/property/subscriptions";
import { getErrorMessage } from "@/utils/httpResponses/others";
import { notification } from "@/utils/notifications";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SelectPicker, useToaster } from "rsuite";
import styles from "../styles.module.css";
import EditBillingDetailsModal from "./editBillingDetailsModal";
import PaymentMethods from "./paymentMethods";

interface iProps {}

const BillingSettings: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const propertyDispatch = usePropertyDispatch();

  const activeSubscription = propertyFindActiveSubscription(activeProperty);
  const activeSubscriptionType = propertyFindActiveSubscriptionType({
    subscription: activeSubscription,
  });
  const [modalBillingDetails, setModalBillingDetails] = useState(false);
  const request = usePutRequest();

  const updateSubscriptionModel = (type: tPropertySubscriptionModel["type"]) => {
    if (request.isLoading) return;
    request.pending();
    axios
      .post(
        ENDPOINTS.PROPERTIES.SUBSCRIPTIONS.FAST_UPDATE,
        {
          type,
        },
        { params: { propertyId: activeProperty._id } }
      )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateProperty(propertyDispatch, activeProperty._id, property);
        request.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const translateBillingType: Record<tPropertySubscriptionModel["type"], string> = {
    business: trans("general.subscription_types.business"),
    enterprise: trans("general.subscription_types.enterprise"),
    starter: trans("general.subscription_types.starter"),
  };

  const renderBillingInfo = () => {
    const entries: {
      label: string;
      key: keyof tPropertyBilling;
    }[] = [
      { label: trans("general.name"), key: "name" },
      { label: trans("general.email"), key: "email" },
      { label: trans("general.address"), key: "address" },
      { label: trans("general.phone"), key: "phone" },
      { label: trans("general.tax_id"), key: "taxId" },
    ];

    const activeBillingInfo = propertyFindActiveBillingInfo(activeProperty);
    return (
      <Fragment>
        <EditBillingDetailsModal
          open={modalBillingDetails}
          onClose={() => setModalBillingDetails(false)}
        />
        <Flex
          id="settings-billing-details"
          row
          between
          middle
          className={[styles["settings-item"], styles["settings-item--not-last"]].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("pages.settings.panels.billing.items.billing_details.label")}
            color={COLORS.secondary}
          />

          <Button
            onClick={() => setModalBillingDetails(true)}
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
          >
            {trans("general.edit")}
          </Button>
        </Flex>
        {entries.map(({ key, label }) => {
          return (
            <Flex
              key={key}
              row
              between
              middle
              className={[
                styles["settings-item"],
                styles["settings-item-text"],
                styles["settings-item--not-last"],
              ].join(" ")}
            >
              <InterTag size={14} color={COLORS.secondary} text={label} />
              <InterTag
                size={14}
                color={COLORS.secondary}
                text={activeBillingInfo && activeBillingInfo[key] ? activeBillingInfo[key] : "-"}
              />
            </Flex>
          );
        })}
      </Fragment>
    );
  };

  const renderCurrentPlans = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-plan"
          row
          between
          middle
          className={[styles["settings-item"], styles["settings-item--not-last"]].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("pages.settings.panels.billing.items.current_plan.label")}
            color={COLORS.secondary}
          />
          <SimpleButton
            onClick={() => navigate("/settings/billing/plan")}
            text={{ text: trans("general.manage"), color: COLORS.primary }}
            appearance="subtle"
          />
        </Flex>
        {activeSubscription && (
          <Flex
            row
            between
            middle
            className={[
              styles["settings-item"],
              styles["settings-item-text"],
              styles["settings-item--not-last"],
            ].join(" ")}
          >
            <InterTag
              text={translateBillingType[activeSubscription.type]}
              size={14}
              color={COLORS.secondary}
            />
            <Flex column gap={4} right>
              <InterTag
                text={
                  activeSubscription.endAt
                    ? trans("pages.settings.panels.billing.items.current_plan.renews_on_x_date", {
                        parameters: [moment(activeSubscription.endAt).format("MMMM DD, YYYY")],
                      })
                    : "-"
                }
                size={14}
                color={COLORS.secondary}
              />
            </Flex>
          </Flex>
        )}
      </Fragment>
    );
  };

  const renderPaymentMethods = () => {
    return <PaymentMethods />;
  };

  const renderInvoices = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-invoices"
          row
          between
          middle
          className={[styles["settings-item"], styles["settings-item--not-last"]].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("pages.settings.panels.billing.items.invoices.label")}
            color={COLORS.secondary}
          />

          <Button
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
            onClick={() => navigate("/settings/billing/invoices")}
          >
            {trans("general.see_all")}
          </Button>
        </Flex>
      </Fragment>
    );
  };
  const renderQuickUpdateSubscriptionModel = () => {
    if (
      ["3dad4406-fe1e-4ece-8ba0-04a04a0396a5", "178b524f-9095-47ad-9d6b-f8875888338b"].includes(
        activeProperty._id
      )
    )
      return (
        <Flex row between middle className={[styles["settings-item"]].join(" ")}>
          <InterTag
            size={14}
            bold
            text={trans("pages.settings.panels.billing.items.subscription_model.label")}
            color={COLORS.secondary}
          />
          <SelectPicker
            style={{ width: "150px" }}
            placement="leftStart"
            searchable={false}
            loading={request.isLoading}
            data={BILLING_TYPES.map((t) => ({
              label: translateBillingType[t],
              value: t,
            }))}
            value={activeSubscriptionType}
            onChange={(t) => {
              if (t && BILLING_TYPES.includes(t) && t !== activeSubscriptionType) {
                updateSubscriptionModel(t);
              }
            }}
            cleanable={false}
            disabled={request.isLoading}
          />
        </Flex>
      );
    return null;
  };

  return (
    <div>
      {renderBillingInfo()}
      {renderCurrentPlans()}
      {renderPaymentMethods()}
      {renderInvoices()}
      {renderQuickUpdateSubscriptionModel()}
    </div>
  );
};

export default BillingSettings;
