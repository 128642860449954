import { ReactComponent as NoytrallSymbolIcon } from "@/assets/noytrall_symbol.svg";
import Icon from "@/components/Icons/Icon";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { useBusinessSubscriptionPrices } from "@/hooks/subscriptions";
import { _round } from "@/lodash-utils";
import { COLORS } from "@/utils/colors";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import { buildTransKey } from "./utils";

// const TAX_PERCENTAGE = 0.23;
const TAX_PERCENTAGE = 0;

const useCalculatePrice = (
  promotion: { code: string; amountOff: number; percentOff: number } | null,
  billingTimePeriod: "year" | "month"
) => {
  const pricesRequest = useBusinessSubscriptionPrices();
  const [price, setPrice] = useState(() => {
    return { final: 0, base: 0, tax: 0, afterDiscount: 0, amountOff: 0, percentOff: 0 };
  });

  useEffect(() => {
    const { amountOff, percentOff } = promotion || { amountOff: 0, percentOff: 0 };

    if (pricesRequest.isSuccess) {
      const base = pricesRequest.data.pricesInCents[billingTimePeriod] / 100;

      const afterDiscount = _round(
        amountOff ? base - amountOff / 100 : percentOff ? (base * (100 - percentOff)) / 100 : base,
        2
      );

      const tax = afterDiscount * TAX_PERCENTAGE;
      const final = afterDiscount + tax;
      setPrice({ final, base, afterDiscount, tax, amountOff, percentOff });
    }
  }, [billingTimePeriod, promotion, pricesRequest.data, pricesRequest.isSuccess]);

  return { price, pricesRequest };
};

interface iProps {
  billingTimePeriod: "year" | "month";
  promotion: { code: string; amountOff: number; percentOff: number } | null;
  removePromotion: () => void;
}

const InfoCard: React.FC<iProps> = ({ billingTimePeriod, promotion, removePromotion }) => {
  const { price, pricesRequest } = useCalculatePrice(promotion, billingTimePeriod);
  const { trans } = useLocalizationState();

  let JSX_finalPriceBig = <SkeletonText height={48} width={100} />;
  let JSX_finalPriceSmall = <SkeletonText height={16} width={50} />;
  let JSX_afterTax = <SkeletonText height={16} width={50} />;
  let JSX_afterDiscount = <SkeletonText height={16} width={50} />;
  let JSX_base = <SkeletonText height={16} width={50} />;
  if (pricesRequest.isSuccess && !pricesRequest.isFetching) {
    JSX_finalPriceBig = (
      <InterTag
        text={`${price.final.toFixed(2)}`.replace(".", ",")}
        size={48}
        color={COLORS.secondary}
        bold
      />
    );
    JSX_finalPriceSmall = (
      <InterTag size={16} bold color={COLORS.secondary} text={`${price.final.toFixed(2)} €`} />
    );
    JSX_afterTax = (
      <InterTag size={16} color={COLORS.secondary} text={`${price.tax.toFixed(2)} €`} />
    );
    JSX_afterDiscount = (
      <InterTag
        size={16}
        bold
        color={COLORS.secondary}
        text={`€ ${price.afterDiscount.toFixed(2)}`}
      />
    );
    JSX_base = (
      <InterTag size={16} bold color={COLORS.secondary} text={`${price.base.toFixed(2)} €`} />
    );
  }

  const renderPromotion = () => {
    if (!promotion?.code) return null;
    return (
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-1 items-center">
          <InterTag text={trans("general.voucher")} size={16} bold color={COLORS.secondary} />
          <InterTag text={`"${promotion.code}"`} size={14} color={COLORS.secondary} />
          <Button
            color="red"
            size="xs"
            appearance="primary"
            onClick={removePromotion}
            className="h-fit"
          >
            <p className="text-white">{trans("general.remove")}</p>
          </Button>
        </div>
        <div className="flex flex-row items-center">
          <InterTag
            size={16}
            bold
            color={COLORS.error}
            text={
              price.amountOff
                ? `- ${(price.amountOff / 100).toFixed(2)} €`
                : `- ${price.percentOff}% = - ${(price.base - price.afterDiscount).toFixed(2)} €`
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="card-m flex flex-col gap-10">
      <div className="flex flex-row gap-2 items-center">
        <Icon Element={NoytrallSymbolIcon} size={24} />
        <InterTag
          text={trans("Noytrall Technologies, Lda.")}
          size={16}
          color={COLORS.secondary}
          bold
        />
      </div>
      <div className="flex flex-col gap-3">
        <InterTag
          text={`${trans("general.subscribe_x", {
            parameters: [trans("general.noytrall.business")],
          })}`}
          bold
          color={COLORS.secondary}
          size={20}
        />
        <div className="flex flex-row gap-2 items-end">
          {JSX_finalPriceBig}
          <InterTag text={"€"} size={48} color={COLORS.secondary} bold />
          <InterTag
            text={trans(billingTimePeriod === "month" ? "general.monthly" : "general.annually")}
            size={16}
            color={COLORS.secondary}
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row justify-between">
          <InterTag
            text={trans("general.noytrall.business")}
            size={16}
            bold
            color={COLORS.secondary}
          />
          {JSX_base}
        </div>
        {renderPromotion()}
        <InterTag
          size={16}
          color={COLORS.secondary}
          text={trans(buildTransKey("sustainability_platform"))}
        />
        <InterTag
          size={16}
          color={COLORS.secondary}
          text={trans(
            buildTransKey(billingTimePeriod === "month" ? "billed_monthly" : "billed_annually")
          )}
        />
        <div
          style={{
            height: "19px",
            borderBottom: `1px solid ${COLORS.secondary}`,
          }}
        />
        <div className="flex flex-row justify-between">
          <InterTag text={trans("general.subtotal")} size={16} bold color={COLORS.secondary} />
          {JSX_afterDiscount}
        </div>
        {TAX_PERCENTAGE > 0 && (
          <div className="flex flex-row justify-between">
            <InterTag
              text={trans("general.tax_x%", { parameters: [TAX_PERCENTAGE * 100] })}
              size={16}
              color={COLORS.secondary}
            />
            {JSX_afterTax}
          </div>
        )}
        <div
          style={{
            height: "19px",
            borderBottom: `1px solid ${COLORS.secondary}`,
          }}
        />
        <div className="flex flex-row justify-between">
          <InterTag
            text={trans(buildTransKey("amount_due"))}
            size={16}
            bold
            color={COLORS.secondary}
          />
          {JSX_finalPriceSmall}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
