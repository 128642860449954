import { ReactComponent as AccountBalanceIcon } from "@/assets/icons/account_balance.svg";
import { ReactComponent as DeleteIcon } from "@/assets/icons/delete.svg";
import { ReactComponent as ElectricBoltIcon } from "@/assets/icons/electric_bolt.svg";
import { ReactComponent as HandshakeIcon } from "@/assets/icons/handshake.svg";
import { ReactComponent as ModeHeatIcon } from "@/assets/icons/mode_heat.svg";
import { ReactComponent as PsychiatryIcon } from "@/assets/icons/psychiatry.svg";
import { ReactComponent as ShoppingCartIcon } from "@/assets/icons/shopping_cart.svg";
import { ReactComponent as WaterDropIcon } from "@/assets/icons/water_drop.svg";
import { ReactComponent as NoytrallIcon } from "@/assets/noytrall_symbol.svg";
import { DEFAULT_LANGAUGE } from "@/context/Localization/utils";
import { tIconElement } from "@/interfaces/icon";
import { tPropertyRoadmap } from "@/models/property";
import { COLORS } from "../colors";
import { tLanguageCode } from "../languages";
import { _find } from "@/lodash-utils";

export const ROADMAP_TYPES: tPropertyRoadmap["type"][] = [
  "electricity",
  "environment",
  "fuels",
  "governance",
  "social",
  "supply chain",
  "waste",
  "water",
];

export const propertyRoadmapTypeInfo = (
  type: tPropertyRoadmap["type"]
): { color: string; transKey: string; icon: tIconElement } => {
  switch (type) {
    case "environment":
      return {
        color: COLORS.esg_environment,
        transKey: "general.environment",
        icon: PsychiatryIcon,
      };
    case "governance":
      return {
        color: COLORS.esg_governance,
        transKey: "general.governance",
        icon: AccountBalanceIcon,
      };
    case "social":
      return {
        color: COLORS.esg_social,
        transKey: "general.social",
        icon: HandshakeIcon,
      };
    case "supply chain":
      return {
        color: COLORS.esg_supply_chain,
        transKey: "general.supply_chain",
        icon: ShoppingCartIcon,
      };
    case "electricity":
      return {
        color: COLORS.energy,
        transKey: "general.measures_.electricity",
        icon: ElectricBoltIcon,
      };
    case "water":
      return {
        color: COLORS.water,
        transKey: "general.measures_.water",
        icon: WaterDropIcon,
      };
    case "waste":
      return {
        color: COLORS.waste,
        transKey: "general.waste",
        icon: DeleteIcon,
      };
    case "fuels":
      return {
        color: COLORS.fuels,
        transKey: "general.fuels",
        icon: ModeHeatIcon,
      };
  }
  return { color: "", transKey: "", icon: NoytrallIcon };
};

export const ROADMAP_PHASE_NUMBERS: tPropertyRoadmap["phaseNumber"][] = [1, 2, 3, 4, 5, 6];

export const ROADMAP_DIFFICULTIES: tPropertyRoadmap["difficulty"][] = ["low", "medium", "high"];

export const PROPERTY_ROADMAP_STATUSES: tPropertyRoadmap["status"][] = [
  "idle",
  "in progress",
  "completed",
];

export const propertyRoadmapPhaseNumberInfo = (phaseNumber: number): { transKey: string } => {
  switch (phaseNumber) {
    case 1:
      return { transKey: "components.roadmap.item.phase.1" };
    case 2:
      return { transKey: "components.roadmap.item.phase.2" };
    case 3:
      return { transKey: "components.roadmap.item.phase.3" };
    case 4:
      return { transKey: "components.roadmap.item.phase.4" };
    case 5:
      return { transKey: "components.roadmap.item.phase.5" };
    case 6:
      return { transKey: "components.roadmap.item.phase.6" };
    default:
      return { transKey: "" };
  }
};

export const propertyRoadmapDifficultyInfo = (
  difficulty: tPropertyRoadmap["difficulty"]
): { badgeColor: string; transKey: string } => {
  switch (difficulty) {
    case "low":
      return {
        badgeColor: "green",
        transKey: "components.roadmap.item.difficulty.low",
      };
    case "medium":
      return {
        badgeColor: "yellow",
        transKey: "components.roadmap.item.difficulty.medium",
      };
    case "high":
      return {
        badgeColor: "red",
        transKey: "components.roadmap.item.difficulty.high",
      };
    default:
      return { badgeColor: "", transKey: "" };
  }
};

export const propertyRoadmapStatusInfo = (
  status: tPropertyRoadmap["status"]
): { transKey: string; color: string; backgroundColor: string } => {
  switch (status) {
    case "in progress":
      return {
        transKey: "components.roadmap.item.status.in progress",
        color: COLORS.info,
        backgroundColor: "rgba(33, 150, 243, 0.15)",
      };
    case "completed":
      return {
        transKey: "components.roadmap.item.status.completed",
        color: COLORS.success,
        backgroundColor: "rgba(76, 175, 80, 0.15)",
      };
    case "idle":
      return {
        transKey: "components.roadmap.item.status.idle",
        color: COLORS.esg_supply_chain,
        backgroundColor: "rgba(237, 134, 84, 0.21)",
      };

    default:
      return { backgroundColor: "", color: "", transKey: "" };
  }
};

export const getRoadmapDescription = (
  roadmap: tPropertyRoadmap,
  language: tLanguageCode | string
): { description: string } | undefined => {
  return (
    _find(roadmap.descriptions, (d) => d.language === language) ||
    _find(roadmap.descriptions, (d) => d.language === DEFAULT_LANGAUGE) ||
    roadmap.descriptions[0]
  );
};
