import { ReactComponent as KeyboardArrowRightIcon } from "@/assets/icons/keyboard_arrow_right.svg";
import { ReactComponent as TuneIcon } from "@/assets/icons/tune.svg";
import Flex from "@/components/Flex";
import Icon from "@/components/Icons/Icon";
import InterTag from "@/components/Text/Inter";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { _find } from "@/lodash-utils";
import { COLORS } from "@/utils/colors";
import { ELEMENT_IDS } from "@/utils/portal";
import { renderRequestedData } from "@/utils/render";
import React, { useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton, Loader } from "rsuite";

const PAGE_TITLES: {
  pathNameRegex: RegExp;
  labelTransKey?: string;
  parentPages?: { transKey: string; to: string }[];
  withPortal?: boolean;
}[] = [
  {
    pathNameRegex: new RegExp("properties"),
    withPortal: true,
  },
  {
    pathNameRegex: new RegExp("overview"),
    withPortal: true,
  },
  {
    pathNameRegex: new RegExp("overview"),
    withPortal: true,
  },
  {
    pathNameRegex: new RegExp("sustainability"),
    labelTransKey: "general.sustainability",
  },
  {
    pathNameRegex: new RegExp("spaces/.+"),
    withPortal: true,
  },
  {
    pathNameRegex: new RegExp("spaces"),
    labelTransKey: "general.spaces",
  },
  {
    pathNameRegex: new RegExp("guests"),
    labelTransKey: "general.guests",
  },
  {
    pathNameRegex: new RegExp("occupancy"),
    labelTransKey: "general.occupancy",
  },
  {
    pathNameRegex: new RegExp("consumption/electricity"),
    labelTransKey: "general.measures_.electricity",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption/water"),
    labelTransKey: "general.measures_.water",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption/fuels"),
    labelTransKey: "general.fuels",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption/waste"),
    labelTransKey: "general.waste",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption/laundry"),
    labelTransKey: "general.laundry",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption/fleet"),
    labelTransKey: "general.fleet",
    parentPages: [{ transKey: "general.consumption", to: "/consumption" }],
  },
  {
    pathNameRegex: new RegExp("consumption"),
    labelTransKey: "general.consumption",
  },
  {
    pathNameRegex: new RegExp("awards"),
    labelTransKey: "general.awards",
  },
  {
    pathNameRegex: new RegExp("challenges"),
    labelTransKey: "general.challenges",
  },
  {
    pathNameRegex: new RegExp("loyalty"),
    labelTransKey: "general.loyalty",
  },
  {
    pathNameRegex: new RegExp("hotel-profile"),
    labelTransKey: "general.profile",
  },
  {
    pathNameRegex: new RegExp("roadmap"),
    labelTransKey: "general.roadmap",
  },
  {
    pathNameRegex: new RegExp("users/.+"),
    withPortal: true,
  },
  {
    pathNameRegex: new RegExp("users"),
    labelTransKey: "general.users",
  },
  {
    pathNameRegex: new RegExp("settings/billing/plan"),
    labelTransKey: "general.plan",
    parentPages: [
      { transKey: "general.settings", to: "/settings" },
      { transKey: "general.billing", to: "/settings#settings-billing" },
    ],
  },
  {
    pathNameRegex: new RegExp("settings/billing/invoices"),
    labelTransKey: "general.invoices",
    parentPages: [
      { transKey: "general.settings", to: "/settings" },
      { transKey: "general.billing", to: "/settings#settings-billing" },
    ],
  },
  {
    pathNameRegex: new RegExp("settings"),
    labelTransKey: "general.settings",
  },
  {
    pathNameRegex: new RegExp("/"),
    withPortal: true,
  },
];

interface iProps {}

const DesktopRootContent: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const location = useLocation();
  const outletRef = useRef<HTMLDivElement>(null);

  const {
    state: { status },
  } = usePropertyState();

  const icons = [
    {
      label: "Settings",
      Element: TuneIcon,
      onClick: () => {
        navigate("/settings");
      },
      hide: ["/settings"].includes(location.pathname),
    },
  ]
    .filter((e) => !e.hide)
    .map(({ Element, onClick, label }) => (
      <IconButton
        key={label}
        onClick={onClick}
        size="xs"
        appearance="subtle"
        circle
        icon={<Icon Element={Element} size={24} fill={COLORS.secondary} />}
      />
    ));

  const mainRender = () => {
    if (["/settings", "/properties"].includes(location.pathname)) {
      return <Outlet />;
    }

    return renderRequestedData(status, {
      resolved: () => <Outlet />,
      pending: () => (
        <div className="flex flex-col h-full justify-center items-center">
          <Loader size="lg" />
        </div>
      ),
      idle: () => (
        <div className="flex flex-col h-full justify-center items-center">
          <Loader size="lg" />
        </div>
      ),
      rejected: () => <Flex></Flex>,
    });
  };

  const pageTitle = _find(PAGE_TITLES, (pt) => pt.pathNameRegex.test(location.pathname));
  const renderPageName = () => {
    if (!pageTitle || pageTitle.withPortal) return null;

    const pageJSX = (
      <InterTag
        {...{
          size: 24,
          bold: true,
          asHTML: true,
          text: trans(pageTitle.labelTransKey || ""),
        }}
      />
    );
    const title =
      pageTitle.parentPages && pageTitle.parentPages.length ? (
        <div className="flex flex-col gap-2 justify-center">
          <div className="flex flex-row gap-1.5 items-center">
            {pageTitle.parentPages
              .flatMap(({ to, transKey }) => [
                <InterTag
                  key={transKey}
                  hoverUnderline
                  onClick={() => {
                    navigate(to);
                  }}
                  text={trans(transKey)}
                  size={12}
                  color={COLORS.secondary}
                />,
                <Icon key={`${transKey}-arrow`} Element={KeyboardArrowRightIcon} size={16} />,
              ])
              .slice(0, -1)}
          </div>
          {pageJSX}
        </div>
      ) : (
        pageJSX
      );
    return title;
  };

  return (
    <div className="w-full h-full flex justify-center" id="root-outlet-container">
      <div id="outlet" className="w-full relative" ref={outletRef}>
        <div className="w-full h-[60px] z-10 bg-app_background">
          <div
            style={{
              width: outletRef.current?.clientWidth,
            }}
            className="fixed top-0 z-30 bg-app_background h-[60px] flex justify-center"
          >
            <div className="w-[90%] h-full flex flex-row justify-between items-center">
              <div className="flex flex-row gap-8 items-center">
                {pageTitle?.withPortal ? (
                  <div id={ELEMENT_IDS.topbarContainerPageName}></div>
                ) : (
                  <div>{renderPageName()}</div>
                )}
                <div id={ELEMENT_IDS.topbarContainerExtra}></div>
              </div>
              {icons.length > 0 && (
                <Flex row gap={40}>
                  {icons}
                </Flex>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-[90%]">
            <div>{mainRender()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopRootContent;
