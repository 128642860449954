import { ReactComponent as MeetingRoomIcon } from "@/assets/icons/meeting_room.svg";
import { ReactComponent as RoomPreferencesIcon } from "@/assets/icons/room_preferences.svg";
import bySpacePlaceholder from "@/assets/placeholders/consumption-by-space.png";
import spacesManagementPlaceholder from "@/assets/placeholders/spaces-management.png";
import Flex from "@/components/Flex";
import IpValidatorGuard from "@/components/Guards/IpValidatorGuard";
import PageBottomPadding from "@/components/PageBottomPadding";
import NoPermissions from "@/components/Pages/NoPermissions";
import PageSection from "@/components/PageSection";
import UnlockPageSection from "@/components/UnlockPageSection";
import { usePropertyState } from "@/context/Property/hooks";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { hasPermission } from "@/context/Permissions";
import usePermissions from "@/context/Permissions/hooks";
import useElementForPortal from "@/hooks/useElementForPortal";
import { propertyActiveSubscriptionTypeIsStarter } from "@/utils/property/subscriptions";
import { ELEMENT_IDS } from "@/utils/portal";
import React from "react";
import { createPortal } from "react-dom";
import PageTitle from "../root/components/pageTitle";
import AccommodationMeasures from "./accommodationMeasures";
import AddButton from "./addButton";
import SpacesTab from "./spacesTab";

const SpacesWrapped: React.FC = () => {
  const topbarContainer = useElementForPortal(ELEMENT_IDS.topbarContainerExtra);
  return (
    <div className="flex flex-col gap-4">
      {topbarContainer &&
        createPortal(
          <div className="flex justify-end">
            <AddButton />
          </div>,
          topbarContainer
        )}
      <SpacesTab />
    </div>
  );
};

const SpacesGuarded: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = usePropertyState();
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const permissionsList = usePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Flex column gap={40}>
          <AccommodationMeasures />
          <Flex column gap={16}>
            <PageSection
              title={trans("pages.spaces.unlock.consumption.title")}
              description={trans("pages.spaces.unlock.consumption.description")}
              icon={MeetingRoomIcon}
            />
            <UnlockPageSection
              image={bySpacePlaceholder}
              title={trans("pages.spaces.unlock.consumption.unlock.title")}
              description={[0].map((i) =>
                trans(`pages.spaces.unlock.consumption.unlock.description.${i}`)
              )}
              style={{ paddingBottom: "200px" }}
            />
          </Flex>
          <Flex column gap={16}>
            <PageSection
              title={trans("pages.spaces.unlock.management.title")}
              description={trans("pages.spaces.unlock.management.description")}
              icon={RoomPreferencesIcon}
            />
            <UnlockPageSection
              image={spacesManagementPlaceholder}
              title={trans("pages.spaces.unlock.management.unlock.title")}
              description={[0].map((i) =>
                trans(`pages.spaces.unlock.management.unlock.description.${i}`)
              )}
            />
          </Flex>
        </Flex>
        <PageBottomPadding />
      </>
    );

  if (!hasPermission(permissionsList, "read:spaces")) return <NoPermissions />;

  return <SpacesWrapped />;
};

const Spaces: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <div>
      <PageTitle page={trans("general.spaces")} />
      <IpValidatorGuard>
        <SpacesGuarded />
      </IpValidatorGuard>
    </div>
  );
};

export default Spaces;
