import PageSectionBG from "@/components/PageSection/PageSectionBG";
import SimpleButton from "@/components/RsuiteWrapper/SimpleButton";
import SkeletonText from "@/components/Skeleton/SkeletonText";
import TableHeaders from "@/components/Tables/TableHeaders";
import InterTag from "@/components/Text/Inter";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import { ProfileStateContext } from "@/context/Profile";
import { setActiveProperty, useProfileDispatch, useProfileState } from "@/context/Profile/hooks";
import { usePropertyState } from "@/context/Property/hooks";
import { nPropertyContext } from "@/context/Property/interfaces";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import { _round } from "@/lodash-utils";
import { tPropertyId } from "@/models/property";
import { ENDPOINTS } from "@/utils/api/endpoints";
import cx from "@/utils/classnames";
import { COLORS } from "@/utils/colors";
import { convertEnergyUnit, convertMassUnit, convertVolumeUnit } from "@/utils/convertUnits";
import { nUnitConverter } from "@/utils/convertUnits/interfaces";
import { numberFormatter } from "@/utils/numbers";
import { TABLE_ROW_CLASSNAMES, TABLE_ROWS_CONTAINER_CLASSNAMES } from "@/utils/tables";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { deburr, sortBy, toLower } from "lodash";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../root/components/pageTitle";

const COLUMNS = [
  { key: "image", label: "", className: "basis-1/5 sm:basis-1/12 " },
  { key: "name", label: "general.property", className: "sm:basis-3/12 " },
  {
    key: "location",
    label: "general.location",
    className: "hidden sm:inline sm:basis-2/12 ",
  },
  {
    key: "footprint",
    label: "general.co_2_emissions",
    className: "basis-28",
  },
  {
    key: "electricity",
    label: "general.measures_.electricity",
    className: "basis-20",
  },
  {
    key: "water",
    label: "general.measures_.water",
    className: "basis-20",
  },
];

type tPropertysConsumptionRequest = Record<tPropertyId, Record<"e" | "w", number>>;

const Rows: React.FC<{
  hotels: nPropertyContext.tPropertyAugmented[];
}> = ({ hotels }) => {
  const { profile } = useProfileState();
  const sorted = sortBy(hotels, (h) => toLower(deburr(h.name)));
  const dispatch = useProfileDispatch();
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT");

  const measuresRequest = useQuery<tPropertysConsumptionRequest>({
    queryKey: ["measures-all-properties"],
    staleTime: 1000 * 60 * 10,
    queryFn: async ({ signal }) => {
      return axios
        .get(ENDPOINTS.MANAGERS.PROPERTIES.CONSUMPTION, {
          signal,
        })
        .then((res) => res.data.properties);
    },
  });

  const renderValue = (
    property: nPropertyContext.tPropertyAugmented,
    key: "e" | "w",
    converter: nUnitConverter.tFunction
  ) => {
    if (measuresRequest.isError) return null;
    if (measuresRequest.isSuccess) {
      const value = measuresRequest.data[property._id]?.[key] || 0;

      const converted = converter(value, { forceUnit: null });

      return (
        <InterTag
          text={`${numberFormatter(_round(converted.value, 2), profile)} ${converted.unit}`}
          color={COLORS.secondary}
          size={12}
        />
      );
    }
    return <SkeletonText width={"50%"} height={12} />;
  };

  const renderFootprint = (property: nPropertyContext.tPropertyAugmented) => {
    if (measuresRequest.isError || conversionFactorsRequest.isRejected) return null;
    if (measuresRequest.isSuccess && conversionFactorsRequest.isResolved) {
      const footprint =
        (measuresRequest.data[property._id]?.e || 0) *
          conversionFactorsRequest.data.electricity_kWh_to_kgCO2e +
        (measuresRequest.data[property._id]?.w || 0) *
          conversionFactorsRequest.data.water_m3_to_kgCO2e;

      const converted = convertMassUnit(footprint, { forceUnit: null });

      return (
        <InterTag
          text={`${numberFormatter(_round(converted.value, 2), profile)} ${converted.unit}`}
          color={COLORS.secondary}
          size={12}
        />
      );
    }
    return <SkeletonText width={"50%"} height={12} />;
  };
  return (
    <>
      {sorted.map((property) => {
        return (
          <div key={property._id} className={TABLE_ROW_CLASSNAMES}>
            <div className={`${COLUMNS[0].className}`}>
              <img src={property.images[0]} alt={property.name} className="size-12 rounded-md" />
            </div>
            <div className={`${COLUMNS[1].className}`}>
              <div className="overflow-ellipsis text-xs">{property.name}</div>
            </div>
            <div className={`${COLUMNS[2].className} flex flex-col justify-start gap-2`}>
              <InterTag text={property.location.county} color={COLORS.secondary} size={12} />
              <InterTag text={property.location.district} color={COLORS.primary} size={10} />
            </div>
            <div className={`${COLUMNS[3].className}`}>{renderFootprint(property)}</div>
            <div className={`${COLUMNS[4].className}`}>
              {renderValue(property, "e", convertEnergyUnit)}
            </div>
            <div className={`${COLUMNS[5].className}`}>
              {renderValue(property, "w", convertVolumeUnit)}
            </div>
            <div className="flex flex-row items-center flex-1 justify-end">
              <SimpleButton
                onClick={() => {
                  setActiveProperty(dispatch, property._id);
                  setTimeout(() => {
                    navigate("/overview");
                  }, 200);
                }}
                text={{ text: trans("general.select"), color: COLORS.primary }}
                appearance="ghost"
                size="xs"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const PropertiesWrappedV2: React.FC = () => {
  const { trans } = useLocalizationState();
  const {
    state: {
      data: { properties },
    },
  } = usePropertyState();

  return (
    <PageSectionBG title={trans("general.properties")}>
      <div>
        <TableHeaders columns={COLUMNS} />
        <div
          className={cx(
            "h-[calc(100vh-290px)] sm:max-h-[calc(100vh-200px)] sm:h-fit ",
            TABLE_ROWS_CONTAINER_CLASSNAMES
          )}
        >
          <Rows hotels={properties} />
        </div>
      </div>
    </PageSectionBG>
  );
};

const Properties: React.FC = () => {
  const { trans } = useLocalizationState();
  const {
    data: { profile },
  } = useContext(ProfileStateContext)!;
  const names = profile!.name.split(" ");

  return (
    <>
      <PageTitle
        page={`${trans("pages.properties.title", {
          parameters: [names.length > 1 ? names.slice(0, -1).join(" ") : names[0]],
        })} &#128075;`}
      />
      <PropertiesWrappedV2 />
    </>
  );
};

export default Properties;
