import { _isString } from "@/lodash-utils";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  tMeasure,
} from "@/models/measures";
import tProperty from "@/models/property";

export const propertyGetMeasuresTracked = (property: tProperty, spaceIds?: string | string[]) => {
  const spaceNames = _isString(spaceIds) ? [spaceIds] : spaceIds;
  const spaces = property
    ? spaceNames
      ? property.spaces.filter((r) => spaceNames.includes(r._id))
      : property.spaces
    : [];
  let measureIsMeasured: Record<tMeasure, boolean> = {
    te: false,
    es: false,
    el: false,
    ac: false,
    wh: false,
    tw: false,
    cw: false,
    hw: false,
    gw: false,
    dw: false,
    ntw: false,
  };

  spaces.forEach((space) => {
    Object.keys(space.measures).forEach((key) => {
      const measureName = key as tMeasure;
      measureIsMeasured[measureName] ||= space.measures[measureName].isMeasured;
    });
  });

  if (
    !measureIsMeasured.te &&
    MEASURES_ELECTRICITY_SPECIFIC.filter((m) => measureIsMeasured[m]).length > 1
  )
    measureIsMeasured.te = true;

  if (
    !measureIsMeasured.tw &&
    MEASURES_WATER_SPECIFIC.filter((m) => measureIsMeasured[m]).length > 1
  )
    measureIsMeasured.tw = true;

  return measureIsMeasured;
};

export const propertyGetBaseMeasuresTrackedV2 = (property: tProperty, spaceIds?: string[]) => {
  const _spaceIds = _isString(spaceIds) ? [spaceIds] : spaceIds;
  const spaces = property
    ? _spaceIds
      ? property.spaces.filter((r) => _spaceIds.includes(r._id))
      : property.spaces
    : [];

  const trackedMeasures = { e: false, w: false };
  for (const tm of spaces.flatMap((s) => s.consumptionMetadata.measures)) {
    if (tm.measure === "e") trackedMeasures.e = true;
    if (tm.measure === "w") trackedMeasures.w = true;

    if (trackedMeasures.e && trackedMeasures.w) break;
  }

  return trackedMeasures;
};
