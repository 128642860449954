import React from "react";
import HistoricDataConsumptionGraphSection from "@/components/HistoricDataConsumptionGraphSection";
import useGetDefaultConverionFactors from "@/hooks/useGetDefaultConverionFactors";
import Showcasing from "./showcasing";

interface iProps {
  selectedSection: number;
}

const HistoricData: React.FC<iProps> = ({ selectedSection }) => {
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {});

  switch (selectedSection) {
    case 0:
      return (
        <HistoricDataConsumptionGraphSection
          {...{ conversionFactorsRequest, measure: "electricity" }}
        />
      );
    case 1:
      return <Showcasing />;

    default:
      return null;
  }
};

export default HistoricData;
