import { useDataType } from "@/context/DataType";
import useLocalizationState from "@/context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "@/context/WindowSize/hooks/useWindowSizeState";
import { DATA_TYPE_TOGGLE_ARCHIVES, DATA_TYPE_TOGGLE_LIVE } from "@/utils/dataTypeToggle";
import React from "react";
import { Toggle, ToggleProps } from "rsuite";

interface iProps extends ToggleProps {}

const DataTypeToggle: React.FC<iProps> = ({ ...props }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const context = useDataType();
  const { setDataType } = useDataType();

  return (
    <Toggle
      size={isMobile ? "md" : "lg"}
      style={{ width: "fit-content" }}
      className={"toggle-data-type"}
      onChange={(checked) => {
        setDataType(checked ? DATA_TYPE_TOGGLE_LIVE : DATA_TYPE_TOGGLE_ARCHIVES);
      }}
      checked={context.dataTypeIsLive}
      checkedChildren={trans("Automático")}
      unCheckedChildren={trans("Manual")}
      {...{ ...props }}
    />
  );
};

export default DataTypeToggle;
